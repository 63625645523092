import React from 'react';
import {
    Button,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay
} from '@chakra-ui/react';

import ShareView from './Share.view';

type Props = {
    isOpen: boolean;
    onClose: () => void;
};

function ShareModal(props: Props) {
    const { isOpen, onClose } = props;

    return (
        <Modal
            // className="px-6 py-4 sm:w-[658px]"
            // fullscreen
            // closeOnClickOverlay
            isOpen={isOpen}
            onClose={onClose}
            size="6xl"
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <div className="flex flex-col gap-2">
                        <p className="px-2 text-sm font-bold text-[#7A7A7A]">Share : folder name</p>
                    </div>
                </ModalHeader>
                <ModalBody className="flex flex-1 flex-col overflow-y-auto p-0">
                    <ShareView onClose={onClose} />
                </ModalBody>
                <ModalFooter className="mt-3">
                    {/* <Button
                    outline
                    onClick={onClose}
                    className="px-4 text-neutral-400"
                >
                    <span className="px-[15px]">Cancel</span>
                </Button> */}
                    <Button
                        size="md"
                        colorScheme="primary"
                        onClick={onClose}
                        className="px-8"
                    >
                        Done
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default ShareModal;
