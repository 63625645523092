import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as Stores from '../../store/File';
import * as Models from '../../models/File';
import { InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import imgFile from '../../Images/Icon/icon-File.png';
import imgPDF from '../../Images/Icon/icon-PDF.png';
import imgXLS from '../../Images/Icon/icon-XLS.png';
import imgWord from '../../Images/Icon/icon-Word.png';
import imgPowerPoint from '../../Images/Icon/icon-PowerPoint.png';
import imgImage from '../../Images/Icon/icon-image.png';
import { Spinner } from 'reactstrap';
import queryString from 'query-string';

//fileComponent
import FilesComponent from './FilesComponent';

import { onClickTrWithOutNewWindow } from '../../modules/common';
import UploadFileComponent from './UploadFileComponent';
import ButtonCopyFileComponent from './ButtonCopyFileComponent';
import ButtonEditFileV2Component from './ButtonEditFileV2Component';
import ButtonDeleteFileComponent from './ButtonDeleteFileComponent';
import { convertMetadataToEntries, convertMetadataToString } from '../../modules/common';
import * as FolderRoleStore from '../../store/ui/Role';
import * as FolderAuthorizeUserMappingStore from '../../store/FolderAuthorizeUserMapping';
import { UserContext, IUserContext } from '../../context/UserProvider';
import { ResponseList } from '../../models/Common';

interface ComponentProps {
    [key: string]: any;
}

type Props = ComponentProps &
    typeof FolderAuthorizeUserMappingStore.actionCreators &
    FolderRoleStore.FolderRoleStoreState &
    typeof FolderRoleStore.actionCreators;

interface State {
    checkValueFile: boolean;
    pageLoad: boolean;
    key: string;
    listModelFile: Array<Models.File>;
    version: number;
    search: boolean;
    search_val: string;
    loadDataFrist: boolean;
    list_company: any;
    checkRefresh: boolean;
    reloadFolder: boolean;
    getValueEdit: boolean;
    btClick: string;
    modelListFileIsSelected: Array<Models.File>;
    isReLoadFile: boolean;
    folderSelectedFullPath: string;
    folderSelectedCode: string;
    folderSelectedName: string;
    metadataGroup: string;
}

class SerachFileComponent extends React.PureComponent<Props, State> {
    public static contextType = UserContext;

    public context!: React.ContextType<typeof UserContext>;

    constructor(props: any) {
        super(props);

        this.state = {
            pageLoad: true,
            key: '',
            search_val: '',
            listModelFile: [],
            version: 0,
            checkValueFile: false,
            search: false,
            loadDataFrist: true,
            list_company: [],
            checkRefresh: false,
            reloadFolder: false,
            btClick: '',
            modelListFileIsSelected: [],
            getValueEdit: false,
            isReLoadFile: false,
            folderSelectedCode: '',
            folderSelectedFullPath: 'All',
            folderSelectedName: 'All',
            metadataGroup: ''
        };

        this.handleChangeKey = this.handleChangeKey.bind(this);
        this.searchClick = this.searchClick.bind(this);
        this.searchEnter = this.searchEnter.bind(this);
        this.previewFile = this.previewFile.bind(this);
        this.removeSelectedFile = this.removeSelectedFile.bind(this);
    }

    public componentDidMount() {
        const { isLoadingEmployee, responseEmployee, user } = this.props;
        if (
            isLoadingEmployee &&
            responseEmployee.result_list !== undefined &&
            responseEmployee.result_list.length !== 0 &&
            this.state.list_company.length === 0
        ) {
            var list_company_user: any = [];
            for (var i = 0; i < responseEmployee.result_list.length; i++) {
                if (responseEmployee.result_list[i].id.toLowerCase() === user.toLowerCase()) {
                    var data = responseEmployee.result_list[i];
                    for (var j = 0; j < data.list_company.length; j++) {
                        list_company_user.push(data.list_company[j].sid);
                    }
                }
            }
            //console.log('list_company_user')
            //console.log(list_company_user)
            this.setState({ list_company: list_company_user });
            this.ensureDataFetched();
        }
    }

    componentDidUpdate(nextProps: any, nextState: any) {
        const { isLoadingEmployee, responseEmployee, user } = this.props;
        const { id: username }: IUserContext = this.context;
        let params: any = queryString.parse(this.props.location.search);

        if (
            params.search !== undefined &&
            this.state.list_company.length !== 0 &&
            username.length > 0
        ) {
            if (params.search !== this.state.search_val) {
                this.props.requestGetFolderAuthUserMapping({ app_id: '*', username });
                var searchKey = params.search;
                if (searchKey) {
                    searchKey = searchKey.replace(new RegExp(' AND ', 'g'), '* AND *');
                    searchKey = '*' + searchKey + '*';
                }
                //console.log(searchKey);
                this.props.requestFunction(
                    true,
                    searchKey,
                    true,
                    '',
                    this.state.version,
                    1,
                    25,
                    this.state.list_company
                );
                this.setState({ search_val: params.search, search: false });
            }
        }

        if (
            isLoadingEmployee &&
            responseEmployee.result_list !== undefined &&
            responseEmployee.result_list.length !== 0 &&
            this.state.list_company.length === 0
        ) {
            var list_company_user: any = [];
            for (var i = 0; i < responseEmployee.result_list.length; i++) {
                if (responseEmployee.result_list[i].id === user) {
                    var data = responseEmployee.result_list[i];
                    for (var j = 0; j < data.list_company.length; j++) {
                        list_company_user.push(data.list_company[j].sid);
                    }
                }
            }
            //console.log('list_company_user')
            //console.log(list_company_user)
            this.setState({
                list_company: list_company_user
            });
        }
    }

    private ensureDataFetched() {
        this.setState({ pageLoad: true });
        var search_data = '';
        let params: any = queryString.parse(this.props.location.search);
        console.log(params.search);
        if (params.search !== undefined) {
            search_data = params.search;
        }
        //this.props.requestFunction(this.state.pageLoad, "", true, this.state.version, 1, 25, this.state.list_company);
        //this.setState({ pageLoad: false });
        var searchKey = search_data.replace(new RegExp(' AND ', 'g'), '* AND *');
        if (searchKey) {
            searchKey = '*' + searchKey + '*';
        }
        //console.log(searchKey);
        this.props.requestFunction(this.state.pageLoad, searchKey, true, this.state.version, 1, 25);
        /*this.setState({ search_val: this.state.key, search: false });*/
        this.setState({ search_val: this.state.key, search: false, pageLoad: false });
    }

    private handleChangeKey(event: any) {
        this.setState({ key: event.target.value });
    }

    private searchClick(event: any) {
        var searchKey = this.state.key;
        //console.log(searchKey);
        this.props.requestFunction(
            true,
            searchKey,
            true,
            '',
            this.state.version,
            1,
            25,
            this.state.list_company
        );
        this.setState({ search_val: this.state.key, search: false });
    }

    private searchEnter(event: any) {
        if (event.key === 'Enter') {
            var searchKey = this.state.key;
            //console.log(searchKey);
            this.props.requestFunction(
                true,
                searchKey,
                true,
                '',
                this.state.version,
                1,
                25,
                this.state.list_company
            );
            this.setState({ search_val: this.state.key, search: false });
        }
    }

    private previewFile(item: Models.File) {
        if (
            item.file_name.indexOf('.jpg') !== -1 ||
            item.file_name.indexOf('.png') !== -1 ||
            item.file_name.indexOf('.JPG') !== -1 ||
            item.file_name.indexOf('.PNG') !== -1
        ) {
            return imgImage;
        } else if (item.file_name.indexOf('.pdf') !== -1) {
            return imgPDF;
        } else if (
            item.file_name.indexOf('.xls') !== -1 ||
            item.file_name.indexOf('.xlsx') !== -1
        ) {
            return imgXLS;
        } else if (item.file_name.indexOf('.docx') !== -1) {
            return imgWord;
        } else if (item.file_name.indexOf('.pptx') !== -1) {
            return imgPowerPoint;
        } else {
            return imgFile;
        }
    }

    resetLoading(value) {
        if (value === 'reset') {
            this.setState({ checkValueFile: false });
        }
    }

    // verbose
    reloading(value) {
        if (value === 'reset') {
            this.setState({
                checkRefresh: true,
                reloadFolder: true
            });
        }
    }

    resetReloadFolder(value) {
        if (value === 'reset') {
            this.setState({
                reloadFolder: false
            });
        }
    }

    resetGetValueEdit(val) {
        if (val === 'reset') {
            this.setState({ getValueEdit: false });
        }
    }

    onClickSaveBt(val) {
        if (val === 'copy') {
            this.setState({ btClick: 'copy' });
        } else if (val === 'edit') {
            this.setState({ btClick: 'edit' });
        }
    }

    public forceRerender(): void {
        this.props.requestFunction(
            true,
            this.state.search_val,
            true,
            '',
            this.state.version,
            1,
            25,
            this.state.list_company
        );
        // this.forceUpdate();
        // setTimeout(() => {
        //     this.setState({ reRenderKey: Math.floor(Math.random() * (100000 - 0) + 0) });
        // }, 1000);
    }

    public onMetadataChange(atFileIndex: number, atMetadataIndex: number) {
        return (key: string, value: string) => {
            // console.log('ngame key/value change', [key, value]);
            this.setState(prevState => {
                const nextSelectedFileState = [...prevState.modelListFileIsSelected];
                const metadataEntries = convertMetadataToEntries(
                    nextSelectedFileState[atFileIndex].metadata
                );
                const metadataTarget = metadataEntries[atMetadataIndex];
                metadataTarget[0] = key;
                metadataTarget[1] = value;

                nextSelectedFileState[atFileIndex].metadata =
                    convertMetadataToString(metadataEntries);

                return {
                    ...prevState,
                    modelListFileIsSelected: nextSelectedFileState
                };
            });
        };
    }

    public addMetadata(atFileIndex: number): void {
        this.setState(prevState => {
            const nextSelectedFileState = [...prevState.modelListFileIsSelected];
            const metadataEntries = convertMetadataToEntries(
                nextSelectedFileState[atFileIndex].metadata
            );
            metadataEntries.push(['', '']);

            nextSelectedFileState[atFileIndex].metadata = convertMetadataToString(metadataEntries);

            return {
                ...prevState,
                modelListFileIsSelected: nextSelectedFileState
            };
        });
    }

    public removeMetadata(atFileIndex: number, removeAtIndex: number): void {
        this.setState(prevState => {
            const nextSelectedFileState = [...prevState.modelListFileIsSelected];
            const metadataEntries = convertMetadataToEntries(
                nextSelectedFileState[atFileIndex].metadata
            );
            metadataEntries.splice(removeAtIndex, 1);

            nextSelectedFileState[atFileIndex].metadata = convertMetadataToString(metadataEntries);

            return {
                ...prevState,
                modelListFileIsSelected: nextSelectedFileState
            };
        });
    }

    public onUploadSuccess() {
        this.setState({ isReLoadFile: true });
        setTimeout(() => {
            this.setState({ isReLoadFile: false });
        }, 100);
    }

    getValueFileList = (check?: string) => {
        var node = 'node';
        var wfList: any[] = [];
        Array.from(document.querySelectorAll('input[name=' + node + ']')).forEach(checkbox => {
            var cb = document.getElementById(checkbox.id) as HTMLInputElement;
            if (cb.checked && !cb.disabled) {
                if (cb.dataset.filelist) {
                    var filelist = JSON.parse(cb.dataset.filelist);
                    wfList.push(filelist);
                }
            }
        });

        this.setState({ modelListFileIsSelected: wfList, getValueEdit: true });
        this.props.calculateRoles(wfList.map(wf => wf.roles));
    };

    public onEditSuccess() {
        this.setState({ isReLoadFile: true });
        setTimeout(() => {
            this.setState({ isReLoadFile: false });
        }, 100);
    }

    GetDataSelectFileToButton = () => {
        return this.state.modelListFileIsSelected;
    };

    public handleChangeSelectedFiles(
        key: keyof Pick<Models.File, 'group_id' | 'bucket'>,
        atIndex: number
    ) {
        return (event: React.ChangeEvent<HTMLInputElement>) => {
            event.persist();
            const value = event.target.value;

            this.setState(prevState => {
                const selectedFiles = [...prevState.modelListFileIsSelected];
                selectedFiles[atIndex][key] = value;

                return {
                    ...prevState,
                    modelListFileIsSelected: selectedFiles
                };
            });
        };
    }

    public handleCreatableSelectChange(
        key: keyof Pick<Models.File, 'index_string' | 'metadata'>,
        atIndex: number
    ) {
        return (
            newValue: { label: string; value: string; __isNew__: boolean }[],
            _actionMeta: unknown
        ) => {
            this.setState(prevState => {
                const selectedFiles = [...prevState.modelListFileIsSelected];

                if (key === 'index_string') {
                    selectedFiles[atIndex][key] = newValue.map(value => value.value).join('|');
                } else if (key === 'metadata') {
                    // under-implement
                }

                return {
                    ...prevState,
                    modelListFileIsSelected: selectedFiles
                };
            });
        };
    }

    public changeSelectedFiles(files: Models.File[]) {
        this.setState({ modelListFileIsSelected: files });
    }

    public removeSelectedFile(atIndex: number) {
        this.setState(prevState => {
            const nextSelectedFiles = [...prevState.modelListFileIsSelected];
            nextSelectedFiles.splice(atIndex, 1);

            return {
                ...prevState,
                modelListFileIsSelected: nextSelectedFiles
            };
        });
    }
    // end

    render() {
        const {
            isReLoadFile,
            folderSelectedFullPath,
            folderSelectedCode,
            folderSelectedName,
            metadataGroup,
            modelListFileIsSelected,
            btClick,
            getValueEdit
        } = this.state;

        if (this.props.version > this.state.version) {
            if (
                this.props.version - this.state.version === 1 &&
                this.state.loadDataFrist !== true
            ) {
                this.setState({ listModelFile: [] });
            } else if (this.props.version - this.state.version === 1 && this.state.loadDataFrist) {
                this.setState({
                    listModelFile:
                        this.props.response === undefined || this.props.response === null
                            ? []
                            : (
                                  (this.props.response.result_list as Array<Models.File>) ?? []
                              ).filter(file => (file.full_path?.length || 0) > 0),
                    checkValueFile: true,
                    search: true
                });
            } else if (this.props.version - this.state.version === 3) {
                this.setState({
                    listModelFile:
                        this.props.response === undefined || this.props.response === null
                            ? []
                            : (
                                  (this.props.response.result_list as Array<Models.File>) ?? []
                              ).filter(file => (file.full_path?.length || 0) > 0),
                    checkValueFile: true,
                    search: true
                });
            }

            this.setState({ version: parseInt(this.props.version) });
        }

        return (
            <div className="div-search-main">
                <div className="d-flex justify-content-end">
                    {/* <UploadFileComponent
                        // disabled={this.state.disabledUpload}
                        disabled={this.props.disabledUpload}
                        reloading={this.reloading.bind(this)}
                        onUploadSuccess={this.onUploadSuccess.bind(this)}
                        isReLoadFile={isReLoadFile}
                        folderSelectedFullPath={folderSelectedFullPath}
                        folderSelectedCode={folderSelectedCode}
                        folderSelectedName={folderSelectedName}
                        metadataGroup={metadataGroup}
                    /> */}
                    <ButtonCopyFileComponent
                        // disabled={(strictMode) ? !(roles.includes('02') || roles.includes('05')) : false}
                        // disabled={this.state.disabledCopy}
                        disabled={this.props.disabledCopy}
                        getValueFileList={this.getValueFileList.bind(this)}
                        onEditSuccess={this.onEditSuccess.bind(this)}
                        reloading={this.reloading.bind(this)}
                        folderSelectedCode={folderSelectedCode}
                        GetDataSelectFileToButton={this.GetDataSelectFileToButton.bind(this)} // Deprecated (messy, verbose)
                        selectedFiles={modelListFileIsSelected}
                        onClickSaveBt={this.onClickSaveBt.bind(this)}
                        btClick={btClick}
                        forceRerender={this.forceRerender.bind(this)}
                        handleChangeSelectedFiles={this.handleChangeSelectedFiles.bind(this)}
                        handleCreatableSelectChange={this.handleCreatableSelectChange.bind(this)}
                        onMetadataChange={this.onMetadataChange.bind(this)}
                        addMetadata={this.addMetadata.bind(this)}
                        removeMetadata={this.removeMetadata.bind(this)}
                        setFileExplorerState={this.setState.bind(this)}
                        changeSelectedFiles={this.changeSelectedFiles.bind(this)}
                        removeSelectedFile={this.removeSelectedFile}
                    />
                    <ButtonEditFileV2Component
                        // disabled={(strictMode) ? !(roles.includes('02')) : false}
                        // disabled={this.state.disabledEdit}
                        disabledEdit={this.props.disabledEdit}
                        disabledMoveLink={this.props.disabledMoveLink}
                        disabledUpdateBoxId={this.props.disabledUpdateBoxId}
                        resetGetValueEdit={this.resetGetValueEdit.bind(this)}
                        getValueEdit={getValueEdit}
                        getValueFileList={this.getValueFileList.bind(this)}
                        onEditSuccess={this.onEditSuccess.bind(this)}
                        reloading={this.reloading.bind(this)}
                        folderSelectedCode={folderSelectedCode}
                        GetDataSelectFileToButton={this.GetDataSelectFileToButton.bind(this)}
                        onClickSaveBt={this.onClickSaveBt.bind(this)}
                        btClick={btClick}
                        forceRerender={this.forceRerender.bind(this)}
                    />
                    <ButtonDeleteFileComponent
                        // disabled={(strictMode) ? !(roles.includes('02') || roles.includes('04')) : false}
                        // disabled={this.state.disabledDelete}
                        disabled={this.props.disabledDelete}
                        reloading={this.reloading.bind(this)}
                        folderSelectedCode={folderSelectedCode}
                        getValueFileList={this.getValueFileList.bind(this)}
                        GetDataSelectFileToButton={this.GetDataSelectFileToButton.bind(this)}
                        isReLoadFile={isReLoadFile}
                        removeSelectedFile={this.removeSelectedFile}
                        selectedFiles={this.state.modelListFileIsSelected}
                    />
                </div>
                {/*<InputGroup>*/}
                {/*    <Input placeholder="search..." type="text" value={this.state.key} onChange={this.handleChangeKey} onKeyPress={this.searchEnter} />*/}
                {/*    <InputGroupAddon addonType="append">*/}
                {/*        <InputGroupText onClick={this.searchClick}><i className="fa fa-search" aria-hidden="true"></i></InputGroupText>*/}
                {/*    </InputGroupAddon>*/}
                {/*</InputGroup>*/}
                <hr />
                <div className="div-list-file-search">
                    {this.state.listModelFile !== null &&
                    this.state.listModelFile.length > 0 &&
                    this.state.search === true ? (
                        <FilesComponent
                            resetLoading={this.resetLoading.bind(this)}
                            checkValueFile={this.state.checkValueFile}
                            valueModelFile={this.state.listModelFile}
                            SearchData={this.state.search_val}
                            getValueFileList={this.getValueFileList.bind(this)}
                        />
                    ) : (
                        <React.Fragment>
                            {this.state.search === true &&
                                this.state.checkValueFile === true &&
                                this.state.listModelFile === null && (
                                    <div style={{ marginLeft: '2%' }}>
                                        <p style={{ paddingTop: '.33em', fontSize: '18px' }}>
                                            การค้นหาของคุณ -{' '}
                                            <span style={{ color: 'red' }}>
                                                {' '}
                                                <em>{this.state.search_val}</em>
                                            </span>{' '}
                                            ไม่ตรงกับเอกสารใดๆ
                                        </p>
                                        <p style={{ marginTop: '1em', fontSize: '18px' }}>
                                            คำแนะนำ:
                                        </p>
                                        <ul style={{ marginLeft: '1.3em', marginBottom: '2em' }}>
                                            <li>ตรวจดูให้แน่ใจว่าสะกดถูกต้องทุกคำ</li>
                                            <li>ลองใช้คีย์เวิร์ดอื่นๆ</li>
                                            <li>ลองใช้คีย์เวิร์ดที่กว้างขึ้น</li>
                                        </ul>
                                    </div>
                                )}
                        </React.Fragment>
                    )}
                </div>
                {this.state.search === false && this.state.search_val !== '' ? (
                    <table className="custom-table table-hover table">
                        <tbody>
                            <tr>
                                <td
                                    colSpan={9}
                                    style={{
                                        background: '#FFFFFF',
                                        color: 'black',
                                        fontSize: '10pt',
                                        textAlign: 'center',
                                        padding: '8px 12px 3px 12px'
                                    }}
                                >
                                    <Spinner color="primary" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                ) : null}
            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => ({
        ...state.file,
        ...state.CheckCompanyUser,
        ...state.folderAuthorizeUserMapping,
        ...state.folderRole
    }),
    {
        ...Stores.actionCreators,
        ...FolderAuthorizeUserMappingStore.actionCreators,
        ...FolderRoleStore.actionCreators
    }
)(SerachFileComponent);
