import { requestEnv } from 'data/datasources/app-service/app.datasource';
import { AccessTokenPayload } from 'models/domain/Authentication';

export const validateNumberOnly = (str: string) => {
    const regex = new RegExp('^[0-9]+$');

    return regex.test(str);
};

export const isNumeric = (str: string) => {
    return !isNaN(parseFloat(str));
};

export function parseJwt(token: string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map(char => '%' + ('00' + char.charCodeAt(0).toString(16)).slice(-2))
            .join('')
    );

    return JSON.parse(jsonPayload) as AccessTokenPayload;
}

export function isTokenExpired(payload: AccessTokenPayload) {
    const exp = new Date(payload.exp * 1000);
    const now = new Date(Date.now());

    return exp < now;
}

export async function redirectToAppPool() {
    const data = (await requestEnv()).data;

    window.open(data.appPoolAPI, '_self');
}
