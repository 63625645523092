import axios from 'axios';

import { ResponseList, ResponsePagination } from 'models/domain/Response';
import { UserRole } from 'models/domain/Admin';
import { EmployeeMain } from 'models/domain/Employee';

export function requestUserRole(citizenId: string) {
    return axios.get<ResponseList<UserRole>>('/v1/admin', {
        params: {
            citizen_id: citizenId
        }
    });
}

export function requestEmployee(citizenId: string) {
    return axios.get<ResponsePagination<EmployeeMain>>('/v1/user', {
        params: {
            id: citizenId,
            mode: 'CheckUser'
        }
    });
}
