import React from 'react';
import { Link } from 'react-router-dom';
import {
    Button,
    Divider,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerProps
} from '@chakra-ui/react';
import { IoSearchSharp } from 'react-icons/io5';

import { MENU_GROUPS } from './menus';

type OwnProps = {
    username?: string;
    userRole?: string;
    onClickOpenSearchDialog?: () => void;
};

type Props = Pick<DrawerProps, 'isOpen' | 'onClose'> & OwnProps;

function MenuDrawer(props: Props) {
    const { username, userRole, isOpen, onClose, onClickOpenSearchDialog } = props;
    const enabledAdminMenus = userRole === 'admin' || userRole === 'super_admin';

    return (
        <Drawer
            size="xs"
            isOpen={isOpen}
            onClose={onClose}
        >
            <DrawerOverlay />
            <DrawerContent className="bg-secondary-900 text-white">
                <DrawerCloseButton />
                <DrawerHeader>{username}</DrawerHeader>
                <DrawerBody>
                    <Button
                        leftIcon={<IoSearchSharp />}
                        size="md"
                        className="flex w-full justify-start bg-secondary-700 text-secondary-100 focus:focused-outline sm:hidden"
                        onClick={onClickOpenSearchDialog}
                    >
                        Search files
                    </Button>
                    {enabledAdminMenus && (
                        <div className="mt-4 sm:mt-0">
                            {MENU_GROUPS.reduce<JSX.Element[]>(
                                (accumulate, group) =>
                                    accumulate.concat(
                                        !group.allowed.includes(userRole)
                                            ? []
                                            : group.menus.map(menu => (
                                                  <React.Fragment key={menu.label}>
                                                      <Button
                                                          as={Link}
                                                          to={menu.to}
                                                          variant="ghost"
                                                          color="white"
                                                          className="flex items-center justify-start rounded-none no-underline hover:bg-secondary-700 hover:text-white"
                                                      >
                                                          {menu.label}
                                                      </Button>
                                                      <Divider />
                                                  </React.Fragment>
                                              ))
                                    ),
                                []
                            )}
                        </div>
                    )}
                </DrawerBody>
                <DrawerFooter>
                    <Button
                        as={Link}
                        to="/auth/signout"
                        colorScheme="gray"
                        className="w-full no-underline"
                    >
                        Back to Portal
                    </Button>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    );
}

export default MenuDrawer;
