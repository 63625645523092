import * as React from 'react';

import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as Stores from '../../store/SignIn';
import * as Models from '../../models/SignIn';

type Store = Models.SignInState & typeof Stores.actionCreators;

class LoginButtonComponent extends React.Component<any, { isLoading: boolean, isLoginSuccess: boolean }, Store> {
    constructor(props: any) {
        super(props)
        this.state = {
            isLoading: false,
            isLoginSuccess:false
        }

        this.onSubmit = this.onSubmit.bind(this);
    }

    private onSubmit() {
        this.setState({
            isLoading: true
        })
        const { username, password } = this.props.loginObject;
        console.log("1. Submit username : " + username + " and password : " + password)
        this.props.requestFunction(username, password);
    }

    render() {
        let { isLoading } = this.state;
        console.log('prop isLoginSuccess ', this.props.isLoginSuccess)
        if (this.props.isLoginSuccess) {
            this.props.onLoginSuccess();
        }
        return (
            <button type="submit" className="btn btn-primary btn-block" onClick={this.onSubmit} disabled={isLoading}>
                {isLoading && (
                    <i
                        className="fa fa-refresh fa-spin"
                        style={{ marginRight: "5px" }}
                    />
                )}
                {isLoading && <span>Loading Data from Server</span>}
                {!isLoading && <span>Submit</span>}
                </button>
        );
    }
}
export default connect(
    (state: ApplicationState) => state.signIn,
    Stores.actionCreators
)(LoginButtonComponent);