import * as React from 'react';
import LoginButtonComponent from './LoginButtonComponent';
import { Redirect } from 'react-router-dom'

class SignInComponent extends React.Component<any, { username: string, password: string, currentUser: any, message: string,isLoginSuccess:boolean }> {
    constructor(props: any) {
        super(props)

        this.state = {
            username: '',
            password: '',
            currentUser: null,
            message: '',
            isLoginSuccess: false
        }

        this.onChangeUserName = this.onChangeUserName.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.onLoginSuccess = this.onLoginSuccess.bind(this);
    }

    private onChangeUserName(e:any) {
        const { name, value } = e.target
        //console.log('username:' + value)
        this.setState({
            username: value
        })
    }
    
    private onChangePassword(e: any) {
        const { name, value } = e.target
        //console.log('password:' + value)
        this.setState({
            password: value
        })
    }

    public onLoginSuccess() {
        this.setState({ isLoginSuccess: true });
    }

    render() {
        let loginObject = this.state;
        if (this.state.isLoginSuccess) {
            return <Redirect to='/' />
        }

        return (
            <form>
                <div className="row justify-content-center" style={{ marginTop:"100px" }}>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <h3>Sign In</h3>

                        <div className="form-group">
                            <label>Username</label>
                            <input type="text" className="form-control" placeholder="Enter username" name="username" onChange={this.onChangeUserName} />
                        </div>

                        <div className="form-group">
                            <label>Password</label>
                            <input type="password" className="form-control" placeholder="Enter password" name="password" onChange={this.onChangePassword} />
                        </div>

                        <div className="form-group">
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id="customCheck1" />
                                <label className="custom-control-label" htmlFor="customCheck1">Remember me</label>
                            </div>
                        </div>

                        <LoginButtonComponent loginObject={loginObject} onLoginSuccess={this.onLoginSuccess.bind(this)} />
                        <p className="forgot-password text-right">
                            Forgot <a href="#">password?</a>
                        </p>

                    </div>
                </div>
            </form>

        );
    }
}

export default SignInComponent