import React from 'react';
import { connect } from 'react-redux';
import { Badge, Button, Card, Col, Form, Row, Spinner, Table } from 'react-bootstrap';
import CreatableSelect from 'react-select/creatable';
import { FcFolder } from 'react-icons/fc';
import Swal from 'sweetalert2';

import { ApplicationState } from '../../../store';
import * as AuthorizeStore from '../../../store/Authorize';
import { AuthorizeStateStore, DisplayAuthorize } from '../../../models/Authorize';
import * as FolderAuthorizeStore from '../../../store/FolderAuthorize';
import { FolderAuthorizeStateStore } from '../../../models/FolderAuthorize';
import * as EmployeeMasterStore from '../../../store/EmployeeMaster';

import FoldersComponent from '../../../common/components/FolderManagement/FoldersComponent';
import { EmployeeState, DisplayEmployee } from '../../../models/Employee';
import { withRemounter, WithRemounterProps } from '../../common/hoc/withRemounter';

interface OwnProps {
    authorize: AuthorizeStateStore;
    folderAuthorize: FolderAuthorizeStateStore;
    employee: EmployeeState;
}

type Props = OwnProps &
    typeof AuthorizeStore.actionCreators &
    typeof FolderAuthorizeStore.actionCreators &
    typeof EmployeeMasterStore.actionCreators &
    WithRemounterProps;

interface State {
    node_name: string;
    node_code: string;
    role_id: string;
    auth_ids: string;
    username: string[];
}

class FolderAuthorize extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            node_name: '',
            node_code: '',
            role_id: '',
            auth_ids: '',
            username: []
        };

        this.handleChangeState = this.handleChangeState.bind(this);
        this.handleSelectFolder = this.handleSelectFolder.bind(this);
        this.handleSelectUser = this.handleSelectUser.bind(this);
        this.handleSelectRoleAccess = this.handleSelectRoleAccess.bind(this);
    }

    public componentDidMount(): void {
        this.props.requestGetAuthorize();
        this.props.requestFunction(
            0,
            'GET',
            { id: '', list_company: [] },
            false,
            null,
            '',
            '',
            '',
            '',
            ''
        );
    }

    public handleSelectFolder(
        _fullPath: string,
        nodeCode: string,
        nodeName: string,
        _metadata: string
    ): void {
        this.setState({ node_code: nodeCode, node_name: nodeName });
        this.props.requestGetFolderAuthorize({ app_id: '*' });
    }

    public handleSelectUser(employee: DisplayEmployee[]): void {
        //console.log("username =", employee)

        const userList = employee.map(user => user.value);
        //const userList = ["ngame"];   // For test
        this.setState({ username: userList });
    }

    public handleSelectRoleAccess(authIdsArr: DisplayAuthorize[]): void {
        //console.log("handleSelectRoleAccess : authIds =", authIdsArr)

        const authIds = authIdsArr.map(auth => auth.value).join('|');
        this.setState({ auth_ids: authIds });
    }

    public saveFolderAuthorize(
        nodeCode: string,
        authIds: string,
        employee: string[],
        roleId: string
    ) {
        const reqBody = {
            node_code: nodeCode,
            auth_ids: authIds,
            username: employee,
            role_id: roleId,
            is_active: true
        };
        const sid: string = '*';
        this.props.requestPostFolderAuthorize(sid, reqBody, error => {
            if (error) {
                // On failure
                //console.log("[Debug 1] error =", error);
                const { message } = error;
                Swal.fire({
                    title: 'ไม่สำเร็จ!',
                    text: message,
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'ตกลง'
                }).then(_value => this.props.remount());
                return;
            }

            // On success
            Swal.fire({
                title: 'Success',
                icon: 'success'
            }).then(_value => this.props.remount());
            return;
        });
    }

    public handleChangeState(
        key: keyof Pick<State, 'node_code' | 'role_id'>
    ): React.ChangeEventHandler<HTMLInputElement> {
        return event => {
            event.persist();
            this.setState(prevState => ({ ...prevState, [key]: event.target.value }));
        };
    }

    public render(): React.ReactNode {
        const { node_code, node_name, role_id, auth_ids, username } = this.state;
        const folderAuthorize = this.props.folderAuthorize;
        const { displayAuthorize } = this.props.authorize;

        return (
            <React.Fragment>
                <Row>
                    <Col>
                        <div
                            className="trapezoid"
                            style={{ width: 'auto', display: 'inline-block' }}
                        >
                            <span className="employee-font font-size-employee">
                                Folder Authorize
                            </span>
                        </div>
                    </Col>
                    <Col className="d-flex justify-content-end align-items-center">
                        <Button
                            size="sm"
                            onClick={() =>
                                this.saveFolderAuthorize(node_code, auth_ids, username, role_id)
                            }
                        >
                            Add
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Card.Body className="mat-box shadow">
                            <Row>
                                <Col md={3}>
                                    <Row>
                                        <Col
                                            xs={6}
                                            className="mb-3"
                                        >
                                            <div>
                                                <h6>Select User</h6>
                                            </div>
                                        </Col>
                                        <Col xs={12}>
                                            <Form.Group
                                                className="mb-3"
                                                controlId="employee"
                                            >
                                                <CreatableSelect
                                                    menuPortalTarget={document.body}
                                                    styles={{
                                                        menuPortal: base => ({
                                                            ...base,
                                                            zIndex: 9999
                                                        })
                                                    }}
                                                    isMulti
                                                    isClearable
                                                    onChange={employee =>
                                                        this.handleSelectUser(employee)
                                                    }
                                                    options={this.props.employee.displayEmployee}
                                                    isValidNewOption={() => false}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={5}>
                                    <div>
                                        <h6>Select Folder</h6>
                                    </div>
                                    <FoldersComponent onSelectFolder={this.handleSelectFolder} />
                                </Col>
                                <Col md={4}>
                                    <Row>
                                        <Col
                                            xs={6}
                                            className="mb-3"
                                        >
                                            <div>
                                                <h6>Folder Name</h6>
                                            </div>
                                            <div
                                                className="d-flex align-items-center border-darken-1 rounded-sm border px-4 py-2"
                                                style={{ gap: '8px' }}
                                            >
                                                <FcFolder
                                                    style={{ width: '30px', height: '30px' }}
                                                />
                                                <span>{node_name || 'Please Select Folder'}</span>
                                                {/*<Badge variant="primary">New</Badge>*/}
                                            </div>
                                        </Col>
                                        {/*<Col xs={12} className="mb-3">*/}
                                        {/*    <div>*/}
                                        {/*        <h6>Role Access</h6>*/}
                                        {/*    </div>*/}
                                        {/*    <div>*/}
                                        {/*        <Col sm={10}>*/}
                                        {/*            {*/}
                                        {/*                (folderAuthorize.isLoading)*/}
                                        {/*                    ? (*/}
                                        {/*                        <Label>*/}
                                        {/*                            <Spinner color="dark" size="sm" animation={'border'} /> Loading...*/}
                                        {/*                        </Label>*/}
                                        {/*                    )*/}
                                        {/*                    : (!folderAuthorize.isLoading && folderAuthorize.response !== null && folderAuthorize.response.total_count === 0)*/}
                                        {/*                        ? (*/}
                                        {/*                            <Label>No Data</Label>*/}
                                        {/*                        )*/}
                                        {/*                        : (*/}
                                        {/*                            !folderAuthorize.isLoading*/}
                                        {/*                            && folderAuthorize.response !== null*/}
                                        {/*                            && folderAuthorize.response.result_list !== null*/}
                                        {/*                            && folderAuthorize.response.total_count > 0*/}
                                        {/*                            && folderAuthorize.response.result_list.filter(folder =>*/}
                                        {/*                                folder.node_code === this.state.node_code).map((node, index) => (*/}
                                        {/*                                    <FormGroup check key={`radio-${index + 1}`}>*/}
                                        {/*                                        <Input*/}
                                        {/*                                            name="radio2"*/}
                                        {/*                                            type="radio"*/}
                                        {/*                                        />*/}
                                        {/*                                        {' '}*/}
                                        {/*                                        <Label>*/}
                                        {/*                                            {*/}
                                        {/*                                                node.auths*/}
                                        {/*                                                    .split('|')*/}
                                        {/*                                                    .map((role, subIndex) =>*/}
                                        {/*                                                        <Badge key={`radio-badge-${index + 1}-${subIndex + 1}`} variant="primary">*/}
                                        {/*                                                            {role}*/}
                                        {/*                                                        </Badge>*/}
                                        {/*                                                    )*/}
                                        {/*                                            }*/}
                                        {/*                                        </Label>*/}
                                        {/*                                    </FormGroup>*/}
                                        {/*                                ))*/}
                                        {/*                        )*/}
                                        {/*            }*/}
                                        {/*        </Col>*/}
                                        {/*    </div>*/}
                                        {/*</Col>*/}
                                        <Col
                                            xs={12}
                                            className="mb-3"
                                        >
                                            <div>
                                                <h6>New Role Access</h6>
                                            </div>
                                            <div>
                                                <Form.Group
                                                    className="mb-3"
                                                    controlId="roleAccess"
                                                >
                                                    <CreatableSelect
                                                        menuPortalTarget={document.body}
                                                        styles={{
                                                            menuPortal: base => ({
                                                                ...base,
                                                                zIndex: 9999
                                                            })
                                                        }}
                                                        isMulti
                                                        isClearable
                                                        onChange={this.handleSelectRoleAccess}
                                                        options={displayAuthorize}
                                                    />
                                                </Form.Group>
                                            </div>
                                        </Col>

                                        {/*<Col xs={12}>*/}
                                        {/*    <Form.Group className="mb-3" controlId="roleAccess">*/}
                                        {/*        <Form.Label>Role ID :</Form.Label>*/}
                                        {/*        <Form.Control*/}
                                        {/*            type="text"*/}
                                        {/*            placeholder="Role ID"*/}
                                        {/*            value={role_id}*/}
                                        {/*            onChange={this.handleChangeState('role_id')}*/}
                                        {/*        />*/}
                                        {/*    </Form.Group>*/}
                                        {/*</Col>*/}
                                        {/*<Col xs={12}>*/}
                                        {/*    <Form.Group className="mb-3" controlId="roleAccess">*/}
                                        {/*        <Form.Label>Role Access :</Form.Label>*/}
                                        {/*        <CreatableSelect*/}
                                        {/*            menuPortalTarget={document.body}*/}
                                        {/*            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}*/}
                                        {/*            isMulti*/}
                                        {/*            isClearable*/}
                                        {/*            onChange={() => { }}*/}
                                        {/*            options={displayAuthorize}*/}
                                        {/*        />*/}
                                        {/*    </Form.Group>*/}
                                        {/*</Col>*/}
                                    </Row>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Table
                            className="table-bordered employee-font font-size-employee align-middle"
                            size="sm"
                        >
                            <thead>
                                <tr
                                    style={{ width: '100%' }}
                                    className="table-active thead-light text-center"
                                >
                                    <th></th>
                                    <th
                                        className="td-menu"
                                        style={{
                                            width: '7%',
                                            top: -1,
                                            position: 'sticky',
                                            zIndex: 1
                                        }}
                                    >
                                        SID
                                    </th>
                                    <th
                                        style={{
                                            width: '30%',
                                            position: 'sticky',
                                            top: -1,
                                            zIndex: 1
                                        }}
                                        className="td-id"
                                    >
                                        Folder Code
                                    </th>
                                    <th
                                        style={{
                                            width: '30%',
                                            position: 'sticky',
                                            top: -1,
                                            zIndex: 1
                                        }}
                                        className="td-id"
                                    >
                                        Role ID
                                    </th>
                                    <th
                                        className="td-role-access"
                                        style={{
                                            width: '30%',
                                            top: -1,
                                            position: 'sticky',
                                            zIndex: 1
                                        }}
                                    >
                                        Role Access
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {folderAuthorize.isLoading ? (
                                    <tr>
                                        <th
                                            className="td-code text-center"
                                            colSpan={9}
                                        >
                                            <Spinner
                                                color="dark"
                                                size="sm"
                                                animation={'border'}
                                            />{' '}
                                            Loading...
                                        </th>
                                    </tr>
                                ) : !folderAuthorize.isLoading &&
                                  folderAuthorize.response !== null &&
                                  folderAuthorize.response.total_count === 0 ? (
                                    <tr>
                                        <th
                                            className="td-code text-center"
                                            colSpan={9}
                                        >
                                            No Data
                                        </th>
                                    </tr>
                                ) : (
                                    !folderAuthorize.isLoading &&
                                    folderAuthorize.response !== null &&
                                    folderAuthorize.response.result_list !== null &&
                                    folderAuthorize.response.total_count > 0 &&
                                    folderAuthorize.response.result_list.map((folder, index) => (
                                        <tr key={`table-row-${index + 1}`}>
                                            <td></td>
                                            <td>{folder.sid}</td>
                                            <td>{folder.node_code}</td>
                                            <td>{folder.role_id}</td>
                                            <td>
                                                <div
                                                    className="d-flex"
                                                    style={{ gap: '4px' }}
                                                >
                                                    {folder.auths
                                                        .split('|')
                                                        .map((role, subIndex) => (
                                                            <Badge
                                                                key={`table-cell-badge-${
                                                                    index + 1
                                                                }-${subIndex + 1}`}
                                                                variant="primary"
                                                            >
                                                                {role}
                                                            </Badge>
                                                        ))}
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

export default connect(
    (state: ApplicationState, ownProps) => ({
        ...ownProps,
        authorize: state.authorize,
        folderAuthorize: state.folderAuthorize,
        employee: state.employee
    }),
    {
        ...AuthorizeStore.actionCreators,
        ...FolderAuthorizeStore.actionCreators,
        ...EmployeeMasterStore.actionCreators
    }
)(withRemounter(FolderAuthorize) as any);
