import axios from 'axios';

import { FileLogResponse } from '../schemas/fileLog.schema';

export async function requestGetFileLog(params: { p_id: string }) {
    return axios.get<FileLogResponse>('/v1/log', {
        params,
        headers: {
            Authorization: `Bearer ${localStorage.getItem('DMS_APPLICATION')}`,
            'Content-Type': 'application/json'
        }
    });
}
