import { Reducer } from 'redux';
import { AppThunkAction } from '.';
import { ResponseList, StateStore } from '../models/Common';
import { FolderAuthorize, FolderAuthorizeStateStore } from '../models/FolderAuthorize';

interface RequestFolderAuthorize {
    type: 'REQUEST_FOLDER_AUTHORIZE';
}

interface ReceiveFolderAuthorize {
    type: 'RECEIVE_FOLDER_AUTHORIZE';
    data: ResponseList<FolderAuthorize>;
}

interface FailureFolderAuthorize {
    type: 'FAILURE_FOLDER_AUTHORIZE';
    error: any;
}

interface ClearFolderAuthorize {
    type: 'CLEAR_FOLDER_AUTHORIZE';
}

interface Params {
    app_id: string;
    node?: string;
    role?: string;
    auth?: string;
}

interface RequestBody {
    node_code: string,
    role_id?: string,
    auth_ids: string,
    username: string[],
    is_active?: boolean,
}

type KnownAction =
    | RequestFolderAuthorize
    | ReceiveFolderAuthorize
    | FailureFolderAuthorize
    | ClearFolderAuthorize;

export const actionCreators = {
    requestGetFolderAuthorize: (params: Params): AppThunkAction<KnownAction> => (dispatch, _getState) => {
        dispatch({ type: 'CLEAR_FOLDER_AUTHORIZE' });
        dispatch({ type: 'REQUEST_FOLDER_AUTHORIZE' });

        fetch('/v1/folder-auth?' + new URLSearchParams(params as any), {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('DMS_APPLICATION')
            }
        })
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    return Promise.reject(response.json());
                }
            })
            .then(data => {
                // console.log('ngame', data)

                dispatch({ type: 'RECEIVE_FOLDER_AUTHORIZE', data });
            })
            .catch(error => dispatch({ type: 'FAILURE_FOLDER_AUTHORIZE', error }));
    },

    requestPostFolderAuthorize: (sid: string, body: RequestBody, callback: (error: any) => void): AppThunkAction<KnownAction> => (dispatch, _getState) => {
        dispatch({ type: 'CLEAR_FOLDER_AUTHORIZE' });
        dispatch({ type: 'REQUEST_FOLDER_AUTHORIZE' });

        //console.log("[Sakaz Debug] add folder auth :", body)
        fetch(`/v2/folder-auth?app_id=${sid}`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('DMS_APPLICATION'),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body),
        })
            .then(response => {
                if (!response.ok) {
                    throw response.json();
                }

                return response.json();
            })
            .then(data => {
                //console.log("[Sakaz Debug] data response :", data);

                callback(null);
                dispatch({ type: 'RECEIVE_FOLDER_AUTHORIZE', data });
            })
            .catch(reason => {
                reason.then(error => {
                    //console.log("[Sakaz Debug] error :", error);

                    callback(error);
                    dispatch({ type: 'FAILURE_FOLDER_AUTHORIZE', error });
                })
            });
    }
};

const initialState: FolderAuthorizeStateStore = {
    isLoading: false,
    response: null,
    status: 0,
    error: null
};

export const reducer: Reducer<FolderAuthorizeStateStore> = (state = initialState, action: KnownAction) => {
    switch (action.type) {
        case 'REQUEST_FOLDER_AUTHORIZE': {
            return {
                ...state,
                isLoading: true
            };
        }
        case 'RECEIVE_FOLDER_AUTHORIZE': {
            return {
                ...state,
                isLoading: false,
                response: action.data,
                status: 200
            };
        }
        case 'FAILURE_FOLDER_AUTHORIZE': {
            return {
                ...state,
                isLoading: false,
                status: 400,
                error: action.error
            };
        }
        case 'CLEAR_FOLDER_AUTHORIZE': {
            return initialState;
        };
        default: return state;
    }
}