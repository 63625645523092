import React from 'react';
import { twMerge } from 'tailwind-merge';

import { sizeTwClasses } from './constant';

type OwnProps = {
    size?: MaterialSize;
};

type Props = Omit<
    React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    'size'
> &
    OwnProps;

function Input(props: Props) {
    const { size = 'sm', className, ...inputProps } = props;

    return (
        <input
            {...inputProps}
            className={twMerge(
                'inline-block w-full rounded-[4px] border border-neutral-300 px-3 py-1.5 text-sm outline-neutral-300 transition-[outline-color] duration-100',
                'focus:outline-primary-500',
                sizeTwClasses.get(size) ?? '',
                className
            )}
        />
    );
}

export default Input;
