import React from 'react';
import {
    Button,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay
} from '@chakra-ui/react';
import CreatableSelect from 'react-select/creatable';
import Swal from 'sweetalert2';
import * as ShareFileModel from '../../../models/ShareFile';
import * as ShareFolderModel from '../../../models/ShareFolder';
import * as FileModel from '../../../models/File';
import * as EmployeeModels from '../../../models/Employee';
import { ResponseList, OptionType } from '../../../models/Common';

import { IUserContext, UserContext } from '../../../context/UserProvider';
import { Authorize } from '../../../models/Authorize';
import {
    ShareFolderAuthorizeResponse,
    SharedUserType,
    ShareFolderRequest,
    ShareFolderRequestType
} from '../../../models/ShareFolder';
import { saveChangeFolderAuthorize } from '../../../data/repositories/shareService/share.repository';
import ShareUserSelector from './ShareUserSelector';

interface Props {
    isFolder: boolean;
    nodeSid?: string;
    nodeName?: string;
    nodeCode?: string;
    selectedFiles?: FileModel.File[];
    isOpenModalShare: boolean;
    toggleModalShare: () => void;
}

interface States {
    addNewUsers: string[];
    // selectedShareFileAuthorize: OptionType;
    shareFileAuthorizeOptions: OptionType[];
    listEmployeeOptions: OptionType[];
    shareFileAuthorizeMaster: Authorize[];
    sharedUsers: SharedUserType[];
    shareRequest: ShareFolderRequestType[];
    displayShareUsers: SharedUserType[];
}

const createShareFileAuthorizeOption = (label: string, value: string) =>
    ({
        label,
        value
    }) as OptionType;

class ModalShareFileComponent extends React.Component<Props, States> {
    static contextType: React.Context<any> | undefined = UserContext;

    public context!: React.ContextType<typeof UserContext>;

    constructor(props: Props) {
        super(props);

        this.state = {
            addNewUsers: [],
            // selectedShareFileAuthorize: { label: '', value: '' },
            shareFileAuthorizeOptions: [],
            listEmployeeOptions: [],
            shareFileAuthorizeMaster: [],
            sharedUsers: [],
            shareRequest: [],
            displayShareUsers: []
        };

        this.shareClick = this.shareClick.bind(this);
        // this.onChangeShareFolderAuthorize = this.onChangeShareFolderAuthorize.bind(this);
        this.handleChangeUserAuthorize = this.handleChangeUserAuthorize.bind(this);
    }

    componentDidMount() {
        this._fetchGetEmployee();
        this._getShareFileAuthorize();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<States>) {
        if (
            // this.props.nodeSid &&
            // this.props.nodeSid !== '*' &&
            this.props.nodeCode !== prevProps.nodeCode
        ) {
            this._fetchGetEmployee();
        }

        // Add new user share folder authorize
        const indexOfNewUsers = this.state.addNewUsers.length - 1;
        if (this.state.addNewUsers.length > prevState.addNewUsers.length && indexOfNewUsers >= 0) {
            // const newUser = this.state.addNewUsers[indexOfNewUsers];
            // console.log('[Debug] new user', newUser);

            // this.onChangeShareFolderAuthorize({ label: 'View Only', value: '71' }, newUser);
            this.handleChangeUserAuthorize({ label: 'View Only', value: '71' }, -1);
        } else if (this.state.addNewUsers.length < prevState.addNewUsers.length) {
            // console.log('[Debug] removeable users', prevState.addNewUsers);

            this.removeShareRequest(prevState.addNewUsers);
        }
    }

    removeShareRequest = (snapshotAddNewUsers: string[]) => {
        const removeableUsers = this.state.shareRequest;

        snapshotAddNewUsers.forEach(addNewUser => {
            const foundIndexRemoveableUser = removeableUsers.findIndex(removeUser =>
                removeUser.payload.some(rm => rm.to_sid === addNewUser)
            );

            if (foundIndexRemoveableUser >= 0) {
                removeableUsers.splice(foundIndexRemoveableUser, 1);
            }
        });

        this.setState({ shareRequest: removeableUsers });
    };

    handleChangeAddUser = (newData: OptionType[]) => {
        const newUsers = newData.map(({ value }) => value);
        const newShareUsers: SharedUserType[] = newData.map(
            ({ value }) =>
                ({
                    shareTo: value,
                    authorize: { label: 'View Only', value: '71' }
                }) as SharedUserType
        );

        this.setState(prevState => ({
            addNewUsers: newUsers,
            displayShareUsers: [...this.state.sharedUsers, ...newShareUsers]
        }));
    };

    customFilterAddUsers = (option: OptionType, inputUser: string) => {
        // if (inputUser === '') return false;

        if (option.label.toLowerCase().includes(inputUser.toLowerCase())) return true;

        return false;
    };

    shareClick(e: any) {
        document.getElementsByClassName('Loader__background')[0].className =
            'Loader__background show';
        if (this.state.displayShareUsers.length == 0) {
            this.afterError('กรุณาระบุ Email ที่ต้องการ Share ข้อมูล');

            return;
        }

        if (this.props.isFolder) {
            // console.log('[Debug] request model', this.state.shareRequest);
            this._callSaveFolderAuthorize(this.state.shareRequest);

            document.getElementsByClassName('Loader__background')[0].className =
                'Loader__background hide';
        } else {
            var bodyFileReq = Array<ShareFileModel.ShareFileRequest>();
            this.state.displayShareUsers.forEach(item => {
                this.props.selectedFiles?.forEach(file => {
                    var data: ShareFileModel.ShareFileRequest = {
                        from_sid: file.sid,
                        file_id: file.id,
                        to_sid: item.shareTo
                    };

                    bodyFileReq.push(data);
                });
            });

            fetch('/v1/share/file', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.getItem('DMS_APPLICATION')
                },
                body: JSON.stringify(bodyFileReq)
            })
                .then(response => {
                    return response.json() as Promise<ShareFileModel.ShareFileResponse>;
                })
                .then(data => {
                    //console.log(data);
                    this.afterSuccess();
                })
                .catch(error => {
                    //console.log(error);
                    this.afterError(error);
                });
        }
    }

    afterSuccess = () => {
        document.getElementsByClassName('Loader__background')[0].className =
            'Loader__background hide';

        Swal.fire({
            title: 'Success',
            icon: 'success'
        }).then(result => {
            if (result.isConfirmed) {
                //this.props.requestFunctionFolder("CLEAR", this.state.modelFolder);
                //this.toggleModal();
                //this.props.afterModalSaveSuccess();
                this.props.toggleModalShare();
                window.location.reload();
            }
        });
    };

    afterError = (message: string) => {
        document.getElementsByClassName('Loader__background')[0].className =
            'Loader__background hide';

        Swal.fire({
            title: 'Error',
            icon: 'error',
            text: message
        }).then(result => {
            if (result.isConfirmed) {
                //this.props.requestFunctionFolder("CLEAR", this.state.modelFolder);
                //this.toggleModal();
                //this.props.afterModalSaveSuccess();
            }
        });
    };

    // Deprecated!
    onChangeShareFolderAuthorize = (selectedAuthorize: OptionType, shareTo: string) => {
        // debugger
        const { id }: IUserContext = this.context;
        const { sharedUsers, displayShareUsers } = this.state;

        // Change share user authorize
        const changedAuthorizeDisplayUsers = [...displayShareUsers].map(displayUser => {
            if (displayUser.shareTo === shareTo) {
                return {
                    shareTo,
                    authorize: {
                        label: selectedAuthorize.label,
                        value: selectedAuthorize.value
                    }
                } as SharedUserType;
            }

            return displayUser;
        });

        // Prepare for Delete
        if (this.props.nodeCode && selectedAuthorize.value === 'remove') {
            const requestDeleteData = [...changedAuthorizeDisplayUsers]
                .filter(displayUser => displayUser.shareTo === shareTo)
                .map(
                    user =>
                        ({
                            node_code: this.props.nodeCode,
                            from_sid: id,
                            to_sid: user.shareTo
                        }) as ShareFolderRequest
                );
            // console.log('[Debug] request delete data', requestDeleteData);

            this.setState(prevState => ({
                shareRequest: [
                    ...prevState.shareRequest,
                    { action: 'delete', payload: requestDeleteData }
                ],
                displayShareUsers: changedAuthorizeDisplayUsers
            }));

            return;
        }

        // Prepare for Edit or Add
        if (this.props.nodeCode && selectedAuthorize.value !== 'remove') {
            const foundSharedUser = sharedUsers.find(shared => shared.shareTo === shareTo);
            // console.log('[Debug] found shared user', foundUser);

            // edit mode
            if (foundSharedUser) {
                const requestEditData = [...changedAuthorizeDisplayUsers]
                    .filter(displayUser => displayUser.shareTo === shareTo)
                    .map(
                        user =>
                            ({
                                authorize_id: user.authorize.value,
                                from_sid: id,
                                to_sid: user.shareTo,
                                node_code: this.props.nodeCode
                            }) as ShareFolderRequest
                    );
                // console.log('[Debug] request edit data', requestEditData);

                this.setState(prevState => ({
                    shareRequest: [
                        ...prevState.shareRequest,
                        { action: 'edit', payload: requestEditData }
                    ],
                    displayShareUsers: changedAuthorizeDisplayUsers
                }));

                return;
            }

            // add mode : create new share folder user
            const requestAddData = [...changedAuthorizeDisplayUsers]
                .filter(displayUser => displayUser.shareTo === shareTo)
                .map(
                    user =>
                        ({
                            from_sid: this.props.nodeSid,
                            node_code: this.props.nodeCode,
                            to_sid: user.shareTo,
                            authorize_id: user.authorize.value
                        }) as ShareFolderRequest
                );
            // console.log('[Debug] request add data', requestAddData);

            this.setState(prevState => ({
                shareRequest: [
                    ...prevState.shareRequest,
                    { action: 'add', payload: requestAddData }
                ],
                displayShareUsers: changedAuthorizeDisplayUsers
            }));
        }
    };

    handleChangeUserAuthorize = (selectedAuthorize: OptionType, index: number) => {
        // debugger
        const { id }: IUserContext = this.context;
        const { displayShareUsers } = this.state;

        if (index >= 0) {
            const selectedDisplayUser = [...displayShareUsers][index];

            // Change share user authorize
            const changedAuthorizeDisplayUsers = [
                {
                    shareTo: selectedDisplayUser.shareTo,
                    authorize: {
                        label: selectedAuthorize.label,
                        value: selectedAuthorize.value
                    }
                } as SharedUserType
            ];

            // Prepare for Delete
            if (this.props.nodeCode && selectedAuthorize.label.toLowerCase().includes('remove')) {
                const requestDeleteData = [
                    {
                        node_code: this.props.nodeCode,
                        from_sid: id,
                        to_sid: selectedDisplayUser.shareTo
                    } as ShareFolderRequest
                ];
                // console.log('[Debug] request delete data', requestDeleteData);

                this.setState(prevState => ({
                    shareRequest: [
                        ...prevState.shareRequest,
                        { action: 'delete', payload: requestDeleteData }
                    ],
                    displayShareUsers: changedAuthorizeDisplayUsers
                }));

                return;
            }

            // Prepare for Edit
            if (this.props.nodeCode && !selectedAuthorize.label.toLowerCase().includes('remove')) {
                const requestEditData = [
                    {
                        authorize_id: selectedAuthorize.value,
                        from_sid: id,
                        to_sid: selectedDisplayUser.shareTo,
                        node_code: this.props.nodeCode
                    } as ShareFolderRequest
                ];
                // console.log('[Debug] request edit data', requestEditData);

                this.setState(prevState => ({
                    shareRequest: [
                        ...prevState.shareRequest,
                        { action: 'edit', payload: requestEditData }
                    ],
                    displayShareUsers: changedAuthorizeDisplayUsers
                }));

                return;
            }
        }

        // Prepare for Add new share folder user
        const indexOfNewDisplayUser = [...displayShareUsers].length - 1;
        const newDisplayUser = [...displayShareUsers][indexOfNewDisplayUser];

        const updateDisplayShareUsers = [
            {
                shareTo: newDisplayUser.shareTo,
                authorize: {
                    label: selectedAuthorize.label,
                    value: selectedAuthorize.value
                }
            } as SharedUserType
        ];

        const requestAddData = [
            {
                from_sid: this.props.nodeSid,
                node_code: this.props.nodeCode,
                to_sid: newDisplayUser.shareTo,
                authorize_id: selectedAuthorize.value
            } as ShareFolderRequest
        ];
        // console.log('[Debug] request add data', requestAddData);

        this.setState(prevState => ({
            shareRequest: [...prevState.shareRequest, { action: 'add', payload: requestAddData }],
            displayShareUsers: updateDisplayShareUsers
        }));
    };

    _fetchGetEmployee = async () => {
        try {
            const { id }: IUserContext = this.context;
            // const { sharedUsers } = this.state;
            const sharedUsers = (await this._getUserShareFolderAuthorize()) ?? [];
            let usersWithoutShared: OptionType[] = [];

            const response = await fetch('/v1/user', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.getItem('DMS_APPLICATION')
                }
            });

            const data = (await response.json()) as EmployeeModels.ResponseEmployee;
            // console.log('[Debug] get list employee master =>', data);

            if (!response.ok) {
                throw new Error(data.message);
            }

            // Set options - All users
            if (data.total_count > 0 && this.props.nodeSid && this.props.nodeSid === '*') {
                // debugger
                const employeeOptions = data.result_list
                    .map(result => ({
                        label: result.id,
                        value: result.id
                    }))
                    .filter(empOption => empOption.label !== id);

                if (sharedUsers.length > 0) {
                    usersWithoutShared = employeeOptions.filter(emp =>
                        sharedUsers.some(shared => shared.shareTo !== emp.label)
                    );
                    // console.log('[Debug] All users - usersWithoutShared', usersWithoutShared);
                }

                this.setState({
                    listEmployeeOptions:
                        usersWithoutShared.length > 0 ? usersWithoutShared : employeeOptions,
                    sharedUsers: sharedUsers,
                    displayShareUsers: sharedUsers
                });
            }

            // Set options - only users in the same company
            if (data.total_count > 0 && this.props.nodeSid && this.props.nodeSid !== '*') {
                // debugger
                const filterEmployee = data.result_list.filter(result =>
                    result.list_company.some(company => company.sid === this.props.nodeSid)
                );

                const filterEmployeeOptions = filterEmployee
                    .map(employee => ({
                        label: employee.id,
                        value: employee.id
                    }))
                    .filter(empOption => empOption.label !== id);

                if (sharedUsers.length > 0) {
                    usersWithoutShared = filterEmployeeOptions.filter(emp =>
                        sharedUsers.some(shared => shared.shareTo !== emp.label)
                    );
                    // console.log('[Debug] users in company - usersWithoutShared', usersWithoutShared);
                }

                this.setState({
                    listEmployeeOptions:
                        usersWithoutShared.length > 0 ? usersWithoutShared : filterEmployeeOptions,
                    sharedUsers: sharedUsers,
                    displayShareUsers: sharedUsers
                });
            }
        } catch (error) {
            //console.log('[Debug] error get employee master', error);
            this.afterError((error as any).message);
        }
    };

    _getShareFileAuthorize = async () => {
        try {
            const response = await fetch('/v1/authorize');

            const data = (await response.json()) as ResponseList<Authorize>;
            // console.log('[Debug] get authorize master', data.result_list);

            if (!response.ok) {
                throw new Error(data.message);
            }

            if (data.total_count > 0) {
                const authOptions = data.result_list.map(result =>
                    createShareFileAuthorizeOption(result.name, result.id)
                );

                // Default value of selected authorize
                // const foundViewAuthorize = data.result_list.find(result =>
                //     result.id.charAt(0) === '7' &&
                //     result.name.toLowerCase().includes('view')
                // );

                this.setState(prevState => ({
                    shareFileAuthorizeMaster: data.result_list ?? [],
                    shareFileAuthorizeOptions:
                        authOptions.filter(auth => auth.value.charAt(0) === '7') ?? []
                    // selectedShareFileAuthorize: (foundViewAuthorize !== undefined)
                    //     ? { label: foundViewAuthorize.name, value: foundViewAuthorize.id } as OptionType
                    //     : prevState.selectedShareFileAuthorize
                }));
            }
        } catch (error) {
            if (error instanceof Error) {
                this.afterError(error.message);

                return;
            }

            this.afterError((error as any).message);
        }
    };

    _getUserShareFolderAuthorize = async () => {
        try {
            const { id }: IUserContext = this.context;
            const { nodeCode, nodeSid } = this.props;

            // console.log('[Debug] node_code', nodeCode);
            const queryString = new URLSearchParams({ owner_sid: id, node_code: nodeCode ?? '' });

            const response = await fetch('/v1/share/folder-auth?' + queryString, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.getItem('DMS_APPLICATION')
                }
            });

            const { message, result_list, total_count } =
                (await response.json()) as ResponseList<ShareFolderAuthorizeResponse>;
            // console.log('[Debug] get list share folder auth =>', result_list);

            if (!response.ok) {
                throw new Error(message);
            }

            if (total_count > 0) {
                const listSharedModel = result_list.map(
                    result =>
                        ({
                            shareTo: result.to_sid,
                            authorize: {
                                label: result.authorize_name,
                                value: result.authorize_id
                            }
                        }) as SharedUserType
                );
                // console.log('[Debug] shared model =>', listSharedModel);

                // this.setState({ sharedUsers: listSharedModel });

                return listSharedModel;
            }
        } catch (error) {
            //console.log('[Debug] error get share folder auth =>', error);
            if (error instanceof Error) {
                this.afterError(error.message);

                return;
            }

            this.afterError((error as any).message);
        }
    };

    _callSaveFolderAuthorize = async (requestBody: ShareFolderRequestType[]) => {
        try {
            const { message } = await saveChangeFolderAuthorize(requestBody);
            // console.log('[Debug] save successfully', message);

            this.afterSuccess();
        } catch (error) {
            if (error instanceof Error) {
                this.afterError(error.message);
            }
        }
    };

    render() {
        //console.log('[Debug] selected files', this.props.selectedFiles);
        //console.log('[Debug] node sid', this.props.nodeSid);
        return (
            <React.Fragment>
                <Modal
                    isOpen={this.props.isOpenModalShare}
                    onClose={this.props.toggleModalShare}
                    size={'6xl'}
                    // className="share-file-modal modal-edit-file modal-dialog-custom"
                    // contentClassName="modal-content-custom"
                >
                    {/* <ModalHeader
                        style={{ fontFamily: 'sans-serif' }}
                        toggle={this.props.toggleModalShare}
                    > */}
                    {/* {this.props.isFolder
                            ? 'Share : ' + this.props.nodeName
                            : 'Share ' +
                              (this.props.selectedFiles ? this.props.selectedFiles.length : 0) +
                              ' files'} */}
                    {/* </ModalHeader> */}
                    <ModalOverlay />
                    <ModalContent>
                        <ModalBody>
                            <div className="flex flex-col gap-2">
                                <div className="p-2.5">
                                    {/* <CreatableSelect
                                    placeholder="Add people and groups"
                                    // components={{ DropdownIndicator: null }}
                                    isMulti
                                    isClearable
                                    onChange={this.handleChangeAddUser.bind(this)}
                                    isValidNewOption={() => false}
                                    options={this.state.listEmployeeOptions}
                                    filterOption={this.customFilterAddUsers.bind(this)}
                                /> */}
                                    <ShareUserSelector
                                        shareFolderName={this.props.nodeName}
                                        employeeList={
                                            this.state.listEmployeeOptions.map(
                                                employee => employee.value
                                            ) ?? []
                                        }
                                        sharedUsers={this.state.sharedUsers.map(
                                            user => user.shareTo
                                        )}
                                        onSelectUser={(selectedUsers: string[]) => {
                                            // After apply button click!
                                            const userOptions = selectedUsers.map(
                                                selectedUser =>
                                                    ({
                                                        label: selectedUser,
                                                        value: selectedUser
                                                    }) as OptionType
                                            );

                                            this.handleChangeAddUser(userOptions);
                                        }}
                                        onClose={this.props.toggleModalShare}
                                    />

                                    <div className="mt-2 bg-[#F7F7F7] p-2.5">
                                        <div className="inline-flex gap-2.5 px-2.5 pb-2.5">
                                            <div className="text-sm font-medium text-black">
                                                User With Access rule
                                            </div>
                                        </div>
                                        <ul className="[&>li:not(:last-child)]:border-b">
                                            {this.state.displayShareUsers.length > 0 &&
                                                this.state.displayShareUsers.map((user, index) => {
                                                    return (
                                                        <li
                                                            key={index}
                                                            className="align-items-center justify-content-between flex cursor-pointer bg-white px-2 py-2"
                                                        >
                                                            <span className="text-sm font-normal">
                                                                {user.shareTo}
                                                            </span>

                                                            <div className="w-1/4">
                                                                <CreatableSelect
                                                                    options={
                                                                        this.state
                                                                            .shareFileAuthorizeOptions
                                                                    }
                                                                    value={user.authorize}
                                                                    onChange={(
                                                                        authOption: OptionType
                                                                    ) =>
                                                                        this.handleChangeUserAuthorize(
                                                                            authOption,
                                                                            index
                                                                        )
                                                                    }
                                                                    isValidNewOption={() => false}
                                                                    className="select-access-rule"
                                                                />
                                                            </div>
                                                        </li>
                                                    );
                                                })}
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            {/* <div className='row mt-3'>
                            <div className='col-3 d-flex justify-content-center align-items-center px-0'>
                                <span style={{ fontSize: '1em', fontWeight: 'bold' }}>
                                    Select Share Authorize
                                </span>
                            </div>
                            <div className='col-8 px-0'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <CreatableSelect
                                            // placeholder="Select share authorize"
                                            options={this.state.shareFileAuthorizeOptions}
                                            value={this.state.selectedShareFileAuthorize}
                                            onChange={(authOption: OptionType) =>
                                                this.setState({ selectedShareFileAuthorize: authOption })
                                            }
                                            isValidNewOption={() => false}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        </ModalBody>
                        <ModalFooter>
                            <div className="d-flex w-100 justify-content-end px-2.5">
                                {/* <Button
                                outline
                                color="primary"
                                style={{ fontFamily: "sans-serif" }}
                                // onClick={this.OnCopyFilesClick}
                                // disabled={this.state.folderSelectedCode === ''}
                            >
                                Save Change
                            </Button> */}
                                <Button
                                    size={'md'}
                                    // className="rounded-md border-none bg-[#3397C3] px-7 text-white"
                                    colorScheme="primary"
                                    onClick={this.shareClick}
                                    className="h-9"
                                >
                                    <span className="px-[15px] text-sm font-medium">Done</span>
                                </Button>
                            </div>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </React.Fragment>
        );
    }
}

export default ModalShareFileComponent;
