import React, { useCallback, useRef, useState } from 'react';
import { useOnClickOutside } from 'usehooks-ts';
import { FiChevronDown } from 'react-icons/fi';
import { MdOutlineUnfoldMore } from 'react-icons/md';

type Props = {
    defaultText?: string;
    options?: string[];
    onSelectOption?: (selectedOption: string) => void;
};

function SelectOptios(props: Props) {
    const { defaultText, options, onSelectOption } = props;

    const containerRef = useRef<HTMLDivElement>(null);

    const [showOptionList, setShowOptionList] = useState(false);
    const [defaultSelectedText, setDefaultSelectedText] = useState(defaultText ?? 'Select');

    useOnClickOutside(
        containerRef,
        useCallback(() => {
            setShowOptionList(false);
        }, [showOptionList])
    );

    const handleOptionClick = (dataName: string | null) => {
        if (!dataName) return;
        if (onSelectOption) onSelectOption(dataName);

        setShowOptionList(false);
        setDefaultSelectedText(dataName);
    };

    return (
        <div
            className="relative inline-block w-[70px] text-center"
            ref={containerRef}
        >
            <div
                className="flex items-center justify-between rounded border border-[#999] px-2 py-0.5"
                onClick={() => setShowOptionList(!showOptionList)}
            >
                <span>{defaultSelectedText}</span>
                <MdOutlineUnfoldMore size={16} />
            </div>
            {showOptionList && (
                <ul className="absolute m-0 w-full p-0 text-center">
                    {options &&
                        options.map((option, index) => {
                            return (
                                <li
                                    key={index + 1}
                                    data-name={option}
                                    className="cursor-pointer list-none bg-white px-5 py-1.5 hover:rounded-sm hover:bg-[#2E445D] hover:text-white hover:opacity-90"
                                    onClick={event =>
                                        handleOptionClick(
                                            event.currentTarget.getAttribute('data-name')
                                        )
                                    }
                                >
                                    {option}
                                </li>
                            );
                        })}
                </ul>
            )}
        </div>
    );
}

export default SelectOptios;
