import * as React from 'react';

import { connect } from 'react-redux';
import { ApplicationState } from 'store';
import * as Stores from 'store/Folder';
import * as Models from 'models/Folder';

import { Button } from 'reactstrap';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import FolderIcon from '@material-ui/icons/Folder';
import TreeItem, { TreeItemProps } from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';

import FolderImg from 'Images/Icon/boxid.png';
import DeleteImg from 'Images/Icon/remove.png';
import AddImg from 'Images/Icon/add.png';
import EditImg from 'Images/Icon/pencil.png';

import { Menu, Item, Separator, Submenu, useContextMenu } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.css';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import ModalManageFolderComponent from 'components/dms/ModalManageFolderComponent';

// tree folder
declare module 'csstype' {
    interface Properties {
        '--tree-view-color'?: string;
        '--tree-view-bg-color'?: string;
    }
}

type StyledTreeItemProps = TreeItemProps & {
    bgColor?: string;
    color?: string;
    labelIcon: React.ElementType<SvgIconProps>;
    labelInfo?: string;
    labelText: string;
};

const useTreeItemStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            color: theme.palette.text.secondary,
            '&:hover > $content': {
                backgroundColor: theme.palette.action.hover
            },
            '&:focus > $content, &$selected > $content': {
                backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
                color: 'var(--tree-view-color)'
            },
            '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
                backgroundColor: 'transparent'
            }
        },
        content: {
            color: theme.palette.text.secondary,
            paddingRight: theme.spacing(1),
            fontWeight: 'bold',
            '$expanded > &': {
                fontWeight: 'normal'
            },
            fontFamily: 'sans-serif'
        },
        group: {
            marginLeft: 0,
            borderLeft: '1px dashed #bebebe'
        },
        expanded: {},
        selected: {},
        label: {
            fontWeight: 'inherit',
            color: 'inherit',
            fontFamily: 'sans-serif'
        },
        labelRoot: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(0.5, 0)
        },
        labelIcon: {
            marginRight: theme.spacing(1)
        },
        labelText: {
            fontWeight: 'inherit',
            flexGrow: 1,
            fontFamily: 'sans-serif'
        }
    })
);

function StyledTreeItem(props: StyledTreeItemProps) {
    const classes = useTreeItemStyles();
    const { labelText, labelIcon: LabelIcon, labelInfo, color, bgColor, ...other } = props;

    return (
        <TreeItem
            onLabelClick={event => {
                event.stopPropagation();
                event.preventDefault();
            }}
            label={
                <div className={classes.labelRoot}>
                    <img
                        src={FolderImg}
                        alt="file"
                        style={{ width: '18px', marginRight: '3px' }}
                    />
                    <Typography
                        variant="body2"
                        className={classes.labelText}
                    >
                        {labelText}
                    </Typography>
                    <Typography
                        variant="caption"
                        color="inherit"
                    ></Typography>
                </div>
            }
            style={{
                '--tree-view-color': color,
                '--tree-view-bg-color': bgColor
            }}
            classes={{
                root: classes.root,
                content: classes.content,
                expanded: classes.expanded,
                selected: classes.selected,
                group: classes.group,
                label: classes.label
            }}
            {...other}
        />
    );
}

// right click
const MENU_ID = 'menu-id';

const { show } = useContextMenu({
    id: MENU_ID
});

// store
type Store = Models.FolderState & typeof Stores.actionCreators;

class BoxIDView extends React.PureComponent<
    any,
    {
        pageLoad: boolean;
        version: number;
        listFolder: Array<Models.FolderMap>;
        isLoadSuccess: boolean;
        dataModal: Models.DataModal;
    },
    Store
> {
    constructor(props: any) {
        super(props);

        this.state = {
            pageLoad: true,
            isLoadSuccess: false,
            version: 0,
            listFolder: [],
            dataModal: {
                sid: '',
                isOpenModal: false,
                mode: '',
                nodeCode: '',
                folderName: '',
                nodeParent: '',
                nodeLevel: 0,
                metadata_group_code: '',
                role_access_code: '',
                max_file_size: '',
                file_extension: '',
                fullPath: '',
                owner: ''
            }
        };
    }

    public componentDidMount() {
        this.ensureDataFetched();
    }

    private ensureDataFetched() {
        this.props.onSelectFolder('', '');
        this.setState({ pageLoad: true, isLoadSuccess: false });

        this.props.requestFunction(this.state.pageLoad, 'DEV', '1000', this.state.version, 'GET');

        this.setState({ pageLoad: false });
    }

    private checkNode(item: Array<Models.FolderMap>, index: number) {
        if ((item as Array<Models.FolderMap>).length > 0) {
            return this.renderNode(item, index);
        }

        return '';
    }

    private onContextMenuClick(e: any) {
        e.stopPropagation();
        console.log('Right Click');
        console.log(e.currentTarget.dataset);

        var data = {
            sid: e.currentTarget.dataset.sid,
            isOpenModal: false,
            mode: '',
            nodeCode: e.currentTarget.dataset.nodecode,
            folderName: '',
            nodeParent: '',
            nodeLevel: 0,
            metadata_group_code: '',
            role_access_code: '',
            max_file_size: '',
            file_extension: '',
            fullPath: '',
            owner: ''
        };

        this.setState({ dataModal: data });

        show(e);
    }

    private onClickMoreFolder(e: any) {
        var data = {
            sid: this.state.dataModal.sid,
            isOpenModal: true,
            mode: e.event.currentTarget.dataset.mode,
            nodeCode: this.state.dataModal.nodeCode,
            folderName: '',
            nodeParent: '',
            nodeLevel: 0,
            metadata_group_code: '',
            role_access_code: '',
            max_file_size: '',
            file_extension: '',
            fullPath: '',
            owner: ''
        };

        this.setState({ dataModal: data });
    }

    private afterOpenModal(e: any) {
        var data = {
            sid: this.state.dataModal.sid,
            isOpenModal: false,
            mode: this.state.dataModal.mode,
            nodeCode: this.state.dataModal.nodeCode,
            folderName: '',
            nodeParent: '',
            nodeLevel: 0,
            metadata_group_code: '',
            role_access_code: '',
            max_file_size: '',
            file_extension: '',
            fullPath: '',
            owner: ''
        };

        this.setState({ dataModal: data });
    }

    private afterModalSaveSuccess(e: any) {
        this.ensureDataFetched();
    }

    onSelectFolder = (e: any) => {
        this.props.onSelectFolder(e.target.dataset.nodecode, e.target.dataset.nodename);
    };

    render() {
        var { dataModal } = this.state;

        if (this.props.version > this.state.version) {
            if (this.props.version - this.state.version === 2) {
                this.setState({ listFolder: this.props.response, version: this.props.version });
                setTimeout(() => {
                    if (this.state.listFolder.length > 0) {
                        this.setState({ isLoadSuccess: true });
                    }
                }, 500);
            }
        }

        return (
            <div className="div-folder-main ">
                <TreeView
                    className=""
                    defaultExpanded={[
                        this.state.isLoadSuccess ? this.state.listFolder[0].node_code : 'N-TEST000'
                    ]}
                    defaultCollapseIcon={<ArrowDropDownIcon />}
                    defaultExpandIcon={<ArrowRightIcon />}
                    onFocus={this.onSelectFolder}
                >
                    {this.state.listFolder !== undefined &&
                        this.renderNode(this.state.listFolder, 0)}
                </TreeView>

                <Menu id={MENU_ID}>
                    <Item disabled>
                        {this.state.dataModal.nodeCode}
                        <CopyToClipboard text={this.state.dataModal.nodeCode}>
                            <Button
                                style={{
                                    position: 'absolute',
                                    right: '5px',
                                    width: '30px',
                                    height: '32px',
                                    padding: '0'
                                }}
                            >
                                <i className="fas fa-copy"></i>
                            </Button>
                        </CopyToClipboard>
                    </Item>

                    {!this.state.dataModal.nodeCode.includes('S-') && (
                        <div>
                            <Separator />
                            <Item
                                data-mode="rename"
                                onClick={this.onClickMoreFolder}
                            >
                                <img
                                    src={EditImg}
                                    alt="menu-delete"
                                    style={{ marginRight: '5px' }}
                                />
                                Rename
                            </Item>
                            <Item
                                data-mode="add"
                                onClick={this.onClickMoreFolder}
                            >
                                <img
                                    src={AddImg}
                                    alt="menu-delete"
                                    style={{ marginRight: '5px' }}
                                />
                                Add Folder
                            </Item>
                            <Item
                                data-mode="delete"
                                onClick={this.onClickMoreFolder}
                            >
                                <img
                                    src={DeleteImg}
                                    alt="menu-delete"
                                    style={{ marginRight: '5px' }}
                                />
                                Delete
                            </Item>
                        </div>
                    )}
                </Menu>

                <ModalManageFolderComponent
                    dataModal={dataModal}
                    afterOpenModal={this.afterOpenModal.bind(this)}
                    afterModalSaveSuccess={this.afterModalSaveSuccess.bind(this)}
                />
            </div>
        );
    }

    renderNode(item: Array<Models.FolderMap>, indexMain: number) {
        return (
            <div>
                {item.map((item: Models.FolderMap, index: number) => (
                    <StyledTreeItem
                        key={item.node_code}
                        nodeId={item.node_code}
                        labelText={item.folder_name}
                        labelIcon={FolderIcon}
                        color="#1a73e8"
                        bgColor="#e8f0fe"
                        style={{ paddingLeft: '10px' }}
                        data-sid={item.sid}
                        data-nodecode={item.node_code}
                        data-nodename={item.folder_name}
                        onContextMenu={this.onContextMenuClick}
                    >
                        {item.list_node.length > 0 && this.renderNode(item.list_node, 0)}
                    </StyledTreeItem>
                ))}
            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.folderMap,
    Stores.actionCreators
)(BoxIDView);
