import * as React from 'react';
import { connect } from 'react-redux';
import { Table, Thead, Tbody, Tr, Th, Td, TableContainer } from '@chakra-ui/react';

import imgFile from 'Images/Icon/icon-File.png';
import imgImage from 'Images/Icon/icon-image.png';
import imgPDF from 'Images/Icon/icon-PDF.png';
import imgPowerPoint from 'Images/Icon/icon-PowerPoint.png';
import imgWord from 'Images/Icon/icon-Word.png';
import imgXLS from 'Images/Icon/icon-XLS.png';
import { ImCancelCircle, ImSpinner8 } from 'react-icons/im';
import { FaRegFile, FaCheckCircle } from 'react-icons/fa';
import { BsList, BsEye } from 'react-icons/bs';

import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';

import * as Models from 'models/File';
import { IUserContext, UserContext } from 'context/UserProvider';
import Swal from 'sweetalert2';

import { ApplicationState } from 'store';

import * as FolderAuthorizeUserMappingStore from 'store/FolderAuthorizeUserMapping';

import { FolderAuthorizeUserMappingStateStore } from 'models/FolderAuthorizeUserMapping';
import { formatYMDToDMY, onClickTr } from 'modules/common';
import { Button } from 'common/materials';

import FileLogModal from '../FileLogs/FileLogModal';

interface ComponentProps {
    [key: string]: any;
    getValueFileList: (_?: string) => void;
}

type Props = ComponentProps &
    FolderAuthorizeUserMappingStateStore &
    typeof FolderAuthorizeUserMappingStore.actionCreators;

interface State {
    modelList: any;
    rowsPerPage: number;
    page: number;
    showModalFileLog: boolean;
    primaryId: string;
}

class TableFilesExplorer extends React.PureComponent<Props, State> {
    public static contextType: React.Context<any> | undefined = UserContext;

    public context!: React.ContextType<typeof UserContext>;

    public fileRefs: React.RefObject<HTMLInputElement[]>;
    public roleMap = {
        '01': 'Upload',
        '02': 'Edit',
        '03': 'View',
        '04': 'Delete',
        '05': 'Copy Link',
        '06': 'Move Link',
        '99': 'Super Admin',
        '00': 'Admin',
        '51': 'Display Update Box ID',
        '52': 'Edit Update Box ID'
    };

    constructor(props: any) {
        super(props);

        this.state = {
            page: 0,
            rowsPerPage: 5,
            modelList: [],
            showModalFileLog: false,
            primaryId: ''
        };

        this.fileRefs = React.createRef<HTMLInputElement[]>();
        this.handleCheckFile = this.handleCheckFile.bind(this);
        this.handleChangeAll = this.handleChangeAll.bind(this);
        this.handleChangeGroup = this.handleChangeGroup.bind(this);
        this.handleChangeSubGroup = this.handleChangeSubGroup.bind(this);
    }

    handleChangeAll(evt) {
        var group = 'group';
        var sub_group = 'sub_group';
        var node = 'node';
        if (evt.target.checked === true) {
            Array.from(
                document.querySelectorAll(
                    'input[name=' +
                    group +
                    ']:not([disabled]), input[name=' +
                    sub_group +
                    ']:not([disabled]), input[name=' +
                    node +
                    ']:not([disabled])'
                )
            ).forEach(checkbox => {
                var cb = document.getElementById(checkbox.id) as HTMLInputElement;
                if (cb) {
                    cb.checked = true;
                }
            });
        } else {
            Array.from(
                document.querySelectorAll(
                    'input[name=' +
                    group +
                    ']:not([disabled]), input[name=' +
                    sub_group +
                    ']:not([disabled]), input[name=' +
                    node +
                    ']:not([disabled])'
                )
            ).forEach(checkbox => {
                var cb = document.getElementById(checkbox.id) as HTMLInputElement;
                if (cb) {
                    cb.checked = false;
                }
            });
        }

        this.props.getValueFileList();
    }

    handleChangeGroup(evt) {
        var sub_group = 'sub_group';
        var name = 'node';
        if (evt.target.checked === true) {
            Array.from(
                document.querySelectorAll(
                    'input[name=' +
                    sub_group +
                    "][id^='" +
                    evt.target.id +
                    "-']:not([disabled]), input[name=" +
                    name +
                    "][id^='" +
                    evt.target.id +
                    "-']:not([disabled])"
                )
            ).forEach(checkbox => {
                var cb = document.getElementById(checkbox.id) as HTMLInputElement;
                if (cb) {
                    cb.checked = true;
                }
            });
        } else {
            Array.from(
                document.querySelectorAll(
                    'input[name=' +
                    sub_group +
                    "][id^='" +
                    evt.target.id +
                    "-']:not([disabled]), input[name=" +
                    name +
                    "][id^='" +
                    evt.target.id +
                    "-']:not([disabled])"
                )
            ).forEach(checkbox => {
                var cb = document.getElementById(checkbox.id) as HTMLInputElement;
                if (cb) {
                    cb.checked = false;
                }
            });
        }

        this.props.getValueFileList();
    }

    handleChangeSubGroup(evt) {
        var name = 'node';
        if (evt.target.checked === true) {
            Array.from(
                document.querySelectorAll(
                    'input[name=' + name + "][id^='" + evt.target.id + "-']:not([disabled])"
                )
            ).forEach(checkbox => {
                var cb = document.getElementById(checkbox.id) as HTMLInputElement;
                if (cb) {
                    cb.checked = true;
                }
            });
        } else {
            Array.from(
                document.querySelectorAll(
                    'input[name=' + name + "][id^='" + evt.target.id + "-']:not([disabled])"
                )
            ).forEach(checkbox => {
                var cb = document.getElementById(checkbox.id) as HTMLInputElement;
                if (cb) {
                    cb.checked = false;
                }
            });
        }

        this.props.getValueFileList();
    }

    public handleCheckFile(): void {
        this.props.getValueFileList();
    }

    private highlight(name, search, id) {
        const parts = name.split(new RegExp(`(${search})`, 'gi'));
        return (
            <span
                key={id}
                data-filename={name}
                data-id={id}
                className="cursor-pointer hover:underline hover:underline-offset-1"
            >
                {parts.map(part =>
                    part.toLowerCase() === search.toLowerCase() ? (
                        <b
                            data-filename={name}
                            data-id={id}
                            className="bg-yellow-300"
                        >
                            {part}
                        </b>
                    ) : (
                        part
                    )
                )}
            </span>
        );
    }

    private previewFile(item: Models.File) {
        if (item.file_name.indexOf('.jpg') !== -1 || item.file_name.indexOf('.png') !== -1) {
            return imgImage;
        } else if (item.file_name.indexOf('.pdf') !== -1) {
            return imgPDF;
        } else if (
            item.file_name.indexOf('.xls') !== -1 ||
            item.file_name.indexOf('.xlsx') !== -1
        ) {
            return imgXLS;
        } else if (
            item.file_name.indexOf('.doc') !== -1 ||
            item.file_name.indexOf('.docx') !== -1
        ) {
            return imgWord;
        } else if (
            item.file_name.indexOf('.ppt') !== -1 ||
            item.file_name.indexOf('.pptx') !== -1
        ) {
            return imgPowerPoint;
        } else {
            return imgFile;
        }
    }

    componentDidUpdate() {
        const { modelListFile } = this.props;
        if (modelListFile.length > 0) {
            this.setState({
                modelList: modelListFile[0]
            });
        }
    }

    convertCompanyName = (name: any) => {
        const { company } = this.props;
        var company_name: string = '';
        for (var i = 0; i < company.result_list.length; i++) {
            var data: any = company.result_list[i];
            if (data.sid === name) {
                company_name = data.company_name;
            }
        }
        return company_name;
    };

    // Check role access before open a file
    public handleBeforeOpenFile(indexString: string, item: any) {
        // const companyCode = indexString.split('|')[0];
        return (event: React.MouseEvent<HTMLTableDataCellElement, MouseEvent>) => {
            event.persist();

            const { id: username }: IUserContext = this.context;
            const { disabledDisplay, onSelectFolder } = this.props;

            if (!disabledDisplay) {
                // onSelectFolder({ sid: item.sid, nodeCode: item.bucket, username }, (buttonStates: ButtonStatesType) => {
                //     console.log('ngame buttons', buttonStates);
                // });
                onClickTr(event);
            } else {
                Swal.fire({
                    title: 'คุณไม่มีสิทธิ์การดูไฟล์นี้',
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'ตกลง'
                });
            }
            //try {
            //    const { availableCompanies, list_company }: IUserContext = this.context;

            //    // Don't forget to remove first if statement, We don't need it anymore
            //    const foundAvailableCompany = availableCompanies.find(availableCompany => availableCompany.company_code === companyCode);

            //    if (foundAvailableCompany !== undefined) {
            //        const foundCompany = list_company.find(company => company.sid === foundAvailableCompany.sid);

            //        if (foundCompany !== undefined) {
            //            const roles = foundCompany.role_access.split('|');

            //            if (roles.includes('03')) {
            //                onClickTr(event);
            //            } else {
            //                throw null;
            //            }
            //        } else {
            //            throw null;
            //        }
            //    } else {
            //        throw null;
            //    }
            //} catch (_error) {
            //    // console.log('ngame you cannot access this file!');

            //    Swal.fire({
            //        title: 'คุณไม่มีสิทธิ์การดูไฟล์นี้',
            //        icon: 'error',
            //        showCancelButton: false,
            //        confirmButtonColor: '#3085d6',
            //        confirmButtonText: 'ตกลง'
            //    });
            //}
        };
    }

    public getFileRoles(bucket: string, options?: { idOnly?: boolean }): string[] {
        const { responseFAUM } = this.props;

        if (responseFAUM === undefined || responseFAUM === null || responseFAUM.total_count === 0) {
            return [];
        }

        const FAUMs = responseFAUM.result_list;
        const foundFAUM = FAUMs.find(faum => faum.roleId.includes(bucket));

        if (foundFAUM !== undefined) {
            const roles = foundFAUM.roleId
                .split('ID')[1]
                .split('|')
                .filter(role => role !== '01');

            return options && options.idOnly ? roles : roles.map(role => this.roleMap[role] || '');
        } else {
            return [];
        }
    }

    public toggleShowModalFileLog = () => {
        this.setState(prevState => ({
            showModalFileLog: !prevState.showModalFileLog
        }));
    };

    public handleFileLog = (primaryId: string) => {
        this.setState({
            primaryId: primaryId
        })

        this.toggleShowModalFileLog()
    }

    render() {
        const { valueModelFile, modelListFile, isNewLoad, checkValueFile, SearchData } = this.props;

        if (checkValueFile === false) {
            this.setState({
                modelList: modelListFile[0]
            });
        }

        const isFromSearch = SearchData !== undefined;

        const THEAD: readonly string[] = ['Name', 'Company', 'Details', 'Metadata', 'Modified','Modified By','Verify','Last Version',''];

        return (
            <React.Fragment>
                <TableContainer>
                    <Table
                        size="sm"
                        className="text-sm"
                    // stickyHeader
                    // aria-label="sticky table"
                    >
                        <Thead>
                            <Tr className="bg-[#ECECEC] [&>th]:border [&>th]:border-[#D8D8D8] [&>th]:font-[Roboto] [&>th]:text-black">
                                <Th>
                                    <input
                                        type="checkbox"
                                        name="all"
                                        onChange={this.handleChangeAll}
                                    />
                                </Th>
                                <Th>
                                    <div className="flex justify-center items-center">
                                        <FaRegFile
                                            size={20}
                                            color="#7A7A7A"
                                        />
                                    </div>
                                </Th>
                                {THEAD.map(thName => (
                                    <th
                                        className={`h-[30px] w-auto border border-[#DDDDDD] px-[16px] py-[8px] text-sm font-normal ${
                                            thName === 'Verify' ? 'text-center' : ''
                                        }`}
                                    >
                                        {thName}
                                    </th>
                                ))}
                                {/* <th>Name</th>
                                {isFromSearch && SearchData !== '' && <th>Path</th>}
                                <th>Company</th>
                                <th>Detail</th>
                                <th>Metadata</th>
                                <th>Modified</th>
                                <th>Modified By</th>
                                <th>Verify</th>
                                <th>Last Version</th>
                                <th></th> */}
                            </Tr>
                        </Thead>
                        {isNewLoad ? (
                            <Tbody>
                                <Tr>
                                    <Td
                                        colSpan={100}
                                        className="bg-[#87C4DA]"
                                    >
                                        <ImSpinner8
                                            size={20}
                                            color="white"
                                            className="animate-spin"
                                        />
                                    </Td>
                                </Tr>
                            </Tbody>
                        ) : null}
                        {modelListFile.length === 0 && !isNewLoad ? (
                            <Tbody>
                                <Tr>
                                    <Td
                                        colSpan={100}
                                        // className="border-b-[#D8D8D8] bg-[#F1F1F1] text-center text-[10pt] text-black"
                                        className="h-[30px] border  border-[#DDDDDD] px-2 py-[5px] text-center text-sm font-normal"
                                    >
                                        <span className="text-[14px]">No Data</span>
                                    </Td>
                                </Tr>
                            </Tbody>
                        ) : modelListFile[0] && modelListFile[0].length === 0 && !isNewLoad ? (
                            <Tbody>
                                <Tr>
                                    <Td
                                        colSpan={100}
                                        // className="border-b-[#D8D8D8] border-t-[#D8D8D8] bg-[#F1F1F1] text-center text-[10pt] text-black"
                                        className="h-[30px] border  border-[#DDDDDD] px-2 py-[5px] text-center text-sm font-normal"
                                    >
                                        <span className="text-[14px]">No Data</span>
                                    </Td>
                                </Tr>
                            </Tbody>
                        ) : null}
                        {modelListFile.length > 0 &&
                            (isFromSearch ? this.props.responseFAUM !== null : true) &&
                            modelListFile[0].map((items: any, index: any) => (
                                <Tbody>
                                    <Tr
                                        key={index}
                                        className="bg-[#D2E2F5] [&>td]:border [&>td]:border-[#D8D8D8]"
                                    >
                                        <Td className='pl-8'>
                                            <input
                                                type="checkbox"
                                                id={index}
                                                name="group"
                                                onChange={this.handleChangeGroup}
                                            />
                                        </Td>
                                        <Td colSpan={100}>
                                            <span>
                                                {`(${items.count_group_id}) Group ID: ${items.group_id}`}
                                            </span>
                                        </Td>
                                    </Tr>
                                    {items.sub_group_detail.map((item, id) => (
                                        <React.Fragment>
                                            <Tr
                                                key={index + '-' + id}
                                                className="bg-[#EFF7FA] [&>td]:border [&>td]:border-[#D8D8D8]"
                                            >
                                                <Td className="pl-12">
                                                    <input
                                                        id={index + '-' + id}
                                                        type="checkbox"
                                                        name="sub_group"
                                                        onChange={this.handleChangeSubGroup}
                                                    />
                                                </Td>
                                                <Td colSpan={100}>
                                                    <span>
                                                        {`(${item.count_sub_group_id}) Sub Group ID: ${item.sub_group_id}`}
                                                    </span>
                                                </Td>
                                            </Tr>
                                            {/* <Tr
                                                        className="bg-white [&>td]:border [&>td]:border-[#D8D8D8] [&>td]:text-center"
                                                    >
                                                        <Td colSpan={3}>
                                                            <div className='flex justify-end'>
                                                                <input
                                                                    // disabled={
                                                                    //     isFromSearch &&
                                                                    //     itemSub.roles.length === 0
                                                                    // }
                                                                    // id={index + '-' + id + '-' + idSub}
                                                                    // type="checkbox"
                                                                    // name="node"
                                                                    // data-filelist={JSON.stringify(itemSub)}
                                                                    // data-filelist={fileData}
                                                                    onChange={this.handleCheckFile}
                                                                />
                                                                    
                                                            </div>
                                                        </Td>
                                                        </Tr> */}
                                            {item.sub_group_id_detail.map(
                                                (itemSub: Models.File, idSub: number) => {
                                                    itemSub.roles = this.getFileRoles(itemSub.bucket, {
                                                        idOnly: true
                                                    });
                                                    const fileData = JSON.stringify(itemSub);

                                                    return (
                                                        <Tr
                                                            className="bg-white [&>td]:border [&>td]:border-[#D8D8D8] [&>td]:text-center"
                                                            key={index + '-' + id + '-' + idSub}
                                                        >
                                                            <Td className="pl-16">
                                                                <input
                                                                    disabled={
                                                                        isFromSearch &&
                                                                        itemSub.roles.length === 0
                                                                    }
                                                                    id={index + '-' + id + '-' + idSub}
                                                                    type="checkbox"
                                                                    name="node"
                                                                    // data-filelist={JSON.stringify(itemSub)}
                                                                    data-filelist={fileData}
                                                                    onChange={this.handleCheckFile}
                                                                />
                                                            </Td>
                                                            <Td>
                                                                <div className="flex justify-center items-center">
                                                                    <img
                                                                        src={this.previewFile(itemSub)}
                                                                        alt="file"
                                                                        width="30"
                                                                        height="30"
                                                                    />
                                                                </div>
                                                            </Td>

                                                            {SearchData !== undefined &&
                                                                SearchData !== '' ? (
                                                                <Td
                                                                    data-filename={itemSub.file_name}
                                                                    data-id={itemSub.id}
                                                                    onClick={this.handleBeforeOpenFile(
                                                                        itemSub.index_string,
                                                                        itemSub
                                                                    )} // <- why u do that? move it to span!
                                                                >
                                                                    {this.highlight(
                                                                        itemSub.file_name,
                                                                        SearchData,
                                                                        itemSub.id
                                                                    )}
                                                                </Td>
                                                            ) : (
                                                                <Td
                                                                    data-filename={itemSub.file_name}
                                                                    data-id={itemSub.id}
                                                                >
                                                                    <span
                                                                        className="cursor-pointer hover:text-[#40B6E4] hover:underline hover:underline-offset-1"
                                                                        data-filename={
                                                                            itemSub.file_name
                                                                        }
                                                                        data-id={itemSub.id}
                                                                        onClick={this.handleBeforeOpenFile(
                                                                            itemSub.index_string,
                                                                            itemSub
                                                                        )}
                                                                    >
                                                                        {itemSub.file_name}
                                                                    </span>
                                                                </Td>
                                                            )}

                                                            {SearchData !== undefined &&
                                                                SearchData !== '' && (
                                                                    <Td>
                                                                        <span>{itemSub.full_path}</span>
                                                                        <span>
                                                                            {this.getFileRoles(
                                                                                itemSub.bucket
                                                                            ).map(role => {
                                                                                const lowerCaseRole =
                                                                                    role.toLowerCase();
                                                                                const color =
                                                                                    lowerCaseRole.includes(
                                                                                        'create'
                                                                                    )
                                                                                        ? '#ffc107'
                                                                                        : lowerCaseRole.includes(
                                                                                            'change'
                                                                                        ) ||
                                                                                            lowerCaseRole.includes(
                                                                                                'move'
                                                                                            ) ||
                                                                                            lowerCaseRole.includes(
                                                                                                'copy'
                                                                                            )
                                                                                            ? 'info'
                                                                                            : lowerCaseRole.includes(
                                                                                                'delete'
                                                                                            )
                                                                                                ? '#dc3545'
                                                                                                : '#007bff';

                                                                                return (
                                                                                    <Badge
                                                                                        color={color}
                                                                                        className="mr-1 p-1"
                                                                                    >
                                                                                        {role}
                                                                                    </Badge>
                                                                                );
                                                                            })}
                                                                        </span>
                                                                    </Td>
                                                                )}
                                                            <Td>
                                                                {this.convertCompanyName(itemSub.sid)}
                                                            </Td>
                                                            {valueModelFile !== undefined ? (
                                                                <Td>
                                                                    {/* <Button
                                                                    // outline
                                                                    // style={{ border: '0px' }}
                                                                    data-id={itemSub.id}
                                                                    onClick={e =>
                                                                        this.props.onDetailClick(
                                                                            e,
                                                                            index,
                                                                            id,
                                                                            idSub
                                                                        )
                                                                    }
                                                                > */}
                                                                    {/* <i className="fas fa-list-ul"></i> */}
                                                                    <BsList
                                                                        data-id={itemSub.id}
                                                                        size={20}
                                                                        className="w-full cursor-pointer hover:text-[#40B6E4]"
                                                                        onClick={e =>
                                                                            this.props.onDetailClick(
                                                                                e,
                                                                                index,
                                                                                id,
                                                                                idSub
                                                                            )
                                                                        }
                                                                    />
                                                                    {/* </Button> */}
                                                                </Td>
                                                            ) : (
                                                                <Td>
                                                                    {/* <Button
                                                                    // outline
                                                                    // style={{ border: '0px' }}
                                                                    data-id={itemSub.id}
                                                                    onClick={e =>
                                                                        this.props.onDetailClick(
                                                                            e,
                                                                            index,
                                                                            id,
                                                                            idSub
                                                                        )
                                                                    }
                                                                > */}
                                                                    {/* <i className="fas fa-list-ul"></i> */}
                                                                    <BsList
                                                                        data-id={itemSub.id}
                                                                        size={20}
                                                                        className="w-full cursor-pointer hover:text-[#40B6E4]"
                                                                        onClick={e =>
                                                                            this.props.onDetailClick(
                                                                                e,
                                                                                index,
                                                                                id,
                                                                                idSub
                                                                            )
                                                                        }
                                                                    />
                                                                    {/* </Button> */}
                                                                </Td>
                                                            )}
                                                            {valueModelFile !== undefined ? (
                                                                <Td
                                                                // className="td-meta-data td-center"
                                                                // style={{ width: 100 }}
                                                                >
                                                                    {/* <Button
                                                                    // outline
                                                                    // style={{ border: '0px' }}
                                                                    data-id={itemSub.id}
                                                                    onClick={e =>
                                                                        this.props.onMetaDataClick(
                                                                            e,
                                                                            index,
                                                                            id,
                                                                            idSub
                                                                        )
                                                                    }
                                                                > */}
                                                                    {/* <i className="far fa-eye"></i> */}
                                                                    <BsEye
                                                                        data-id={itemSub.id}
                                                                        size={20}
                                                                        className="w-full cursor-pointer hover:text-[#40B6E4]"
                                                                        onClick={e =>
                                                                            this.props.onMetaDataClick(
                                                                                e,
                                                                                index,
                                                                                id,
                                                                                idSub
                                                                            )
                                                                        }
                                                                    />
                                                                    {/* </Button> */}
                                                                </Td>
                                                            ) : (
                                                                <Td>
                                                                    {/* <Button
                                                                    // outline
                                                                    // style={{ border: '0px' }}
                                                                    data-id={itemSub.id}
                                                                    onClick={e =>
                                                                        this.props.onMetaDataClick(
                                                                            e,
                                                                            index,
                                                                            id,
                                                                            idSub
                                                                        )
                                                                    }
                                                                >
                                                                    <i className="far fa-eye"></i>
                                                                </Button> */}
                                                                    <BsEye
                                                                        data-id={itemSub.id}
                                                                        size={20}
                                                                        className="w-full cursor-pointer hover:text-[#40B6E4]"
                                                                        onClick={e =>
                                                                            this.props.onMetaDataClick(
                                                                                e,
                                                                                index,
                                                                                id,
                                                                                idSub
                                                                            )
                                                                        }
                                                                    />
                                                                </Td>
                                                            )}
                                                            {valueModelFile !== undefined ? (
                                                                <Td
                                                                // className="td-update-on"
                                                                // style={{ width: 120 }}
                                                                >
                                                                    {formatYMDToDMY(itemSub.created_on)}
                                                                </Td>
                                                            ) : (
                                                                <Td>
                                                                    {formatYMDToDMY(itemSub.created_on)}
                                                                </Td>
                                                            )}
                                                            {valueModelFile !== undefined ? (
                                                                <Td
                                                                // className="td-update-by"
                                                                // style={{ width: 100 }}
                                                                >
                                                                    {itemSub.created_by}
                                                                </Td>
                                                            ) : (
                                                                <Td
                                                                // className="td-update-by"
                                                                // style={{ width: '180px' }}
                                                                >
                                                                    {itemSub.created_by}
                                                                </Td>
                                                            )}
                                                            {valueModelFile !== undefined ? (
                                                                <Td>
                                                                    <ImCancelCircle
                                                                        size={20}
                                                                        color="red"
                                                                        className="w-full"
                                                                    />
                                                                </Td>
                                                            ) : itemSub.verify === true ? (
                                                                <Td>
                                                                    <FaCheckCircle
                                                                        size={20}
                                                                        color="green"
                                                                        className="w-full"
                                                                    />
                                                                </Td>
                                                            ) : (
                                                                <Td>
                                                                    <ImCancelCircle
                                                                        size={20}
                                                                        color="#B3261E"
                                                                        className="w-full"
                                                                    />
                                                                </Td>
                                                            )}
                                                            {valueModelFile !== undefined ? (
                                                                <Td
                                                                // className="td-version td-center"
                                                                // style={{ width: 150 }}
                                                                >
                                                                    <Link
                                                                        to={
                                                                            '/dms/file-version/' +
                                                                            encodeURIComponent(
                                                                                itemSub.primary_index
                                                                            )
                                                                        }
                                                                        target="_blank"
                                                                    >
                                                                        {itemSub.version}
                                                                    </Link>
                                                                </Td>
                                                            ) : (
                                                                <Td>
                                                                    <div className="flex justify-start items-start">
                                                                        <Link
                                                                            to={
                                                                                '/dms/file-version/' +
                                                                                encodeURIComponent(
                                                                                    itemSub.primary_index
                                                                                )
                                                                            }
                                                                            target="_blank"
                                                                        >
                                                                            {itemSub.version}
                                                                        </Link>
                                                                            
                                                                    </div>
                                                                </Td>
                                                            )}
                                                            {valueModelFile !== undefined ? (
                                                                <Td
                                                                // className="td-version td-center"
                                                                // style={{ width: 150 }}
                                                                >
                                                                    <Link
                                                                        to={`/dms/file-explorer/log?p_id=${itemSub.primary_index}`}
                                                                        target="_blank"
                                                                    >
                                                                        <span className="text-[#3397C3]">
                                                                            Logs
                                                                        </span>
                                                                    </Link>
                                                                </Td>
                                                            ) : (
                                                                <Td>
                                                                    {/* <Link
                                                                    to={`/dms/file-explorer/log?p_id=${itemSub.primary_index}`}
                                                                    target="_blank"
                                                                >
                                                                    <span className="text-[#3397C3]">
                                                                        Logs2
                                                                    </span>
                                                                </Link> */}
                                                                    <span onClick={() => this.handleFileLog(itemSub.primary_index)} className="cursor-pointer hover:underline text-[#3397C3]">
                                                                        Logs
                                                                    </span>
                                                                </Td>
                                                            )}
                                                        </Tr>
                                                    );
                                                }
                                            )}
                                        </React.Fragment>
                                    ))}
                                </Tbody>
                            ))}
                    </Table>
                </TableContainer>

                <FileLogModal
                    isOpen={this.state.showModalFileLog}
                    onClose={this.toggleShowModalFileLog}
                    primaryId={this.state.primaryId}
                />
            </React.Fragment>
        );
    }
}

export default connect((state: ApplicationState) => ({ ...state.folderAuthorizeUserMapping }), {
    ...FolderAuthorizeUserMappingStore.actionCreators
})(TableFilesExplorer);
