import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as Stores from '../../store/File';
import * as Models from '../../models/File';
import { InputGroup, InputGroupAddon, InputGroupText, Input, FormGroup, Label, Modal, ModalHeader, ModalBody, ModalFooter, Button, Card, CardBody } from 'reactstrap';

import imgFile from '../../Images/Icon/icon-File.png'
import imgPDF from '../../Images/Icon/icon-PDF.png'
import imgXLS from '../../Images/Icon/icon-XLS.png'
import imgWord from '../../Images/Icon/icon-Word.png'
import imgPowerPoint from '../../Images/Icon/icon-PowerPoint.png'

type Store = Models.FileState & typeof Stores.actionCreators;

class SerachFileExtenstionComponent extends React.PureComponent<any, { pageLoad: boolean, key: string, listModelFile: Array<Models.File>, version: number, showModal: boolean, listSelectfile: Array<Models.File>, checkKey: boolean }, Store> {

    constructor(props: any) {
        super(props);

        this.state = {
            pageLoad: true,
            key: "",
            listModelFile: [],
            version: 0,
            showModal: false,
            listSelectfile: [],
            checkKey: true
        }

        this.handleChangeKey = this.handleChangeKey.bind(this);
        this.searchClick = this.searchClick.bind(this);
        this.searchEnter = this.searchEnter.bind(this);
        this.previewFile = this.previewFile.bind(this);
    }

    public componentDidMount() {
        this.ensureDataFetched();
    }

    private ensureDataFetched() {
        this.setState({ pageLoad: true });

        this.props.requestFunction(this.state.pageLoad, "", true, this.state.version);

        this.setState({ pageLoad: false });
    }

    private handleChangeKey(event: any) {
        this.setState({ key: event.target.value, checkKey: true });
    }

    private searchClick(event: any) {
        if (this.state.checkKey) {
            this.props.requestFunction(true, this.state.key, true, "", this.state.version);
            this.setState({
                checkKey: false
            })
        }
    }

    private searchEnter(event: any) {
        if (event.key === 'Enter' && this.state.checkKey) {
            this.props.requestFunction(true, this.state.key, true, "", this.state.version);
            this.setState({
                checkKey: false
            })
        }
    }

    private previewFile(item: Models.File) {

        if (item.file_name.indexOf('.jpg') !== -1 || item.file_name.indexOf('.png') !== -1 || item.file_name.indexOf('.JPG') !== -1 || item.file_name.indexOf('.PNG') !== -1) {
            return item.file_path;
        } else if (item.file_name.indexOf('.pdf') !== -1) {
            return imgPDF;
        } else if (item.file_name.indexOf('.xls') !== -1 || item.file_name.indexOf('.xlsx') !== -1) {
            return imgXLS;
        } else if (item.file_name.indexOf('.docx') !== -1) {
            return imgWord;
        } else if (item.file_name.indexOf('.pptx') !== -1) {
            return imgPowerPoint;
        } else {
            return imgFile;
        }

    }

    handleClose = () => {
        if (this.state.showModal) {
            this.setState({
                showModal: false
            })
        } else {
            this.setState({
                showModal: true
            })
        }
    }

    checkedValue = (id: any, ss: any) => {
        console.log(id);
        var row: Array<Models.File> = []
        row = this.state.listSelectfile
        row.push(id)
        this.setState({
            listSelectfile: row
        })
    }

    deleteSelectList = (id: any) => {
        console.log(id);
        var row: Array<Models.File> = []
        row = this.state.listSelectfile
        row.splice(id, 1)
        this.setState({
            listSelectfile: row
        })
        this.forceUpdate()
    }

    render() {

        if (this.props.version > this.state.version) {

            if (this.props.version - this.state.version === 1) {
                this.setState({ listModelFile: [] });

            } else if (this.props.version - this.state.version === 3) {
                this.setState({
                    listModelFile: this.props.response.result_list as Array<Models.File>
                });
            }

            this.setState({ version: parseInt(this.props.version) });
        }

        return (
            <div className="div-search-main">
                <InputGroup>
                    <Input placeholder="search..." type="text" value={this.state.key} onChange={this.handleChangeKey} onKeyPress={this.searchEnter} />
                    <InputGroupAddon addonType="append">
                        <InputGroupText onClick={this.searchClick}><i className="fa fa-search" aria-hidden="true"></i></InputGroupText>
                    </InputGroupAddon>
                    <InputGroupAddon addonType="append">
                        <InputGroupText onClick={() => this.handleClose()}><i className="fa fa-list" aria-hidden="true"></i></InputGroupText>
                    </InputGroupAddon>
                </InputGroup>
                <hr />
                <div className="div-list-file-search">
                    {this.state.listModelFile.length > 0 && this.state.listModelFile.map((item: Models.File, idx) =>
                        <div key={item.id} >
                            <div className="row">
                                <div className="col-1 text-center">
                                    <FormGroup check>
                                        <Label check>
                                            <Input type="checkbox" onChange={() => this.checkedValue(this.state.listModelFile[idx], this)} />
                                        </Label>
                                    </FormGroup>
                                </div>
                                <div className="col-2">
                                    <img src={this.previewFile(item)} alt="file" style={{ maxWidth: "170px" }} />
                                </div>
                                <div className="col-9">
                                    <a href={item.file_path} target="bank">
                                        <p>{item.file_path}</p>
                                        <h5>{item.file_name}</h5>
                                    </a>
                                    <p>{item.index_string}</p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <Modal className="modal-dialog-scrollable" scrollable={true} isOpen={this.state.showModal} toggle={() => this.handleClose()} size="lg" backdrop="static" keyboard={false} style={{ maxWidth: '1920px', width: '98%', height: '90%' }}>
                    <ModalHeader toggle={() => this.handleClose()}>รายการที่เลือกไว้</ModalHeader>
                    <ModalBody>

                        <div className="div-list-file-search mt-3">
                            {this.state.listModelFile.length > 0 && this.state.listSelectfile.map((item: Models.File, idx) =>
                                <Card>
                                    <CardBody>
                                        <div key={item.id} >
                                            <div className="row">
                                                <div className="col-1">
                                                    <Button color="danger" onClick={() => this.deleteSelectList(idx)}><i className="fa fa-times-circle" aria-hidden="true"></i> ลบ</Button>
                                                </div>
                                                <div className="col-2">
                                                    <img src={this.previewFile(item)} alt="file" style={{ maxWidth: "170px" }} />
                                                </div>
                                                <div className="col-9">
                                                    <a href={item.file_path} target="bank">
                                                        <p>{item.file_path}</p>
                                                        <h5>{item.file_name}</h5>
                                                    </a>
                                                    <p>{item.index_string}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            )}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="info" onClick={() => this.handleClose()}>บันทึก</Button>
                        <Button color="secondary" onClick={() => this.handleClose()}>ปิด</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default connect((state: ApplicationState) => state.file,
    Stores.actionCreators
)(SerachFileExtenstionComponent);