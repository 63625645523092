import * as React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as Stores from '../../store/FileManage';
import * as Models from '../../models/File';
import Swal from 'sweetalert2';
import CancelIcon from '@material-ui/icons/Cancel';
import { Link } from 'react-router-dom';
import { FaTimesCircle } from "react-icons/fa";
import DeleteButton from '../common/DeleteButton';

interface Props {
    [key: string]: any;
    selectedFiles: Models.File[];
    removeSelectedFile: (atIndex: number) => void;
}

interface State {
    version: number,
    pageLoad: boolean,
    isLoadSuccess: boolean,
    isOpenModalDelete: boolean
}

class ButtonDeleteFileComponent extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);

        this.state = {
            version: 0,
            pageLoad: true,
            isLoadSuccess: false,
            isOpenModalDelete: false,
        }

        this.handleClickRemoveSelectedFile = this.handleClickRemoveSelectedFile.bind(this);
    }

    componentDidUpdate() {
        if (this.props.version > this.state.version) {
            if (this.props.version - this.state.version === 2) {
                document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
                Swal.fire({
                    title: 'สำเร็จ!',
                    text: 'กดปุ่มยืนยัน',
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'ยืนยัน!'
                }).then((result) => {

                    this.props.reloading("reset");
                    this.toggleModal();
                })
            }
            this.setState({ version: this.props.version });
        }
    }

    OnDeleteClick = (e: any) => {
        document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
        this.setState({ pageLoad: true, isLoadSuccess: false });
        this.props.requestFunction(this.props.selectedFiles, "", this.state.version, "DELETE");
    }

    OnButtonDeleteClick = (e: any) => {
        this.setState({ pageLoad: true, isLoadSuccess: false });
        this.toggleModal();
        this.props.getValueFileList("get")
    }
    toggleModal = () => {
        this.setState({
            isOpenModalDelete: !this.state.isOpenModalDelete
        });
    }
    private replaceDate(e: string) {

        var date = "";
        if (e !== null) {
            if (e.length >= 10) {
                var date = e.substring(0, 10);
            }
        }
        return date;
    }

    public handleClickRemoveSelectedFile(atIndex: number) {
        return (_event: React.MouseEvent<HTMLElement>) => {
            if (this.props.selectedFiles.length === 1) {
                this.toggleModal();
            }

            this.props.removeSelectedFile(atIndex);
        }
    }

    render() {
        let isDisableButton = false;
        if (this.props.folderSelectedCode.includes("S-")) {
            isDisableButton = true;
        }

        return (
            <div style={{ display: "inline", paddingLeft: "5px" }}>
                {/* {!this.props.disabled ?
                    <label className="btn-delete-file" htmlFor="btnDeleteFile"> <FaTimesCircle style={{ marginBottom: '4px' }} /> Delete</label> :
                    <label className="btn-delete-file-disable"> <FaTimesCircle style={{ marginBottom: '4px' }} /> Delete</label>
                }
                <Button id="btnDeleteFile" className="btn-delete-file-hide" color="danger" size="sm" style={{ borderRadius: "5px" }} disabled={isDisableButton} onClick={this.OnButtonDeleteClick}>Delete</Button> */}
                <Button
                    disabled={this.props.disabled}
                    outline
                    className={(!this.props.disabled) ? 'btn-delete-file' : 'btn-delete-file-disable'}
                    onClick={this.OnButtonDeleteClick}
                >
                    <FaTimesCircle style={{ marginBottom: '4px', marginRight: '4px' }} />
                    Delete
                </Button>
                <Modal isOpen={this.state.isOpenModalDelete} className="modal-confirm">
                    <ModalHeader toggle={this.toggleModal}> Delete File </ModalHeader>
                    <ModalBody>
                        <div style={{ paddingBottom: "10px" }}>
                            <h4 style={{ display: "inline" }}>Are you sure to </h4> <h4 style={{ color: "red", display: "inline" }}>delete</h4><h4 style={{ display: "inline" }}> file ?</h4>
                        </div>
                        <table className='table table-main-style'>
                            <thead>
                                <tr>
                                    <td></td>
                                    <td>Name</td>
                                    <td className="td-update-on">Modified</td>
                                    <td className="td-update-by">Modified By</td>
                                    <td className="td-verify">Verify</td>
                                    <td className="td-version">Last Version</td>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.selectedFiles.length > 0 && this.props.selectedFiles.map((item: Models.File, index: number) =>
                                    <tr key={item.id}>
                                        <td className="text-center">
                                            <DeleteButton onClick={this.handleClickRemoveSelectedFile(index)} />
                                        </td>
                                        <td data-filepath={item.file_path} className="td-file-name">{item.file_name}</td>
                                        <td className="td-update-on">{this.replaceDate(item.created_on)}</td>
                                        <td className="td-update-by">{item.created_by}</td>
                                        <td className="td-verify"><CancelIcon className="td-icon-verify th-center" style={{ color: "red" }} /></td>
                                        { /*  <td className="td-verify"><CheckCircleIcon className="td-icon-verify" /></td>*/}
                                        <td className="td-version"><Link to={'/dms/file-version/' + item.primary_index} target="_blank">{item.version}</Link></td>
                                    </tr>
                                )}

                            </tbody>
                        </table>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={this.OnDeleteClick}>Delete</Button>
                        <Button color="secondary" onClick={this.toggleModal}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default connect((state: ApplicationState) => state.fileDelete,
    Stores.actionCreators
)(ButtonDeleteFileComponent);