import React from 'react';
import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';

import EditFolderViewModel from './EditFolder.viewmodel';
import OutsideClickFallback from '../../../components/common/hoc/OutsideClickFallback';
import * as FolderModels from '../../../models/Folder';
import FoldersComponent from '../../../common/components/FolderManagement/FoldersComponent';

export type EditFolderProps = {
    folderName: string;
    parentFolder: string;
    folderInfo: FolderModels.Folder;
};

function EditFolder(props: EditFolderProps) {
    const { toggleShowFolders, isShowFolders } = EditFolderViewModel();
    const { folderName, parentFolder, folderInfo } = props;

    return (
        <React.Fragment>
            <InputGroup>
                <InputGroup>
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText className="w-[160px]">Parent Folder</InputGroupText>
                    </InputGroupAddon>
                    <div className="w-[800px]">
                        <OutsideClickFallback handleClickOutside={() => toggleShowFolders(false)}>
                            <div className="relative w-full">
                                <Input
                                    type="text"
                                    disabled={false}
                                    className="w-full"
                                    // value={this.state.bucket}
                                    value={parentFolder}
                                    placeholder="Select folder"
                                    onKeyPress={event => event.preventDefault()} // Prevent user typing
                                    // onChange={(e) => this.addTextvalue(e, "bucket")}
                                    onFocus={() => toggleShowFolders(true)}
                                />
                                {isShowFolders && (
                                    <div className="absolute left-0 z-[99999] max-h-[300px] w-full overflow-auto rounded-sm bg-white shadow-lg">
                                        <FoldersComponent
                                            onSelectFolder={(
                                                fullPath: string,
                                                nodeCode: string,
                                                _nodeName: string,
                                                _metadata: string
                                            ) => {
                                                if (nodeCode !== '') {
                                                    let stateModelFolder = folderInfo;
                                                    stateModelFolder.node_parent = nodeCode;
                                                    // this.setState({
                                                    //     modelFolder: stateModelFolder,
                                                    //     parentFolder: _nodeName
                                                    // });
                                                }
                                            }}
                                            folderSid={folderInfo.sid}
                                        />
                                    </div>
                                )}
                            </div>
                        </OutsideClickFallback>
                    </div>
                </InputGroup>
                <InputGroup className="mt-3">
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText className="w-[160px]">Folder Name</InputGroupText>
                    </InputGroupAddon>
                    <div className="w-[800px]">
                        <Input
                            placeholder="Folder Name"
                            type="text"
                            defaultValue={folderName}
                            // onChange={this.handleChangeName}
                        />
                    </div>
                </InputGroup>
            </InputGroup>
        </React.Fragment>
    );
}

export default EditFolder;
