import { parseResponseError } from 'common/helpers/handle-error';
import { FileLog } from 'models/FileLog';
import { DatasourceReturnType, ResponseError, ResponseList } from 'models/domain/Response';

import { requestGetFileLog } from '../datasources/fileLog.datasource';

export async function getFileLog(params: {
    p_id: string;
}): Promise<DatasourceReturnType<ResponseError, ResponseList<FileLog>>> {
    try {
        const data = (await requestGetFileLog(params)).data;
        // console.log('[Debug] getFileLog success', data);

        return [null!, data];
    } catch (error) {
        // console.log('[Debug] getFileLog error', error);
        const reason = parseResponseError(error);

        if (reason) {
            return [reason, null!];
        }

        throw error;
    }
}
