import 'bootstrap/dist/css/bootstrap.css';

import { createRoot } from 'react-dom/client';

import * as serviceWorker from './serviceWorker';
import App from './App';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);

root.render(<App />);

serviceWorker.unregister();
