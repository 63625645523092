import React from 'react';
import { Input, InputProps } from 'reactstrap';
import FoldersComponent from '../../common/components/FolderManagement/FoldersComponent';

import OutsideClickFallback from './hoc/OutsideClickFallback';

interface Props {
    disablePreventTyping?: boolean;
    onSelectFolder?: (
        fullPath: string,
        nodeCode: string,
        nodeName: string,
        metadata: string
    ) => void;
}

interface State {
    isShowFolders: boolean;
}

class SelectFolderInput extends React.Component<Props & InputProps, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isShowFolders: false
        };

        this.preventTyping = this.preventTyping.bind(this);
        this.handleInputFocus = this.handleInputFocus.bind(this);
    }

    public preventTyping(event: React.KeyboardEvent<HTMLInputElement>): void {
        event.preventDefault();
    }

    public handleInputFocus(event: React.FocusEvent<HTMLInputElement>): void {
        this.setState({ isShowFolders: true });

        if (this.props.onFocus) {
            this.props.onFocus(event);
        }
    }

    public render(): React.ReactNode {
        const {
            style = {},
            placeholder = 'Select Folder',
            disablePreventTyping = false,

            onKeyPress,
            onSelectFolder = () => null
        } = this.props;

        return (
            <OutsideClickFallback
                handleClickOutside={() => this.setState({ isShowFolders: false })}
            >
                <div style={{ width: '100%', position: 'relative' }}>
                    <Input
                        {...this.props}
                        style={{ width: '100%', ...style }}
                        placeholder={placeholder}
                        onKeyPress={disablePreventTyping ? this.preventTyping : onKeyPress}
                        onFocus={this.handleInputFocus}
                    />
                    {this.state.isShowFolders && (
                        <div
                            className="bg-light rounded-sm shadow-lg"
                            style={{
                                width: '100%',
                                maxHeight: '300px',
                                overflow: 'auto',
                                position: 'absolute',
                                left: 0,
                                zIndex: 99999
                            }}
                        >
                            <FoldersComponent onSelectFolder={onSelectFolder} />
                        </div>
                    )}
                </div>
            </OutsideClickFallback>
        );
    }
}

export default SelectFolderInput;
