import * as React from 'react';
import FilesVersionComponent from './FilesVersionComponent';
import { Link } from 'react-router-dom';
import Switch from "react-switch";

class ShowFileVersionComponent extends React.Component<any, { isReLoadFile: boolean, isVesionFile: boolean}> {

    constructor(props: any) {
        super(props);
        this.state = {
            isReLoadFile: false,
            isVesionFile: false
        }
    }

    render() {
        
        const fileKey = this.props.match.params.key;
        var { isReLoadFile } = this.state;
        var { isVesionFile } = this.state;
       
        return (
            <div>
                <div className="row row-file-explorer" >
                    <div className="col-12">
                        <div className="pl-3">
                            <div className="row">
                                <div className="col-8">
                                    <h5 className="file-vision-font"> 
                                        <Link to='/' className="text-dark file-vision-font" >Home &gt; </Link>
                                    Primary Index : {fileKey}</h5>
                                </div>
                                <div className="col-4" style={{ position: "relative", textAlign: "right" }}>
                                    <span style={{ position: "absolute", marginLeft: "-140px", fontFamily:"sans-serif" }}>ดูรายละเอียดเพิ่มเติม </span>
                                        <Switch onChange={() => this.setState({ isVesionFile: !isVesionFile })} checked={isVesionFile} />
                                </div>
                            </div>
                        </div>
                        <hr style={{ marginBottom: "-1px" }} />
                        <div className="div-file-component-explorer">
                            <FilesVersionComponent primaryIndex={fileKey} isReLoadFile={isReLoadFile} isVesionFile={isVesionFile} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default ShowFileVersionComponent;