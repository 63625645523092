import * as React from 'react';

class TXTView extends React.Component<any, { text: string }> {
    constructor(props: any) {
        super(props);

        this.state = {
            text: '',
        }
    }

    componentDidMount() {
        var { filePath } = this.props

        fetch(filePath)
            .then(response => response.text())
            .then(text => {
                this.setState({
                    text: text
                })
            })
    }

    render() {
        let { text } = this.state
        if (text) {
            return (
                <div className="div-disable-select" onContextMenu={(e) => e.preventDefault()}>
                    {text}
                </div>
            );
        } else {
            return (
                <div></div>
            );
        }
    }
}

export default TXTView;