import React, { useCallback, useEffect, useState } from 'react';

import { useLoader } from 'common/hooks';
import { FileLog } from 'models/FileLog';
import { getFileLog } from 'data/fileLog/repositories/fileLogRepository';

const TABS: string[] = ['Log', 'Raw Log'];

export type Props = {
    primaryId?: string;
};

function FileLogViewModel(props: Props) {
    const { primaryId } = props;

    const [fileLog, setFileLog] = useState<FileLog[]>();
    const [selectedTab, setSelectedTab] = useState(TABS[0]);

    const loader = useLoader();

    useEffect(() => {
        if (!primaryId) {
            return;
        }

        fetchFileLog(primaryId);
    }, [primaryId]);

    const handleChangeTab = (selectedTab: string): void => {
        setSelectedTab(selectedTab);
    };

    const fetchFileLog = useCallback(async (p_id: string): Promise<void> => {
        try {
            loader.show();

            const [error, data] = await getFileLog({ p_id });

            if (error) {
                throw error;
            }

            if (data.total_count === 0) {
                console.info(`fetch file log total count: ${data.total_count}`);
                return;
            }

            setFileLog(data.result_list);

            loader.hide();
        } catch (error) {
            console.log('fetch file log error:', error);

            loader.hide();
        }
    }, []);

    return {
        TABS,
        handleChangeTab,
        selectedTab,
        fileLog,
        fetchFileLog
    };
}

export default FileLogViewModel;
