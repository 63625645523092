import 'components/dms/dms.css';
import 'components/NavMenu.css';

import { useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Button, Badge, useBoolean, IconButton } from '@chakra-ui/react';
import { AiFillCaretDown, AiOutlineMenu, AiOutlineQuestionCircle } from 'react-icons/ai';
import { IoNotificationsOutline, IoSearchSharp } from 'react-icons/io5';
import { PiChatsLight } from 'react-icons/pi';

import F1_LOGO from 'Images/f1-new-logo.png';
import { useStore } from 'common/hooks';
import { convertSearchKey } from 'modules/common';

import SearchDialog from './SearchDialog';
import MenuDrawer from './MenuDrawer';
import MenuSelect from './MenuSelect';
import { BsTicketPerforated } from 'react-icons/bs';
import SpeedTestPopover from './SpeedTestPopover';
import ProfilePopover from './ProfilePopover';

function TopNavigation() {
    const history = useHistory();
    const { appStore, userStore } = useStore();
    const { version } = appStore;
    const { userInfo, userRole } = userStore;

    const [showSearchDialog, setShowSearchDialog] = useBoolean();
    const [showMenuDrawer, setShowMenuDrawer] = useBoolean();

    const username =
        userInfo?.citizen_id ??
        userInfo?.username ??
        userInfo?.first_name + ' ' + userInfo?.last_name;

    const handleClickLogout = useCallback(() => {
        history.replace('/auth/signout');
    }, []);

    const handleSubmitSearch = useCallback(
        (search: string) => {
            history.push(`/dms/search-file?search=${convertSearchKey(search)}`);
        },
        [history]
    );

    return (
        <header>
            <div className="flex bg-[#1C2F36]">
                {/* Logo */}
                <div className="trapezoid-logo w-[150px] xl:w-[416px]">
                    <Link
                        to="/"
                        className="mt-1 inline-flex h-[40px]"
                    >
                        <img
                            src={F1_LOGO}
                            className="w-full"
                        />
                    </Link>
                </div>

                <div className="flex flex-1 items-center justify-between gap-6 px-4">
                    <span className="inline-flex items-center gap-2 whitespace-nowrap text-lg font-semibold text-white">
                        DMS {version && <Badge className="text-xs">V {version}</Badge>}
                    </span>

                    <Button
                        leftIcon={<IoSearchSharp />}
                        size="sm"
                        className="hidden max-w-xs basis-1/2 justify-start bg-secondary-700 text-secondary-100 focus:focused-outline sm:flex"
                        onClick={setShowSearchDialog.on}
                    >
                        Search files
                    </Button>
                    <SearchDialog
                        isOpen={showSearchDialog}
                        onClose={setShowSearchDialog.off}
                        onSubmitSearch={handleSubmitSearch}
                    />

                    <div className="hidden items-center text-sm lg:flex">
                        <IconButton
                            size="md"
                            variant="unstyled"
                            aria-label="Line Connect"
                            className="group inline-flex justify-center"
                            icon={
                                <img
                                    src="https://focusone-documents.s3.ap-southeast-1.amazonaws.com/line-icon/Line-Icon.png"
                                    className="h-8 w-8"
                                />
                            }
                            onClick={() => void 0}
                        />

                        <IconButton
                            size="md"
                            variant="unstyled"
                            aria-label="Ticket"
                            className="group"
                            icon={
                                <BsTicketPerforated
                                    size={28}
                                    className="text-white transition-colors group-hover:text-primary-900"
                                />
                            }
                        />

                        <SpeedTestPopover />

                        <IconButton
                            size="md"
                            variant="unstyled"
                            aria-label="Notification"
                            className="group"
                            icon={
                                <IoNotificationsOutline
                                    size={28}
                                    className="text-white transition-colors group-hover:text-primary-900"
                                />
                            }
                        />

                        <IconButton
                            size="md"
                            variant="unstyled"
                            aria-label="Question"
                            className="group"
                            icon={
                                <PiChatsLight
                                    size={28}
                                    className="text-white transition-colors group-hover:text-primary-900"
                                />
                            }
                        />

                        <MenuSelect userRole={userRole} />

                        <IconButton
                            size="md"
                            variant="unstyled"
                            aria-label="Help"
                            className="group"
                            icon={
                                <AiOutlineQuestionCircle
                                    size={28}
                                    className="mr-1 text-white transition-colors group-hover:text-primary-900"
                                />
                            }
                        />

                        <div className="flex cursor-pointer items-center gap-1 border-l border-l-white pl-2">
                            <AiFillCaretDown
                                size={14}
                                className="text-white"
                            />
                            <span className="text-[14px] text-white">EN</span>
                        </div>

                        <ProfilePopover
                            userName={username}
                            userRole={userRole}
                            email=""
                            onClickNavigateToProfile={() => history.push('/profile')}
                            onClickLogout={handleClickLogout}
                            onClickOpenSupportModal={() => void 0}
                        />
                    </div>
                    <Button
                        variant="unstyled"
                        className="inline-flex items-center justify-center active:bg-secondary-700 lg:hidden"
                        onClick={setShowMenuDrawer.on}
                    >
                        <AiOutlineMenu size={20} />
                    </Button>
                </div>
            </div>

            <MenuDrawer
                username={username}
                userRole={userRole}
                isOpen={showMenuDrawer}
                onClose={setShowMenuDrawer.off}
            />
        </header>
    );
}

export default observer(TopNavigation);
