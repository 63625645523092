import React from 'react';

import { formatYMDToDMY } from '../../../../../modules/common';

import FileLogTableViewModel from './FileLogTable.viewmodel';
import { FileLog } from '../../../../../models/FileLog';

const FILE_LOG_THEAD: readonly string[] = ['Action By', 'Log Action', 'Message', 'Action On'];

const RAW_FILELOG_THEAD: readonly string[] = [
    'Primary Index',
    'DMS ID',
    'Log Action',
    'Request Message',
    'Message',
    'Created By',
    'Created On'
];

const MAP_FILELOG_THEAD: Map<string, readonly string[]> = new Map<string, readonly string[]>([
    ['Log', FILE_LOG_THEAD],
    ['Raw Log', RAW_FILELOG_THEAD]
]);

type FileLogTableViewProps = {
    selectedTab: string;
    fileLog?: FileLog[];
};

function FileLogTableView(props: FileLogTableViewProps) {
    const { selectedTab, fileLog } = props;

    const { handleAction, handleMessage } = FileLogTableViewModel();

    return (
        <div className="flex overflow-auto">
            <table className="flex-1 table-auto border-2">
                <thead className="bg-[#ECECEC]">
                    <tr>
                        {selectedTab &&
                            MAP_FILELOG_THEAD.get(selectedTab)?.map(thName => (
                                <th className="h-[30px] whitespace-nowrap border border-[#DDDDDD] px-2 py-[5px] text-xs font-normal">
                                    {thName}
                                </th>
                            ))}
                    </tr>
                </thead>
                <tbody>
                    {selectedTab === 'Log' &&
                        fileLog &&
                        fileLog.length > 0 &&
                        fileLog.map((log, index) =>
                            (log.log_action === 'create' || log.log_action === 'copy') &&
                            log.message ? (
                                <tr
                                    key={`log-${log.id}-${index}`}
                                    className="h-[30px] text-xs  font-normal [&>*]:border [&>*]:border-[#DDDDDD] [&>*]:px-2 [&>*]:py-[5px]"
                                >
                                    <td>{log.created_by}</td>
                                    {/*<td>{log.dms_primary_index}</td>*/}
                                    {/*<td>{log.dms_id}</td>*/}
                                    <td>{handleAction(log.log_action)}</td>
                                    {/*<td>{log.request_message}</td>*/}
                                    <td>
                                        {handleMessage(
                                            log.log_action,
                                            log.log_action === 'create' || log.log_action === 'copy'
                                                ? log.message
                                                : log.request_message
                                        )}
                                    </td>
                                    <td>{formatYMDToDMY(log.created_on)}</td>
                                </tr>
                            ) : (
                                log.log_action === 'view' && (
                                    <tr
                                        key={`log-${log.id}-${index}`}
                                        className="h-[30px] text-xs  font-normal [&>*]:border [&>*]:border-[#DDDDDD] [&>*]:px-2 [&>*]:py-[5px]"
                                    >
                                        <td>{log.created_by}</td>
                                        {/*<td>{log.dms_primary_index}</td>*/}
                                        {/*<td>{log.dms_id}</td>*/}
                                        <td>{handleAction(log.log_action)}</td>
                                        {/*<td>{log.request_message}</td>*/}
                                        <td>
                                            {handleMessage(log.log_action, log.request_message)}
                                        </td>
                                        <td>{formatYMDToDMY(log.created_on)}</td>
                                    </tr>
                                )
                            )
                        )}

                    {selectedTab === 'Raw Log' &&
                        fileLog &&
                        fileLog.length > 0 &&
                        fileLog.map((log, index) => (
                            <tr
                                key={`log-${log.id}-${index}`}
                                className="h-[30px] text-xs  font-normal [&>*]:border [&>*]:border-[#DDDDDD] [&>*]:px-2 [&>*]:py-[5px]"
                            >
                                <td>{log.dms_primary_index}</td>
                                <td>{log.dms_id}</td>
                                <td>{log.log_action}</td>
                                <td>{log.request_message}</td>
                                <td>{log.message}</td>
                                <td>{log.created_by}</td>
                                <td>{formatYMDToDMY(log.created_on)}</td>
                            </tr>
                        ))}

                    {!fileLog && (
                        <td
                            colSpan={MAP_FILELOG_THEAD.get(selectedTab)?.length}
                            className="h-[30px] border  border-[#DDDDDD] px-2 py-[5px] text-center text-xs font-normal"
                        >
                            No Log
                        </td>
                    )}
                </tbody>
            </table>
        </div>
    );
}

export default FileLogTableView;
