import * as React from "react";
import { Button, InputGroup, InputGroupAddon, Input, Table, ButtonGroup } from 'reactstrap';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import Swal from 'sweetalert2'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaPlusCircle, FaKey, FaTrashAlt, FaRegCopy } from 'react-icons/fa';

export interface initRow {
    sid: string,
    id: string,
    name: string,
    token: string,
    endpoint: string,
    created_by: string,
    created_on: string
}

class CallbackMaster extends React.Component<any, { rows: Array<initRow>, checkRequest: boolean, checkResponse: boolean, checkGenerate: boolean, textarea: any, requestrows: Array<initRow>, deleterows: Array<initRow>, copied: boolean, company: string }>{
    constructor(props: any) {
        super(props);
        this.state = {
            rows: [],
            requestrows: [],
            deleterows: [],
            checkRequest: true,
            checkResponse: true,
            checkGenerate: false,
            textarea: "",
            copied: false,
            company: ""
        }
    }

    componentDidUpdate() {
        if (this.state.company !== this.props.company) {
            this.setState({
                company: this.props.company
            })
            //this.ensureDataFetched();
        }
    }

    handleAddRow = () => {
        const item = {
            sid: "",
            id: "",
            name: "",
            token: "",
            endpoint: "",
            created_by: "",
            created_on: ""
        };
        this.setState({
            rows: [...this.state.rows, item]
        });
        setTimeout(() => {
            console.log(this.state.rows)
        }, 1000);
    };

    handleRemoveSpecificRow = (idx: number) => () => {
        const rows = [...this.state.rows]
        rows.splice(idx, 1)
        this.setState({ rows })
    }

    onCopy = () => {
        this.setState({ copied: true });
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: 'success',
            title: 'Copy success!'
        })
    };

    generateToken = (id: any, idx: any) => {
        if (id !== undefined && id !== null && id !== "") {
            this.props.requestCallbackToken(true, "POST", id, idx)
            this.setState({
                checkGenerate: true
            })
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
        } else {
            Swal.fire({
                title: 'ไม่สำเร็จ!',
                text: 'กรุณาบันทึก Name และ Callback ก่อนจึงจะสามารถ generate token ได้',
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!'
            }).then((result) => {
                if (result.value) {
                }
            })
        }
    }

    render() {
        return (
            <div>
                <div>
                    <div className='trapezoid' style={{ width: 'auto', display: 'inline-block' }}>
                        <span className="callback-font">
                            Callback
                        </span>
                    </div>
                </div>
                <Table hover className="custom-table" style={{ width: '100%' }}>
                    <thead>
                        <tr>
                            <th style={{ width: '5%' }}>#</th>
                            <th style={{ width: '20%' }}>Name</th>
                            <th style={{ width: '45%' }}>Token</th>
                            <th style={{ width: '30%' }}>Callback</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.rows.map((item, idx) => (
                            <tr id="addr0" key={idx}>
                                <td className='text-center'><Button className="mx-2" variant="danger" size="sm" onClick={this.handleRemoveSpecificRow(idx)}><FaTrashAlt /></Button></td>
                                <td>
                                    <InputGroup>
                                        <Input placeholder="Name" />
                                    </InputGroup>
                                </td>
                                <td>
                                    <InputGroup className="mb-3">
                                        <Input type="textarea" name="text" id="exampleText" />
                                        <InputGroupAddon addonType="append">
                                            <ButtonGroup vertical>
                                                <Button variant="info" onClick={() => this.generateToken(item.id, idx)}><FaKey /></Button>
                                                <CopyToClipboard onCopy={this.onCopy} text={this.state.rows[idx].token}>
                                                    <Button variant="primary"><FaRegCopy /></Button>
                                                </CopyToClipboard>
                                            </ButtonGroup>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </td>
                                <td>
                                    <InputGroup>
                                        <Input placeholder="Endpoint" />
                                    </InputGroup>
                                </td>
                            </tr>
                        ))}
                        <tr>
                            <td className='text-center'><Button className="mx-2" variant="info" size="sm" onClick={this.handleAddRow}><FaPlusCircle /></Button></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        )
    }
}
export default connect((state: ApplicationState) => ({ ...state.appState }))(CallbackMaster)