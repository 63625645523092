const colorPalette = {
    primary: {
        50: '#e2f4f8',
        100: '#b5e4ef',
        200: '#89d1e5',
        300: '#63bfdb',
        400: '#4bb2d6',
        500: '#3aa5d0',
        600: '#3398c3',
        700: '#2a85b1',
        800: '#27759d',
        900: '#1d557b'
    },
    secondary: {
        50: '#e6eff4',
        100: '#c8d8dd',
        200: '#a7bcc5',
        300: '#86a2ac',
        400: '#6d8d99',
        500: '#537a88',
        600: '#486b77',
        700: '#3a5761',
        800: '#2c444d',
        900: '#1c2f36'
    }
};

export default colorPalette;
