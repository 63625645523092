import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import * as Model from '../models/File';

interface RequestFunctionAction {
    type: 'REQUEST_FILEDELETE_BUCKET';
    version: number;
}

interface ReceiveFunctionAction {
    type: 'RECEIVE_FILEDELETE_BUCKET';
    model: Model.ResponseFile;
    version: number;
}

type KnownAction = RequestFunctionAction | ReceiveFunctionAction

export const actionCreators = {
    requestFunction: (listFile: Array<Model.File>, nodeCode: string, version: number, method: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        var obj = {
            total_count: listFile.length,
            result_list: listFile
        }

        if (appState && appState.folderMap && method === "DELETE") {

            fetch(`/v1/dms/delete`, {
                method: 'PUT',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('DMS_APPLICATION'),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(obj)
            })
                .then(response => {
                    return response.json() as Promise<Model.ResponseFile>
                })
                .then(data => {
                    console.log("response", data);
                    dispatch({ type: 'RECEIVE_FILEDELETE_BUCKET', model: data, version: version + 2 });
                })
                .catch(error => {
                    dispatch({ type: 'REQUEST_FILEDELETE_BUCKET', version: version + 1 });
                });
        }

        if (appState && appState.folderMap && method === "PUT") {
            fetch(`/v1/dms/editlist?nodeCode=` + nodeCode, {
                method: 'PUT',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('DMS_APPLICATION'),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(obj)
            })
                .then(response => {
                    return response.json() as Promise<Model.ResponseFile>
                })
                .then(data => {
                    dispatch({ type: 'RECEIVE_FILEDELETE_BUCKET', model: data, version: version + 3 });
                })
                .catch(error => {
                    dispatch({ type: 'REQUEST_FILEDELETE_BUCKET', version: version + 1 });
                });
        }

        if (appState && appState.fileDetailImportExcel && method === "IMPORT") {
            console.log("fileDetailImportExcel", obj);

            fetch(`/v1/dms/editdetail`, {
                method: 'PUT',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('DMS_APPLICATION'),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(obj)
            }).then(response => {
                console.log("response", response);
                return response.json() as Promise<Model.ResponseFile>
            }).then(data => {
                console.log("data", data);
                dispatch({ type: 'RECEIVE_FILEDELETE_BUCKET', model: data, version: version + 4 });
            }).catch(error => {
                console.log("error", error);
                dispatch({ type: 'REQUEST_FILEDELETE_BUCKET', version: version + 1 });
            });
        }
        else if (appState && appState.folderMap && method === "Copy") {
            fetch(`/v1/dms/copy?nodeCode=` + nodeCode, {
                method: 'PUT',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('DMS_APPLICATION'),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(obj)
            })
                .then(response => {
                    return response.json() as Promise<Model.ResponseFile>
                })
                .then(data => {
                    dispatch({ type: 'RECEIVE_FILEDELETE_BUCKET', model: data, version: version + 3 });
                })
                .catch(error => {
                    dispatch({ type: 'REQUEST_FILEDELETE_BUCKET', version: version + 1 });
                });
        }
    }
};

const unloadedState: Model.FileState = { isLoading: false, response: undefined, version: 0 };

export const reducer: Reducer<Model.FileState> = (state: Model.FileState | undefined, incomingAction: Action): Model.FileState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {

        case 'REQUEST_FILEDELETE_BUCKET':
            return {
                isLoading: false,
                version: action.version
            };
        case 'RECEIVE_FILEDELETE_BUCKET':
            return {
                isLoading: true,
                response: action.model,
                version: action.version
            };

        default: return state;
    }
};