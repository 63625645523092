import * as React from 'react';
import { Container, Row, Col, Button, Table, Spinner, Form, InputGroup, FormControl, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import Pagination from '@material-ui/lab/Pagination';
import { FaCopy, FaEnvelopeOpenText, FaTimesCircle } from 'react-icons/fa'
import { ApplicationState } from '../../store';
//import { formatRFC3339TpDMY, findColurLevel } from '../modules/Common'

import * as GetLogStore from '../../store/Logger'
import * as GetLogModel from '../../models/Logger'
import { yellow } from '@material-ui/core/colors';
import { reverse } from 'dns';
type store = GetLogModel.GetLoggerState & typeof GetLogStore.actionCreators

class LogTable extends React.Component<any, { isready: boolean, pageSize: number, pageBtn: any, keySearchTb: any, checkPageSize: boolean, SearchLoggerReq: GetLogModel.SearchLoggerReq, isShowMessageModal: boolean, selectMessageID: any, messageMode: string, formData: Array<GetLogModel.LoggerModel> }, store>{

    constructor(props: any) {
        super(props);
        this.state = {
            isready: true,
            pageSize: 25,
            pageBtn: [''],
            checkPageSize: true,
            SearchLoggerReq: {
                function_name: '',
                action: '',
                log_type: '',
                legacy_id: '',
                created_by: '',
                created_on: '',
            },
            isShowMessageModal: false,
            selectMessageID: null,
            messageMode: "",
            formData: [],
            keySearchTb: ""
        }
        this.handleSearch = this.handleSearch.bind(this)
        this.handlePageSize = this.handlePageSize.bind(this)
        this.handleChangePage = this.handleChangePage.bind(this)
        this.handleChangeSearchInput = this.handleChangeSearchInput.bind(this)
        this.hilightText = this.hilightText.bind(this)
        this.formatRFC3339TpDMY = this.formatRFC3339TpDMY.bind(this)
    }

    componentDidMount() {
        this.props.requestGetLogger(this.state.isready, "GET", "", "", "", "", "", "", 1, this.state.pageSize);
    }

    handlePageSize(e: any) {
        //console.log("5555")
        //console.log(e.target.value)
        //const { SearchLoggerReq } = this.state;
        const { searchLoggerReq } = this.props;
        //console.log(SearchLoggerReq)
        var parse = parseInt(e.target.value)
        this.setState({
            pageSize: parse,
            isready: true,
            checkPageSize: true

        });
        this.props.requestGetLogger(true, "GET", searchLoggerReq.function_name, searchLoggerReq.legacy_id, searchLoggerReq.action, searchLoggerReq.log_type, searchLoggerReq.created_by, searchLoggerReq.created_on, 1, parse,)
    }

    handleChangePage = (event: any, size: any) => {
        //const { SearchLoggerReq } = this.state;
        const { searchLoggerReq } = this.props;
        //console.log(SearchLoggerReq)
        this.setState({
            isready: true,
        })
        this.props.requestGetLogger(true, "GET", searchLoggerReq.function_name, searchLoggerReq.legacy_id, searchLoggerReq.action, searchLoggerReq.log_type, searchLoggerReq.created_by, searchLoggerReq.created_on, 1, this.state.pageSize)
        //this.props.requestGetDmsConfig(true, "GET", "", size, this.state.pageSize)
    }

    componentDidUpdate(nextProps: any, nextState: any) {
        const { checkkey, GetAPI } = this.props
        if (checkkey === "Reset" && checkkey !== undefined) {
            console.log("Reset")
            console.log(checkkey)
            this.setState({
                formData: [],
            })
            this.handleSearch()
        }

        if (GetAPI.statusLogger === 200 && GetAPI.isLoadLogger && GetAPI.responseLogger['result_list'] !== undefined && this.state.checkPageSize) {
            var countingPage: any = 0
            var dataArr: any = []
            countingPage = Math.ceil(GetAPI.responseLogger['total_count'] / this.state.pageSize)
            //console.log(countingPage)

            for (let i = 0; i < countingPage; i++) {
                var data: any = {}
                data['page'] = i + 1
                //data['pageTab'] = this.props.pageTab
                dataArr.push(data)
            }

            this.setState({
                pageBtn: dataArr,
                checkPageSize: false
            })
        }

        if (GetAPI.statusLogger === 200 && GetAPI.isLoadLogger && GetAPI.responseLogger['result_list'] !== undefined && this.state.isready) {
            console.log('Change datatable')
            console.log(GetAPI.responseLogger['result_list'])
            console.log(dataArr)
            this.setState({
                formData: GetAPI.responseLogger['result_list'],
                isready: false,
            })
        }
        else if ((GetAPI.statusLogger === 200 || GetAPI.statusLogger !== 200) && GetAPI.isLoadLogger && GetAPI.responseLogger['result_list'] === undefined && this.state.isready) {
            this.setState({
                formData: [],
                isready: false,
            })
        }
    }

    handleSearch() {
        //document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
        const { searchLoggerReq } = this.props;
        //console.log(searchLoggerReq)
        //console.log("handleSearch 204")
        const { isready, pageSize } = this.state
        this.props.requestGetLogger(true, "GET", searchLoggerReq.function_name, searchLoggerReq.legacy_id, searchLoggerReq.action, searchLoggerReq.log_type, searchLoggerReq.created_by, searchLoggerReq.created_on, 1, pageSize,)
        this.props.callbackkey()
        this.setState({
            SearchLoggerReq: this.props.SearchLoggerReq,
            checkPageSize: true,
            isready: true
        })
    }

    handleCloseMessageModal = (id: any, key: string) => {
        if (this.state.isShowMessageModal) {
            this.setState({
                isShowMessageModal: false,
                selectMessageID: null,
                messageMode: ""
            })
        } else {
            this.setState({
                isShowMessageModal: true,
                selectMessageID: id,
                messageMode: key
            })
        }
    }

    handleChangeSearchInput(e: any) {
        console.log(e.target.value)
        var arr: any = []
        var key: any = e.target.value
        const { GetAPI } = this.props;
        if (GetAPI.statusLogger === 200 && GetAPI.isLoadLogger && GetAPI.responseLogger['result_list'] !== undefined) {
            //console.log(GetAPI.responseLogger['result_list'])
            for (var i = 0; i < GetAPI.responseLogger['result_list'].length; i++) {
                let data: any = GetAPI.responseLogger['result_list'][i]

                let id: string = data.id.toLowerCase()
                let legacy_id: string = data.legacy_id.toLowerCase()
                let function_name: string = data.function_name.toLowerCase()
                let action: string = data.action.toLowerCase()
                let log_type: string = data.log_type.toLowerCase()
                //let timestamp: string = formatRFC3339TpDMY(String(data.timestamp)).toLowerCase()
                let data_action: string = data.data_action.toLowerCase()
                let created_by: string = data.created_by.toLowerCase()
                let created_on: string = this.formatRFC3339TpDMY(String(data.created_on)).toLowerCase()

                let check_id: number = id.indexOf(key.toLowerCase())
                let check_legacy_id: number = legacy_id.indexOf(key.toLowerCase())
                let check_function_name: number = function_name.indexOf(key.toLowerCase())
                let check_action: number = action.indexOf(key.toLowerCase())
                let check_log_type: number = log_type.indexOf(key.toLowerCase())
                let check_data_action: number = data_action.indexOf(key.toLowerCase())
                let check_created_by: number = created_by.indexOf(key.toLowerCase())
                let check_created_on: number = created_on.indexOf(key.toLowerCase())
                if (check_id > -1 || check_legacy_id > -1 || check_function_name > -1 || check_action > -1 || check_log_type > -1 || check_data_action > -1 || check_created_by > -1 || check_created_on > -1) {
                    arr.push(data)
                }
            }
            this.setState({
                formData: arr,
                keySearchTb: key
            })
        }
    }

    hilightText(val: any) {
        //console.log('val')
        //console.log(val)
        const { keySearchTb } = this.state;
        if (keySearchTb !== "" && val !== null && val !== undefined && val !== "") {
            var check: number = (val.toLowerCase()).indexOf(keySearchTb.toLowerCase())
            if (check > -1) {
                var str = val.substring(0, check) + '<span style="background-color: yellow;">' + val.substring(check, check + keySearchTb.length) + '</span>' + val.substring(check + keySearchTb.length)
                //console.log('str')
                //console.log(str)
                return (
                    <span>{val.substring(0, check)}<span style={{ backgroundColor: 'yellow' }}>{val.substring(check, check + keySearchTb.length)}</span>{val.substring(check + keySearchTb.length)}</span>
                )
            }
            else {
                return val;
            }
        }
        else {
            return val;
        }
    }

    formatRFC3339TpDMY(dmy: string) {
        if (dmy !== undefined && dmy !== "" && dmy !== 'null') {
            var year = dmy.substring(0, 4);
            var day = dmy.substring(8, 10);
            var month = dmy.substring(5, 7);
            var Datevalue = "";
            var datevalue = "";
            var UTC = new Date()
            if (dmy.length < 9) {
                datevalue = day + '-' + month + '-' + year

            } else {
                var times = dmy.substring(11, 19);
                datevalue = day + '-' + month + '-' + year + ' ' + times;
            }
            return datevalue;
        } else {
            Datevalue = "";

            return Datevalue;
        }
    }

    render() {
        const { GetAPI } = this.props
        console.log(this.state.formData)
        return (
            <div>
                <Row className='mt-3'>
                    <Col xs={6}>
                        <div className='d-flex justify-content-start my-2 my-lg-0'>
                            <Form inline>
                                <Form.Label className="my-1 mr-2" htmlFor="inlineFormCustomSelectPref">
                                    Show
                                </Form.Label>
                                <Form.Control
                                    as="select"
                                    className="my-1 mr-sm-2"
                                    id="inlineFormCustomSelectPref"
                                    custom
                                    size="sm"
                                    value={this.state.pageSize}
                                    onChange={this.handlePageSize}
                                >
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </Form.Control>
                                <Form.Label className="my-1 mr-2" htmlFor="inlineFormCustomSelectPref">
                                    entries
                                </Form.Label>
                            </Form>
                        </div>
                    </Col>
                    <Col xs={6}>
                        <Form.Group className="mb-3 flex-row-reverse" as={Row}>
                            <Col sm="5">
                                <div className='d-flex justify-content-end my-2 my-lg-0'>
                                    <Pagination count={this.state.pageBtn.length > 0 ? this.state.pageBtn.length : undefined} onChange={this.handleChangePage} shape="rounded" siblingCount={0} defaultPage={1} showFirstButton showLastButton />
                                </div>
                            </Col>
                            <Form.Group as={Row} >
                                <Form.Label className="text-center mr-10 mt-1">Search :</Form.Label>
                                <Col>
                                    <Form.Control type="text" size='sm' placeholder='Search...' onChange={(e) => this.handleChangeSearchInput(e)} />
                                </Col>
                            </Form.Group>
                        </Form.Group>
                    </Col>
                </Row>
                <table className='table custom-table table-hover'>
                    <thead className="">
                        <tr style={{ width: '100%' }} className="text-center table-active thead-light font-weight-bold require-field">
                            <th style={{ width: '5%' }}>No</th>
                            <th style={{ width: '10%' }}>Legacy ID</th>
                            <th style={{ width: '25%' }}>Function Name</th>
                            <th style={{ width: '10%' }}>Action</th>
                            <th style={{ width: '10%' }}>Log Type</th>
                            <th style={{ width: '20%' }}>Data</th>
                            <th style={{ width: '10%' }}>Create By</th>
                            <th style={{ width: '10%' }}>Created On</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!GetAPI.isLoadLogger ? <tr><td colSpan={8} style={{ background: "rgba(209, 250, 221, 1)", color: "black", fontSize: '10pt', textAlign: "center" }}><Spinner animation="border" size="sm" />&nbsp;&nbsp;Loading...</td></tr> : <tr></tr>}
                        {GetAPI.isLoadLogger && this.state.formData.length === 0 ? <tr><td colSpan={8} style={{ background: "#b7b7a4", color: "black", fontSize: '10pt', textAlign: "center" }}>&nbsp;&nbsp;No Data...</td></tr> : <tr></tr>}
                        {this.state.formData.length !== 0 && this.state.formData.map((item: GetLogModel.LoggerModel, idx: any) => {
                            return (
                                <tr key={idx}>
                                    <td className="text-center">{this.hilightText(item.id)}</td>
                                    <td>{this.hilightText(item.legacy_id)}</td>
                                    <td>{this.hilightText(item.function_name)}</td>
                                    <td className="text-center" >{this.hilightText(item.action)}</td>
                                    <td className="text-center" >{this.hilightText(item.log_type)}</td>
                                    <td>{this.hilightText(item.data_action)}</td>
                                    <td className="text-center">{this.hilightText((item.created_by))}</td>
                                    <td className="text-center">{this.hilightText(this.formatRFC3339TpDMY(String(item.created_on)))}</td>

                                </tr>
                            )
                        })}
                    </tbody>
                </table>

                {/*Modal Message Error*/}
                <Modal show={this.state.isShowMessageModal} onHide={() => this.handleCloseMessageModal(null, '')} size="lg" backdrop="static" keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {GetAPI.statusLogger === 200 && GetAPI.isLoadLogger && GetAPI.responseLogger['result_list'] !== null && this.state.selectMessageID !== null &&
                                GetAPI.responseLogger['result_list'][this.state.selectMessageID].function_name
                            }
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col>
                                {GetAPI.statusLogger === 200 && GetAPI.isLoadLogger && GetAPI.responseLogger['result_list'] !== null && this.state.selectMessageID !== null &&
                                    <FormControl as="textarea" rows={5} aria-label="With textarea" value={GetAPI.responseLogger['result_list'][this.state.selectMessageID][this.state.messageMode]} readOnly />
                                }
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={() => this.handleCloseMessageModal(null, '')}>
                            <FaTimesCircle /> Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}
export default connect((state: ApplicationState) => ({ ...state.Logger }), ({ ...GetLogStore.actionCreators }))(LogTable)