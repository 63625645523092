import React from 'react';
import {
    Button,
    Modal,
    ModalCloseButton,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalFooter,
    ModalHeader
} from '@chakra-ui/react';
import { Input, Collapse, CardBody, Row, Col } from 'reactstrap';
import FoldersComponent from 'common/components/FolderManagement/FoldersComponent';
import { connect } from 'react-redux';
import { ApplicationState } from 'store';
import * as Stores from 'store/FileManage';
import * as Models from 'models/File';
import Swal from 'sweetalert2';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { Link } from 'react-router-dom';
import * as Model from 'models/Folder';
import { FaAngleDoubleDown, FaCopy, FaMinus, FaPlus } from 'react-icons/fa';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import KeyValuePairInput from '../common/KeyValuePairInput';
import {
    convertMetadataToEntries,
    validateMetadataCollections,
    convertFullpathToFolderName
} from 'modules/common';
import SelectFolderInput from '../common/SelectFolderInput';

import DeleteButton from '../common/DeleteButton';
import F1ConnectForm, { F1ConnectFormValues } from '../common/F1ConnectForm';

interface Props {
    [key: string]: any;
    isOpen: boolean;
    onClose: () => void;
    selectedFiles: Models.File[];
    changeSelectedFiles: (value: Models.File[]) => void;
    handleChangeSelectedFiles: (
        key: keyof Pick<Models.File, 'group_id' | 'bucket'>,
        atIndex: number
    ) => (event: React.ChangeEvent) => void;
    handleCreatableSelectChange: (
        key: keyof Pick<Models.File, 'index_string'>,
        atIndex: number
    ) => Function;
    onMetadataChange: (
        atFileIndex: number,
        atMetadataIndex: number
    ) => (key: string, value: string) => void;
    addMetadata: (atFileIndex: number) => void;
    removeMetadata: (atFileIndex: number, removeAtIndex: number) => void;
    removeSelectedFile: (atIndex: number) => void;
}

interface State {
    folderSelectedFullPath: string;
    version: number;
    pageLoad: boolean;
    isLoadSuccess: boolean;
    isOpenModalCopy: boolean;
    IsOpenDropdown: boolean;
    folderSelectedCode: string;
    folderSelectedName: string;
    listFolder: Array<Model.FolderMap>;
    isOpenConfigMetadataMap: Map<number | string, boolean>;
    groupId: string;
    searchKey: string;
}

type Store = Models.FileState & typeof Stores.actionCreators;

class ButtonCopyFileComponent extends React.Component<Props, State, Store> {
    constructor(props: any) {
        super(props);

        this.state = {
            version: 0,
            pageLoad: true,
            isLoadSuccess: false,
            isOpenModalCopy: false,
            IsOpenDropdown: false,
            folderSelectedCode: '',
            folderSelectedName: '',
            folderSelectedFullPath: '',
            listFolder: [],
            isOpenConfigMetadataMap: new Map(),
            groupId: '',
            searchKey: ''
        };

        this.handleSelectFolderEachFile = this.handleSelectFolderEachFile.bind(this);
        this.handleBlurGroupId = this.handleBlurGroupId.bind(this);
        this.handleClickRemoveSelectedFile = this.handleClickRemoveSelectedFile.bind(this);
        this.handleClickApplyAll = this.handleClickApplyAll.bind(this);
        this.handleClickMassMaintain = this.handleClickMassMaintain.bind(this);
        this.handleChangeState = this.handleChangeState.bind(this);
        this.handleChangeSearchKey = this.handleChangeSearchKey.bind(this);
    }

    componentDidUpdate() {
        if (this.props.version > this.state.version) {
            if (this.props.version - this.state.version === 3 && this.props.btClick === 'copy') {
                Swal.fire({
                    title: 'Success',
                    icon: 'success'
                });
                // this.props.onEditSuccess();
                this.props.forceRerender();
                // this.props.reloading("reset")
                // this.toggleModalCopy();
                // this.props.onClickSaveBt("")
            }
            this.setState({ version: this.props.version });
        }
    }

    setTextHeaderModal = () => {
        var txtHead = 'Copy Files';
        return txtHead;
    };

    toggleModalCopy = () => {
        this.setState({
            isOpenModalCopy: !this.state.isOpenModalCopy
        });
    };

    ClickSelected = (fullPath: string, nodeCode: string, name: string) => {
        this.setState({
            folderSelectedFullPath: fullPath,
            folderSelectedCode: nodeCode,
            folderSelectedName: name
        });

        const nextSelectedFiles = this.props.selectedFiles.map(file => {
            file.bucket = nodeCode || file.bucket;
            file.full_path = fullPath || file.full_path;

            return file;
        });

        this.props.changeSelectedFiles(nextSelectedFiles);
    };

    public handleBlurGroupId(event: React.FocusEvent<HTMLInputElement>) {
        const groupId = event.target.value;

        if (!groupId.trim().includes(' ')) {
            return;
        }

        const selectedFiles = [...this.props.selectedFiles];
        const sourceFilePosition = selectedFiles.findIndex(file => (file.group_id = groupId))!; // Must be found
        const splitedGroupIds = groupId.split(' ');
        const copiedFiles = splitedGroupIds.map(groupId => {
            const copiedFile: Models.File = {
                ...selectedFiles[sourceFilePosition],
                group_id: groupId
            };
            return copiedFile;
        });

        selectedFiles.splice(sourceFilePosition, 1, ...copiedFiles);

        this.props.changeSelectedFiles(selectedFiles);
    }

    public handleSelectFolderEachFile(atIndex: number) {
        return (fullPath: string, nodeCode: string, _nodeName: string, _metadata: string) => {
            if (nodeCode === '') {
                return;
            }

            const nextSelectedFiles = [...this.props.selectedFiles];
            const targetFile = nextSelectedFiles[atIndex];
            targetFile.bucket = nodeCode;
            targetFile.full_path = fullPath;

            this.props.changeSelectedFiles(nextSelectedFiles);
        };
    }

    private replaceDate(e: string) {
        var date = '';
        if (e !== null) {
            if (e.length >= 10) {
                var date = e.substring(0, 10);
            }
        }
        return date;
    }

    OnCopyFilesClick = (e: any) => {
        const selectedFiles = this.props.selectedFiles;

        try {
            for (let i = 0; i < selectedFiles.length; i++) {
                const file = selectedFiles[i];
                const metadataCollections = convertMetadataToEntries(file.metadata);

                validateMetadataCollections(metadataCollections);
            }
        } catch (error) {
            Swal.fire({
                title: 'เกิดข้อผิดพลาด',
                text: error as any,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน'
            });
            return;
        }

        this.setState({ pageLoad: true, isLoadSuccess: false });
        this.props.onClickSaveBt('copy');
        this.props.requestFunction(
            this.props.GetDataSelectFileToButton(),
            this.state.folderSelectedCode,
            this.state.version,
            'Copy'
        );
        this.toggleModalCopy();
    };

    OnCopyClick = (e: any) => {
        this.setState({ pageLoad: true, isLoadSuccess: false });
        this.toggleModalCopy();
        this.props.getValueFileList('get');
    };

    public formatToCreatable = (values: string) => {
        try {
            if (values === '' && !values.includes('|')) {
                throw new Error('You must be provide string with pipe include.');
            }

            const splitedValues = values.split('|');

            return splitedValues.map(value => ({ label: value, value }));
        } catch (error) {
            console.warn('Warning:', error);
            return values;
        }
    };

    public toggleConfigMetaData = (key: number | string) => {
        this.setState(prevState => {
            const value = prevState.isOpenConfigMetadataMap.get(key) || false;
            const nextIsOpenConfigMetadataMap = new Map(prevState.isOpenConfigMetadataMap);

            return { isOpenConfigMetadataMap: nextIsOpenConfigMetadataMap.set(key, !value) };
        });
    };

    public handleClickRemoveSelectedFile(atIndex: number) {
        return (_event: React.MouseEvent<HTMLElement>) => {
            if (this.props.selectedFiles.length === 1) {
                this.toggleModalCopy();
            }

            this.props.removeSelectedFile(atIndex);
        };
    }

    public handleClickApplyAll(formValues: F1ConnectFormValues) {
        const keys: (keyof F1ConnectFormValues)[] = [
            'companyCode',
            'documentType',
            'fiscalYear',
            'documentNo'
        ];

        const prepareValue = keys
            .map(key => formValues[key])
            .filter(value => (value as string).length !== 0);
        const newGroupId = prepareValue.join('');
        const newSearchKey = prepareValue.join('|');

        const nextSelectedFiles = [...this.props.selectedFiles].map(file => {
            file.group_id = newGroupId;

            if (newGroupId.length > 0) {
                file.index_string = file.index_string + '|' + newSearchKey + '|' + newGroupId;
            }

            return file;
        });
        this.props.changeSelectedFiles(nextSelectedFiles);
    }

    public handleClickMassMaintain(key: keyof Pick<Models.File, 'group_id' | 'index_string'>) {
        return (_event: React.MouseEvent<HTMLButtonElement>) => {
            const selectedFiles = [...this.props.selectedFiles];
            const nextSelectedFiles =
                key === 'group_id'
                    ? selectedFiles.map(file => {
                          file.group_id = this.state.groupId;

                          return file;
                      })
                    : selectedFiles.map(file => {
                          file.index_string = this.state.searchKey;

                          return file;
                      });

            this.props.changeSelectedFiles(nextSelectedFiles);
        };
    }

    public handleChangeState(key: keyof Pick<State, 'groupId' | 'searchKey'>, value: string) {
        this.setState(prevState => ({
            ...prevState,
            [key]: value
        }));
    }

    public handleChangeSearchKey(newData: { label: string; value: string }[]) {
        if (newData === null) {
            return;
        }

        const newSearchKey = newData.map(({ value }) => value).join('|');

        this.handleChangeState('searchKey', newSearchKey);
    }

    render() {
        let isDisableButtonCopy = false;
        if (this.props.folderSelectedCode.includes('S-')) {
            isDisableButtonCopy = true;
        }

        return (
            <React.Fragment>
                {/* {!this.props.disabled ?
                    <Button onClick={this.OnCopyClick} className="btn-copy-file " htmlFor="btnCopyFile"><FaCopy style={{ marginBottom: '4px' }} />&nbsp;Copy</Button>
                    : <Button disabled={this.props.disabled} className="btn-edit-file-disable"><FaCopy style={{ marginBottom: '4px' }} />&nbsp;Copy</Button>
                } */}
                {/* <Button
                    disabled={this.props.disabled}
                    outline
                    className={!this.props.disabled ? 'btn-edit-file' : 'btn-edit-file-disable'}
                    onClick={this.OnCopyClick}
                >
                    <FaCopy style={{ marginBottom: '4px' }} />
                    &nbsp;Copy
                </Button> */}

                <Modal
                    // isOpen={this.state.isOpenModalCopy}
                    size="full"
                    isOpen={this.props.isOpen}
                    onClose={this.props.onClose}
                >
                    <ModalOverlay />
                    <ModalContent className="m-4 min-h-[calc(100dvh_-_32px)] rounded-md">
                        <ModalHeader>Copy Files</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <Row>
                                <Col xs={12}>
                                    <Row style={{ gap: '6px 0' }}>
                                        <Col xs={6}>
                                            <Row
                                                className="d-flex align-items-center"
                                                style={{ gap: '8px 0' }}
                                            >
                                                {/* Group ID */}
                                                <Col
                                                    xs={3}
                                                    className="font-weight-bold"
                                                >
                                                    Group ID
                                                </Col>
                                                <Col xs={6}>
                                                    <Input
                                                        type="text"
                                                        value={this.state.groupId}
                                                        onChange={event =>
                                                            this.handleChangeState(
                                                                'groupId',
                                                                event.target.value
                                                            )
                                                        }
                                                    />
                                                </Col>
                                                <Col xs={3}>
                                                    <Button
                                                        variant="outline"
                                                        colorScheme="green"
                                                        onClick={this.handleClickMassMaintain(
                                                            'group_id'
                                                        )}
                                                    >
                                                        Mass Maintain
                                                    </Button>
                                                </Col>

                                                {/* Search Key */}
                                                <Col
                                                    xs={3}
                                                    className="font-weight-bold"
                                                >
                                                    Search Key
                                                </Col>
                                                <Col xs={6}>
                                                    <CreatableSelect
                                                        components={{ DropdownIndicator: null }}
                                                        isMulti
                                                        isClearable
                                                        onChange={this.handleChangeSearchKey}
                                                    />
                                                </Col>
                                                <Col xs={3}>
                                                    <Button
                                                        variant="outline"
                                                        colorScheme="green"
                                                        onClick={this.handleClickMassMaintain(
                                                            'index_string'
                                                        )}
                                                    >
                                                        Mass Maintain
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Col>

                                        <Col
                                            xs={6}
                                            className="border-darken-1 rounded-lg border py-3"
                                        >
                                            <F1ConnectForm
                                                handleClickApplyAll={this.handleClickApplyAll}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col
                                    xs={3}
                                    className="sub-left"
                                >
                                    <p
                                        className="d-inline"
                                        style={{ fontFamily: 'sans-serif' }}
                                    >
                                        Choose folder to move files to
                                    </p>
                                    <FoldersComponent onSelectFolder={this.ClickSelected} />
                                </Col>
                                <Col
                                    xs={9}
                                    className="sub-right"
                                >
                                    <div>
                                        <p style={{ fontFamily: 'sans-serif' }}>
                                            Selected Folder : {this.state.folderSelectedFullPath}
                                        </p>
                                        <table className="table-main-style table">
                                            <thead>
                                                <tr>
                                                    <td></td>
                                                    <td>To Folder</td>
                                                    <td>Name</td>
                                                    <td>Group Id</td>
                                                    <td>Search Key</td>
                                                    <td className="td-update-on">Modified</td>
                                                    <td className="td-update-by">Modified By</td>
                                                    <td className="td-verify">Verify</td>
                                                    <td className="td-version">Last Version</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.props.selectedFiles.length > 0 &&
                                                    this.props.selectedFiles.map(
                                                        (item: Models.File, index: number) => (
                                                            <React.Fragment>
                                                                <tr key={item.id}>
                                                                    <td className="text-center">
                                                                        <DeleteButton
                                                                            onClick={this.handleClickRemoveSelectedFile(
                                                                                index
                                                                            )}
                                                                        />
                                                                    </td>
                                                                    <td
                                                                        style={{
                                                                            minWidth: '200px'
                                                                        }}
                                                                    >
                                                                        <SelectFolderInput
                                                                            type="text"
                                                                            value={convertFullpathToFolderName(
                                                                                item.full_path
                                                                            )}
                                                                            onSelectFolder={this.handleSelectFolderEachFile(
                                                                                index
                                                                            )}
                                                                        />
                                                                    </td>
                                                                    <td
                                                                        data-filepath={
                                                                            item.file_path
                                                                        }
                                                                        className="td-file-name"
                                                                    >
                                                                        {item.file_name}
                                                                    </td>
                                                                    <td
                                                                        data-filepath={
                                                                            item.file_path
                                                                        }
                                                                        className="td-file-name"
                                                                        style={{
                                                                            minWidth: '200px'
                                                                        }}
                                                                    >
                                                                        <Input
                                                                            bsSize="sm"
                                                                            value={item.group_id}
                                                                            onChange={this.props.handleChangeSelectedFiles(
                                                                                'group_id',
                                                                                index
                                                                            )}
                                                                            onBlur={
                                                                                this
                                                                                    .handleBlurGroupId
                                                                            }
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <CreatableSelect
                                                                            components={{
                                                                                DropdownIndicator:
                                                                                    null
                                                                            }}
                                                                            isMulti
                                                                            isClearable
                                                                            defaultValue={this.formatToCreatable(
                                                                                item.index_string
                                                                            )}
                                                                            onChange={this.props.handleCreatableSelectChange(
                                                                                'index_string',
                                                                                index
                                                                            )}
                                                                            value={this.formatToCreatable(
                                                                                item.index_string
                                                                            )}
                                                                        />
                                                                    </td>
                                                                    <td className="td-update-on">
                                                                        {this.replaceDate(
                                                                            item.created_on
                                                                        )}
                                                                    </td>
                                                                    <td className="td-update-by">
                                                                        {item.created_by}
                                                                    </td>
                                                                    <td className="td-verify">
                                                                        <CancelIcon
                                                                            className="td-icon-verify"
                                                                            style={{ color: 'red' }}
                                                                        />
                                                                    </td>
                                                                    <td className="td-version">
                                                                        <Link
                                                                            to={
                                                                                '/dms/file-version/' +
                                                                                item.primary_index
                                                                            }
                                                                            target="_blank"
                                                                        >
                                                                            {item.version}
                                                                        </Link>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td
                                                                        className="p-0"
                                                                        colSpan={100}
                                                                    >
                                                                        <Collapse
                                                                            isOpen={this.state.isOpenConfigMetadataMap.get(
                                                                                index
                                                                            )}
                                                                        >
                                                                            <CardBody className="p-2">
                                                                                {this.props
                                                                                    .selectedFiles[
                                                                                    index
                                                                                ].metadata.length >
                                                                                    0 && (
                                                                                    <Row>
                                                                                        <Col
                                                                                            xs={3}
                                                                                            className="font-weight-bold"
                                                                                        >
                                                                                            Key
                                                                                        </Col>
                                                                                        <Col
                                                                                            xs={3}
                                                                                            className="font-weight-bold"
                                                                                        >
                                                                                            Value
                                                                                        </Col>
                                                                                    </Row>
                                                                                )}
                                                                                {convertMetadataToEntries(
                                                                                    this.props
                                                                                        .selectedFiles[
                                                                                        index
                                                                                    ].metadata
                                                                                ).map(
                                                                                    (
                                                                                        [
                                                                                            key,
                                                                                            value
                                                                                        ],
                                                                                        metadataIndex
                                                                                    ) => (
                                                                                        <Row>
                                                                                            <KeyValuePairInput
                                                                                                showPlaceholder
                                                                                                bsSize="sm"
                                                                                                span={
                                                                                                    3
                                                                                                }
                                                                                                valuePair={{
                                                                                                    key,
                                                                                                    value
                                                                                                }}
                                                                                                onChange={this.props.onMetadataChange(
                                                                                                    index,
                                                                                                    metadataIndex
                                                                                                )}
                                                                                            />
                                                                                            <Col>
                                                                                                <Button
                                                                                                    outline="outline"
                                                                                                    size="sm"
                                                                                                    color="danger"
                                                                                                    onClick={_event =>
                                                                                                        this.props.removeMetadata(
                                                                                                            index,
                                                                                                            metadataIndex
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    <FaMinus />
                                                                                                </Button>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    )
                                                                                )}
                                                                                <Row>
                                                                                    <Col xs={6}>
                                                                                        <Button
                                                                                            size="sm"
                                                                                            color="primary"
                                                                                            onClick={_event =>
                                                                                                this.props.addMetadata(
                                                                                                    index
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <FaPlus />
                                                                                        </Button>
                                                                                    </Col>
                                                                                </Row>
                                                                            </CardBody>
                                                                        </Collapse>
                                                                        <Button
                                                                            size="sm"
                                                                            colorScheme="blue"
                                                                            w="full"
                                                                            rounded="md"
                                                                            onClick={() =>
                                                                                this.toggleConfigMetaData(
                                                                                    index
                                                                                )
                                                                            }
                                                                        >
                                                                            Config Metadata{' '}
                                                                            {index + 1}
                                                                            &nbsp;
                                                                            <FaAngleDoubleDown
                                                                                style={{
                                                                                    width: '18px',
                                                                                    height: '18px',
                                                                                    transition:
                                                                                        'transform 0.2s ease',
                                                                                    transform: `rotate(${
                                                                                        this.state.isOpenConfigMetadataMap.get(
                                                                                            index
                                                                                        )
                                                                                            ? '-3.142rad'
                                                                                            : '0'
                                                                                    })`
                                                                                }}
                                                                            />
                                                                        </Button>
                                                                    </td>
                                                                </tr>
                                                            </React.Fragment>
                                                        )
                                                    )}
                                            </tbody>
                                        </table>
                                    </div>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter className="gap-2">
                            <Button
                                colorScheme="green"
                                style={{ fontFamily: 'sans-serif' }}
                                onClick={this.OnCopyFilesClick}
                                disabled={this.state.folderSelectedCode === ''}
                            >
                                Save
                            </Button>
                            <Button
                                colorScheme="gray"
                                style={{ fontFamily: 'sans-serif' }}
                                onClick={this.props.onClose}
                            >
                                Cancel
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </React.Fragment>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.fileEdit,
    Stores.actionCreators
)(ButtonCopyFileComponent);
