import { useState } from 'react';

function EditFolderViewModel() {
    const [isShowFolders, setShowFolders] = useState(false);

    const toggleShowFolders = (isShow: boolean) => {
        setShowFolders(isShow);
    };

    return { toggleShowFolders, isShowFolders };
}

export default EditFolderViewModel;
