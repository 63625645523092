import React, { PropsWithChildren } from 'react';

export function combineComponents(
    ...components: React.FC<PropsWithChildren>[]
): React.FC<PropsWithChildren> {
    return components.reduce(
        (AccumulatedComponents, CurrentComponent) => {
            return ({ children }: PropsWithChildren) => (
                <AccumulatedComponents>
                    <CurrentComponent>{children}</CurrentComponent>
                </AccumulatedComponents>
            );
        },
        ({ children }: PropsWithChildren) => <React.Fragment>{children}</React.Fragment>
    );
}
