import AppStore from './domain/App.store';
import FolderMapStore from './domain/Folder.store';
import UserStore from './domain/User.store';

class RootStore {
    public appStore = new AppStore(this);
    public userStore = new UserStore(this);
    public folderMapStore = new FolderMapStore(this);
}

export default RootStore;
