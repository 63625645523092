import { AuthenticationInfo, UserInfo } from 'models/domain/Authentication';

import { isTokenExpired, parseJwt, redirectToAppPool } from './validation-utillities';

export function extractAccessToken() {
    const rawAuth = localStorage.getItem('DMS_APPLICATION');
    if (!rawAuth) {
        return;
    }

    const auth = JSON.parse(rawAuth) as AuthenticationInfo;
    const payload = parseJwt(auth.access_token);
    if (isTokenExpired(payload)) {
        redirectToAppPool();
    }

    return JSON.parse(payload.extra) as UserInfo;
}
