import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { ResponseListPaging, RequestOptions } from '../models/Common';
import { FileLog, FileLogStoreState } from '../models/FileLog';

enum Actions {
    REQUEST_FILELOG = 'REQUEST_FILELOG',
    RECEIVE_FILELOG = 'RECEIVE_FILELOG',
    FAILURE_FILELOG = 'FAILURE_FILELOG',
    CLEAR_FILELOG = 'CLEAR_FILELOG'
}

interface RequestFileLogAction {
    type: Actions.REQUEST_FILELOG;
}

interface ReceiveFileLogAction {
    type: Actions.RECEIVE_FILELOG;
    model: ResponseListPaging<FileLog>;
}

interface FailureFileLogAction {
    type: Actions.FAILURE_FILELOG;
    error: Error;
    status: number;
}

interface ClearFileLogAction {
    type: Actions.CLEAR_FILELOG;
}

type KnownAction =
    | RequestFileLogAction
    | ReceiveFileLogAction
    | FailureFileLogAction
    | ClearFileLogAction;

export interface ActionCreators {
    requestFileLog: (options: RequestOptions<{}, { p_id: string }>) => AppThunkAction<KnownAction>;
}

export const actionCreators: ActionCreators = {
    requestFileLog: (options): AppThunkAction<KnownAction> => (dispatch, _getState) => {
        dispatch({ type: Actions.REQUEST_FILELOG });

        fetch('/v1/log?' + new URLSearchParams(options.params), {
            method: options.method,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('DMS_APPLICATION')}`,
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    return Promise.reject(response.json());
                }
            })
            .then(data => dispatch({ type: Actions.RECEIVE_FILELOG, model: data }))
            .catch(error => dispatch({ type: Actions.FAILURE_FILELOG, error: new Error(error.message), status: 400 }));
    }
};

const initialState: FileLogStoreState = {
    isLoading: false,
    response: null,
    status: 0,
    error: null
};

export const reducer: Reducer<FileLogStoreState> = (state: FileLogStoreState = initialState, action: KnownAction): FileLogStoreState => {
    switch (action.type) {
        case Actions.REQUEST_FILELOG:
            return {
                ...state,
                isLoading: true
            };
        case Actions.RECEIVE_FILELOG:
            return {
                ...state,
                isLoading: false,
                response: action.model,
                status: 200
            }
        case Actions.FAILURE_FILELOG:
            return {
                ...state,
                isLoading: false,
                error: action.error,
                status: action.status
            }
        case Actions.CLEAR_FILELOG:
            return initialState;
        default:
            return initialState;
    }
};