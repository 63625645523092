import { Button /*, Spinner*/ } from 'reactstrap';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import FolderIcon from '@material-ui/icons/Folder';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import TreeItem, { TreeItemProps } from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import * as React from 'react';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import DeleteImg from '../../Images/Icon/remove.png';
import AddImg from '../../Images/Icon/add.png';
import EditImg from '../../Images/Icon/pencil.png';
import ShareImg from '../../Images/Icon/share.png';
import { ApplicationState } from '../../store';
import * as Models from '../../models/Folder';
import * as Stores from '../../store/Folder';
// import * as StoresFolder from '../../store/FolderManage';
import { Menu, Item, Separator, Submenu, useContextMenu } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.css';
import { checkExp, getRedirectAppPoolUrl, parseJwt } from '../../modules/common';

import ModalManageFolderComponent from './ModalManageFolderComponent';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import { UserContext, IUserContext } from '../../context/UserProvider';

import f1Loading from '../../Images/Gif/f1_loading.gif';

import { BsFolderFill } from 'react-icons/bs';
import { Icons } from '../../assets';

// tree folder
declare module 'csstype' {
    interface Properties {
        '--tree-view-color'?: string;
        '--tree-view-bg-color'?: string;
    }
}

type StyledTreeItemProps = TreeItemProps & {
    bgColor?: string;
    color?: string;
    labelIcon: React.ElementType<SvgIconProps>;
    labelInfo?: string;
    labelText: string;
    labelTextcount: string;
    iconimg: JSX.Element | string; // Required. Don't give "?" to this property --- NGAME
};

const useTreeItemStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            color: theme.palette.text.secondary,
            '&:hover > $content': {
                backgroundColor: theme.palette.action.hover
            },
            '&:focus > $content, &$selected > $content': {
                backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
                color: 'var(--tree-view-color)'
            },
            '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
                backgroundColor: 'transparent'
            }
        },
        content: {
            color: theme.palette.text.secondary,
            paddingRight: theme.spacing(1),
            fontWeight: 'bold',
            '$expanded > &': {
                fontWeight: 'normal'
            },
            fontFamily: 'sans-serif'
        },
        group: {
            marginLeft: 0,
            borderLeft: '1px dashed #bebebe'
        },
        expanded: {},
        selected: {},
        label: {
            fontWeight: 'inherit',
            color: 'inherit',
            fontFamily: 'sans-serif'
        },
        labelRoot: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(0.5, 0)
        },
        labelIcon: {
            marginRight: theme.spacing(1)
        },
        labelText: {
            fontWeight: 'inherit',
            flexGrow: 1,
            fontFamily: 'sans-serif'
        }
    })
);

function StyledTreeItem(props: StyledTreeItemProps) {
    const classes = useTreeItemStyles();
    const {
        labelText,
        labelIcon: LabelIcon,
        labelInfo,
        color,
        bgColor,
        labelTextcount,
        // reactIcon,
        ...other
    } = props;

    var fileCount = '';
    if (labelTextcount !== '0') {
        fileCount = labelTextcount;
    }

    return (
        <TreeItem
            onLabelClick={event => {
                // event.stopPropagation();
                event.preventDefault();
            }}
            label={
                <div className={classes.labelRoot}>
                    {/* <img
                        src={props.iconimg}
                        alt="file"
                        style={{ width: '14px', marginRight: '3px' }}
                    /> */}
                    {typeof props.iconimg === 'string' ? (
                        <img
                            src={props.iconimg}
                            alt="file"
                            style={{ width: '14px', marginRight: '3px' }}
                        />
                    ) : (
                        props.iconimg
                    )}
                    <Typography
                        variant="body2"
                        className={classes.labelText}
                        style={{ fontSize: '12px' }}
                    >
                        {labelText}
                    </Typography>
                    <Typography
                        variant="caption"
                        color="inherit"
                    >
                        {fileCount}
                    </Typography>
                </div>
            }
            style={{
                '--tree-view-color': color,
                '--tree-view-bg-color': bgColor
            }}
            classes={{
                root: classes.root,
                content: classes.content,
                expanded: classes.expanded,
                selected: classes.selected,
                group: classes.group,
                label: classes.label
            }}
            {...other}
        />
    );
}

// right click
const MENU_ID = 'menu-id';

const { show } = useContextMenu({
    id: MENU_ID
});

// store
// type Store = Models.FolderState & typeof Stores.actionCreators;
// type StoreGetEdit = Models.FolderState & typeof StoresFolder.actionCreators;

interface State {
    detailFolder: any;
    pathAll: string;
    levelPath: any;
    pageLoad: boolean;
    version: number;
    listFolder: Array<Models.FolderMap>;
    isLoadSuccess: boolean;
    dataModal: Models.DataModal;
    folderOwner: string;
}

type Props = { [key: string]: any } & typeof Stores.actionCreators & Models.FolderMapState;

class FolderParentComponent extends React.PureComponent<Props, State> {
    static contextType: React.Context<any> | undefined = UserContext;

    public context!: React.ContextType<typeof UserContext>;

    constructor(props: Props) {
        super(props);

        this.state = {
            pageLoad: true,
            isLoadSuccess: false,
            version: 0,
            listFolder: [],
            dataModal: {
                sid: '',
                isOpenModal: false,
                mode: '',
                nodeCode: '',
                folderName: '',
                nodeParent: '',
                nodeLevel: 0,
                metadata_group_code: '',
                role_access_code: '',
                max_file_size: '',
                file_extension: '',
                authorizeId: '',
                fullPath: '',
                owner: ''
            },
            levelPath: [],
            pathAll: '',
            detailFolder: [],
            folderOwner: ''
        };

        this.checkNode = this.checkNode.bind(this);
        this.onClickMoreFolder = this.onClickMoreFolder.bind(this);
        this.onContextMenuClick = this.onContextMenuClick.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.afterModalSaveSuccess = this.afterModalSaveSuccess.bind(this);
    }

    public componentDidMount() {
        this.ensureDataFetched();
    }

    public componentDidUpdate() {
        //console.log(this.props.CompanyShortName); // code below is need to be maintenance (performance issues)
        //console.log(this.props.availableCompanies);
        //console.log(this.props);
        if (this.props.response != undefined && !this.state.isLoadSuccess) {
            if (
                /*this.props.version > this.state.version &&*/ this.props.CompanyShortName !==
                    undefined &&
                this.props.CompanyShortName.length !== 0
            ) {
                //if (this.props.version - this.state.version === 2) {
                // console.log('ngame original response', this.props.response) // <-- original
                var List_response: any = [];
                List_response = JSON.parse(JSON.stringify(this.props.response));
                //console.log('All Folder original');
                //console.log(List_response);
                //console.log('-------------------------------------------');

                var systemPosi: number = List_response.findIndex(
                    obj => obj.folder_name === 'System'
                );
                //console.log(systemPosi);
                var system_list: any = JSON.parse(
                    JSON.stringify(
                        List_response.filter(
                            item => item.folder_name === 'System' && item.node_code === 'SYS_'
                        )
                    )
                );
                //var system_list = List_response[systemPosi]
                console.log(system_list);
                if (system_list.length > 0) {
                    var archive_zone = system_list[0].list_node.filter(
                        item => item.folder_name === 'Archive Zone'
                    );
                    var FocusOneERP = system_list[0].list_node.filter(
                        item => item.folder_name === 'Focus One ERP'
                    );
                }
                //console.log(FocusOneERP);

                //console.log('All Folder archive_zone');
                //console.log(archive_zone);
                var archive_ans: any = [];
                for (let i = 0; i < this.props.CompanyShortName.length; i++) {
                    if (archive_zone && archive_zone.length > 0) {
                        var archive_ans_: any = archive_zone[0].list_node.filter(
                            item => item.folder_name === this.props.CompanyShortName[i]
                        );
                        if (archive_ans_.length !== 0) {
                            archive_ans.push(archive_ans_[0]);
                        }
                    }
                }
                //console.log(archive_ans);

                //console.log('-------------------------------------------');
                //console.log('All Folder Focusone ERP');
                //console.log(FocusOneERP);
                var FocusOneERP_ans: any = [];
                for (let i = 0; i < this.props.CompanyShortName.length; i++) {
                    if (FocusOneERP && FocusOneERP.length > 0) {
                        var focus_ans_: any = FocusOneERP[0].list_node.filter(
                            item => item.folder_name === this.props.CompanyShortName[i]
                        );
                        if (focus_ans_.length !== 0) {
                            FocusOneERP_ans.push(focus_ans_[0]);
                        }
                    }
                }
                //console.log(FocusOneERP_ans);
                //console.log('-------------------------------------------');
                if (archive_zone && archive_zone.length > 0) {
                    archive_zone[0].list_node = [];
                    archive_zone[0].list_node = archive_ans;
                    console.log(archive_zone[0]);
                }

                if (FocusOneERP && FocusOneERP.length > 0) {
                    FocusOneERP[0].list_node = [];
                    FocusOneERP[0].list_node = FocusOneERP_ans;
                    console.log(FocusOneERP[0]);
                }

                //console.log(system_list);

                //console.log(systemPosi);
                //List_response[systemPosi].list_node.splice(0, List_response[systemPosi].list_node.length)

                // -- Begin folder sorting --
                /** Example:
                 * N-MB_
                 * Folder name order by ASC (A-Z)
                 * SYS-F1ERP_
                 * SYS-F1LINK_
                 * */
                // Step 1: Sorting folder name A-Z
                List_response = [...List_response].sort((a, b) => {
                    if (a.folder_name < b.folder_name) return -1;
                    if (a.folder_name > b.folder_name) return 1;

                    return 0;
                });

                // Step 2: Find the index of the element you want to move
                const foundMyFolder = [...List_response].find(
                    (item: any, index: number) => item.node_code === 'N-MB_'
                );
                const foundIndexOfMyFolder = [...List_response].findIndex(
                    item => item.node_code === 'N-MB_'
                );
                // console.log('[Debug] foundIndexOfMyFolder',foundIndexOfMyFolder)
                // Step 3: Remove the element from its current position in the array
                List_response.splice(foundIndexOfMyFolder, 1);
                // Step 4: Insert the element at the desired new position in the array
                List_response.splice(0, 0, foundMyFolder);

                const foundFocusOneERP = [...List_response].find(
                    item => item.node_code === 'SYS-F1ERP_'
                );
                const foundIndexOfFocusOneERP = [...List_response].findIndex(
                    item => item.node_code === 'SYS-F1ERP_'
                );
                if (foundFocusOneERP) {
                    List_response.splice(foundIndexOfFocusOneERP, 1);
                    List_response.splice(List_response.length, 0, foundFocusOneERP);
                }

                const foundFocusOneLink = [...List_response].find(
                    item => item.node_code === 'SYS-F1LINK_'
                );
                const foundIndexOfFocusOneLink = [...List_response].findIndex(
                    item => item.node_code === 'SYS-F1LINK_'
                );
                if (foundFocusOneLink) {
                    List_response.splice(foundIndexOfFocusOneLink, 1);
                    List_response.splice(List_response.length, 0, foundFocusOneLink);
                }

                // console.log('[Debug] List_response', List_response);
                // -- End folder sorting --

                if (system_list && system_list.length > 0) {
                    List_response[systemPosi] = JSON.parse(JSON.stringify(system_list[0]));
                }

                //console.log('ngame wtf response', List_response)
                //console.log('-------------------------------------------');
                //this.setState({ listFolder: List_response, version: this.props.version });
                setTimeout(() => {
                    //if (this.state.listFolder.length > 0) {
                    this.setState({
                        listFolder:
                            this.props.folderSid !== ''
                                ? List_response.filter(
                                      response => response.sid === this.props.folderSid
                                  )
                                : List_response,
                        version: this.props.version,
                        isLoadSuccess: true
                    });
                    //}
                }, 500);
                //}
            } else if (
                /*this.props.version > this.state.version &&*/ this.props.CompanyShortName !==
                undefined
            ) {
                console.log(this.props.response);
                var List_response: any = [];
                List_response = JSON.parse(JSON.stringify(this.props.response));
                //console.log('All Folder original');
                //console.log(List_response);
                //console.log('-------------------------------------------');
                //this.setState({ version: this.props.version, listFolder: [] });
                setTimeout(() => {
                    //if (this.state.listFolder.length > 0) {
                    this.setState({
                        version: this.props.version,
                        listFolder: [],
                        isLoadSuccess: true
                    });
                    //}
                }, 500);
            }
        }

        //if (this.props.reloadFolder === true) {
        //    this.ensureDataFetchedFix()
        //    this.props.resetReloadFolder("reset")
        //}
    }

    private ensureDataFetched() {
        this.props.onSelectFolder('', '', '', '');
        this.setState({ pageLoad: true, isLoadSuccess: false });
        this.props.requestFunction(this.state.pageLoad, 'DEV', '1000', this.state.version, 'GET');
        this.setState({ pageLoad: false });
    }

    //private ensureDataFetchedFix() {
    //    this.props.onSelectFolder(
    //        this.props.valueCodeAndName.fullpath,
    //        this.props.valueCodeAndName.code,
    //        this.props.valueCodeAndName.name,
    //        this.props.valueCodeAndName.createdBy
    //    );
    //    this.setState({ pageLoad: true, isLoadSuccess: false });
    //    this.props.requestFunction(true, 'DEV', '1000', this.state.version, 'GET');
    //    this.setState({ pageLoad: false });
    //}

    private checkNode(item: Array<Models.FolderMap>, index: number) {
        if ((item as Array<Models.FolderMap>).length > 0) {
            return this.renderNode(item);
        }

        return '';
    }

    private onContextMenuClick(e: any) {
        // console.log('[Debug] onContextMenuClick dataset', e.currentTarget.dataset)
        e.stopPropagation();
        var data = {
            sid: e.currentTarget.dataset.sid,
            isOpenModal: false,
            mode: '',
            nodeCode: e.currentTarget.dataset.nodecode,
            folderName: e.currentTarget.dataset.nodename,
            nodeParent: e.currentTarget.dataset.nodeparent,
            nodeLevel: e.currentTarget.dataset.nodelevel,
            fullPath: e.currentTarget.dataset.fullpath,
            metadata_group_code: e.currentTarget.dataset.metadata,
            role_access_code: '',
            max_file_size: '',
            file_extension: '',
            authorizeId: e.currentTarget.dataset.authorizeid,
            owner: e.currentTarget.dataset.owner
        } as Models.DataModal;
        // console.log('ngame click', data)
        this.setState({ dataModal: data });

        show(e);
    }

    filter(data, key, datause) {
        if (datause !== '') {
            this.setState({ detailFolder: datause });
        } else {
            for (let i = 0; i < data.length; i++) {
                this.filter(data[i].list_node, key, '');
                if (data[i].node_code === key) {
                    this.filter('', '', data[i]);
                    break;
                }
            }
        }
    }

    private onClickMoreFolder(e: any) {
        if (e.event.currentTarget.dataset.mode === 'edit') {
            var folderDetail: any = this.filter(
                this.state.listFolder,
                this.state.dataModal.nodeCode,
                ''
            );
        }

        // switch (e.event.currentTarget.dataset.mode) {
        //     case 'edit': {
        //         this.filter(
        //             this.state.listFolder,
        //             this.state.dataModal.nodeCode,
        //             ''
        //         );

        //         break;
        //     }
        //     case 'share': {
        //         console.log('open share file popup!');

        //         break;
        //     }
        //     default:
        //         break;
        // }

        var data = {
            sid: this.state.dataModal.sid,
            isOpenModal: true,
            mode: e.event.currentTarget.dataset.mode,
            nodeCode: this.state.dataModal.nodeCode,
            folderName: this.state.dataModal.folderName,
            nodeParent: this.state.dataModal.nodeParent,
            nodeLevel: this.state.dataModal.nodeLevel,
            fullPath: this.state.dataModal.fullPath,
            metadata_group_code: '',
            role_access_code: '',
            max_file_size: '',
            file_extension: '',
            owner: this.state.dataModal.owner
        };

        this.setState({ dataModal: data });
    }

    private afterOpenModal(e: any) {
        var data = {
            sid: this.state.dataModal.sid,
            isOpenModal: false,
            mode: this.state.dataModal.mode,
            nodeCode: this.state.dataModal.nodeCode,
            folderName: this.state.dataModal.folderName,
            nodeParent: this.state.dataModal.nodeParent,
            nodeLevel: this.state.dataModal.nodeLevel,
            fullPath: this.state.dataModal.fullPath,
            metadata_group_code: '',
            role_access_code: '',
            max_file_size: '',
            file_extension: '',
            owner: this.state.dataModal.owner
        };

        this.setState({ dataModal: data });
    }

    private afterModalSaveSuccess(e: any) {
        this.ensureDataFetched();
    }

    onSelectFolder = (e: any) => {
        this.props.onSelectFolder(
            e.target.dataset.fullpath,
            e.target.dataset.nodecode,
            e.target.dataset.nodename,
            e.target.dataset.metadata,
            e.target.dataset.sid,
            e.target.dataset.createdby,
            e.target.dataset.authorizeid
        );

        // Set folder owner on select folder
        // console.log('[Debug] e.target.dataset', e.target.dataset);
        this.setState({ folderOwner: e.target.dataset.owner ?? '' });
    };

    renderNode(item: Array<Models.FolderMap>) {
        return item.map((item: Models.FolderMap, index: number) => {
            if (item) {
                const hasChildNode = item.list_node.length > 0;

                return (
                    <StyledTreeItem
                        key={item.node_code}
                        nodeId={item.node_code}
                        labelText={item.folder_name}
                        labelIcon={FolderIcon}
                        labelTextcount={item.file_count + '(' + item.file_total + ')'}
                        color="#1a73e8"
                        bgColor="#e8f0fe"
                        style={{ paddingLeft: '10px' }}
                        data-sid={item.sid}
                        data-fullpath={item.full_path}
                        data-nodecode={item.node_code}
                        data-nodename={item.folder_name}
                        data-nodeparent={item.node_parent}
                        data-nodelevel={item.node_level}
                        data-metadata={item.metadata_group_code}
                        data-createdby={item.created_by}
                        data-owner={item.owner}
                        data-authorizeid={item.authorize_id}
                        onContextMenu={this.onContextMenuClick}
                        iconimg={
                            hasChildNode ? (
                                this.context.id === item.created_by ||
                                (item.created_by.toLowerCase() === 'system' &&
                                    !item.node_code.includes('_SWM_I')) ? (
                                    <BsFolderFill
                                        color="#ffc107"
                                        style={{ width: '14px', marginRight: '3px' }}
                                    />
                                ) : (
                                    <BsFolderFill
                                        color="#6cb8ff"
                                        style={{ width: '14px', marginRight: '3px' }}
                                    />
                                )
                            ) : this.context.id === item.created_by ||
                              (item.created_by.toLowerCase() === 'system' &&
                                  !item.node_code.includes('_SWM_I')) ? (
                                <Icons.IconBox
                                    color="#ffc107"
                                    style={{ width: '14px', marginRight: '3px' }}
                                />
                            ) : (
                                <Icons.IconBox
                                    color="#6cb8ff"
                                    style={{ width: '14px', marginRight: '3px' }}
                                />
                            )
                        }
                    >
                        {hasChildNode && this.renderNode(item.list_node)}
                    </StyledTreeItem>
                );
            }
        });
    }

    render() {
        const { id }: IUserContext = this.context;
        var { dataModal } = this.state;
        //console.log(this.state);
        // console.log('ngame list folder', this.state.listFolder);
        // console.log('[Debug] dataModal', dataModal)
        //console.log('[Debug] folder owner =>', this.state.folderOwner)
        return (
            <div className="div-folder-main">
                <TreeView
                    defaultCollapseIcon={<ArrowDropDownIcon />}
                    defaultExpandIcon={<ArrowRightIcon />}
                    onFocus={this.onSelectFolder}
                >
                    {!this.state.isLoadSuccess && (
                        <div className="mt-5 text-center">
                            <label>
                                <img
                                    src={f1Loading}
                                    style={{ width: '8rem', height: '8rem' }}
                                />
                            </label>
                        </div>
                    )}
                    {this.state.isLoadSuccess && this.state.listFolder.length === 0 && (
                        <div className="mt-5 text-center">
                            <h6>
                                <ErrorOutlineIcon /> ไม่ได้รับอนุมัติให้ใช้งาน
                            </h6>
                        </div>
                    )}
                    {this.state.isLoadSuccess &&
                        this.state.listFolder.length !== 0 &&
                        this.renderNode(this.state.listFolder)}
                </TreeView>

                <Menu
                    id={MENU_ID}
                    style={{ fontSize: '12px' }}
                >
                    <Item disabled>
                        <span>{this.state.dataModal.nodeCode}</span>
                        <CopyToClipboard text={this.state.dataModal.nodeCode}>
                            <Button
                                style={{
                                    right: '5px',
                                    width: '30px',
                                    height: '30px',
                                    padding: '0',
                                    marginLeft: '12px'
                                }}
                            >
                                <i className="fas fa-copy"></i>
                            </Button>
                        </CopyToClipboard>
                    </Item>

                    {!dataModal.nodeCode.includes('S-BIN') &&
                        !dataModal.nodeCode.includes('SYS-F1ERP_') &&
                        !dataModal.nodeCode.includes('_SWM_I') && (
                            //&& (
                            //    (dataModal.sid === "*")
                            //    || ((this.state.folderOwner === id)
                            //    //&& (this.state.folderOwner !== 'system')
                            //)
                            //    //|| dataModal.nodeParent === ""
                            //    //|| dataModal.fullPath.toUpperCase().includes('SHARE')
                            //)
                            <React.Fragment>
                                {!dataModal.nodeCode.toUpperCase().includes('SHARE') &&
                                    ((dataModal.authorizeId && dataModal.authorizeId === '72') ||
                                        !dataModal.authorizeId) && (
                                        <React.Fragment>
                                            <Separator />
                                            <Item
                                                data-mode="add"
                                                onClick={this.onClickMoreFolder}
                                            >
                                                <img
                                                    src={AddImg}
                                                    alt="menu-delete"
                                                    style={{ marginRight: '5px', width: '20px' }}
                                                />
                                                Add
                                            </Item>
                                        </React.Fragment>
                                    )}

                                {/* Hide Edit and Delete button from My Bucket folder */}
                                {dataModal.nodeCode !== 'N-MB_' &&
                                    !dataModal.nodeCode.includes('SYS-F1ERP-') &&
                                    (this.state.folderOwner === id ||
                                        (!dataModal.nodeCode.toUpperCase().includes('SHARE') &&
                                            dataModal.authorizeId &&
                                            dataModal.authorizeId !== '71')) && (
                                        <React.Fragment>
                                            <Item
                                                data-mode="edit"
                                                onClick={this.onClickMoreFolder}
                                            >
                                                <img
                                                    src={EditImg}
                                                    alt="menu-delete"
                                                    style={{ marginRight: '5px', width: '20px' }}
                                                />
                                                Edit
                                            </Item>
                                            <Item
                                                data-mode="delete"
                                                onClick={this.onClickMoreFolder}
                                            >
                                                <img
                                                    src={DeleteImg}
                                                    alt="menu-delete"
                                                    style={{ marginRight: '5px', width: '20px' }}
                                                />
                                                Delete
                                            </Item>
                                        </React.Fragment>
                                    )}

                                {dataModal.nodeCode.charAt(dataModal.nodeCode.length - 1) !== '_' &&
                                    !dataModal.nodeCode.includes('SYS-F1ERP-') &&
                                    this.state.folderOwner === id && (
                                        /*(dataModal.nodeCode.includes('N-MB_') ||
                                        dataModal.nodeParent.includes('N-MB_')) &&*/
                                        <React.Fragment>
                                            <Item
                                                data-mode="share"
                                                onClick={this.onClickMoreFolder}
                                            >
                                                <img
                                                    src={ShareImg}
                                                    alt="menu-delete"
                                                    style={{ marginRight: '5px', width: '20px' }}
                                                />
                                                Share
                                            </Item>
                                        </React.Fragment>
                                    )}
                            </React.Fragment>
                        )}
                </Menu>

                <ModalManageFolderComponent
                    detailFolder={this.state.detailFolder}
                    valueCodeAndName={this.props.valueCodeAndName}
                    dataModal={dataModal}
                    afterOpenModal={this.afterOpenModal.bind(this)}
                    afterModalSaveSuccess={this.afterModalSaveSuccess.bind(this)}
                />
            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({ ...state.folderMap, ...state.appState }), {
    ...Stores.actionCreators /*...StoresFolder.actionCreators*/
})(FolderParentComponent as any) as any;
