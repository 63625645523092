import React, { useState } from 'react';
import { twMerge } from 'tailwind-merge';

type Props = {
    values?: string[];
    defaultSelectedValue?: string;
    onChange?: (values: string) => void;
};

function Tab(props: Props) {
    const { values = [], defaultSelectedValue, onChange } = props;

    const [selectedValue, setSelectedValue] = useState(defaultSelectedValue);

    const handleClick = (value: string) => {
        setSelectedValue(value);
        onChange && onChange(value);
    };

    return (
        <React.Fragment>
            <div className="inline-flex flex-1 items-center [&>button:first-child]:rounded-l-md [&>button:last-child]:rounded-r-md">
                {values.map((value, index) => (
                    <div
                        key={`btn-${value}-${index}`}
                        className={twMerge(
                            'cursor-pointer px-4 py-2',
                            value === selectedValue
                                ? 'rounded-lg rounded-b-none border-l border-r border-t text-[#3397C3]'
                                : 'border-b'
                        )}
                        onClick={() => handleClick(value)}
                    >
                        <span className="relative top-[1px]">{value}</span>
                    </div>
                ))}
                <div className="flex flex-1 self-end border-b" />
            </div>
        </React.Fragment>
    );
}

export default Tab;
