import axios from "axios";

import { ShareFolderAuthorizeRequest } from '../../../schemas/shareService';
import { ResponseMessage } from '../../../schemas/common';

export async function shareFolderAuthorize(params: ShareFolderAuthorizeRequest[]) {
    return axios.post<ResponseMessage>('v2/share/folder', params, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('DMS_APPLICATION')
        }
    });
}