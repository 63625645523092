import React, { useCallback, useRef } from 'react';
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogProps,
    FormControl,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    Kbd
} from '@chakra-ui/react';
import { IoSearchSharp } from 'react-icons/io5';

type OwnProps = {
    onSubmitSearch: (search: string) => void;
};

type Props = Pick<AlertDialogProps, 'isOpen' | 'onClose'> & OwnProps;

function SearchDialog(props: Props) {
    const { isOpen, onClose, onSubmitSearch } = props;

    const cancelSearchRef = useRef<HTMLButtonElement>(null);
    const searchInputRef = useRef<HTMLInputElement>(null);

    const handleSubmitSearch = useCallback(
        (event: React.FormEvent<HTMLDivElement>) => {
            event.preventDefault();

            const searchInput = searchInputRef.current;
            if (!searchInput) {
                return;
            }

            onClose();
            onSubmitSearch(searchInput.value);
        },
        [onSubmitSearch]
    );

    return (
        <AlertDialog
            size="xl"
            isOpen={isOpen}
            onClose={onClose}
            leastDestructiveRef={cancelSearchRef}
        >
            <AlertDialogOverlay />
            <AlertDialogContent className="bg-transparent">
                <AlertDialogBody className="p-0">
                    <FormControl
                        as="form"
                        onSubmit={handleSubmitSearch}
                    >
                        <InputGroup>
                            <InputLeftElement className="pointer-events-none left-5 top-1/2 -translate-y-1/2">
                                <IoSearchSharp
                                    size={20}
                                    className="text-primary-400"
                                />
                            </InputLeftElement>
                            <Input
                                ref={searchInputRef}
                                size="lg"
                                placeholder="Search files"
                                className="h-16 border-0 bg-secondary-700 px-20 text-white shadow-none outline-0"
                            />
                            <InputRightElement className="pointer-events-none right-5 top-1/2 -translate-y-1/2">
                                <span>
                                    <Kbd className="text-neutral-800">Enter</Kbd>
                                </span>
                            </InputRightElement>
                        </InputGroup>
                    </FormControl>
                </AlertDialogBody>
            </AlertDialogContent>
        </AlertDialog>
    );
}

export default SearchDialog;
