import React from 'react';

import DeleteFolderViewModel from './DeleteFolder.viewmodel';

export type DeleteFodlerProps = {
    folderName: string;
};

function DeleteFolder(props: DeleteFodlerProps) {
    const {} = DeleteFolderViewModel();
    const { folderName } = props;

    return (
        <React.Fragment>
            <p>
                Are you sure to delete folder <span className="text-red-600"> {folderName}. </span>
            </p>
        </React.Fragment>
    );
}

export default DeleteFolder;
