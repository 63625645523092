import React from 'react';
import { twMerge } from 'tailwind-merge';

import {  sizeTwClasses } from './constant';

type OwnProps = {
    outline?: boolean;
    size?: MaterialSize;
};

type Props = React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
> &
    OwnProps;

function Button(props: Props) {
    const { size = 'sm', outline , type = 'button', className, children } = props;

    const buttonClass = !outline ? 'bg-neutral-100' : 'bg-transparent border';

    return (
        <button
            {...props}
            type={type}
            className={twMerge(
                'inline-flex items-center justify-center rounded-[4px] font-medium text-[14px] px-[10px] text-[#2E445D]',
                buttonClass,
                sizeTwClasses.get(size) ?? '',
                className
            )}
        >
            {children}
        </button>
    );
}

export default Button;
