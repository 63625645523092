import axios from 'axios';
import { makeAutoObservable } from 'mobx';

import RootStore from 'mobx-store/Root.store';
import { ResponseFolderMap } from 'models/Folder';

class FolderMapStore {
    constructor(private rootStore: RootStore) {
        makeAutoObservable(this, {}, { autoBind: true });
    }

    public async fetchFolders(params: { sid: string; companyCode: string }) {
        await axios.get<ResponseFolderMap>('/v1/folder/map', {
            params: {
                sid: params.sid,
                companyCode: params.companyCode
            }
        });
    }

    // TODO
}

export default FolderMapStore;
