import { Link } from 'react-router-dom';
import {
    IconButton,
    Menu,
    MenuButton,
    MenuDivider,
    MenuGroup,
    MenuItem,
    MenuList
} from '@chakra-ui/react';

import { MENU_GROUPS } from './menus';
import { BsGrid3X3Gap } from 'react-icons/bs';
import React from 'react';

type Props = {
    userRole?: string;
};

function MenuSelect(props: Props) {
    const { userRole } = props;

    const enabledAdminMenus = userRole === 'admin' || userRole === 'super_admin';

    if (!enabledAdminMenus) {
        return null;
    }

    return (
        <Menu>
            <MenuButton
                as={IconButton}
                size="md"
                aria-label="Menu"
                variant="unstyled"
                icon={
                    <BsGrid3X3Gap
                        size={28}
                        className="text-white transition-colors group-hover:text-primary-900"
                    />
                }
                className="group"
            />
            <MenuList>
                <MenuGroup
                    title="F1 ERP ADMIN"
                    className="text-base font-bold text-primary-900"
                >
                    {MENU_GROUPS.reduce<JSX.Element[]>((accumulate, group, index) => {
                        if (!group.allowed.includes(userRole)) {
                            return accumulate;
                        }

                        if (index > 0) {
                            accumulate.push(<MenuDivider key={`divider-${index}`} />);
                        }

                        accumulate.push(
                            <React.Fragment>
                                {group.menus.map(menu => (
                                    <MenuItem
                                        key={menu.label}
                                        as={Link}
                                        to={menu.to}
                                        className="no-underline hover:bg-secondary-700 hover:text-white"
                                    >
                                        {menu.label}
                                    </MenuItem>
                                ))}
                            </React.Fragment>
                        );

                        return accumulate;
                    }, [])}
                </MenuGroup>
            </MenuList>
        </Menu>
    );
}

export default MenuSelect;
