import React, { useContext } from 'react';
import { Menu, Item, Separator, useContextMenu } from 'react-contexify';
import { CopyToClipboard } from 'react-copy-to-clipboard';

// Icons
import { FaRegCopy } from 'react-icons/fa';
import { RiDeleteBin6Fill, RiDeleteBin6Line, RiShareFill } from 'react-icons/ri';
import { MdContentCopy, MdOutlineAddCircleOutline, MdOutlineEdit } from 'react-icons/md';

// Models
import * as FolderModels from '../../../models/Folder';
import { UserContext } from '../../../context/UserProvider';
import { Button } from '@chakra-ui/react';

// right click
const MENU_ID = 'menu-id';

const { show } = useContextMenu({
    id: MENU_ID
});

interface Props {
    dataModal: FolderModels.DataModal;
    folderOwner: string;
    listFolder: FolderModels.FolderMap[];
    onClickMenuFolder: (data: FolderModels.DataModal) => void;
    saveFolderDetail: (folderMapping: FolderModels.FolderMap) => void;
}

const FolderManageMenuButton = (props: Props) => {
    const { dataModal, folderOwner, listFolder } = props;
    const { id } = useContext(UserContext);

    const filter = (
        folderMappingList: FolderModels.FolderMap[],
        key: string,
        folderMapping?: FolderModels.FolderMap
    ) => {
        if (folderMapping) {
            props.saveFolderDetail(folderMapping);

            return;
        }

        for (let i = 0; i < folderMappingList.length; i++) {
            filter(folderMappingList[i].list_node, key);

            if (folderMappingList[i].node_code === key) {
                filter([], '', folderMappingList[i]);
                break;
            }
        }
    };

    const folderManageMenuHandle = (e: any) => {
        if (e.event.currentTarget.dataset.mode === 'edit') {
            filter(listFolder, dataModal.nodeCode);
        }

        const data = {
            sid: dataModal.sid,
            isOpenModal: true,
            mode: e.event.currentTarget.dataset.mode,
            nodeCode: dataModal.nodeCode,
            folderName: dataModal.folderName,
            nodeParent: dataModal.nodeParent,
            nodeLevel: dataModal.nodeLevel,
            fullPath: dataModal.fullPath,
            metadata_group_code: '',
            role_access_code: '',
            max_file_size: '',
            file_extension: '',
            owner: dataModal.owner
        };

        props.onClickMenuFolder(data);
    };

    return (
        <Menu
            id={MENU_ID}
            className="text-xs"
        >
            <Item
                disabled
                className="px-3"
            >
                <CopyToClipboard text={dataModal.nodeCode}>
                    <div className="flex h-6 w-6 min-w-0 items-center justify-center rounded-sm border-none bg-[#D9D9D9]">
                        <MdContentCopy
                            size={16}
                            color="#7A7A7A"
                        />
                    </div>
                </CopyToClipboard>
                <span className="right-[5px] ml-3">{dataModal.nodeCode}</span>
            </Item>

            {!dataModal.nodeCode.includes('S-BIN') &&
                !dataModal.nodeCode.includes('SYS-F1ERP_') &&
                !dataModal.nodeCode.includes('_SWM_I') && (
                    //&& (
                    //    (dataModal.sid === "*")
                    //    || ((folderOwner === id)
                    //    //&& (folderOwner !== 'system')
                    //)
                    //    //|| dataModal.nodeParent === ""
                    //    //|| dataModal.fullPath.toUpperCase().includes('SHARE')
                    //)
                    <React.Fragment>
                        {!dataModal.nodeCode.toUpperCase().includes('SHARE') &&
                            ((dataModal.authorizeId && dataModal.authorizeId === '72') ||
                                !dataModal.authorizeId) && (
                                <React.Fragment>
                                    <Separator />
                                    <Item
                                        data-mode="add"
                                        onClick={e => folderManageMenuHandle(e)}
                                        className="px-3"
                                    >
                                        {/* <img
                                            src={AddImg}
                                            alt="menu-delete"
                                            style={{ marginRight: '5px', width: '20px' }}
                                        /> */}
                                        <div className="flex h-6 w-6 items-center justify-center">
                                            <MdOutlineAddCircleOutline
                                                size={20}
                                                color="#33BEA6"
                                            />
                                        </div>
                                        <span className="ml-3">Add</span>
                                    </Item>
                                </React.Fragment>
                            )}

                        {/* Hide Edit and Delete button from My Bucket folder */}
                        {dataModal.nodeCode !== 'N-MB_' &&
                            !dataModal.nodeCode.includes('SYS-F1ERP-') &&
                            (folderOwner === id ||
                                (!dataModal.nodeCode.toUpperCase().includes('SHARE') &&
                                    dataModal.authorizeId &&
                                    dataModal.authorizeId !== '71')) && (
                                <React.Fragment>
                                    <Item
                                        data-mode="edit"
                                        // data-mode="rename"
                                        onClick={e => folderManageMenuHandle(e)}
                                        className="px-3"
                                    >
                                        <div className="flex h-6 w-6 items-center justify-center">
                                            <MdOutlineEdit
                                                size={20}
                                                color="#FFB600"
                                            />
                                        </div>
                                        <span className="ml-3">Edit</span>
                                    </Item>
                                    <Item
                                        data-mode="delete"
                                        onClick={e => folderManageMenuHandle(e)}
                                        className="px-3"
                                    >
                                        {/* <img
                                            src={DeleteImg}
                                            alt="menu-delete"
                                            style={{ marginRight: '5px', width: '20px' }}
                                        /> */}
                                        <div className="flex h-6 w-6 items-center justify-center">
                                            <RiDeleteBin6Line
                                                size={20}
                                                color="#B3261E"
                                            />
                                        </div>
                                        <span className="ml-3">Delete</span>
                                    </Item>
                                </React.Fragment>
                            )}

                        {dataModal.nodeCode.charAt(dataModal.nodeCode.length - 1) !== '_' &&
                            !dataModal.nodeCode.includes('SYS-F1ERP-') &&
                            (folderOwner === id ||
                                (dataModal.authorizeId && dataModal.authorizeId === '70')) && (
                                /*(dataModal.nodeCode.includes('N-MB_') ||
                                        dataModal.nodeParent.includes('N-MB_')) &&*/
                                <React.Fragment>
                                    <Item
                                        data-mode="share"
                                        onClick={e => folderManageMenuHandle(e)}
                                        className="px-3"
                                    >
                                        {/* <img
                                            src={ShareImg}
                                            alt="menu-delete"
                                            style={{ marginRight: '5px', width: '20px' }}
                                        /> */}
                                        <div className="flex h-6 w-6 items-center justify-center">
                                            <RiShareFill
                                                size={22}
                                                color="#2E445D"
                                                className="rounded-full border bg-[#D9D9D9] p-1"
                                            />
                                        </div>
                                        <span className="ml-3">Share</span>
                                    </Item>
                                </React.Fragment>
                            )}
                    </React.Fragment>
                )}
        </Menu>
    );
};

export default FolderManageMenuButton;
