import React from 'react';
import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';

import AddFolderViewModel from './AddFolder.viewmodel';

export type AddFodlerProps = {
    isSave: boolean;
    afterSuccess?: () => void;
};

function AddFolder(props: AddFodlerProps) {
    const { handleAddFolderName, folderName } = AddFolderViewModel(props);

    return (
        <React.Fragment>
            <InputGroup>
                <InputGroup>
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText className="w-[160px]">Folder Name</InputGroupText>
                    </InputGroupAddon>
                    <div className="w-[800px]">
                        <Input
                            placeholder="Folder Name"
                            type="text"
                            value={folderName}
                            onChange={e => handleAddFolderName(e.target.value)}
                        />
                    </div>
                </InputGroup>
            </InputGroup>
        </React.Fragment>
    );
}

export default AddFolder;
