import { defineStyle, defineStyleConfig, extendTheme } from '@chakra-ui/react';

import colorPalette from './color-palette';

const theme = extendTheme({
    components: {
        Button: defineStyleConfig({
            variants: {
                unstyled: defineStyle({
                    display: 'inline-flex',
                    justifyContent: 'center'
                })
            }
        })
    },
    colors: {
        ...colorPalette
    }
});

export default theme;
