import * as React from 'react';
import { Route, Switch } from 'react-router';
import { useUserContext } from '../context/UserProvider';
import SignInComponent from '../components/auth/SignInComponent';
import SignOutComponent from '../components/auth/SignOutComponent';
import CallbackMaster from '../components/callback/Callback';
import MetaDataComponent from '../components/dms/MetaDataComponent';
import MetaDataGroupComponent from '../components/dms/MetaDataGroupComponent';
import RoleAccessComponent from '../components/dms/RoleAccessComponent';
import SerachFileExtenstionComponent from '../components/dms/SearchFileExtenstionComponent';
import SerachFileComponent from '../components/dms/SerachFileComponent';
import ShowFileVersionComponent from '../components/dms/ShowFileVersionComponent';
import EmployeeMasterComponent from '../components/Employee/EmployeeMasterComponent';
import FileExplorerComponent from '../components/dms/FileExplorerComponent';
import AccessManagement from '../components/dms/AccessManagement';
import Logger from '../components/log/Logger';
import Home from '../components/Home';
import Ingress from '../components/ingress/Ingress';
import { PrivateRoute } from './PrivateRoute';
import SubLayout from './SubLayout';
import View from '../components/view/View';
import SecureView from '../components/view/SecureView';
import SearchWithCriteria from '../components/dms/SearchCriteria/SearchWithCriteriaComponent';
import LogComponent from '../components/dms/Log/LogComponent';
import Swal from 'sweetalert2';
import { getRedirectAppPoolUrl } from '../modules/common';
import UserFolderAuthorizeMapping from '../components/dms/FolderAuthorize/UserFolderAuthorizeMapping';
import FolderAuthorize from '../components/dms/FolderAuthorize/FolderAuthorize';
import UserFolderAuthorization from '../components/dms/FolderAuthorize/UserFolderAuthorization';
import LogUserFolderAuthorization from '../components/dms/FolderAuthorize/LogUserFolderAuthorization';
import FileExplorerView from '../presentations/view/FileExplorer/FileExplorer.view';

export default () => {
    const { isRoleAccessLoaded, list_company } = useUserContext();

    // Check role access at first
    React.useEffect(() => {
        if (isRoleAccessLoaded) {
            let canAccess = false;

            for (const company of list_company) {
                if (company.role_access.length !== 0) {
                    canAccess = true;
                    break;
                }
            }

            if (!canAccess) {
                // Swal.fire({ // Comment this execute for development purpose (prevent annoyed alert)
                //     title: 'ไม่พบ Authorize',
                //     icon: 'error',
                //     showCancelButton: false,
                //     confirmButtonColor: '#3085d6',
                //     confirmButtonText: 'ตกลง',
                // })
                //     .then(() => localStorage.removeItem('DMS_APPLICATION')) // Comment this line for development purpose (prevent redirect)
                //     .then(() => getRedirectAppPoolUrl()); // Comment this line for development purpose (prevent redirect)
            }
        }
    }, [isRoleAccessLoaded]);

    return (
        <React.Fragment>
            <Switch>
                <Route
                    path="/ingress"
                    component={Ingress}
                />
                <Route
                    path="/view"
                    component={View}
                />
                <Route
                    path="/secure-view"
                    component={SecureView}
                />
                <Route
                    path="/auth/signout"
                    component={SignOutComponent}
                />
                <Route
                    path="/dms/search-with-criteria"
                    component={SearchWithCriteria}
                />
                <SubLayout>
                    <PrivateRoute
                        exact
                        path="/"
                        component={Home}
                    />
                    {/* // ? class component */}
                    {/* <PrivateRoute
                        exact
                        path="/dms/file-explorer"
                        component={FileExplorerComponent}
                    /> */}
                    {/* // ? functional redesign */}
                    <PrivateRoute
                        exact
                        path="/dms/file-explorer"
                        component={FileExplorerView}
                    />
                    <PrivateRoute
                        path="/dms/file-explorer/log"
                        component={LogComponent}
                    />
                    <PrivateRoute
                        path="/dms/search-with-criteria"
                        component={SearchWithCriteria}
                    />
                    <PrivateRoute
                        path="/dms/search-file"
                        component={SerachFileComponent}
                    />
                    <PrivateRoute
                        path="/dms/search-file-extenstion"
                        component={SerachFileExtenstionComponent}
                    />
                    <PrivateRoute
                        path="/dms/file-version/:key"
                        component={ShowFileVersionComponent}
                    />
                    <PrivateRoute
                        path="/auth/signin"
                        component={SignInComponent}
                    />
                    <PrivateRoute
                        path="/dms/meta-data"
                        component={MetaDataComponent}
                    />
                    <PrivateRoute
                        path="/dms/meta-data-group"
                        component={MetaDataGroupComponent}
                    />
                    <PrivateRoute
                        path="/dms/role-access"
                        component={RoleAccessComponent}
                    />
                    <PrivateRoute
                        path="/employee"
                        component={EmployeeMasterComponent}
                    />
                    <PrivateRoute
                        path="/user-folder-authorize"
                        component={UserFolderAuthorizeMapping}
                    />
                    <PrivateRoute
                        path="/folder-authorize"
                        component={FolderAuthorize}
                    />
                    <PrivateRoute
                        exact
                        path="/user-folder-authorizev2"
                        component={UserFolderAuthorization}
                    />
                    <PrivateRoute
                        path="/user-folder-authorizev2/log"
                        component={LogUserFolderAuthorization}
                    />
                    <PrivateRoute
                        path="/callback"
                        component={CallbackMaster}
                    />
                    <PrivateRoute
                        path="/access-management"
                        component={AccessManagement}
                    />
                    <PrivateRoute
                        path="/dms/log"
                        component={Logger}
                    />
                </SubLayout>
            </Switch>
        </React.Fragment>
    );
};
