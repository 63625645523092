import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import * as Employee from '../models/Employee';

interface RequestFunctionAction {
    type: 'REQUEST_CHECKCOMPANYUSER';
    version: number;
    status: number;
}

interface ReceiveFunctionAction {
    type: 'RECEIVE_CHECKCOMPANYUSER';
    model: Employee.ResponseEmployee;
    version: number;
    status: number;
    user: string;
}

interface ClearFunctionAction {
    type: 'CLEAR_CHECKCOMPANYUSER';
    status: number;
}

interface WhaitFunctionAction {
    type: 'WHAIT_CHECKCOMPANYUSER';
}

type KnownAction = RequestFunctionAction | ReceiveFunctionAction | ClearFunctionAction | WhaitFunctionAction

export const actionCreators = {
    requestFunctionCheckCompanyUser: (version: number, method: string, id: string, page: string, page_size: string, user_id: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState && appState.fileDetail && method === "GET") {
            console.log("Call requestFunctionCheckCompanyUser")
            fetch(`/v1/user?&id=` + id + `&page=` + page + `&page_size=` + page_size + `&mode=CheckUser`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('DMS_APPLICATION')
                }
            })
                .then(response => {
                    return response.json() as Promise<Employee.ResponseEmployee>
                })
                .then(data => {
                    dispatch({ type: 'RECEIVE_CHECKCOMPANYUSER', model: data, version: version + 2, status: 200, user: user_id });
                })
                .catch(error => {
                    dispatch({ type: 'REQUEST_CHECKCOMPANYUSER', version: version + 1, status: 204 });
                });
        }

        //dispatch({ type: 'WHAIT_CHECKCOMPANYUSER' });

        if (method === "CLEAR") {
            dispatch({ type: 'CLEAR_CHECKCOMPANYUSER', status: 400 });
        }

    }
};

const unloadedState: Employee.EmployeeState = { isLoadingEmployee: false, responseEmployee: undefined, versionEmployee: 0, statusEmployee: 0 };

export const reducer: Reducer<Employee.EmployeeState> = (state: Employee.EmployeeState | undefined, incomingAction: Action): Employee.EmployeeState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {

        case 'WHAIT_CHECKCOMPANYUSER':
            return {
                isLoadingEmployee: false,
                statusEmployee: 400,
            };
        case 'REQUEST_CHECKCOMPANYUSER':
            return {
                isLoadingEmployee: false,
                versionEmployee: action.version,
                statusEmployee: action.status,
            };
        case 'RECEIVE_CHECKCOMPANYUSER':
            return {
                isLoadingEmployee: true,
                responseEmployee: action.model,
                versionEmployee: action.version,
                statusEmployee: action.status,
                user: action.user
            };
        case 'CLEAR_CHECKCOMPANYUSER':
            return {
                isLoadingEmployee: false,
                statusEmployee: action.status
            };

        default: return state;
    }
};