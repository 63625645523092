import React, { createRef } from 'react';
import { connect } from 'react-redux';
import { FaRegCopy, FaSync } from 'react-icons/fa';
import { RiDeleteBin6Line, RiShareFill } from 'react-icons/ri';
import { MdOutlineEdit, MdOutlineShare, MdOutlineUploadFile } from 'react-icons/md';

import { ApplicationState } from 'store';
import * as FolderRoleStore from 'store/ui/Role';
import { File } from 'models/File';
import { convertMetadataToEntries, convertMetadataToString } from 'modules/common';
import { UserContext, IUserContext } from 'context/UserProvider';

import FolderImg from 'Images/Icon/folder.png';

import ResizableDiv from 'common/components/ResizeableDiv';
import FoldersComponent from 'common/components/FolderManagement/FoldersComponent';
import { Button } from 'common/materials';
import ShareModal from 'common/components/ShareModal/ShareModal';

import ButtonCopyFileComponent from 'components/dms/ButtonCopyFileComponent';
import ButtonEditFileV2Component from 'components/dms/ButtonEditFileV2Component';
import FilesTableView from './components/FilesTable/FilesTable.view';
import UploadFileModal, {
    UploadFileModalConstruct
} from './components/UploadFileModal/UploadFileModal';
import DeleteFileModal from './components/DeleteFileModal/DeleteFileModal';
import FileLogModal from './components/FileLogs/FileLogModal';
import BoxIDView from './components/BoxID/BoxID.view';

interface State {
    sid: string;
    folderSelectedFullPath: string;
    valueCodeAndName: any;
    reloadFolder: boolean;
    checkFile: boolean;
    checkRefresh: boolean;
    switchPage: string;
    folderSelectedCode: string;
    folderSelectedName: string;
    folderSelectedCreatedBy: string;
    isReLoadFile: boolean;
    getValueEdit: boolean;
    btClick: string;
    metadataGroup: string;
    modelListFileIsSelected: Array<File>;
    strictMode: boolean;
    disabledUpload: boolean;
    disabledCopy: boolean;
    disabledEdit: boolean;
    disabledDelete: boolean;
    showModalUpload: boolean;
    showModalCopy: boolean;
    showModalEdit: boolean;
    showModalDelete: boolean;
    showModalShare: boolean;
    showModalFileLog: boolean;
}

class FileExplorerView extends React.Component<any, State, any> {
    static contextType: React.Context<any> | undefined = UserContext;

    public context!: React.ContextType<typeof UserContext>;

    private uploadFileModalRef: React.RefObject<UploadFileModalConstruct>;

    constructor(props: any) {
        super(props);

        this.uploadFileModalRef = createRef();

        this.state = {
            sid: '',
            folderSelectedCode: '',
            folderSelectedFullPath: 'All',
            folderSelectedName: 'All',
            folderSelectedCreatedBy: '',
            isReLoadFile: false,
            modelListFileIsSelected: [],
            switchPage: 'File',
            checkRefresh: false,
            checkFile: false,
            reloadFolder: false,
            valueCodeAndName: {},
            getValueEdit: false,
            btClick: '',
            metadataGroup: '',
            strictMode: false,

            disabledUpload: true,
            disabledCopy: true,
            disabledEdit: true,
            disabledDelete: true,
            showModalUpload: false,
            showModalCopy: false,
            showModalEdit: false,
            showModalDelete: false,
            showModalShare: false,
            showModalFileLog: false
        };

        this.onSelectFolder = this.onSelectFolder.bind(this);
        this.onUploadSuccess = this.onUploadSuccess.bind(this);
        this.handleChangeSelectedFiles = this.handleChangeSelectedFiles.bind(this);
        this.handleCreatableSelectChange = this.handleCreatableSelectChange.bind(this);
        this.onMetadataChange = this.onMetadataChange.bind(this);
        this.addMetadata = this.addMetadata.bind(this);
        this.removeMetadata = this.removeMetadata.bind(this);
        this.changeSelectedFiles = this.changeSelectedFiles.bind(this);
        this.getValueFileList = this.getValueFileList.bind(this);
        this.removeSelectedFile = this.removeSelectedFile.bind(this);
    }

    // componentDidUpdate() {
    //     const path = this.state.folderSelectedFullPath;
    //     const pathArray = path.split(' > ');

    //     const { availableCompanies }: IUserContext = this.context;

    //     for (const company of availableCompanies) {
    //         if (pathArray.includes(company.company_short_name)) {
    //             if (!this.state.strictMode) {
    //                 this.setState({ strictMode: true });
    //                 return;
    //             }
    //         }
    //     }
    // }

    public onSelectFolder(
        fullpath: string,
        nodeCode: string,
        name: string,
        metadata: string,
        sid: string,
        createdBy: string
    ) {
        const { id: username, roles: userRoles }: IUserContext = this.context;

        this.props.onSelectFolder({ sid: sid || '*', nodeCode, username, createdBy });

        var pathArray: string[] = [];
        if (fullpath) {
            pathArray = fullpath.split(' > ');
        }
        const { availableCompanies, list_company, actions }: IUserContext = this.context;

        let strictMode = false;
        let roles: Array<string> = [];

        for (let i = 0; i < availableCompanies.length; i++) {
            if (pathArray) {
                if (pathArray.includes(availableCompanies[i].company_short_name)) {
                    const rolesRaw = list_company[i].role_access;
                    roles = rolesRaw.split('|');
                    strictMode = true;

                    break;
                }
            }
        }

        var codeandname = {
            fullpath: fullpath,
            code: nodeCode,
            name: name
        };

        this.props.setRoles(roles);
        actions.setRoles(roles);
        this.setState({
            sid: sid,
            folderSelectedFullPath: fullpath,
            folderSelectedCode: nodeCode,
            folderSelectedName: name,
            folderSelectedCreatedBy: createdBy,
            checkFile: true,
            valueCodeAndName: codeandname,
            metadataGroup: metadata,
            strictMode
        });
    }

    public changeSelectedFiles(files: File[]) {
        this.setState({ modelListFileIsSelected: files });
    }

    public handleChangeSelectedFiles(
        key: keyof Pick<File, 'group_id' | 'bucket'>,
        atIndex: number
    ) {
        return (event: React.ChangeEvent<HTMLInputElement>) => {
            event.persist();
            const value = event.target.value;

            this.setState(prevState => {
                const selectedFiles = [...prevState.modelListFileIsSelected];
                selectedFiles[atIndex][key] = value;

                return {
                    ...prevState,
                    modelListFileIsSelected: selectedFiles
                };
            });
        };
    }

    public handleCreatableSelectChange(
        key: keyof Pick<File, 'index_string' | 'metadata'>,
        atIndex: number
    ) {
        return (
            newValue: { label: string; value: string; __isNew__: boolean }[],
            _actionMeta: unknown
        ) => {
            this.setState(prevState => {
                const selectedFiles = [...prevState.modelListFileIsSelected];

                if (key === 'index_string') {
                    selectedFiles[atIndex][key] = newValue.map(value => value.value).join('|');
                } else if (key === 'metadata') {
                    // under-implement
                }

                return {
                    ...prevState,
                    modelListFileIsSelected: selectedFiles
                };
            });
        };
    }

    resetCheckFile(value) {
        if (value === 'reset') {
            this.setState({
                checkFile: false
            });
        }
    }

    oncheckRefresh = () => {
        this.setState({
            checkRefresh: true
        });
    };

    resetoncheckRefresh = check => {
        if (check === 'reset') {
            this.setState({
                checkRefresh: false
            });
        }
    };

    public onUploadSuccess() {
        this.setState({ isReLoadFile: true });
        setTimeout(() => {
            this.setState({ isReLoadFile: false });
        }, 100);
    }

    public onEditSuccess() {
        this.setState({ isReLoadFile: true });
        setTimeout(() => {
            this.setState({ isReLoadFile: false });
        }, 100);
    }

    public switchPage(check) {
        this.setState({ switchPage: check });
    }

    //to do get data from file component
    GetDataSelectFileToButton = () => {
        return this.state.modelListFileIsSelected;
    };

    // ??? Get or update ???
    GetDataSelectFile = (fileSelect: Array<File>) => {
        this.setState({
            modelListFileIsSelected: fileSelect
        });
    };

    public getValueFileList(check: string) {
        if (check === 'get') {
            var node = 'node';
            var wfList: any = [];
            Array.from(document.querySelectorAll('input[name=' + node + ']')).forEach(checkbox => {
                var cb = document.getElementById(checkbox.id) as HTMLInputElement;
                if (cb.checked) {
                    if (cb.dataset.filelist) {
                        var filelist = JSON.parse(cb.dataset.filelist);
                        wfList.push(filelist);
                    }
                }
                this.setState({ modelListFileIsSelected: wfList, getValueEdit: true });
            });
        }
    }

    reloading(value) {
        if (value === 'reset') {
            this.setState({
                checkRefresh: true,
                reloadFolder: true
            });
        }
    }

    resetReloadFolder(value) {
        if (value === 'reset') {
            this.setState({
                reloadFolder: false
            });
        }
    }

    resetGetValueEdit(val) {
        if (val === 'reset') {
            this.setState({ getValueEdit: false });
        }
    }

    onClickSaveBt(val) {
        if (val === 'copy') {
            this.setState({ btClick: 'copy' });
        } else if (val === 'edit') {
            this.setState({ btClick: 'edit' });
        }
    }

    public forceRerender(): void {
        this.forceUpdate();
    }

    public onMetadataChange(atFileIndex: number, atMetadataIndex: number) {
        return (key: string, value: string) => {
            // console.log('ngame key/value change', [key, value]);
            this.setState(prevState => {
                const nextSelectedFileState = [...prevState.modelListFileIsSelected];
                const metadataEntries = convertMetadataToEntries(
                    nextSelectedFileState[atFileIndex].metadata
                );
                const metadataTarget = metadataEntries[atMetadataIndex];
                metadataTarget[0] = key;
                metadataTarget[1] = value;

                nextSelectedFileState[atFileIndex].metadata =
                    convertMetadataToString(metadataEntries);

                return {
                    ...prevState,
                    modelListFileIsSelected: nextSelectedFileState
                };
            });
        };
    }

    public addMetadata(atFileIndex: number): void {
        this.setState(prevState => {
            const nextSelectedFileState = [...prevState.modelListFileIsSelected];
            const metadataEntries = convertMetadataToEntries(
                nextSelectedFileState[atFileIndex].metadata
            );
            metadataEntries.push(['', '']);

            nextSelectedFileState[atFileIndex].metadata = convertMetadataToString(metadataEntries);

            return {
                ...prevState,
                modelListFileIsSelected: nextSelectedFileState
            };
        });
    }

    public removeMetadata(atFileIndex: number, removeAtIndex: number): void {
        this.setState(prevState => {
            const nextSelectedFileState = [...prevState.modelListFileIsSelected];
            const metadataEntries = convertMetadataToEntries(
                nextSelectedFileState[atFileIndex].metadata
            );
            metadataEntries.splice(removeAtIndex, 1);

            nextSelectedFileState[atFileIndex].metadata = convertMetadataToString(metadataEntries);

            return {
                ...prevState,
                modelListFileIsSelected: nextSelectedFileState
            };
        });
    }

    public removeSelectedFile(atIndex: number) {
        this.setState(prevState => {
            const nextSelectedFiles = [...prevState.modelListFileIsSelected];
            nextSelectedFiles.splice(atIndex, 1);

            return {
                ...prevState,
                modelListFileIsSelected: nextSelectedFiles
            };
        });
    }

    toggleShowModalUpload = () => {
        this.setState(prevState => ({
            showModalUpload: !prevState.showModalUpload
        }));
    };

    toggleShowModalCopy = () => {
        this.getValueFileList('get');
        this.setState(prevState => ({
            showModalCopy: !prevState.showModalCopy
        }));
    };

    toggleShowModalEdit = () => {
        this.getValueFileList('get');
        this.setState(prevState => ({
            showModalEdit: !prevState.showModalEdit
        }));
    };

    toggleShowModalDelete = () => {
        this.getValueFileList('get');
        this.setState(prevState => ({
            showModalDelete: !prevState.showModalDelete
        }));
    };

    toggleShowModalShare = () => {
        this.setState(prevState => ({
            showModalShare: !prevState.showModalShare
        }));
    };

    render() {
        var {
            sid,
            isReLoadFile,
            folderSelectedCode,
            folderSelectedName,
            folderSelectedFullPath,
            folderSelectedCreatedBy,
            metadataGroup,
            strictMode
        } = this.state;
        const { id, roles }: IUserContext = this.context;

        // กรณีที่ folder มี role auth: bypass จะเป็นสิทธิ์สูงสุด
        // bypass เมื่อ folder ที่เลิอกถูกสร้างโดย user เอง
        //console.log('[Debug] folderSelectedCreatedBy', folderSelectedCreatedBy)
        //console.log('[Debug] sid', sid)
        //console.log('[Debug] id', id)
        var disableBypass: boolean = true;
        if (
            id === sid ||
            (id === folderSelectedCreatedBy &&
                folderSelectedCreatedBy !== 'system' &&
                folderSelectedCreatedBy !== undefined)
        ) {
            disableBypass = false;
        }
        //console.log('[Debug] disableBypass', disableBypass)

        // console.log('pwd =>', folderSelectedFullPath);
        var disabledShareFile: boolean = true;
        if (folderSelectedFullPath) {
            disabledShareFile = !folderSelectedFullPath.includes('My Bucket');
        }

        return (
            <div className="d-flex row-file-explorer">
                <div className="d-flex sub-left">
                    <ResizableDiv className="row m-0">
                        <div className="col p-0">
                            {this.state.switchPage === 'File' && (
                                <FoldersComponent
                                    valueCodeAndName={this.state.valueCodeAndName}
                                    resetReloadFolder={this.resetReloadFolder.bind(this)}
                                    reloadFolder={this.state.reloadFolder}
                                    oncheckRefresh={this.oncheckRefresh.bind(this)}
                                    onSelectFolder={this.onSelectFolder.bind(this)}
                                />
                            )}
                            {this.state.switchPage === 'Box' && (
                                <BoxIDView onSelectFolder={this.onSelectFolder.bind(this)} />
                            )}
                        </div>
                    </ResizableDiv>
                </div>

                <div className="flex flex-1 flex-col gap-2 overflow-hidden px-4 pt-5">
                    <div className="flex items-center gap-3">
                        <Button
                            disabled={this.props.disabledUpload && disableBypass}
                            className="group mb-0 cursor-pointer rounded-[0.2rem] border border-solid border-[#D8D8D8] bg-[#D2E2F5] pl-2.5 pr-2.5 text-[#2E445D] hover:bg-[#2E445D] hover:text-white disabled:mb-0 disabled:cursor-not-allowed disabled:rounded-[0.2rem] disabled:border disabled:border-solid disabled:border-[rgba(0,0,0,0.1)] disabled:bg-[#D8D8D8] disabled:pl-2.5 disabled:pr-2.5 disabled:text-[grey]"
                            // onClick={this.toggleShowModalUpload}
                            onClick={() => {
                                this.uploadFileModalRef.current?.clickFileInput();
                                // this.toggleShowModalUpload();
                            }}
                        >
                            <MdOutlineUploadFile
                                size={22}
                                color="#7A7A7A"
                                className="mr-[5px] group-hover:text-white group-disabled:text-[gray]"
                            />
                            Upload file
                        </Button>

                        <Button
                            disabled={this.props.disabledCopy && disableBypass}
                            className="group mb-0 cursor-pointer rounded-[0.2rem] border border-solid border-[#D8D8D8] bg-[#D2E2F5] pl-2.5 pr-2.5 text-[#2E445D] hover:bg-[#2E445D] hover:text-white disabled:mb-0 disabled:cursor-not-allowed disabled:rounded-[0.2rem] disabled:border disabled:border-solid disabled:border-[rgba(0,0,0,0.1)] disabled:bg-[#D8D8D8] disabled:pl-2.5 disabled:pr-2.5 disabled:text-[grey]"
                            onClick={this.toggleShowModalCopy}
                        >
                            <FaRegCopy
                                size={20}
                                color="#7A7A7A"
                                className="mr-[5px] group-hover:text-white group-disabled:text-[gray]"
                            />
                            Copy
                        </Button>

                        <Button
                            disabled={this.props.disabledEdit && disableBypass}
                            className="group mb-0 cursor-pointer rounded-[0.2rem] border border-solid border-[#D8D8D8] bg-[#D2E2F5] pl-2.5 pr-2.5 text-[#2E445D] hover:bg-[#2E445D] hover:text-white disabled:mb-0 disabled:cursor-not-allowed disabled:rounded-[0.2rem] disabled:border disabled:border-solid disabled:border-[rgba(0,0,0,0.1)] disabled:bg-[#D8D8D8] disabled:pl-2.5 disabled:pr-2.5 disabled:text-[grey]"
                            onClick={this.toggleShowModalEdit}
                        >
                            <MdOutlineEdit
                                size={22}
                                color="#7A7A7A"
                                className="mr-[5px] group-hover:text-white group-disabled:text-[gray]"
                            />
                            Edit
                        </Button>

                        <Button
                            disabled={this.props.disabledDelete && disableBypass}
                            className="group mb-0 cursor-pointer rounded-[0.2rem] border border-solid border-[#D8D8D8] bg-[#D2E2F5] pl-2.5 pr-2.5 text-[#2E445D] hover:bg-[#2E445D] hover:text-white disabled:mb-0 disabled:cursor-not-allowed disabled:rounded-[0.2rem] disabled:border disabled:border-solid disabled:border-[rgba(0,0,0,0.1)] disabled:bg-[#D8D8D8] disabled:pl-2.5 disabled:pr-2.5 disabled:text-[grey]"
                            onClick={this.toggleShowModalDelete}
                        >
                            <RiDeleteBin6Line
                                size={22}
                                color="#7A7A7A"
                                className="mr-[5px] group-hover:text-white group-disabled:text-[gray]"
                            />
                            Delete
                        </Button>

                        <Button
                            disabled={this.props.disabledUpload && disableBypass}
                            className="group mb-0 cursor-pointer rounded-[0.2rem] border border-solid border-[#D8D8D8] bg-[#D2E2F5] pl-2.5 pr-2.5 text-[#2E445D] hover:bg-[#2E445D] hover:text-white disabled:mb-0 disabled:cursor-not-allowed disabled:rounded-[0.2rem] disabled:border disabled:border-solid disabled:border-[rgba(0,0,0,0.1)] disabled:bg-[#D8D8D8] disabled:pl-2.5 disabled:pr-2.5 disabled:text-[grey]"
                            onClick={this.toggleShowModalShare}
                        >
                            <RiShareFill
                                size={22}
                                color="#7A7A7A"
                                className="mr-[5px] group-hover:text-white group-disabled:text-[gray]"
                            />
                            Share
                        </Button>

                        <Button
                            className="group flex items-center rounded border border-[#0E5E99] text-[#0E5E99] outline outline-1 hover:bg-[#0e5f9999] hover:text-white"
                            onClick={() => this.oncheckRefresh()}
                        >
                            <FaSync
                                size={15}
                                color="#0E5E99"
                                className="mr-[5px] group-hover:text-white group-disabled:text-[gray]"
                            />
                            Refresh
                        </Button>
                    </div>
                    <div className="flex flex-1 py-2">
                        <span className="flex flex-1 gap-2.5 bg-[#3397C3] px-3 py-1.5 text-white">
                            <img
                                src={FolderImg}
                                alt="file"
                                style={{ width: '18px', marginRight: '3px' }}
                            />
                            {/* {'foldername > subfolder_name01 > subfolder_name02'} */}
                            &nbsp;
                            {this.state.folderSelectedFullPath !== ''
                                ? this.state.folderSelectedFullPath
                                : 'All'}
                        </span>
                    </div>

                    <div className="div-file-component-explorer">
                        <FilesTableView
                            resetCheckFile={this.resetCheckFile.bind(this)}
                            checkFile={this.state.checkFile}
                            resetoncheckRefresh={this.resetoncheckRefresh.bind(this)}
                            checkRefresh={this.state.checkRefresh}
                            isReLoadFile={isReLoadFile}
                            folderSelectedSid={sid}
                            folderSelectedCode={folderSelectedCode}
                            GetDataSelectFile={this.GetDataSelectFile.bind(this)}
                            getValueFileList={this.getValueFileList}
                            strictMode={strictMode}
                        />
                    </div>
                </div>

                {/* modal */}
                <UploadFileModal
                    isOpen={this.state.showModalUpload}
                    onClose={this.toggleShowModalUpload}
                    ref={this.uploadFileModalRef}
                    reloading={this.reloading.bind(this)}
                    onUploadSuccess={this.onUploadSuccess.bind(this)}
                    isReLoadFile={isReLoadFile}
                    folderSelectedFullPath={folderSelectedFullPath}
                    folderSelectedCode={folderSelectedCode}
                    folderSelectedSid={sid}
                    folderSelectedName={folderSelectedName}
                    metadataGroup={metadataGroup}
                />
                <ButtonCopyFileComponent
                    isOpen={this.state.showModalCopy}
                    onClose={this.toggleShowModalCopy}
                    disabled={this.props.disabledCopy}
                    getValueFileList={this.getValueFileList.bind(this)}
                    onEditSuccess={this.onEditSuccess.bind(this)}
                    reloading={this.reloading.bind(this)}
                    folderSelectedCode={folderSelectedCode}
                    GetDataSelectFileToButton={this.GetDataSelectFileToButton.bind(this)} // Deprecated (messy, verbose)
                    selectedFiles={this.state.modelListFileIsSelected}
                    onClickSaveBt={this.onClickSaveBt.bind(this)}
                    btClick={this.state.btClick}
                    forceRerender={this.forceRerender.bind(this)}
                    handleChangeSelectedFiles={this.handleChangeSelectedFiles.bind(this)}
                    handleCreatableSelectChange={this.handleCreatableSelectChange.bind(this)}
                    onMetadataChange={this.onMetadataChange.bind(this)}
                    addMetadata={this.addMetadata.bind(this)}
                    removeMetadata={this.removeMetadata.bind(this)}
                    setFileExplorerState={this.setState.bind(this)}
                    changeSelectedFiles={this.changeSelectedFiles.bind(this)}
                    removeSelectedFile={this.removeSelectedFile}
                />
                <ButtonEditFileV2Component
                    // disabled={(strictMode) ? !(roles.includes('02')) : false}
                    // disabled={this.state.disabledEdit}
                    isOpen={this.state.showModalEdit}
                    onClose={this.toggleShowModalEdit}
                    disabledEdit={this.props.disabledEdit && disableBypass}
                    disabledMoveLink={this.props.disabledMoveLink}
                    disabledUpdateBoxId={this.props.disabledUpdateBoxId}
                    resetGetValueEdit={this.resetGetValueEdit.bind(this)}
                    getValueEdit={this.state.getValueEdit}
                    getValueFileList={this.getValueFileList}
                    onEditSuccess={this.onEditSuccess.bind(this)}
                    reloading={this.reloading.bind(this)}
                    folderSelectedCode={folderSelectedCode}
                    GetDataSelectFileToButton={this.GetDataSelectFileToButton.bind(this)}
                    onClickSaveBt={this.onClickSaveBt.bind(this)}
                    btClick={this.state.btClick}
                    forceRerender={this.forceRerender.bind(this)}
                />
                <DeleteFileModal
                    isOpen={this.state.showModalDelete}
                    onClose={this.toggleShowModalDelete}
                    reloading={this.reloading.bind(this)}
                    removeSelectedFile={this.removeSelectedFile}
                    selectedFiles={this.state.modelListFileIsSelected}
                />
                <ShareModal
                    isOpen={this.state.showModalShare}
                    onClose={this.toggleShowModalShare}
                />
            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.folderRole,
    FolderRoleStore.actionCreators
)(FileExplorerView);
