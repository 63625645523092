import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as Stores from '../../store/RoleAccess';
import * as Models from '../../models/RoleAccess';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, InputGroupAddon, InputGroupText, Input, Label } from 'reactstrap';
import { FaPlusCircle, FaEdit, FaTimesCircle } from 'react-icons/fa';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Swal from 'sweetalert2';

type Store = Models.RoleAccessState & typeof Stores.actionCreators;

class RoleAccessComponent extends React.PureComponent<any, { pageLoad: boolean, isOpenModal: boolean, modelRoleAccess: Models.RoleAccess, manageMode: string, version: number, modelListRoleAccess: Array<Models.RoleAccess>, company: string }, Store> {

    constructor(props: any) {
        super(props);

        this.state = {
            pageLoad: true,
            isOpenModal: false,
            modelRoleAccess: {
                sid: "DEV",
                role_access_code: "",
                role_access_name: "",
                created_by: "",
                created_on: "",
                updated_by: "",
                updated_on: "",
                can_view_file: false,
                can_edit_file: false,
                can_edit_folder: false,
                can_edit_box: false,
                can_edit_relation: false,
                can_create_file: false,
                can_create_folder: false,
                can_create_box: false,
                can_create_relation: false,
            },
            manageMode: "",
            version: 0,
            modelListRoleAccess: [],
            company: ""
        }

        this.toggleModal = this.toggleModal.bind(this);
        this.replaceDate = this.replaceDate.bind(this);
        this.handleChangeMataDataCode = this.handleChangeMataDataCode.bind(this);
        this.handleChangeMataDataName = this.handleChangeMataDataName.bind(this);
        this.editClick = this.editClick.bind(this);
        this.addClick = this.addClick.bind(this);
        this.deleteClick = this.deleteClick.bind(this);
    }

    public componentDidMount() {
        //this.ensureDataFetched();

        //if (this.state.company !== this.props.company) {
        //    console.log('My company')
        //    console.log(this.props.company)
        //    this.setState({
        //        company: this.props.company
        //    })
        //}
    }

    componentDidUpdate() {
        if (this.state.company !== this.props.company) {
            console.log('reset company')
            this.setState({
                company: this.props.company
            })
            this.ensureDataFetched();
        }
    }

    private ensureDataFetched() {
        this.setState({ pageLoad: true });

        this.props.requestFunctionRole(true, this.state.version, "GET", { role_access_code: "", sid: this.props.company });

        this.setState({ pageLoad: false });
    }

    private toggleModal() {
        this.setState({
            isOpenModal: !this.state.isOpenModal
        });
    }

    private replaceDate(e: string) {

        var date = "";
        if (e !== null) {
            if (e.length >= 10) {
                var date = e.substring(0, 10);
            }
        }

        return date;
    }

    private handleChangeMataDataCode(e: any) {
        var data = this.state.modelRoleAccess;
        data.role_access_code = e.target.value;

        this.setState({
            modelRoleAccess: data
        });
    }

    private handleChangeMataDataName(e: any) {
        var data = this.state.modelRoleAccess;
        data.role_access_name = e.target.value;

        this.setState({
            modelRoleAccess: data
        });
    }

    private handleChangeFileExtension(e: any, check: string) {
        var data = this.state.modelRoleAccess

        if (check === "viewfile") {
            data.can_view_file = e.target.checked
        } else if (check === "editfile") {
            data.can_edit_file = e.target.checked
        } else if (check === "editfolder") {
            data.can_edit_folder = e.target.checked
        } else if (check === "editbox") {
            data.can_edit_box = e.target.checked
        } else if (check === "editrelation") {
            data.can_edit_relation = e.target.checked
        } else if (check === "createfile") {
            data.can_create_file = e.target.checked
        } else if (check === "createfolder") {
            data.can_create_folder = e.target.checked
        } else if (check === "createbox") {
            data.can_create_box = e.target.checked
        } else if (check === "createrelation") {
            data.can_create_relation = e.target.checked
        }

        this.setState({
            modelRoleAccess: data
        });
    }

    private editClick(e: any) {
        this.props.requestFunctionRole(this.state.pageLoad, this.state.version, "PUT", this.state.modelRoleAccess);
        document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
    }

    private addClick(e: any) {
        const { modelRoleAccess } = this.state
        if (modelRoleAccess.role_access_code !== "") {
            this.props.requestFunctionRole(this.state.pageLoad, this.state.version, "POST", this.state.modelRoleAccess);
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
        } else {
            Swal.fire({
                title: 'ไม่สำเร็จ!',
                text: 'โปรดกรอก Role Access Code',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!'
            })
        }
    }

    private deleteClick(e: any) {
        this.props.requestFunctionRole(this.state.pageLoad, this.state.version, "DELETE", this.state.modelRoleAccess);
        document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
    }

    openModalAdd = () => {
        this.setState({
            manageMode: "Add",
            modelRoleAccess: {
                sid: "DEV",
                role_access_code: "",
                role_access_name: "",
                created_by: "",
                created_on: "",
                updated_by: "",
                updated_on: "",
                can_view_file: false,
                can_edit_file: false,
                can_edit_folder: false,
                can_edit_box: false,
                can_edit_relation: false,
                can_create_file: false,
                can_create_folder: false,
                can_create_box: false,
                can_create_relation: false,
            }
        });

        this.toggleModal();
    }

    openModalEdit = (data) => {
        this.state.modelRoleAccess.role_access_code = data.item.role_access_code
        this.state.modelRoleAccess.role_access_name = data.item.role_access_name

        this.state.modelRoleAccess.can_view_file = data.item.can_view_file
        this.state.modelRoleAccess.can_edit_file = data.item.can_edit_file
        this.state.modelRoleAccess.can_edit_folder = data.item.can_edit_folder
        this.state.modelRoleAccess.can_edit_box = data.item.can_edit_box
        this.state.modelRoleAccess.can_edit_relation = data.item.can_edit_relation
        this.state.modelRoleAccess.can_create_file = data.item.can_create_file
        this.state.modelRoleAccess.can_create_folder = data.item.can_create_folder
        this.state.modelRoleAccess.can_create_box = data.item.can_create_box
        this.state.modelRoleAccess.can_create_relation = data.item.can_create_relation

        this.setState({ manageMode: "Edit" });
        this.toggleModal();
    }

    openModalDelete = (data) => {
        this.state.modelRoleAccess.role_access_code = data.item.role_access_code
        this.state.modelRoleAccess.role_access_name = data.item.role_access_name

        this.state.modelRoleAccess.can_view_file = data.item.can_view_file
        this.state.modelRoleAccess.can_edit_file = data.item.can_edit_file
        this.state.modelRoleAccess.can_edit_folder = data.item.can_edit_folder
        this.state.modelRoleAccess.can_edit_box = data.item.can_edit_box
        this.state.modelRoleAccess.can_edit_relation = data.item.can_edit_relation
        this.state.modelRoleAccess.can_create_file = data.item.can_create_file
        this.state.modelRoleAccess.can_create_folder = data.item.can_create_folder
        this.state.modelRoleAccess.can_create_box = data.item.can_create_box
        this.state.modelRoleAccess.can_create_relation = data.item.can_create_relation

        this.setState({ manageMode: "Delete" });
        this.toggleModal();
    }

    setBodyModal = () => {

        if (this.state.manageMode === "Add") {
            return <div>
                <InputGroup className="mt-3">
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText className="role-font" style={{ width: "160px" }}>Role Access Code</InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Role Access Code" type="text" onChange={this.handleChangeMataDataCode} />
                </InputGroup>
                <InputGroup className="mt-3">
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText className="role-font" style={{ width: "160px" }}>Role Access Name</InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Role Access Name" type="text" onChange={this.handleChangeMataDataName} />
                </InputGroup>
                <InputGroup className="mt-3">
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText style={{ width: "160px" }}>Permission</InputGroupText>
                    </InputGroupAddon>

                    <div className="row" style={{ width: "80%" }}>
                        <div className="column" style={{ marginLeft: "50px", width: "25%" }}>
                            <InputGroup check="true">
                                <Label check>
                                    <Input type="checkbox"
                                        name="cbFileExtension"
                                        data-fileextension="*"
                                        onChange={(e) => this.handleChangeFileExtension(e, "viewfile")}
                                    />{' '}
                                    View File
                                </Label>
                            </InputGroup>
                        </div>
                        <div className="column" style={{ marginLeft: "50px", width: "25%" }}>
                            <InputGroup check="true">
                                <Label check>
                                    <Input type="checkbox"
                                        name="cbFileExtension"
                                        data-fileextension="*"
                                        onChange={(e) => this.handleChangeFileExtension(e, "editfile")}
                                    />{' '}
                                    Edit File
                                </Label>
                            </InputGroup>
                            <InputGroup check="true">
                                <Label check>
                                    <Input type="checkbox"
                                        name="cbFileExtension"
                                        data-fileextension="*"
                                        onChange={(e) => this.handleChangeFileExtension(e, "editfolder")}
                                    />{' '}
                                    Edit Folder
                                </Label>
                            </InputGroup>
                            <InputGroup check="true">
                                <Label check>
                                    <Input type="checkbox"
                                        name="cbFileExtension"
                                        data-fileextension="*"
                                        onChange={(e) => this.handleChangeFileExtension(e, "editbox")}
                                    />{' '}
                                    Edit Box
                                </Label>
                            </InputGroup>
                            <InputGroup check="true">
                                <Label check>
                                    <Input type="checkbox"
                                        name="cbFileExtension"
                                        data-fileextension="*"
                                        onChange={(e) => this.handleChangeFileExtension(e, "editrelation")}
                                    />{' '}
                                    Edit Relation
                                </Label>
                            </InputGroup>
                        </div>
                        <div className="column" style={{ marginLeft: "50px", width: "25%" }}>
                            <InputGroup check="true">
                                <Label check>
                                    <Input type="checkbox"
                                        name="cbFileExtension"
                                        data-fileextension="*"
                                        onChange={(e) => this.handleChangeFileExtension(e, "createfile")}
                                    />{' '}
                                    Create File
                                </Label>
                            </InputGroup>
                            <InputGroup check="true">
                                <Label check>
                                    <Input type="checkbox"
                                        name="cbFileExtension"
                                        data-fileextension="*"
                                        onChange={(e) => this.handleChangeFileExtension(e, "createfolder")}
                                    />{' '}
                                    Create Folder
                                </Label>
                            </InputGroup>
                            <InputGroup check="true">
                                <Label check>
                                    <Input type="checkbox"
                                        name="cbFileExtension"
                                        data-fileextension="*"
                                        onChange={(e) => this.handleChangeFileExtension(e, "createbox")}
                                    />{' '}
                                    Create Box
                                </Label>
                            </InputGroup>
                            <InputGroup check="true">
                                <Label check>
                                    <Input type="checkbox"
                                        name="cbFileExtension"
                                        data-fileextension="*"
                                        onChange={(e) => this.handleChangeFileExtension(e, "createrelation")}
                                    />{' '}
                                    Create Relation
                                </Label>
                            </InputGroup>
                        </div>
                    </div>
                </InputGroup>
            </div>;
        } else if (this.state.manageMode === "Edit") {
            return <div>
                <InputGroup>
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText className="role-font">Role Access Code</InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Role Access Code" type="text" readOnly defaultValue={this.state.modelRoleAccess.role_access_code} onChange={this.handleChangeMataDataCode} />
                </InputGroup>
                <br />
                <InputGroup>
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText className="role-font">Role Access Name</InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Role Access Name" type="text" defaultValue={this.state.modelRoleAccess.role_access_name} onChange={this.handleChangeMataDataName} />
                </InputGroup>
                <InputGroup className="mt-3">
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText style={{ width: "160px" }}>Permission</InputGroupText>
                    </InputGroupAddon>

                    <div className="row" style={{ width: "80%" }}>
                        <div className="column" style={{ marginLeft: "50px", width: "25%" }}>
                            <InputGroup check="true">
                                <Label check>
                                    <Input type="checkbox"
                                        defaultChecked={this.state.modelRoleAccess.can_view_file}
                                        name="cbFileExtension"
                                        data-fileextension="*"
                                        onChange={(e) => this.handleChangeFileExtension(e, "viewfile")}
                                    />{' '}
                                    View File
                                </Label>
                            </InputGroup>
                        </div>
                        <div className="column" style={{ marginLeft: "50px", width: "25%" }}>
                            <InputGroup check="true">
                                <Label check>
                                    <Input type="checkbox"
                                        defaultChecked={this.state.modelRoleAccess.can_edit_file}
                                        name="cbFileExtension"
                                        data-fileextension="*"
                                        onChange={(e) => this.handleChangeFileExtension(e, "editfile")}
                                    />{' '}
                                    Edit File
                                </Label>
                            </InputGroup>
                            <InputGroup check="true">
                                <Label check>
                                    <Input type="checkbox"
                                        defaultChecked={this.state.modelRoleAccess.can_edit_folder}
                                        name="cbFileExtension"
                                        data-fileextension="*"
                                        onChange={(e) => this.handleChangeFileExtension(e, "editfolder")}
                                    />{' '}
                                    Edit Folder
                                </Label>
                            </InputGroup>
                            <InputGroup check="true">
                                <Label check>
                                    <Input type="checkbox"
                                        defaultChecked={this.state.modelRoleAccess.can_edit_box}
                                        name="cbFileExtension"
                                        data-fileextension="*"
                                        onChange={(e) => this.handleChangeFileExtension(e, "editbox")}
                                    />{' '}
                                    Edit Box
                                </Label>
                            </InputGroup>
                            <InputGroup check="true">
                                <Label check>
                                    <Input type="checkbox"
                                        defaultChecked={this.state.modelRoleAccess.can_edit_relation}
                                        name="cbFileExtension"
                                        data-fileextension="*"
                                        onChange={(e) => this.handleChangeFileExtension(e, "editrelation")}
                                    />{' '}
                                    Edit Relation
                                </Label>
                            </InputGroup>
                        </div>
                        <div className="column" style={{ marginLeft: "50px", width: "25%" }}>
                            <InputGroup check="true">
                                <Label check>
                                    <Input type="checkbox"
                                        defaultChecked={this.state.modelRoleAccess.can_create_file}
                                        name="cbFileExtension"
                                        data-fileextension="*"
                                        onChange={(e) => this.handleChangeFileExtension(e, "createfile")}
                                    />{' '}
                                    Create File
                                </Label>
                            </InputGroup>
                            <InputGroup check="true">
                                <Label check>
                                    <Input type="checkbox"
                                        defaultChecked={this.state.modelRoleAccess.can_create_folder}
                                        name="cbFileExtension"
                                        data-fileextension="*"
                                        onChange={(e) => this.handleChangeFileExtension(e, "createfolder")}
                                    />{' '}
                                    Create Folder
                                </Label>
                            </InputGroup>
                            <InputGroup check="true">
                                <Label check>
                                    <Input type="checkbox"
                                        defaultChecked={this.state.modelRoleAccess.can_create_box}
                                        name="cbFileExtension"
                                        data-fileextension="*"
                                        onChange={(e) => this.handleChangeFileExtension(e, "createbox")}
                                    />{' '}
                                    Create Box
                                </Label>
                            </InputGroup>
                            <InputGroup check="true">
                                <Label check>
                                    <Input type="checkbox"
                                        defaultChecked={this.state.modelRoleAccess.can_create_relation}
                                        name="cbFileExtension"
                                        data-fileextension="*"
                                        onChange={(e) => this.handleChangeFileExtension(e, "createrelation")}
                                    />{' '}
                                    Create Relation
                                </Label>
                            </InputGroup>
                        </div>
                    </div>
                </InputGroup>
            </div>;
        } else if (this.state.manageMode === "Delete") {
            return <div>
                <InputGroup>
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText className="role-font">Role Access Code</InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Role Access Code" type="text" value={this.state.modelRoleAccess.role_access_code} onChange={this.handleChangeMataDataCode} disabled />
                </InputGroup>
                <br />
                <InputGroup>
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText className="role-font">Role Access Name</InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Role Access Name" type="text" value={this.state.modelRoleAccess.role_access_name} onChange={this.handleChangeMataDataName} disabled />
                </InputGroup>
                <InputGroup className="mt-3">
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText style={{ width: "160px" }}>Permission</InputGroupText>
                    </InputGroupAddon>

                    <div className="row" style={{ width: "80%" }}>
                        <div className="column" style={{ marginLeft: "50px", width: "25%" }}>
                            <InputGroup check="true">
                                <Label check>
                                    <Input type="checkbox"
                                        disabled
                                        defaultChecked={this.state.modelRoleAccess.can_view_file}
                                        name="cbFileExtension"
                                        data-fileextension="*"
                                        onChange={(e) => this.handleChangeFileExtension(e, "viewfile")}
                                    />{' '}
                                    View File
                                </Label>
                            </InputGroup>
                        </div>
                        <div className="column" style={{ marginLeft: "50px", width: "25%" }}>
                            <InputGroup check="true">
                                <Label check>
                                    <Input type="checkbox"
                                        disabled
                                        defaultChecked={this.state.modelRoleAccess.can_edit_file}
                                        name="cbFileExtension"
                                        data-fileextension="*"
                                        onChange={(e) => this.handleChangeFileExtension(e, "editfile")}
                                    />{' '}
                                    Edit File
                                </Label>
                            </InputGroup>
                            <InputGroup check="true">
                                <Label check>
                                    <Input type="checkbox"
                                        disabled
                                        defaultChecked={this.state.modelRoleAccess.can_edit_folder}
                                        name="cbFileExtension"
                                        data-fileextension="*"
                                        onChange={(e) => this.handleChangeFileExtension(e, "editfolder")}
                                    />{' '}
                                    Edit Folder
                                </Label>
                            </InputGroup>
                            <InputGroup check="true">
                                <Label check>
                                    <Input type="checkbox"
                                        disabled
                                        defaultChecked={this.state.modelRoleAccess.can_edit_box}
                                        name="cbFileExtension"
                                        data-fileextension="*"
                                        onChange={(e) => this.handleChangeFileExtension(e, "editbox")}
                                    />{' '}
                                    Edit Box
                                </Label>
                            </InputGroup>
                            <InputGroup check="true">
                                <Label check>
                                    <Input type="checkbox"
                                        disabled
                                        defaultChecked={this.state.modelRoleAccess.can_edit_relation}
                                        name="cbFileExtension"
                                        data-fileextension="*"
                                        onChange={(e) => this.handleChangeFileExtension(e, "editrelation")}
                                    />{' '}
                                    Edit Relation
                                </Label>
                            </InputGroup>
                        </div>
                        <div className="column" style={{ marginLeft: "50px", width: "25%" }}>
                            <InputGroup check="true">
                                <Label check>
                                    <Input type="checkbox"
                                        disabled
                                        defaultChecked={this.state.modelRoleAccess.can_create_file}
                                        name="cbFileExtension"
                                        data-fileextension="*"
                                        onChange={(e) => this.handleChangeFileExtension(e, "createfile")}
                                    />{' '}
                                    Create File
                                </Label>
                            </InputGroup>
                            <InputGroup check="true">
                                <Label check>
                                    <Input type="checkbox"
                                        disabled
                                        defaultChecked={this.state.modelRoleAccess.can_create_folder}
                                        name="cbFileExtension"
                                        data-fileextension="*"
                                        onChange={(e) => this.handleChangeFileExtension(e, "createfolder")}
                                    />{' '}
                                    Create Folder
                                </Label>
                            </InputGroup>
                            <InputGroup check="true">
                                <Label check>
                                    <Input type="checkbox"
                                        disabled
                                        defaultChecked={this.state.modelRoleAccess.can_create_box}
                                        name="cbFileExtension"
                                        data-fileextension="*"
                                        onChange={(e) => this.handleChangeFileExtension(e, "createbox")}
                                    />{' '}
                                    Create Box
                                </Label>
                            </InputGroup>
                            <InputGroup check="true">
                                <Label check>
                                    <Input type="checkbox"
                                        disabled
                                        defaultChecked={this.state.modelRoleAccess.can_create_relation}
                                        name="cbFileExtension"
                                        data-fileextension="*"
                                        onChange={(e) => this.handleChangeFileExtension(e, "createrelation")}
                                    />{' '}
                                    Create Relation
                                </Label>
                            </InputGroup>
                        </div>
                    </div>
                </InputGroup>
            </div>;
        }

    }

    setButtonModal = () => {

        if (this.state.manageMode === "Add") {
            return <Button color="primary" className="role-font" onClick={this.addClick}>Add</Button>;
        } else if (this.state.manageMode === "Edit") {
            return <Button color="warning" className="role-font" onClick={this.editClick}>Edit</Button>;
        } else if (this.state.manageMode === "Delete") {
            return <Button color="danger" className="role-font" onClick={this.deleteClick}>Delete</Button>;
        }

    }

    render() {

        const { response } = this.props
        if (this.props.isLoadingRole) {
            this.setState({ modelListRoleAccess: this.props.response.result_list as Array<Models.RoleAccess>, isOpenModal: false });
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
        }
        return (
            <div className="div-role-access-main div-meta-role">
                <div>
                    <div className='trapezoid' style={{ width: 'auto', display: 'inline-block' }}>
                        <span className="role-font">
                            Role Access
                        </span>
                    </div>
                    <div style={{ display: 'inline', float: 'right' }}>
                        <Button className="btn-add role-font" onClick={this.openModalAdd}><FaPlusCircle style={{ marginBottom: '4px' }} /> Add</Button>
                    </div>
                </div>
                <table className='table custom-table table-hover'>
                    <thead>
                        <tr>
                            <th className="td-code">ID</th>
                            <th className="td-name">Name</th>
                            <th className="td-name">View File</th>
                            <th className="td-name">Edit File</th>
                            <th className="td-name">Edit Folder</th>
                            <th className="td-name">Edit Box</th>
                            <th className="td-name">Edit Relation</th>
                            <th className="td-name">Create File</th>
                            <th className="td-name">Create Folder</th>
                            <th className="td-name">Create Box</th>
                            <th className="td-name">Create Relation</th>
                            <th className="td-update-on" >Modified</th>
                            <th className="td-update-by" >Modified By</th>
                            <th className="td-menu" >Menu</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.modelListRoleAccess !== undefined && this.state.modelListRoleAccess.length > 0 ? this.state.modelListRoleAccess.map((item: Models.RoleAccess) =>
                                <tr key={item.role_access_code} className="table-tbody"  >
                                    <td className="td-code">{item.role_access_code}</td>
                                    <td className="td-name">{item.role_access_name}</td>
                                    {item.can_view_file ?
                                        <td className="td-verify td-center" ><CheckCircleIcon className="td-icon-close" style={{ color: "green" }} /></td>
                                        : <td className="td-verify td-center" ><CancelIcon className="td-icon-close" style={{ color: "red" }} /></td>
                                    }
                                    {item.can_edit_file ?
                                        <td className="td-verify td-center" ><CheckCircleIcon className="td-icon-close" style={{ color: "green" }} /></td>
                                        : <td className="td-verify td-center" ><CancelIcon className="td-icon-close" style={{ color: "red" }} /></td>
                                    }
                                    {item.can_edit_folder ?
                                        <td className="td-verify td-center" ><CheckCircleIcon className="td-icon-close" style={{ color: "green" }} /></td>
                                        : <td className="td-verify td-center" ><CancelIcon className="td-icon-close" style={{ color: "red" }} /></td>
                                    }
                                    {item.can_edit_box ?
                                        <td className="td-verify td-center" ><CheckCircleIcon className="td-icon-close" style={{ color: "green" }} /></td>
                                        : <td className="td-verify td-center" ><CancelIcon className="td-icon-close" style={{ color: "red" }} /></td>
                                    }
                                    {item.can_edit_relation ?
                                        <td className="td-verify td-center" ><CheckCircleIcon className="td-icon-close" style={{ color: "green" }} /></td>
                                        : <td className="td-verify td-center" ><CancelIcon className="td-icon-close" style={{ color: "red" }} /></td>
                                    }
                                    {item.can_create_file ?
                                        <td className="td-verify td-center" ><CheckCircleIcon className="td-icon-close" style={{ color: "green" }} /></td>
                                        : <td className="td-verify td-center" ><CancelIcon className="td-icon-close" style={{ color: "red" }} /></td>
                                    }
                                    {item.can_create_folder ?
                                        <td className="td-verify td-center" ><CheckCircleIcon className="td-icon-close" style={{ color: "green" }} /></td>
                                        : <td className="td-verify td-center" ><CancelIcon className="td-icon-close" style={{ color: "red" }} /></td>
                                    }
                                    {item.can_create_box ?
                                        <td className="td-verify td-center" ><CheckCircleIcon className="td-icon-close" style={{ color: "green" }} /></td>
                                        : <td className="td-verify td-center" ><CancelIcon className="td-icon-close" style={{ color: "red" }} /></td>
                                    }
                                    {item.can_create_relation ?
                                        <td className="td-verify td-center" ><CheckCircleIcon className="td-icon-close" style={{ color: "green" }} /></td>
                                        : <td className="td-verify td-center" ><CancelIcon className="td-icon-close" style={{ color: "red" }} /></td>
                                    }

                                    <td className="td-update-on" >{this.replaceDate(item.created_on)}</td>
                                    <td className="td-update-by" >{item.created_by}</td>
                                    <td className="td-menu" >
                                        <Button className="btn-edit-file" size="sm" onClick={() => this.openModalEdit({ item })}><FaEdit style={{ marginBottom: '4px' }} /> Edit</Button>{' '}
                                        <Button className="btn-delete-file" size="sm" style={{ padding: '3px 5px' }} color="danger" onClick={() => this.openModalDelete({ item })}><FaTimesCircle style={{ marginBottom: '2px' }} /> Delete</Button>
                                    </td>
                                </tr>
                            ) : <tr></tr>}

                        {this.state.modelListRoleAccess === undefined ? <tr><td colSpan={14} style={{ color: "black", fontSize: '10pt', textAlign: "center" }}>No data.</td></tr> : ''}
                    </tbody>
                </table>

                <Modal isOpen={this.state.isOpenModal} className="modal-upload-file">
                    <ModalHeader className="role-font" toggle={this.toggleModal}>{this.state.manageMode + ' Role Access'}</ModalHeader>
                    <ModalBody>

                        {this.setBodyModal()}

                    </ModalBody>
                    <ModalFooter>
                        {this.setButtonModal()}
                        <Button color="secondary" className="role-font" onClick={this.toggleModal}>Cancel</Button>
                    </ModalFooter>
                </Modal>

            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({ ...state.roleAccess, ...state.appState }),
    Stores.actionCreators
)(RoleAccessComponent);