import React from 'react';
import { MdOutlineSchedule } from 'react-icons/md';

import { Tab } from 'common/materials';

import FileLogTableView from './FileLogTable.view';
import FileLogViewModel, { Props } from './FileLog.viewmodel';

function FileLogView(props: Props) {
    const { TABS, handleChangeTab, selectedTab, fileLog, fetchFileLog } = FileLogViewModel(props);

    return (
        <React.Fragment>
            <div className="flex flex-col gap-2 bg-white p-2">
                <div className="flex items-center gap-2 px-5 pt-2.5">
                    <Tab
                        values={TABS}
                        onChange={handleChangeTab}
                        defaultSelectedValue={TABS[0]}
                    />

                    <div className="flex items-center gap-1">
                        <MdOutlineSchedule
                            size={20}
                            color="#75796C"
                        />

                        <p className="text-xs font-light text-[#333333]">
                            login at 24/02/2023 10:00:00
                        </p>
                    </div>
                </div>

                <FileLogTableView
                    selectedTab={selectedTab}
                    fileLog={fileLog}
                />
            </div>
        </React.Fragment>
    );
}

export default FileLogView;
