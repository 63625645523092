import 'custom.css';

import React, { useEffect } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { observer } from 'mobx-react-lite';
import { createBrowserHistory } from 'history';
import { ConnectedRouter } from 'connected-react-router';
import { ChakraProvider } from '@chakra-ui/react';
import Swal from 'sweetalert2';

import Layout from 'routes/Layout';
import DefaultValueProvider from 'context/DefaultValueProvider';
import colorPalette from 'constants/color-palette';
import { combineComponents, redirectToAppPool } from 'common/helpers';
import { useStore } from 'common/hooks';
import { LoaderProvider } from 'common/context';
import AxiosInterceptorUtils from 'common/helpers/axios-intercepter';
import { extractAccessToken } from 'common/helpers/local-storage';
import UserProvider, { useUserContext } from 'context/UserProvider';
import AdminProvider, { useAdminContext } from 'context/AdminProvider';
import chakraTheme from 'constants/chakra-ui-theme';

// TODO - Move this * to redux (make sure every page use redux too)
import { ApplicationState } from 'store';
import configureStore from 'store/configureStore';
import { actionCreators as CompanyActions } from 'store/CompanyMaster';
import { actionCreators as AppStateActions } from 'store/AppState';

AxiosInterceptorUtils.initialInterceptRequest();

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') as string;
const history = createBrowserHistory({ basename: baseUrl });
const store = configureStore(history);

const Main = observer(() => {
    const { appStore, userStore } = useStore();
    const { employees, userRoles } = userStore;

    const { actions } = useUserContext();
    const { setUserRole } = useAdminContext();
    const dispatch = useDispatch();
    const { companyMaster } = useSelector<
        ApplicationState,
        Pick<ApplicationState, 'companyMaster'>
    >((state: ApplicationState) => ({ companyMaster: state.companyMaster }));
    const location = useLocation();

    // * Initial fetch data
    useEffect(() => {
        appStore.fetchAppVersion();

        if (location.pathname.includes('/view')) {
            return;
        }

        const userInfo = extractAccessToken();
        const citizenId = userInfo?.citizen_id ?? userInfo?.username;
        if (!userInfo || !citizenId) {
            Swal.fire({
                title: 'เกิดข้อผิดพลาด',
                text: 'ไม่พบ user ใน token',
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: colorPalette.primary[600],
                confirmButtonText: 'ยืนยัน'
            }).then(() => redirectToAppPool());

            return;
        }

        dispatch(CompanyActions.requestFunctionCompanyMaster(true, 'GET', '', 'active', '') as any);
        fetchUserRole(citizenId);
        fetchEmployee(citizenId);
    }, []);

    /**
     * * Handling response from company master
     * ! Hardly working stuff
     */
    useEffect(() => {
        if (!companyMaster) {
            return;
        }

        const { statusCompanyMaster, responseCompanyMaster } = companyMaster;

        if (
            statusCompanyMaster !== 200 ||
            !responseCompanyMaster ||
            responseCompanyMaster.total_count === 0 ||
            !employees
        ) {
            return;
        }

        dispatch(AppStateActions.updateCompany(responseCompanyMaster.result_list[0].sid) as any);

        const userInfo = extractAccessToken();
        const citizenId = userInfo?.citizen_id ?? userInfo?.username;
        const userCompany = employees.find(employee => employee.id.toLowerCase() === citizenId);

        if (!userCompany) {
            return;
        }

        const availableCompanies = responseCompanyMaster.result_list.filter(company =>
            userCompany.list_company.some(userCompany => company.sid === userCompany.sid)
        );

        actions.setAvailableCompanies(availableCompanies);
        dispatch(AppStateActions.updateAvailableCompany(availableCompanies) as any);
        dispatch(
            AppStateActions.updateCompanyShortName(
                availableCompanies.map(company => company.company_short_name)
            ) as any
        );
    }, [companyMaster, employees]);

    // TODO - Eject admin context
    useEffect(() => {
        if (userRoles.length === 0) {
            return;
        }

        setUserRole(userRoles);
    }, [userRoles]);

    const fetchUserRole = async (citizenId: string) => {
        try {
            await userStore.fetchUserRole(citizenId);
        } catch (error) {
            Swal.fire({
                title: 'เกิดข้อผิดพลาด',
                text: 'ไม่สามารถเชื่อมต่อกับ server ได้ในขณะนี้',
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน'
            }).then(() => window.location.reload());
        }
    };

    const fetchEmployee = async (citizenId: string) => {
        try {
            const employees = await userStore.fetchEmployees(citizenId);
            if (employees.total_count === 0) {
                throw void 0;
            }

            const employee = employees.result_list[0];

            actions.setId(employee.id);
            actions.setListCompany(employee.list_company);
        } catch (error) {
            Swal.fire({
                title: 'เกิดข้อผิดพลาด',
                // text: 'ไม่พบ user นี้ในระบบ',
                html: `<span>ไม่พบ user ${citizenId} ในระบบ DMS<br/>กรุณาติดต่อผู้ดูแลระบบ</span>`,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน'
            }).then(() => redirectToAppPool());
        }
    };

    return <Layout />;
});

const AppContextProvider = combineComponents(
    ({ children }) => <ChakraProvider theme={chakraTheme}>{children}</ChakraProvider>,
    ({ children }) => <Provider store={store}>{children}</Provider>,
    ({ children }) => <ConnectedRouter history={history}>{children}</ConnectedRouter>,
    UserProvider,
    LoaderProvider,
    DefaultValueProvider,
    AdminProvider
);

function App() {
    return (
        <AppContextProvider>
            <Main />
        </AppContextProvider>
    );
}

export default App;
