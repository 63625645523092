import { makeAutoObservable, runInAction } from 'mobx';

import { requestAppVersion } from 'data/datasources/app-service/app.datasource';

import RootStore from 'mobx-store/Root.store';

class AppStore {
    public version = '';

    constructor(private rootStore: RootStore) {
        makeAutoObservable(this, {}, { autoBind: true });
    }

    public async fetchAppVersion() {
        const data = (await requestAppVersion()).data;
        runInAction(() => (this.version = data.message ?? 'unknown version'));
    }
}

export default AppStore;
