import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import * as File from '../models/File';

interface RequestFunctionAction {
    type: 'REQUEST_FILEDETAIL';
    versionDetail: number;
}

interface ReceiveFunctionAction {
    type: 'RECEIVE_FILEDETAIL';
    model: File.ResponseFile;
    versionDetail: number;
}

type KnownAction = RequestFunctionAction | ReceiveFunctionAction

export const actionCreators = {
    requestFunctionMeta: (pageload: boolean, version: number, method: string, body: File.File): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        var reqBody = {
            id: body.id,
            bucket: body.bucket,
            index_string: body.index_string,
            role_access: body.role_access,
            file_name: body.file_name,
            metadata: body.metadata,
            group_id: body.group_id,
            sub_group_id: body.sub_group_id,
            box_id: body.box_id,
            relation: body.relation
        }
        console.log("start call reducer");
        console.log(reqBody);

        if (appState && appState.fileDetail && method === "PUT") {

            fetch(`/v1/dms/edit`, {
                method: 'PUT',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('DMS_APPLICATION'),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(reqBody),
            })
                .then(response => {
                    return response.json() as Promise<File.ResponseFile>
                })
                .then(data => {
                    dispatch({ type: 'RECEIVE_FILEDETAIL', model: data, versionDetail: version + 2 });
                })
                .then(error => {
                    dispatch({ type: 'REQUEST_FILEDETAIL', versionDetail: version + 1 });
                });
        }

    }
};

const unloadedState: File.FileDetailState = { isLoadingDetail: false, responseDetail: undefined, versionDetail: 0 };

export const reducer: Reducer<File.FileDetailState> = (state: File.FileDetailState | undefined, incomingAction: Action): File.FileDetailState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {

        case 'REQUEST_FILEDETAIL':
            return {
                isLoadingDetail: false,
                versionDetail: action.versionDetail
            };
        case 'RECEIVE_FILEDETAIL':
            return {
                isLoadingDetail: true,
                responseDetail: action.model,
                versionDetail: action.versionDetail
            };

        default: return state;
    }
};