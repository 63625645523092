import { createContext, useContext } from 'react';

import { store } from 'mobx-store';

const StoreContext = createContext(store);

function useStore() {
    return useContext(StoreContext);
}

export default useStore;
