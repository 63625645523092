import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { ResponseList, RequestOptions } from '../models/Common';
import { SearchCriteria, SearchCriteriaStoreState, SearchCriteriaState, ResponseSearchCriteria, DocumentType, User } from '../models/SearchCriteria';

enum Actions {
    REQUEST_CRITERIASEARCH = 'REQUEST_CRITERIASEARCH',
    RECEIVE_CRITERIASEARCH = 'RECEIVE_CRITERIASEARCH',
    CLEAR_CRITERIASEARCH = 'CLEAR_CRITERIASEARCH',
    FAILURE_CRITERIASEARCH = 'FAILURE_CRITERIASEARCH',

    REQUEST_DOCUMENTTYPE = 'REQUEST_DOCUMENTTYPE',
    RECEIVE_DOCUMENTTYPE = 'RECEIVE_DOCUMENTTYPE',
    CLEAR_DOCUMENTTYPE = 'CLEAR_DOCUMENTTYPE',
    FAILURE_DOCUMENTTYPE = 'FAILURE_DOCUMENTTYPE',

    REQUEST_USER = 'REQUEST_USER',
    CLEAR_USER = 'CLEAR_USER'
}

interface RequestAction {
    type: Actions.REQUEST_CRITERIASEARCH;
    status: number;
}

interface ReceiveAction {
    type: Actions.RECEIVE_CRITERIASEARCH;
    model: ResponseSearchCriteria;
    status: number;
}

interface ClearAction {
    type: Actions.CLEAR_CRITERIASEARCH;
    status: number;
}

interface FailureAction {
    type: Actions.FAILURE_CRITERIASEARCH;
    status: number;
}

interface RequestDocumentTypeAction {
    type: Actions.REQUEST_DOCUMENTTYPE;
    status: number;
}

interface ClearDocumentTypeAction {
    type: Actions.CLEAR_DOCUMENTTYPE;
}

interface RequestUserAction {
    type: Actions.REQUEST_USER;
}

interface ClearUserAction {
    type: Actions.CLEAR_USER;
}

type KnownAction =
    | RequestAction
    | ReceiveAction
    | ClearAction
    | FailureAction

    | RequestDocumentTypeAction
    | ClearDocumentTypeAction

    | RequestUserAction
    | ClearUserAction;

// interface RequestOptions<T = {}> {
//     version: number;
//     method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'CLEAR';
//     body?: T;
// }

export interface ActionCreators {
    requestCriteriaSearch: (options: RequestOptions<SearchCriteriaState>) => AppThunkAction<KnownAction>;
    requestUpdateBoxId: (options: RequestOptions<Pick<SearchCriteria, 'id' | 'boxId'>[]>, onResponse: (data: any, error: Error | null) => void) => AppThunkAction<KnownAction>;
    requestDocumentType: (options: RequestOptions, sid: string, onResponse: (data: ResponseList<DocumentType> | null, error: Error | null) => void) => AppThunkAction<KnownAction>;
    requestUser: (options: RequestOptions<{}, { sid: string }>, onResponse: (data: ResponseList<User> | null, error: Error | null) => void) => AppThunkAction<KnownAction>;
}

export const actionCreators: ActionCreators = {
    requestCriteriaSearch: (options: RequestOptions<SearchCriteriaState>): AppThunkAction<KnownAction> => (dispatch, _getState) => {
        // Waiting reponse
        dispatch({ type: Actions.REQUEST_CRITERIASEARCH, status: 0 });

        // Send request
        fetch('/v1/criteria-search', {
            method: options.method,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('DMS_APPLICATION')}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(options.body)
        })
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    return Promise.reject(response.json());
                }
            })
            .then(data => dispatch({ type: Actions.RECEIVE_CRITERIASEARCH, model: data, status: 200 }))
            .catch(_error => dispatch({ type: Actions.FAILURE_CRITERIASEARCH, status: 400 }));
    },
    requestUpdateBoxId: (options, onResponse) => (dispatch, _getState) => {
        dispatch({ type: Actions.REQUEST_CRITERIASEARCH, status: 0 });

        fetch('/v1/criteria-search/update-box-id', {
            method: options.method,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('DMS_APPLICATION')}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(options.body)
        })
            .then(response => {
                dispatch({ type: Actions.CLEAR_CRITERIASEARCH, status: 0 });

                if (response.status === 200) {
                    response.json().then(data => onResponse(data, null));
                } else {
                    response.json().then(error => onResponse(null, new Error(error.message)));
                }
            });
    },
    requestDocumentType: (options, sid, onResponse) => (dispatch, _getState) => {
        dispatch({ type: Actions.REQUEST_DOCUMENTTYPE, status: 0 });

        fetch(`/v1/criteria-search/doc-type?sid=${sid}`, {
            method: options.method,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('DMS_APPLICATION')}`,
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                dispatch({ type: Actions.CLEAR_DOCUMENTTYPE });

                if (response.status === 200) {
                    return response.json();
                } else {
                    return Promise.reject(response.json());
                }
            })
            .then(data => onResponse(data, null))
            .catch(error => onResponse(null, new Error(error.message)));
    },
    requestUser: (options, onResponse) => (dispatch, _getState) => {
        dispatch({ type: Actions.REQUEST_USER });

        fetch(`/v1/criteria-search/user?` + new URLSearchParams({ ...options.params }), {
            method: options.method,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('DMS_APPLICATION')}`,
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                dispatch({ type: Actions.CLEAR_USER });

                if (response.status === 200) {
                    return response.json();
                } else {
                    return Promise.reject(response.json());
                }
            })
            .then(data => onResponse(data, null))
            .catch(error => onResponse(null, new Error(error.message)));
    }
}

const initialState: SearchCriteriaStoreState = {
    isLoading: false,
    response: null,
    status: 0,
    isLoadingDocumentType: false,
    isLoadingUser: false
};

export const reducer: Reducer<SearchCriteriaStoreState> = (state: SearchCriteriaStoreState = initialState, action: Action): SearchCriteriaStoreState => {

    const knownAction = action as KnownAction;

    switch (knownAction.type) {
        case Actions.REQUEST_CRITERIASEARCH:
            return {
                ...state,
                isLoading: true,
                status: knownAction.status,
            };
        case Actions.RECEIVE_CRITERIASEARCH:
            return {
                ...state,
                isLoading: false,
                response: knownAction.model,
                status: knownAction.status
            };
        case Actions.CLEAR_CRITERIASEARCH:
            return {
                ...state,
                isLoading: false,
                status: knownAction.status
            };
        case Actions.FAILURE_CRITERIASEARCH:
            return {
                ...state,
                isLoading: false,
                status: knownAction.status
            };
        case Actions.REQUEST_DOCUMENTTYPE:
            return {
                ...state,
                isLoadingDocumentType: true,
                status: 0
            };
        case Actions.CLEAR_DOCUMENTTYPE:
            return {
                ...state,
                isLoadingDocumentType: false,
                status: 0
            };
        case Actions.REQUEST_USER:
            return {
                ...state,
                isLoadingUser: true
            };
        case Actions.CLEAR_USER:
            return {
                ...state,
                isLoadingUser: false
            }
        default: return state;
    }
}