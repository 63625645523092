import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import NavMenuOrigin from 'components/NavMenu';
import { AdminContext } from 'context/AdminProvider';
import { TopNavigation } from 'common/components';

const NavMenu = NavMenuOrigin as any;

function SubLayout(props: { children?: React.ReactNode }) {
    return (
        <React.Fragment>
            <TopNavigation />
            {/* <AdminContext.Consumer>{admin => <NavMenu admin={admin as any} />}</AdminContext.Consumer> */}
            <Container fluid={true}>
                <Row>
                    <Col
                        md="12"
                        style={{ marginLeft: 0 }}
                    >
                        <main
                            role="main"
                            style={{ minHeight: '90vh' }}
                        >
                            {props.children}
                        </main>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
}

export default SubLayout;
