import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ApplicationState } from 'store';
import { File, FileState } from 'models/File';
import * as Stores from 'store/FileManage';
import { MdDoNotDisturbOn, MdOutlineCancel } from 'react-icons/md';
import { Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import Swal from 'sweetalert2';
import { useLoader } from 'common/hooks';

const THEAD: readonly string[] = ['', 'Name', 'Modified', 'Modified By', 'Verify', 'Last Version'];

export type DeleteFileViewRef = {
    onClickDelete: () => void;
};

type Props = {
    onClose: () => void;
    reloading: (value: string) => void;
    selectedFiles: File[];
    removeSelectedFile: (atIndex: number) => void;
};

const DeleteFileView = forwardRef<DeleteFileViewRef, Props>((props, ref) => {
    const { onClose: closeModal, reloading, selectedFiles, removeSelectedFile } = props;

    const [version, setVersion] = useState<number>(0);

    const loader = useLoader();

    const {
        isLoading,
        version: fileStateVersion,
        response
    } = useSelector<ApplicationState, FileState>((state: ApplicationState) => ({
        isLoading: state.fileDelete?.isLoading || false,
        version: state.fileDelete?.version,
        response: state.fileDelete?.response
    }));

    const dispatch = useDispatch();

    useEffect(() => {
        if (!fileStateVersion) {
            return;
        }

        if (fileStateVersion > version) {
            if (fileStateVersion - version === 2) {
                loader.hide();
                closeModal();

                Swal.fire({
                    title: 'สำเร็จ!',
                    text: 'กดปุ่มยืนยัน',
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'ยืนยัน!'
                }).then(result => {
                    dispatch({ type: 'REQUEST_FILEDELETE_BUCKET', version: 0 });
                    reloading('reset');
                });
            }
            setVersion(fileStateVersion);
        }
    }, [fileStateVersion]);

    const handleClickRemoveSelectedFile = (atIndex: number) => {
        if (selectedFiles.length === 1) {
            closeModal();
        }

        removeSelectedFile(atIndex);
    };

    const replaceDate = (date: string) => {
        if (!date) {
            return;
        }

        return date.split('T', 1)[0];
    };

    const onClickDelete = () => {
        if (selectedFiles.length === 0) {
            closeModal();

            Swal.fire({
                title: 'Error',
                text: 'กรุณาระบุ File ที่ต้องการลบ',
                icon: 'error'
            });

            return;
        }

        loader.show();

        // todo: fix type late
        dispatch(Stores.actionCreators.requestFunction(selectedFiles, '', 0, 'DELETE') as any);
    };

    useImperativeHandle(ref, () => {
        return {
            onClickDelete
        };
    });

    return (
        <React.Fragment>
            <TableContainer>
                <Table className="flex-1 table-auto border-2">
                    <Thead className="bg-[#ECECEC]">
                        <Tr>
                            {THEAD.map(thName => (
                                <th
                                    className={`h-[30px] w-auto border border-[#DDDDDD] px-2 py-[5px] text-xs font-normal ${
                                        thName === 'Verify' ? 'text-center' : ''
                                    }`}
                                >
                                    {thName}
                                </th>
                            ))}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {selectedFiles &&
                            selectedFiles.length > 0 &&
                            selectedFiles.map((item: File, index: number) => (
                                <Tr
                                    key={item.id}
                                    className="h-[30px] text-xs  font-normal [&>*]:border [&>*]:border-[#DDDDDD] [&>*]:px-2.5 [&>*]:py-[5px]"
                                >
                                    <Td className="w-[34px]">
                                        <div className="flex justify-center ">
                                            <MdDoNotDisturbOn
                                                size={20}
                                                color="#B3261E"
                                                className="cursor-pointer"
                                                onClick={() => handleClickRemoveSelectedFile(index)}
                                            />
                                        </div>
                                    </Td>
                                    <Td
                                        className="w-auto"
                                        data-filepath={item.file_path}
                                    >
                                        {item.file_name}
                                    </Td>
                                    <Td className="w-[95px]">{replaceDate(item.created_on)}</Td>
                                    <Td className="w-[95px]">{item.created_by}</Td>
                                    <Td className="w-[48px]">
                                        <div className="flex justify-center">
                                            <MdOutlineCancel
                                                size={20}
                                                color="#B3261E"
                                            />
                                        </div>
                                    </Td>
                                    <Td className="w-[95px]">
                                        <Link
                                            to={'/dms/file-version/' + item.primary_index}
                                            target="_blank"
                                        >
                                            {item.version}
                                        </Link>
                                    </Td>
                                </Tr>
                            ))}
                    </Tbody>
                </Table>
            </TableContainer>
        </React.Fragment>
    );
});

export default DeleteFileView;
// export default connect(
//     (state: ApplicationState) => state.fileDelete,
//     Stores.actionCreators
// )(DeleteFileView);
