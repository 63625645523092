import React from 'react';
import * as XLSX from 'xlsx';
import Swal from 'sweetalert2';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import CreatableSelect from 'react-select/creatable';

import { DisplayCompany, ResponseCompanyMaster } from '../../../models/CompanyMaster';
import { DisplayEmployee, ResponseEmployee } from '../../../models/Employee';
import { FolderAuthUserLogging, FolderAuthUserLoggingResponse } from '../../../models/FolderAuthorizeUserMapping';


interface State {
    filterUser: DisplayEmployee[],
    selectedCompanyExport: string[],
    companyShortName: string[],
    selectedUsersExport: string[],
    responseFolderAuthUserLog: FolderAuthUserLogging[],
}

interface Props {
    openModalExportExcel: (isOpen: boolean) => void,
    isOpen: boolean,
    companyOptions: SelectorOptions[],
    responseCompanyMaster?: ResponseCompanyMaster,
    responseEmployee?: ResponseEmployee,
    displayEmployee?: DisplayEmployee[],
    loadingAction: () => void,
}

interface SelectorOptions {
    label: string,
    value: string
}

type ExportExcelType = {
    Company: string,
    ActionBy: string,
    LogAction: string,
    Folder: string,
    OldRoleAccess: string,
    NewRoleAccess: string,
    ActionOn: string,
};

class ExportExcelLogModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            filterUser: [],
            selectedCompanyExport: [],
            companyShortName: [],
            selectedUsersExport: [],
            responseFolderAuthUserLog: [],
        }

        this.onToggle = this.onToggle.bind(this);
        this.exportToExcel = this.exportToExcel.bind(this);
    }

    public handleSelectCompany(companies: DisplayCompany[]): void {
        this.props.loadingAction();

        const companyList = companies.map(company => company.label);
        const companyShortNameList = companies.map(company => company.shortName);

        this.setState({
            selectedCompanyExport: companyList,
            companyShortName: companyShortNameList,
        });

        const sidList = this.findSidFromCompany(companyList);
        if (sidList !== undefined) {
            this.filterUserBySid(sidList);
        }

        setTimeout(() => this.props.loadingAction(), 0)
    }

    public async handleSelectUser(employee: DisplayEmployee[]) {
        this.props.loadingAction();

        //console.log("[Debug] selected user", employee)

        if (employee.length === 0) {
            this.setState({ responseFolderAuthUserLog: [] });
        }

        const { filterUser, selectedCompanyExport } = this.state;
        const displayEmployee = this.props.displayEmployee;

        const selectAll = employee.find(f => f.value === '*');

        let userList: string[] = [];

        if (selectAll && selectedCompanyExport.length > 0 && filterUser.length > 0) {
            // Select all users in selected company.
            userList = filterUser
                .map(user => user.value)
                .filter(val => val !== "*");

        } else if (selectAll && displayEmployee && displayEmployee.length > 0) {
            // Select all users in all companies.
            userList = displayEmployee
                .map(user => user.value)
                .filter(val => val !== "*");

        } else {
            // Select any users.
            userList = employee.map(user => user.value);
        }

        this.setState({ selectedUsersExport: userList }); // Deprecated

        const { companyShortName, selectedUsersExport, responseFolderAuthUserLog } = this.state;

        if (userList.length > 0 &&
            responseFolderAuthUserLog !== null &&
            responseFolderAuthUserLog.length === 0) {

            await this.getFolderAuthUserLogForExport('', userList, companyShortName);
        }

        setTimeout(() => this.props.loadingAction(), 0)
    }

    public onToggle() {
        this.props.openModalExportExcel(!this.props.isOpen);
    }

    public formatAuthIds(authIds: string): string {
        if (authIds) {
            const splitAuthIds = authIds.split('|');
            const roleAccessArr: string[] = [];

            splitAuthIds.map((authId, index) => {
                switch (authId) {
                    case '01': {
                        roleAccessArr.push("Create");
                        break;
                    }
                    case '02': {
                        roleAccessArr.push("Edit");
                        break;
                    }
                    case '03': {
                        roleAccessArr.push("Display");
                        break;
                    }
                    case '04': {
                        roleAccessArr.push("Delete");
                        break;
                    }
                    case '05': {
                        roleAccessArr.push("Copy Link");
                        break;
                    }
                    case '06': {
                        roleAccessArr.push("Move Link");
                        break;
                    }
                    default: {
                        roleAccessArr.push('');
                        break;
                    }
                }
            });

            return roleAccessArr.join('|');
        }

        return '';
    }

    public formatRFC3339ToDMY(dmy: string) {
        if (dmy !== undefined && dmy !== "" && dmy !== 'null') {
            var year = dmy.substring(0, 4);
            var day = dmy.substring(8, 10);
            var month = dmy.substring(5, 7);
            var Datevalue = "";
            var datevalue = "";
            var UTC = new Date()
            if (dmy.length < 9) {
                datevalue = day + '-' + month + '-' + year

            } else {
                var times = dmy.substring(11, 19);
                datevalue = day + '-' + month + '-' + year + ' ' + times;
            }
            return datevalue;
        } else {
            Datevalue = "";

            return Datevalue;
        }
    }

    public exportToExcel = () => {
        try {
            this.props.loadingAction();

            const { selectedUsersExport, responseFolderAuthUserLog } = this.state;

            //console.log("[Debug] responseFolderAuthUserLog", responseFolderAuthUserLog)

            if (responseFolderAuthUserLog === null || responseFolderAuthUserLog.length === 0) {
                throw void (0);
            }

            const prepareSheetData =
                responseFolderAuthUserLog
                    .reduce<ExportExcelType[]>((accumulate, userFolderLog, index) => {

                        const company = this.props
                            .responseCompanyMaster?.result_list
                            .find(company => {
                                if (userFolderLog.new_role_id !== null && userFolderLog.new_role_id !== '') {
                                    return company.company_short_name === userFolderLog.new_role_id.split('-')[2];

                                } else {
                                    return company.company_short_name === userFolderLog.old_role_id.split('-')[2];
                                }
                            });

                        accumulate.push({
                            Company: company?.company_name || '',
                            ActionBy: userFolderLog.username,
                            LogAction: userFolderLog.action_log,
                            Folder: userFolderLog.new_full_path || userFolderLog.old_full_path,
                            OldRoleAccess: this.formatAuthIds(userFolderLog.old_role_id.split("_ID")[1]),
                            NewRoleAccess: this.formatAuthIds(userFolderLog.new_role_id.split("_ID")[1]),
                            ActionOn: this.formatRFC3339ToDMY(userFolderLog.created_on),

                        });

                        return accumulate;
                    }, [])
                    .sort((a, b) => (
                        (a.ActionBy < b.ActionBy)
                            ? -1
                            : (a.ActionBy > b.ActionBy)
                                ? 1
                                : 0
                    ))
                    .sort((a, b) => (
                        (a.Company < b.Company)
                            ? -1
                            : (a.Company > b.Company)
                                ? 1
                                : 0
                    ));

            if (selectedUsersExport.length > 0) {
                const date = new Date();
                const fileName = "Log_FolderUserAuthorization_" + date.getTime().toString() + ".xlsx";
                const worksheet = XLSX.utils.json_to_sheet(prepareSheetData);
                const workbook = XLSX.utils.book_new();

                XLSX.utils.book_append_sheet(workbook, worksheet, "LogFolderUserAuthorization");
                XLSX.writeFile(workbook, fileName);
            }

            setTimeout(() => this.props.loadingAction(), 0);
        } catch {
            setTimeout(() => this.props.loadingAction(), 0);

            Swal.fire({
                title: 'เกิดข้อผิดพลาด',
                text: 'ไม่มีข้อมูลผู้ใช้นี้',
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน',
            });
        }
    }

    private filterUserBySid(companySidList: string[]) {
        const empResultList = this.props.responseEmployee?.result_list;

        if (empResultList) {
            const userInCompany = empResultList.filter(emp => {
                const foundCompany = emp.list_company.find(company => companySidList.includes(company.sid));

                return (foundCompany) ? true : false;
            });

            const foundUserInCompany: DisplayEmployee[] = [];
            foundUserInCompany.push({ label: 'select all user', value: '*' });
            userInCompany.map(user => foundUserInCompany.push({ label: user.id, value: user.id }));

            this.setState({
                filterUser: foundUserInCompany,
            });
        }
    }

    private findSidFromCompany(companyNameArr: string[]): string[] | undefined {
        const companyResultList = this.props.responseCompanyMaster?.result_list;

        if (companyResultList === undefined) return;

        const sidArr = companyNameArr.map((companyName, index) => {
            const companyList = companyResultList.filter(result => result.company_name === companyName);

            return companyList.map(company => company.sid)[0];
        });

        return sidArr;
    }

    private async getFolderAuthUserLogForExport(sid: string, usernameList: string[], companyList: string[]) {
        const req = {
            users: usernameList,
            companies: companyList
        }

        const response = await fetch(`/v1/folder-auth-user/get-log?app_id=${sid}`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('DMS_APPLICATION'),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(req),
        });

        try {
            const { message, result_list } = await response.json() as FolderAuthUserLoggingResponse;

            if (!response.ok) {
                throw new Error(message);
            }
            //console.log("[Debug] getFolderAuthUserLogForExport", result_list)

            this.setState({
                responseFolderAuthUserLog: result_list,
            });

        } catch (error) {
            Swal.fire(
                'Error',
                `${(error as any).message}`,
                'error'
            )
        }
    }

    render() {
        return (
            <Modal isOpen={this.props.isOpen} toggle={this.onToggle} className="folder-user-auth-modal-content">
                <ModalHeader toggle={this.onToggle}>Export Excel</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col xs={12}>
                            <Card.Body className="mat-box shadow">
                                <Row>
                                    <Col md={6}>
                                        <Row>
                                            <Col sm={6} className="mb-3">
                                                <div>
                                                    <h6>Company</h6>
                                                </div>
                                            </Col>
                                            <Col sm={12}>
                                                <Form.Group className="mb-3" controlId="companyExport">
                                                    <CreatableSelect
                                                        menuPortalTarget={document.body}
                                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                        onChange={(company) => this.handleSelectCompany([company])}
                                                        options={this.props.companyOptions}
                                                        isValidNewOption={() => false}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={6}>
                                        <Row>
                                            <Col sm={6} className="mb-3">
                                                <div>
                                                    <h6>User</h6>
                                                </div>
                                            </Col>
                                            <Col sm={12}>
                                                <Form.Group className="mb-3" controlId="employeeExport">
                                                    <CreatableSelect
                                                        menuPortalTarget={document.body}
                                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                        isMulti
                                                        isClearable
                                                        onChange={(employee) => this.handleSelectUser(employee)}
                                                        options={
                                                            (this.state.filterUser.length > 0)
                                                                ? this.state.filterUser
                                                                : [{ label: 'select all user', value: '*' }]
                                                        }
                                                        isValidNewOption={() => false}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button
                        disabled={this.state.selectedUsersExport.length === 0}
                        className="btn-export-excel"
                        onClick={this.exportToExcel}
                    >
                        Export
                    </Button>{' '}
                    <Button color="secondary" onClick={this.onToggle}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default ExportExcelLogModal;