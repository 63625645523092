import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import * as ActionLog from '../models/ActionLog';

interface RequestFunctionAction {
    type: 'REQUEST_ACTIONLOG';
    version: number;
}

interface ReceiveFunctionAction {
    type: 'RECEIVE_ACTIONLOG';
    model: ActionLog.ResponseActionLog;
    version: number;
}

interface ClearLoadedActionLog {
    type: 'CLEARLOADEDACTIONLOG'
}

type KnownAction = RequestFunctionAction | ReceiveFunctionAction | ClearLoadedActionLog

export const actionCreators = {
    requestFunctionActionLog: (version: number, method: string, body: ActionLog.ActionLog): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState && appState.file && method === "GET") {

            fetch(`/v1/action-log`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('DMS_APPLICATION'),
                    'REQUEST_IP': window.location.hostname
                },
            })
                .then(response => response.json() as Promise<ActionLog.ResponseActionLog>)
                .then(data => {
                    dispatch({ type: 'RECEIVE_ACTIONLOG', model: data, version: version + 2 });
                })
                .then(error => {
                    dispatch({ type: 'REQUEST_ACTIONLOG', version: version + 1 });
                });
        }
        else if (appState && appState.file && method === 'CLEAR') {
            dispatch({ type: 'CLEARLOADEDACTIONLOG' });
        }
    }
};

const unloadedState: ActionLog.ActionLogState = { isLoadingActionLog: false, responseActionLog: undefined, versionActionLog: 0 };

export const reducer: Reducer<ActionLog.ActionLogState> = (state: ActionLog.ActionLogState | undefined, incomingAction: Action): ActionLog.ActionLogState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_ACTIONLOG':
            return {
                isLoadingActionLog: false,
                versionActionLog: action.version
            };
        case 'RECEIVE_ACTIONLOG':
            return {
                isLoadingActionLog: true,
                responseActionLog: action.model,
                versionActionLog: action.version
            };
        case 'CLEARLOADEDACTIONLOG':
            return {
                isLoadingActionLog: false,
                responseActionLog: undefined,
                versionActionLog: 0
            };

        default: return state;
    }
};