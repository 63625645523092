import './common.css';

import React from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import {
    MdOutlineChevronLeft,
    MdOutlineChevronRight,
    MdOutlineFirstPage,
    MdOutlineLastPage
} from 'react-icons/md';

type OnClickPageType = (event: React.MouseEvent<HTMLButtonElement>, page: number) => void;

interface ComponentProps {
    page?: number;
    pageSize?: number;
    disabledNext?: boolean;
    onChangePage?: OnClickPageType;
}
type Props = ComponentProps;

function InfinitePagination(props: Props) {
    const emptyFunction: OnClickPageType = () => undefined;

    const { page = 1, pageSize = 25, disabledNext = false, onChangePage = emptyFunction } = props;

    const listItem = [
        {
            disable: page <= 1,
            onClick: () => {},
            Icon: MdOutlineFirstPage
        },
        {
            disable: page <= 1,
            onClick: event => onChangePage(event, page - 1),
            Icon: MdOutlineChevronLeft
        },
        {
            onClick: () => {},
            page: page
        },
        {
            onClick: event => onChangePage(event, page + 1),
            Icon: MdOutlineChevronRight
        },
        {
            onClick: () => {},
            Icon: MdOutlineLastPage
        }
    ];

    return (
        // ? before redesign
        // <ul className="infinite-pagination-container">
        //     <li>
        //         <button
        //             disabled={page <= 1}
        //             onClick={event => onChangePage(event, page - 1)}
        //             className="infinite-pagination-button"
        //         >
        //             <FaChevronLeft />
        //         </button>
        //     </li>
        //     <li>...</li>
        //     <li>
        //         <button className="infinite-pagination-button active">{page}</button>
        //     </li>
        //     <li>...</li>
        //     <li>
        //         <button
        //             disabled={disabledNext}
        //             onClick={event => onChangePage(event, page + 1)}
        //             className="infinite-pagination-button"
        //         >
        //             <FaChevronRight />
        //         </button>
        //     </li>
        // </ul>
        <ul className="flex flex-row gap-1">
            {listItem.map(({ disable, onClick, Icon, page }) => (
                <li className="flex items-center justify-center">
                    <button
                        disabled={disable}
                        onClick={onClick}
                    >
                        {Icon && (
                            <Icon
                                size={20}
                                color="#CCCCCC"
                                className="hover:bg-[#f2f2f2]"
                            />
                        )}

                        {page && (
                            <span className="text-sm font-light text-[#333333]">
                                Page {page} of ..
                            </span>
                        )}
                    </button>
                </li>
            ))}
        </ul>
    );
}

export default InfinitePagination;
