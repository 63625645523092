// TODO: lines 111 (Import excel from user machine)

import React, { RefObject } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button } from 'react-bootstrap';
import { FaFileDownload, FaFileExport } from 'react-icons/fa';
import * as XLSX from 'xlsx';
import { SearchCriteria } from '../../../models/SearchCriteria';
import { ApplicationState } from '../../../store';
import { SearchCriteriaStoreState } from '../../../models/SearchCriteria';
import SearchCriteriaImportExcelModal from './SearchCriteriaImportExcelModal';
import { formatNumberOfDateToDDMMYYYY } from '../../../modules/common';
import { Link } from 'react-router-dom';

interface SearchCriteriaTableProps {
    disableImportExcelButton: boolean;
}

type Props =
    & SearchCriteriaTableProps
    & SearchCriteriaStoreState;

interface State {
    isOpenModalImportExcel: boolean;
    importExcelData: SearchCriteria[];
    isLoading: boolean;
}

class SearchCriteriaTable extends React.Component<Props, State> {
    public inputFileRef: RefObject<HTMLInputElement>;

    constructor(props: Props) {
        super(props);

        this.state = {
            isOpenModalImportExcel: false,
            importExcelData: [],
            isLoading: false
        };

        this.inputFileRef = React.createRef<HTMLInputElement>();

        this.toggleIsLoading = this.toggleIsLoading.bind(this);
    }

    public componentDidUpdate() {
        const { isLoading, isLoadingDocumentType } = this.props;
        const isLoadingLocal = this.state.isLoading;

        if (isLoading || isLoadingLocal || isLoadingDocumentType) {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
        } else {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
        }
    }

    public toggleIsLoading(): void {
        this.setState({ isLoading: !this.state.isLoading });
    }

    public exportToExcel = () => {
        this.toggleIsLoading();

        const response = this.props.response;

        if (response !== null && response.result_list.length > 0) {
            const data = response.result_list;
            const date = new Date();
            const fileName = "Criteria_" + date.getTime().toString() + ".xlsx";
            const worksheet = XLSX.utils.json_to_sheet(data);
            const workbook = XLSX.utils.book_new();

            XLSX.utils.book_append_sheet(workbook, worksheet, "Criteria");
            XLSX.writeFile(workbook, fileName);
        }

        setTimeout(() => this.toggleIsLoading(), 0)
    }

    public handleToggleModalImportExcel = (): void => {
        this.setState({ isOpenModalImportExcel: !this.state.isOpenModalImportExcel });
    }

    public handleClickRefInputFile = (): void => {
        const inputFileRef = this.inputFileRef.current;

        if (inputFileRef !== null) {
            inputFileRef.click();
        }
    }

    public render() {
        return (
            <div>
                <Row className="my-3">
                    <Col className="d-flex justify-content-end">
                        <Button
                            className="mx-1"
                            variant="success"
                            size="sm"
                            onClick={this.handleToggleModalImportExcel}
                            disabled={this.props.disableImportExcelButton}
                        >
                            <FaFileDownload />&nbsp;Import Excel
                        </Button>
                        <Button
                            className="mx-1"
                            variant="success"
                            size="sm"
                            onClick={this.exportToExcel}
                            disabled={this.props.response === null}
                        >
                            <FaFileExport />&nbsp;Export Excel
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col style={{ overflowX: 'scroll' }}>
                        <table className="table custom-table table-hover">
                            <thead>
                                <tr style={{ width: '100%' }} className="text-center table-active thead-light font-weight-bold require-field">
                                    <th>Box ID</th>
                                    <th>Company Code</th>
                                    <th>Document No.</th>
                                    <th>Doc. Type</th>
                                    <th>Doc. Year</th>
                                    <th>Posting Date</th>
                                    <th>Invoice Date</th>
                                    <th>Reference Doc.</th>
                                    <th>IRM No.</th>
                                    <th>IRM. Year</th>
                                    <th>VAT Code</th>
                                    <th>User</th>
                                    <th>File Name</th>
                                    <th>Image Document Type</th>
                                    <th>ID</th>
                                    <th>Logs</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.props.response !== null
                                    && this.props.response.total_count > 0
                                    && this.props.response.result_list.map((item, index) => {
                                        const key = (item.id !== '')
                                            ? item.id
                                            : index + 1

                                        return (
                                            <tr key={key} className="text-center">
                                                <td style={{ padding: '10px' }}>{item.boxId}</td>
                                                <td style={{ padding: '10px' }}>{item.companyCode}</td>
                                                <td style={{ padding: '10px' }}>{item.documentNo}</td>
                                                <td style={{ padding: '10px' }}>{item.documentType}</td>
                                                <td style={{ padding: '10px' }}>{item.year}</td>
                                                <td style={{ padding: '10px' }}>{formatNumberOfDateToDDMMYYYY(item.postingDate)}</td>
                                                <td style={{ padding: '10px' }}>{formatNumberOfDateToDDMMYYYY(item.invoiceDate)}</td>
                                                <td style={{ padding: '10px' }}>{item.referenceDoc}</td>
                                                <td style={{ padding: '10px' }}>{item.irmNo}</td>
                                                <td style={{ padding: '10px' }}>{item.irmYear}</td>
                                                <td style={{ padding: '10px' }}>{item.vatCode}</td>
                                                <td style={{ padding: '10px' }}>{item.user}</td>
                                                <td style={{ padding: '10px' }}>{item.fileName}</td>
                                                <td style={{ padding: '10px' }}>{item.imageDocumentType}</td>
                                                <td style={{ padding: '10px' }}>{item.id}</td>
                                                <td className="td-version td-center" ><Link to={`/dms/file-explorer/log?p_id=${item.primaryIndex}`}>Logs</Link></td>
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </table>
                    </Col>
                </Row>

                <SearchCriteriaImportExcelModal
                    isOpen={this.state.isOpenModalImportExcel}
                    toggle={this.handleToggleModalImportExcel}
                    toggleIsLoading={this.toggleIsLoading}
                />
            </div>
        );
    }
}

const enhance = connect((state: ApplicationState) => ({ ...state.searchCriteria }), null);
export default enhance(SearchCriteriaTable as any);