import React, { useState, useEffect } from 'react';
import { Button } from '@chakra-ui/react';
import { FiChevronLeft, FiChevronRight, FiChevronsLeft, FiChevronsRight } from 'react-icons/fi';

type Props = {
    onClose?: () => void;
    employeeList: string[];
    sharedUsers: string[];
    onSelectUser: (selectedUsers: string[]) => void;
};

function ShareUserSelector(props: Props) {
    const { employeeList, sharedUsers, onSelectUser, onClose } = props;

    const [isInitialAvailableUsers, setIsInitialAvailableUsers] = useState<boolean>(false);
    const [availableUsers, setAvailableUsers] = useState<string[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
    const [backupAvailableUsers, setBackupAvailableUsers] = useState<string[]>([]);
    const [backupSelectedUsers, setBackupSelectedUsers] = useState<string[]>([]);
    const [checkAvailableUsers, setCheckAvailableUsers] = useState<boolean[]>([]);
    const [checkSelectedUser, setCheckSelectedUser] = useState<boolean[]>([]);

    const initialAvailableUsers = () => {
        setAvailableUsers(employeeList);
        setIsInitialAvailableUsers(true);
    };

    useEffect(() => {
        if (employeeList.length > 0 && availableUsers.length === 0 && !isInitialAvailableUsers) {
            initialAvailableUsers();
        }
    }, [employeeList, availableUsers, isInitialAvailableUsers]);

    const setStatusRowAvailableUser = (index: number) => {
        const newStatusAvailableUser = [...checkAvailableUsers];
        newStatusAvailableUser[index] = !newStatusAvailableUser[index];

        setCheckAvailableUsers(newStatusAvailableUser);
    };

    const setStatusRowSelected = (index: number) => {
        const newStatusSelected = [...checkSelectedUser];
        newStatusSelected[index] = !newStatusSelected[index];

        setCheckSelectedUser(newStatusSelected);
    };

    const setStatusRowIsFalseAll = () => {
        setCheckAvailableUsers(availableUsers.map(employee => false));
        setCheckSelectedUser(selectedUsers.map(employee => false));
    };

    const handleBackupAvailableUser = (index: number, rowCurrent: string) => {
        if (!backupAvailableUsers.includes(rowCurrent)) {
            setBackupAvailableUsers([...backupAvailableUsers, rowCurrent]);
            setStatusRowAvailableUser(index);
        } else {
            setBackupAvailableUsers(backupAvailableUsers.filter(rowSame => rowSame !== rowCurrent));
            setStatusRowAvailableUser(index);
        }
    };

    const handleBackupSelectedUser = (index: number, rowCurrent: string) => {
        if (!backupSelectedUsers.includes(rowCurrent)) {
            setBackupSelectedUsers([...backupSelectedUsers, rowCurrent]);
            setStatusRowSelected(index);
        } else {
            setBackupSelectedUsers(backupSelectedUsers.filter(rowSame => rowSame !== rowCurrent));
            setStatusRowSelected(index);
        }
    };

    const handleMoveToSelectedUser = () => {
        if (backupAvailableUsers.length) {
            setSelectedUsers([...selectedUsers, ...backupAvailableUsers]);
            setAvailableUsers(
                availableUsers.filter(
                    availableUser => !backupAvailableUsers.includes(availableUser)
                )
            );
            setBackupAvailableUsers([]);
            setStatusRowIsFalseAll();
        }
    };

    const handleMoveToAvailableUser = () => {
        if (backupSelectedUsers.length) {
            setAvailableUsers([...availableUsers, ...backupSelectedUsers]);
            setSelectedUsers(
                selectedUsers.filter(selectedUser => !backupSelectedUsers.includes(selectedUser))
            );
            setBackupSelectedUsers([]);
            setStatusRowIsFalseAll();
        }
    };

    const handleMoveToSelectedAll = () => {
        setSelectedUsers([...selectedUsers, ...availableUsers]);
        setAvailableUsers([]);
        setBackupSelectedUsers([]);
        setStatusRowIsFalseAll();
    };

    const handleMoveToAvailableAll = () => {
        setAvailableUsers([...availableUsers, ...selectedUsers]);
        setSelectedUsers([]);
        setBackupAvailableUsers([]);
        setStatusRowIsFalseAll();
    };

    const commitSelectedUser = () => {
        onSelectUser(selectedUsers);
    };

    const selectorAction = [
        {
            onClick: handleMoveToSelectedUser,
            Icon: FiChevronRight
        },
        {
            onClick: handleMoveToAvailableUser,
            Icon: FiChevronLeft
        },
        {
            onClick: handleMoveToSelectedAll,
            Icon: FiChevronsRight
        },
        {
            onClick: handleMoveToAvailableAll,
            Icon: FiChevronsLeft
        }
    ];

    return (
        <div className="flex flex-col gap-2 ">
            <div className="flex">
                {/* User Option */}
                <div className="flex min-h-[166px] flex-1 flex-col bg-[#F7F7F7] p-2.5">
                    <div className="inline-flex  gap-2.5 px-2.5 pb-2.5">
                        <div className="text-sm font-medium text-black">Available</div>
                    </div>
                    <ul className="[&>li:not(:last-child)]:border-b">
                        {availableUsers
                            .filter(rowOption => !sharedUsers.includes(rowOption))
                            .map((rowOption, index) => (
                                <li
                                    key={index}
                                    className="d-flex align-items-center justify-content-between cursor-pointer bg-white px-2 py-2"
                                    onClick={() => handleBackupAvailableUser(index, rowOption)}
                                >
                                    <span className="text-sm font-normal">{rowOption}</span>
                                    <input
                                        type="checkbox"
                                        className="h-4 w-4 cursor-pointer accent-[#3397C3]"
                                        checked={checkAvailableUsers[index]}
                                    />
                                </li>
                            ))}
                    </ul>
                </div>

                {/* Add and Remove button */}
                <div className="flex flex-col items-center justify-center gap-2.5 p-2.5">
                    {selectorAction.map(({ onClick, Icon }) => (
                        <Button
                            className="flex h-6 w-6 items-center justify-center rounded-[4px] border border-[#7A7A7A] bg-white p-0.5 hover:border-[#3397C3]"
                            onClick={onClick}
                        >
                            <Icon
                                size={20}
                                color="#7A7A7A"
                                className="hover:text-[#3397C3]"
                            />
                        </Button>
                    ))}
                </div>

                {/* Selected User */}
                <div className="flex min-h-[166px] flex-1 flex-col bg-[#F7F7F7] p-2.5">
                    <div className="inline-flex gap-2.5 px-2.5 pb-2.5">
                        <div className="text-sm font-medium text-black">Selected User</div>
                    </div>
                    <ul className="[&>li:not(:last-child)]:border-b">
                        {selectedUsers.map((rowSelected, index) => (
                            <li
                                key={index}
                                className="d-flex align-items-center justify-content-between cursor-pointer bg-white px-2 py-2"
                                onClick={() => handleBackupSelectedUser(index, rowSelected)}
                            >
                                <span className="text-sm font-normal">{rowSelected}</span>
                                <input
                                    type="checkbox"
                                    className="h-4 w-4 cursor-pointer accent-[#3397C3]"
                                    checked={checkSelectedUser[index]}
                                />
                            </li>
                        ))}
                    </ul>
                </div>
            </div>

            {/* Apply Button */}
            <div className="flex flex-row justify-end gap-2 p-1">
                <Button
                    variant="outline"
                    className="px-4 text-[#999999]"
                    onClick={onClose}
                >
                    <span className="px-[15px]">Cancel</span>
                </Button>
                <Button
                    colorScheme="primary"
                    className="px-4"
                    onClick={commitSelectedUser}
                >
                    <span className="px-[15px]">Apply</span>
                </Button>
            </div>
        </div>
    );
}

export default ShareUserSelector;
