import React, { useState, PropsWithChildren } from 'react';

import '../../components/styles/ResizeableDiv.css';

interface Props {
    className: string;
}

// const MIN_OFFSET = 375; // min-width
// const MAX_OFFSET = 680; // max-width

const ResizableDiv = (props: PropsWithChildren<Props>) => {
    const [initialPos, setInitialPos] = useState(0);
    const [initialSize, setInitialSize] = useState(0);

    const initial = (e: React.DragEvent<HTMLDivElement>) => {
        let resizable = document.getElementById('Resizable');

        if (!resizable) return;

        // hide shadow of draggable div while dragging
        e.dataTransfer.setDragImage(resizable, window.outerWidth, window.outerHeight);

        setInitialPos(e.clientX);
        setInitialSize(resizable.offsetWidth);
    }

    const resize = (e: React.DragEvent<HTMLDivElement>) => {
        let resizable = document.getElementById('Resizable');

        if (!resizable) return;

        // resizable.style.cursor = 'col-resize';
        resizable.style.width = `${initialSize + (e.clientX - initialPos)}px`;
    }

    return(
        <React.Fragment>
            <div
                id='Resizable'
                className={props.className}
            >
                {props.children}
            </div>
            <div id='Draggable'
                draggable={true}
                onDragStart={(event) => initial(event)} 
                onDrag={(event) => resize(event)}
            />
        </React.Fragment>
    );
}

export default ResizableDiv;
