import React from 'react';

function FileLogTableViewModel() {
    const handleAction = (action: string) => {
        if (!action) {
            return '';
        }

        switch (action) {
            case 'view':
                return 'ดูไฟล์';
            case 'create':
                return 'อัพโหลดไฟล์';
            case 'edit':
                return 'แก้ไขข้อมูล';
            case 'copy':
                return 'คัดลอกไฟล์';
            case 'delete':
                return 'ลบไฟล์';
            default:
                return action;
        }
    };

    const handleMessage = (key: string, message: string) => {
        if (!message) {
            return '';
        }

        var reMessage = '';
        let obj = JSON.parse(message);
        if (key === 'copy') {
            reMessage += ' ไฟล์ ' + obj.file_name;

            if (obj.full_path) {
                reMessage += ' เข้า Folder ' + obj.full_path;
            } else if (obj.bucket) {
                reMessage += ' เข้า Bucket ' + obj.bucket;
            }

            if (obj.group_id) {
                reMessage += ' ด้วย Group Id ' + obj.group_id;
            }

            if (obj.sub_group_id) {
                reMessage += ' ด้วย Sub Group Id ' + obj.sub_group_id;
            }

            //if (obj.index_string) {
            //    reMessage += ' ด้วย Searck Key ' + this.replaceAll(obj.index_string, '|', ', ');
            //}

            //if (obj.metadata) {
            //    reMessage += ' ด้วย Metadata ' + this.replaceAll(obj.metadata, '|', ', ');
            //}
        } else if (key === 'create') {
            reMessage += ' ไฟล์ ' + obj.file_name;

            if (obj.full_path) {
                reMessage += ' เข้า Folder ' + obj.full_path;
            } else if (obj.bucket) {
                reMessage += ' เข้า Bucket ' + obj.bucket;
            }

            if (obj.group_id) {
                reMessage += ' ด้วย Group Id ' + obj.group_id;
            }

            if (obj.sub_group_id) {
                reMessage += ' ด้วย Sub Group Id ' + obj.sub_group_id;
            }

            //if (obj.index_string) {
            //    reMessage += ' ด้วย Searck Key ' + this.replaceAll(obj.index_string, '|', ', ');
            //}

            //if (obj.metadata) {
            //    reMessage += ' ด้วย Metadata ' + this.replaceAll(obj.metadata, '|', ', ');
            //}
        } else if (key === 'edit') {
            if (obj.full_path) {
                reMessage += ' เปลี่ยน Folder เป็น ' + obj.full_path;
            } else if (obj.bucket) {
                reMessage += ' เปลี่ยน Bucket เป็น ' + obj.bucket;
            }

            if (obj.box_id) {
                reMessage += ' เปลียน Box Id เป็น ' + obj.box_id;
            }

            if (obj.group_id) {
                reMessage += ' เปลียน Group Id เป็น ' + obj.group_id;
            }

            if (obj.sub_group_id) {
                reMessage += ' เปลียน Sub Group Id เป็น ' + obj.sub_group_id;
            }

            if (obj.index_string) {
                reMessage += ' เปลียน Searck Key';
            }

            if (obj.metadata) {
                reMessage += ' เปลียน Metadata';
            }
        }

        return reMessage;
    };

    const replaceAll = (target, search, replace) => {
        return target.split(search).join(replace);
    };

    return {
        handleAction,
        handleMessage,
        replaceAll
    };
}

export default FileLogTableViewModel;
