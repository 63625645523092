import React, { PropsWithChildren } from 'react';
import { Col, FormGroup, Label, Input, InputProps } from 'reactstrap';

interface Props extends Omit<InputProps, 'onChange'> {
    showLabel?: boolean;
    showPlaceholder?: boolean;
    span?: number;
    valuePair: { key: string, value: string };

    onChange?(key: string, value: string): void;
}

interface State { }

class KeyValuePairInput extends React.Component<PropsWithChildren<Props>, State> {
    constructor(props: Props) {
        super(props);

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    public handleInputChange(type: 'key' | 'value') {
        const { onChange: onChangeCb } = this.props;

        return (event: React.ChangeEvent<HTMLInputElement>) => {
            event.persist();
            const value = event.target.value;

            const valuePairParams = { ...this.props.valuePair };
            valuePairParams[type] = value;

            if (onChangeCb !== undefined) {
                onChangeCb(valuePairParams.key, valuePairParams.value);
            }
        }
    }

    public render(): React.ReactNode {
        const {
            span,
            showLabel,
            showPlaceholder,
            bsSize,
            valuePair
        } = this.props;

        return (
            <React.Fragment>
                <Col xs={span || 6}>
                    <FormGroup className="m-0">
                        {
                            showLabel
                            && (
                                <Label for="key">
                                    Key
                                </Label>
                            )
                        }
                        <Input
                            id="key"
                            name="key"
                            placeholder={(showPlaceholder) ? 'Key' : ''}
                            type="text"
                            bsSize={bsSize}
                            value={valuePair.key}
                            onChange={this.handleInputChange('key')}
                        />
                    </FormGroup>
                </Col>
                <Col xs={span || 6}>
                    <FormGroup className="m-0">
                        {
                            showLabel
                            && (
                                <Label for="value">
                                    Value
                                </Label>
                            )
                        }
                        <Input
                            id="value"
                            name="value"
                            placeholder={(showPlaceholder) ? 'Value' : ''}
                            type="text"
                            bsSize={bsSize}
                            value={valuePair.value}
                            onChange={this.handleInputChange('value')}
                        />
                    </FormGroup>
                </Col>
            </React.Fragment>
        );
    }
}

export default KeyValuePairInput;