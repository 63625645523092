import * as React from 'react';
import { connect } from 'react-redux';
import Select, { components } from 'react-select'
import { ApplicationState } from '../../store';
import * as Stores from '../../store/MetaData';
import * as StoreGroup from '../../store/MetaDataGroup';
import * as Models from '../../models/MetaData';
import * as ModelsGroup from '../../models/MetaDataGroup';
import IconButton from '@material-ui/core/IconButton';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import { FaPlusCircle, FaEdit, FaTimesCircle } from 'react-icons/fa';
import Swal from 'sweetalert2';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';

type Store = Models.MetaDataState & typeof Stores.actionCreators;
type StoresGroup = ModelsGroup.MetaDataGroupState & typeof StoreGroup.actionCreators;

class MetaDataGroupComponent extends React.PureComponent<any, { checkValueDel: any, isOpenModalDetail: boolean, pageLoad: boolean, isOpenModal: boolean, modelMetaDataGroup: ModelsGroup.MetaDataGroup, manageMode: string, version: number, modelListGet: Array<ModelsGroup.MetaDataGroup>, modelListGetDetail: Array<ModelsGroup.MetaDataGroup>, modelListGetDetailUse: Array<ModelsGroup.MetaDataGroup>, multiValue: any, valueSelect: any }, Store & StoresGroup> {

    constructor(props: any) {
        super(props);

        this.state = {
            pageLoad: true,
            isOpenModal: false,
            isOpenModalDetail: false,
            modelMetaDataGroup: {
                sid: "DEV",
                metadata_group_code: "metadata_group_code",
                metadata_group_name: "metadata_group_name",
                metadata_code: "metadata_code",
                metadata_name: "metadata_name",
                created_by: "created_by",
                created_on: "created_on",
                updated_by: "updated_by",
                updated_on: "updated_on",
            },
            manageMode: "",
            version: 0,
            modelListGet: [],
            modelListGetDetail: [],
            modelListGetDetailUse: [],
            multiValue: [],
            valueSelect: [],
            checkValueDel: []
        }

        this.toggleModal = this.toggleModal.bind(this);
        this.replaceDate = this.replaceDate.bind(this);
        this.handleChangeMataDataCode = this.handleChangeMataDataCode.bind(this);
        this.handleChangeMataDataName = this.handleChangeMataDataName.bind(this);
        this.actionsClick = this.actionsClick.bind(this)
    }

    public componentDidMount() {
        this.ensureDataFetched();
    }

    private ensureDataFetched() {

        this.props.requestFunction(this.state.version, "GET");
        this.props.requestFunctionGroup(this.state.version, "GET");

    }

    private toggleModal() {
        this.setState({
            isOpenModal: !this.state.isOpenModal,
            multiValue: []
        });
    }

    private replaceDate(e: string) {

        var date = "";
        if (e !== null) {
            if (e.length >= 10) {
                var date = e.substring(0, 10);
            }
        }

        return date;
    }

    private handleChangeMataDataCode(e: any) {
        var data = this.state.modelMetaDataGroup;
        data.metadata_group_code = e.target.value;

        this.setState({
            modelMetaDataGroup: data
        });

    }

    private handleChangeMataDataName(e: any) {
        var data = this.state.modelMetaDataGroup;
        data.metadata_group_name = e.target.value;

        this.setState({
            modelMetaDataGroup: data
        });
    }

    private actionsClick(type) {
        const { modelMetaDataGroup } = this.state
        if (this.state.multiValue !== "" && modelMetaDataGroup.metadata_group_code !== "metadata_group_code" && modelMetaDataGroup.metadata_group_name !== "metadata_group_name"
            && modelMetaDataGroup.metadata_group_code !== "" && modelMetaDataGroup.metadata_group_name !== "") {
            var countValue = []
            countValue = this.state.multiValue
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
            this.props.requestFunctionGroup(this.state.version, type, this.state.modelMetaDataGroup, countValue);

        } else if (type === "DEL") {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
            this.props.requestFunctionGroup(this.state.version, type, this.state.modelMetaDataGroup);
        } else {
            Swal.fire({
                title: 'ไม่สำเร็จ!',
                text: 'โปรดกรอกข้อมูลให้ครบถ้วน',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!'
            })
        }
    }

    openModal = (type, code, name) => {
        var data = this.state.modelMetaDataGroup;
        data.metadata_group_code = code
        data.metadata_group_name = name

        this.setState({ manageMode: type, modelMetaDataGroup: data });

        this.toggleModal();
        if (type === "Edit") {
            var valueselect = new Array()
            var valueGetSelect = this.state.modelListGetDetail
            if (valueGetSelect !== undefined) {
                var valueGetDetail = valueGetSelect.filter(person => person.metadata_group_code === code).map(filteredPerson => (filteredPerson))
                for (var i = 0; i < valueGetDetail.length; i++) {
                    let data = {}
                    data['value'] = valueGetDetail[i].metadata_code
                    data['label'] = valueGetDetail[i].metadata_name
                    valueselect.push(data)
                }
            }
            this.handleValueSelect(valueselect, { action: 'setValue' })
        }
    }

    openModalDetail = (type, value) => {
        var valueGetSelect = this.state.modelListGetDetail
        var valueGetDetail = valueGetSelect.filter(person => person.metadata_group_code === value).map(filteredPerson => (filteredPerson))
        this.setState({ modelListGetDetailUse: valueGetDetail, manageMode: type })
        this.toggleModal();
    }

    setBodyModal = () => {

        if (this.state.manageMode === "Add") {
            return <div style={{ paddingBottom: 0 }}>
                <InputGroup>
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText className="metadata-group-font">Metadata Group Code</InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="" type="text" onChange={this.handleChangeMataDataCode} />
                </InputGroup>
                <br />
                <InputGroup>
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText className="metadata-group-font">Metadata Group Name</InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="" type="text" onChange={this.handleChangeMataDataName} />
                </InputGroup>
                <br />
                <InputGroup>
                    <InputGroupAddon addonType="prepend" >
                        <InputGroupText className="metadata-group-font" style={{ width: 190 }}>Select Metadata</InputGroupText>
                    </InputGroupAddon>
                    <div style={{ width: '80%' }}>
                        <Select
                            className="dropdown metadata-group-font"
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            placeholder="Select Metadata"
                            value={this.state.valueSelect.filter(obj => this.state.multiValue.includes(obj.value))}
                            options={this.state.valueSelect}
                            onChange={this.handleValueSelectAdd}
                            isMulti
                            isClearable
                        />
                    </div>
                </InputGroup>
            </div>;
        } else if (this.state.manageMode === "Edit") {
            return <div style={{ paddingBottom: 0 }}>
                <InputGroup>
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText className="metadata-group-font">Metadata Group Code</InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="" type="text" defaultValue={this.state.modelMetaDataGroup.metadata_group_code} readOnly onChange={this.handleChangeMataDataCode} />
                </InputGroup>
                <br />
                <InputGroup>
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText className="metadata-group-font">Metadata Group Name</InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="" type="text" defaultValue={this.state.modelMetaDataGroup.metadata_group_name} onChange={this.handleChangeMataDataName} />
                </InputGroup>
                <br />
                <InputGroup >
                    <InputGroupAddon addonType="prepend" >
                        <InputGroupText className="metadata-group-font" style={{ width: 190 }}>Select Metadata</InputGroupText>
                    </InputGroupAddon>
                    <div style={{ width: '80%' }}>
                        <Select
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            className="basic-multi-select metadata-group-font"
                            classNamePrefix="select"
                            placeholder="Select Metadata"
                            value={this.state.valueSelect.filter(obj => this.state.multiValue.includes(obj.value))}
                            options={this.state.valueSelect}
                            onChange={this.handleValueSelect}
                            isMulti
                            isClearable
                        />
                    </div>
                </InputGroup>
            </div>;
        } else if (this.state.manageMode === "Delete") {
            return <div>
                <InputGroup>
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText className="metadata-group-font">Metadata Group Code</InputGroupText>
                    </InputGroupAddon>
                    <Input className="metadata-group-font" placeholder="Metadata Code" type="text" value={this.state.modelMetaDataGroup.metadata_group_code} onChange={this.handleChangeMataDataCode} disabled />
                </InputGroup>
                <br />
                <InputGroup>
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText>Metadata Group Name</InputGroupText>
                    </InputGroupAddon>
                    <Input className="metadata-group-font" placeholder="Metadata Name" type="text" value={this.state.modelMetaDataGroup.metadata_group_name} onChange={this.handleChangeMataDataName} disabled />
                </InputGroup>
            </div>;
        } else if (this.state.manageMode === 'Detail') {
            return (
                <div>
                    <table className='table table-main-style'>
                        <thead>
                            <tr>
                                <td className="td-code">ID</td>
                                <td className="td-name">Name</td>
                                <td className="td-update-on" >Modified</td>
                                <td className="td-update-by" >Modified By</td>

                            </tr>
                        </thead>
                        <tbody>

                            {this.state.modelListGetDetailUse !== undefined && this.state.modelListGetDetailUse.length > 0 && this.state.modelListGetDetailUse.map((item: ModelsGroup.MetaDataGroup) =>
                                <tr key={Math.random()} className="table-tbody">
                                    <td className="td-code">{item.metadata_code}</td>
                                    <td className="td-name">{item.metadata_name}</td>
                                    <td className="td-update-on" >{this.replaceDate(item.created_on)}</td>
                                    <td className="td-update-by" >{item.created_by}</td>
                                </tr>
                            )}

                            {this.state.modelListGetDetailUse === null ? <tr><td colSpan={5} style={{ color: "black", fontSize: '10pt', textAlign: "center" }}>No data.</td></tr> : ''}
                        </tbody>
                    </table>
                </div>
            )

        }
    }

    setButtonModal = () => {

        if (this.state.manageMode === "Add") {
            return <Button color="primary" className="metadata-group-font" onClick={() => this.actionsClick('POST')}>Add</Button>;
        } else if (this.state.manageMode === "Edit") {
            return <Button color="warning" className="metadata-group-font" onClick={() => this.actionsClick('PUT')}>Edit</Button>;
        } else if (this.state.manageMode === "Delete") {
            return <Button color="danger" className="metadata-group-font" onClick={() => this.actionsClick('DEL')}>Delete</Button>;
        }
    }

    handleValueSelectAdd = (e, { action }) => {
        this.setState({
            multiValue: (Array.isArray(e) ? e.map(x => x.value) : [])
        });
    }

    handleValueSelect = (e, { action }) => {
        if (action === 'select-option') {
            var checkArray = e
            e.map((data, i) => {
                if (checkArray.length === i + 1) {
                    checkArray = []
                    checkArray.push(data.value)
                }
            })
            this.setState({
                checkValueDel: (Array.isArray(e) ? e.map(x => x.value) : [])
            });
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
            this.props.requestFunctionGroup(this.state.version, 'POST_SELECT', this.state.modelMetaDataGroup, checkArray);
        } else if (action === 'remove-value') {
            var valueCurrent = e.map(item => item.value);
            let difference = this.state.checkValueDel.filter(x => !valueCurrent.includes(x));
            difference = String(difference)
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
            this.props.requestFunctionGroup(this.state.version, 'DEL_META', this.state.modelMetaDataGroup, '', difference);
            this.setState({
                checkValueDel: (Array.isArray(e) ? e.map(x => x.value) : [])
            });
        }
        if (action === 'setValue') {
            this.setState({
                checkValueDel: (Array.isArray(e) ? e.map(x => x.value) : [])
            });
        }
        this.setState({
            multiValue: (Array.isArray(e) ? e.map(x => x.value) : [])
        });
    }

    getUnique(arr, index) {
        if (arr) {
            const unique = arr
                .map(e => e[index])
                .map((e, i, final) => final.indexOf(e) === i && i)
                .filter(e => arr[e]).map(e => arr[e]);

            return unique;
        }

        return null;
    }

    formatDataGet = (versionGroup) => {
        var valueGetSelect = this.props.responseGroup.result_list as Array<ModelsGroup.MetaDataGroup>
        var valueGet = this.props.responseGroup.result_list as Array<ModelsGroup.MetaDataGroup>
        document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
        this.props.requestFunctionGroup(this.state.version, 'CLEAR');
        valueGet = this.getUnique(valueGet, 'metadata_group_code')
        if (versionGroup === 10) {
            this.setState({ modelListGet: valueGet, modelListGetDetail: valueGetSelect });
        } else {
            this.setState({ modelListGet: valueGet, modelListGetDetail: valueGetSelect, isOpenModal: false });
        }
    }

    render() {
        const { isLoading, response, versionGroup } = this.props
        if (this.props.isLoadingGroup === true) {
            this.formatDataGet(versionGroup)
        }

        if (isLoading) {
            var DataSelectArray = this.state.valueSelect
            for (var i = 0; i < response.total_count; i++) {
                let data = {}
                data['value'] = response.result_list[i].metadata_code
                data['label'] = response.result_list[i].metadata_name
                DataSelectArray.push(data)
            }
            this.setState({ valueSelect: DataSelectArray })
        }
        return (
            <div className="div-meta-data-main div-meta-role">
                <div>
                    {/* <div className="col-8">
                        <h1>Meta Data Group</h1>
                    </div>*/}
                    <div className='trapezoid' style={{ width: 'auto', display: 'inline-block' }}>
                        <span className="metadata-group-font">
                            Metadata Group
                        </span>
                    </div>
                    <div style={{ display: 'inline', float: 'right' }}>
                        <Button className="btn-add metadata-group-font" onClick={() => this.openModal('Add', '', '')}> <FaPlusCircle style={{ marginBottom: '4px' }} /> Add</Button>
                    </div>
                </div>
                <table className='table custom-table table-hover'>
                    <thead>
                        <tr>
                            <th className="td-code">ID</th>
                            <th className="td-name">Name</th>
                            <th className="td-update-on" >Modified</th>
                            <th className="td-update-by" >Modified By</th>
                            <th className="td-update-by th-center" >Detail</th>
                            <th className="td-menu" >Menu</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.modelListGet !== undefined && this.state.modelListGet.length > 0 && this.state.modelListGet.map((item: ModelsGroup.MetaDataGroup) =>
                            <tr key={Math.random()} className="table-tbody">
                                <td className="td-code">{item.metadata_group_code}</td>
                                <td className="td-name">{item.metadata_group_name}</td>
                                <td className="td-update-on" >{this.replaceDate(item.created_on)}</td>
                                <td className="td-update-by" >{item.created_by}</td>
                                <td className="td-update-by td-center" >
                                    <IconButton style={{ padding: "3px" }} aria-label="delete" onClick={() => this.openModalDetail('Detail', item.metadata_group_code)}>
                                        <FormatListBulletedIcon />
                                    </IconButton>
                                </td>
                                <td className="td-menu" >
                                    <Button className="btn-edit-file" size="sm" onClick={() => this.openModal('Edit', item.metadata_group_code, item.metadata_group_name)}><FaEdit style={{ marginBottom: '4px' }} /> Edit</Button>{' '}
                                    <Button className="btn-delete-file" size="sm" style={{ padding: '3px 5px' }} onClick={() => this.openModal('Delete', item.metadata_group_code, item.metadata_group_name)}><FaTimesCircle style={{ marginBottom: '4px' }} /> Delete</Button>
                                </td>
                            </tr>
                        )}

                        {this.state.modelListGet === null ? <tr><td colSpan={5} style={{ color: "black", fontSize: '10pt', textAlign: "center" }}>No data.</td></tr> : ''}
                    </tbody>
                </table>

                <Modal isOpen={this.state.isOpenModal} className="modal-upload-file">
                    <ModalHeader className="metadata-group-font" toggle={this.toggleModal}>{this.state.manageMode + ' Mata Data Group'}</ModalHeader>
                    <ModalBody>

                        {this.setBodyModal()}

                    </ModalBody>
                    <ModalFooter>
                        {this.setButtonModal()}
                        <Button color="secondary" className="metadata-group-font" onClick={this.toggleModal}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default connect((state: ApplicationState) =>
    ({ ...state.metaData, ...state.metaDataGroup }),
    ({ ...Stores.actionCreators, ...StoreGroup.actionCreators }))
    (MetaDataGroupComponent)