import { makeAutoObservable, runInAction } from 'mobx';

import { extractAccessToken } from 'common/helpers';
import { requestEmployee, requestUserRole } from 'data/datasources/app-service/user.datasource';
import { UserRole } from 'models/domain/Admin';
import { EmployeeMain } from 'models/domain/Employee';
import { UserInfo } from 'models/domain/Authentication';

import RootStore from 'mobx-store/Root.store';

class UserStore {
    public employees?: EmployeeMain[];
    public userRoles: UserRole[] = [];
    public userRole = '';

    public userInfo?: UserInfo;

    constructor(private rootStore: RootStore) {
        makeAutoObservable(this, {}, { autoBind: true });

        this.userInfo = extractAccessToken();
    }

    public async fetchUserRole(citizenId: string) {
        const userRolesResponse = (await requestUserRole(citizenId)).data;
        const foundAminRole = userRolesResponse.result_list.find(
            user => user.roleAccess === 'admin' || user.roleAccess === 'super_admin'
        );

        runInAction(() => {
            this.userRoles = userRolesResponse.result_list;

            if (foundAminRole) {
                this.userRole = foundAminRole.roleAccess;
            }
        });
    }

    public async fetchEmployees(citizenId: string) {
        const employeeResponse = (await requestEmployee(citizenId)).data;
        runInAction(() => (this.employees = employeeResponse.result_list));

        return employeeResponse;
    }
}

export default UserStore;
