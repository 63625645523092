import React from 'react';
import {
    Button,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay
} from '@chakra-ui/react';
import { connect } from 'react-redux';
import CreatableSelect from 'react-select/creatable';
import { MdDoNotDisturbOn, MdOutlineInsertDriveFile } from 'react-icons/md';
import { FaUpload, FaPlus, FaAngleDoubleDown, FaMinus, FaMinusCircle } from 'react-icons/fa';
import Swal from 'sweetalert2';

import * as FileUpload from 'models/FileUpload';
import { ApplicationState } from 'store';
import * as FileUploadStore from 'store/FileUpload';
import * as FileModels from 'models/File';
import { UserContext, IUserContext } from 'context/UserProvider';
import { validateMetadataCollections } from 'modules/common';

import imgFile from 'Images/Icon/icon-File.png';
import imgPDF from 'Images/Icon/icon-PDF.png';
import imgXLS from 'Images/Icon/icon-XLS.png';
import imgWord from 'Images/Icon/icon-Word.png';
import imgPowerPoint from 'Images/Icon/icon-PowerPoint.png';

import { Input } from 'common/materials';
import withLoader, { WithLoaderProps } from 'components/common/hoc/withLoader';
import KeyValuePairInput from './KeyValuePairInput';
import F1ConnectForm, { F1ConnectFormValues } from './F1ConnectForm';

export type UploadFileModalConstruct = UploadFileModal;

interface Props {
    [key: string]: any;
    isOpen: boolean;
    onClose: () => void;
    removeSelectedFile: (atIndex: number) => void;
}

interface State {
    alertSaveClick: string;
    isOpenModal: boolean;
    selectedFile: FileUpload.FileUpload[];
    relation: string;
    sFile: any;
    version: number;
    inputValue: string[];
    inputValueTotal: any;
    valueTotal: any;
    groupId: any;
    subGroupId: any;
    boxId: any;
    value: any;
    inputSearchKeyIndex: number;
    isOpenConfigMetadataMap: Map<number | string, boolean>;
    duplicatePrimaryIndexes: FileModels.File[];
}

const components = {
    DropdownIndicator: null
};

const createOption = (label: string) => ({
    label,
    value: label
});

class UploadFileModal extends React.Component<Props & WithLoaderProps, State> {
    static contextType: React.Context<any> | undefined = UserContext;

    public context!: React.ContextType<typeof UserContext>;

    private inputFileElement: HTMLInputElement | null = null;

    constructor(props: any) {
        super(props);

        this.state = {
            alertSaveClick: '',
            isOpenModal: false,
            selectedFile: [
                {
                    lastModified: '',
                    lastModifiedDate: '',
                    name: '',
                    size: 0,
                    full_path: '',
                    type: '',
                    webkitRelativePath: '',
                    keyIndex: '',
                    primaryIndex: '',
                    role: '',
                    metadata: [],
                    sid: '',
                    bucket: '',
                    group_id: '',
                    sub_group_id: '',
                    box_id: '',
                    relation: ''
                }
            ],
            sFile: null,
            version: 0,
            inputSearchKeyIndex: 0,
            inputValue: [],
            value: [],
            inputValueTotal: '',
            valueTotal: [],
            groupId: '',
            subGroupId: '',
            boxId: '',
            relation: '',
            isOpenConfigMetadataMap: new Map(),
            duplicatePrimaryIndexes: []
        };

        this.toggleModal = this.toggleModal.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.previewFile = this.previewFile.bind(this);
        this.uploadClick = this.uploadClick.bind(this);
        this.handleChangePid = this.handleChangePid.bind(this);
        this.handleChangeRole = this.handleChangeRole.bind(this);
        this.handleChangeMataData = this.handleChangeMataData.bind(this);
        this.fomatSizeFile = this.fomatSizeFile.bind(this);
        this.onMetadataChange = this.onMetadataChange.bind(this);
        this.addMetadata = this.addMetadata.bind(this);
        this.removeSelectedFile = this.removeSelectedFile.bind(this);
        this.handleClickApplyAll = this.handleClickApplyAll.bind(this);
        this.clickFileInput = this.clickFileInput.bind(this);
    }

    componentDidUpdate() {
        if (this.props.version > this.state.version) {
            this.toggleModal();
            this.props.loader.hide();

            if (this.props.version - this.state.version === 2) {
                Swal.fire({
                    title: 'สำเร็จ!',
                    text: 'กดปุ่มยืนยัน',
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'ยืนยัน!'
                }).then(result => {
                    //this.props.onUploadSuccess();
                    this.props.reloading('reset');
                });
            } else {
                Swal.fire('Save Fail.', '', 'error');
            }

            this.setState({ version: this.props.version });
        }
    }

    public toggleConfigMetaData = (key: number | string) => {
        this.setState(prevState => {
            const value = prevState.isOpenConfigMetadataMap.get(key) || false;
            const nextIsOpenConfigMetadataMap = new Map(prevState.isOpenConfigMetadataMap);

            return { isOpenConfigMetadataMap: nextIsOpenConfigMetadataMap.set(key, !value) };
        });
    };

    private async toggleModal() {
        if (this.state.isOpenModal && this.inputFileElement) {
            this.inputFileElement.value = null as unknown as string;
        }

        // Check duplicate primary index
        const primaryIndexList = this.state.selectedFile.map(f => f.bucket + '__' + f.primaryIndex);
        const foundPrimaryIndexFiles =
            (await this.fetchFilePrimaryIndexStore({
                key_list: primaryIndexList,
                bucket: this.state.selectedFile[0].bucket
            })) ?? [];

        //console.log("[Debug] buckets", this.state.selectedFile.map(s => s.bucket))
        console.log('[Debug] foundPrimaryIndexFiles', foundPrimaryIndexFiles);

        //const foundPrimaryIndexInBuckets = this.state.selectedFile.reduce<FileModels.File[]>((accumulate, selectedFile) => {
        //    const foundSameBucket = foundPrimaryIndexFiles.find(file => file.bucket === selectedFile.bucket);

        //    if (!foundSameBucket) {
        //        return accumulate;
        //    }

        //    accumulate.push(foundSameBucket);

        //    return accumulate;
        //}, []);

        //console.log("[Debug] foundPrimaryIndexInBuckets", foundPrimaryIndexInBuckets)

        if (!this.state.isOpenModal && foundPrimaryIndexFiles.length > 0) {
            this.popupAlertDuplicatePrimaryIndex(foundPrimaryIndexFiles);
        }

        this.setState({
            isOpenModal: !this.state.isOpenModal,
            alertSaveClick: '',
            inputValue: [],
            value: [],
            inputValueTotal: '',
            valueTotal: [],
            duplicatePrimaryIndexes: foundPrimaryIndexFiles
        });
    }

    private async fetchFilePrimaryIndexStore(req: FileModels.FindPrimaryFilesRequest) {
        const response = await fetch(`/v2/dms/primary`, {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('DMS_APPLICATION'),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(req)
        });

        if (!response.ok) {
            return;
        }

        const data: FileModels.ResponseFile = await response.json();

        if (data.total_count === 0) {
            return;
        }

        return data.result_list;
    }

    public popupAlertDuplicatePrimaryIndex(duplicateFiles: FileModels.File[]) {
        const elements = duplicateFiles
            .map(
                file =>
                    `<div style="text-align: left;">
                <span style="color: red;">${file.primary_index.split('__')[1].split('.')[0]}</span>
                <span> ที่ ${file.full_path.slice(2, file.full_path.length - 3)},</span>
            </div>`
            )
            .join('<br/>');

        Swal.fire({
            title: 'มี Primary Index ซ้ำในระบบ!',
            width: '85vw',
            html: elements,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'ยืนยัน',
            footer: `<span style="color: red">กรุณาเปลี่ยน <b>Primary Index</b> เพื่อป้องกันข้อมูลสูญหาย</span>`
        });
    }

    private handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
        const files = event.target.files;

        if (files === null || files.length === 0) {
            return;
        }

        const prepareFiles = Array.from<File & FileUpload.FileUpload>(files as any).map(file => {
            file.keyIndex = '';
            file.primaryIndex = file.name;
            file.role = '';
            file.metadata = [];
            file.bucket = this.props.folderSelectedCode;
            file.sid = this.props.folderSelectedSid;

            return file;
        });

        this.setState({
            selectedFile: prepareFiles,
            sFile: event.target.files
        });

        setTimeout(() => this.toggleModal(), 500);
    }

    public onMetadataChange(atFileIndex: number, atMetadataIndex: number) {
        return (key: string, value: string) => {
            // console.log('ngame key/value change', [key, value]);
            this.setState(prevState => {
                const nextSelectedFileState = [...prevState.selectedFile];
                const metadataTarget = nextSelectedFileState[atFileIndex].metadata[atMetadataIndex];
                metadataTarget[0] = key;
                metadataTarget[1] = value;

                return {
                    ...prevState,
                    selectedFile: nextSelectedFileState
                };
            });
        };
    }

    public addMetadata(atFileIndex: number): void {
        this.setState(prevState => {
            const nextSelectedFileState = [...prevState.selectedFile];
            nextSelectedFileState[atFileIndex].metadata.push(['', '']);

            return {
                ...prevState,
                selectedFile: nextSelectedFileState
            };
        });
    }

    public removeMetadata(atFileIndex: number, removeAtIndex: number): void {
        this.setState(prevState => {
            const nextSelectedFileState = [...prevState.selectedFile];
            nextSelectedFileState[atFileIndex].metadata.splice(removeAtIndex, 1);

            return {
                ...prevState,
                selectedFile: nextSelectedFileState
            };
        });
    }

    addTextvalue(e, check) {
        var Group: string = '';
        Group = e.target.value;
        if (check === 'groupId') {
            this.setState({
                groupId: Group
            });
        } else if (check === 'subGroupId') {
            this.setState({
                subGroupId: Group
            });
        } else if (check === 'boxId') {
            this.setState({
                boxId: Group
            });
        } else if (check === 'relation') {
            this.setState({
                relation: Group
            });
        }
    }

    private previewFile(item: FileUpload.FileUpload) {
        if (item.type.indexOf('image') !== -1) {
            return URL.createObjectURL(item as any);
        } else if (item.type.indexOf('pdf') !== -1) {
            return imgPDF;
        } else if (item.type.indexOf('.sheet') !== -1) {
            return imgXLS;
        } else if (item.type.indexOf('.document') !== -1) {
            return imgWord;
        } else if (item.type.indexOf('.presentation') !== -1) {
            return imgPowerPoint;
        } else {
            return imgFile;
        }
    }

    private handleChangePid(event: any) {
        let fileindex = parseInt(event.target.getAttribute('data-fileindex'));
        if (typeof fileindex !== 'number') {
            fileindex = 0;
        }

        var value = event.target.value;

        var listFileUpload = this.state.selectedFile;
        listFileUpload[fileindex].primaryIndex = value;

        console.log('handleChangePid');
        console.log('File index', fileindex);
        console.log('File', listFileUpload[fileindex]);

        this.setState({
            selectedFile: listFileUpload
        });
    }

    private handleChangeRole(event: any) {
        var value = event.target.value;

        var listFileUpload = this.state.selectedFile;
        listFileUpload[0].role = value;

        this.setState({
            selectedFile: listFileUpload
        });
    }

    private handleChangeMataData(event: any) {
        var value = event.target.value;

        var listFileUpload = this.state.selectedFile;
        listFileUpload[0].metadata = value;

        this.setState({
            selectedFile: listFileUpload
        });
    }

    private fomatSizeFile(item: number) {
        var txtSize = '';

        if (item < 1024) {
            txtSize = item + ' B';
        } else {
            var size = item / 1024;
            txtSize = parseInt(size.toString()) + ' KB';
        }

        return txtSize;
    }

    private uploadClick() {
        const selectedFiles = this.state.selectedFile;

        try {
            for (let i = 0; i < selectedFiles.length; i++) {
                const file = selectedFiles[i];
                const metadataCollections = file.metadata;

                validateMetadataCollections(metadataCollections);
            }
        } catch (error) {
            Swal.fire({
                title: 'เกิดข้อผิดพลาด',
                text: error as any,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน'
            });
            return;
        }

        var checkValue = true;
        this.setState({ alertSaveClick: '' });

        // Validate & Prepar
        this.state.selectedFile.map((file, index) => {
            var listValue: Array<string> = [];

            if (this.state.value[index]) {
                for (var i = 0; i < this.state.value[index].length; i++) {
                    listValue.push(this.state.value[index][i].value);
                }
            }

            this.state.selectedFile[index].keyIndex = listValue.join('|');
            this.state.selectedFile[index].group_id = this.state.groupId;
            this.state.selectedFile[index].sub_group_id = this.state.subGroupId;
            this.state.selectedFile[index].box_id = this.state.boxId;

            var pid = this.state.selectedFile[index].primaryIndex;

            if (pid === null || pid === '') {
                this.setState({ alertSaveClick: 'Please insert Primary Index.' });
                checkValue = false;
            }
        });

        if (checkValue) {
            this.props.loader.show();

            // Process Upload
            this.state.selectedFile.map((file, index) => {
                let _sFile = [this.state.sFile[index]];
                //debugger;
                this.props.requestFunction(
                    _sFile,
                    this.state.selectedFile[index],
                    this.state.version
                );
            });
        }
    }

    handleChange = (valueChange: any, actionMeta: any, checkRow: any) => {
        if (actionMeta === 'Total') {
            this.setState({ valueTotal: valueChange });
        } else {
            const { value } = this.state;
            let _value = [...value];
            if (_value[checkRow] === undefined) {
                _value[checkRow] = [];
            }
            _value[checkRow] = valueChange;
            this.setState({
                value: _value
            });
        }
    };

    handleInputChange = (inputValueSearchKey: string, check: string) => {
        if (check === 'Total') {
            this.setState({ inputValueTotal: inputValueSearchKey });
        } else {
            this.state.inputValue[this.state.inputSearchKeyIndex] = inputValueSearchKey;
            this.setState({ inputValue: this.state.inputValue });
        }
    };

    handleKeyDown = (event: any, check: string) => {
        if (check === 'Total') {
            const { inputValueTotal, valueTotal } = this.state;
            if (!inputValueTotal) return;
            switch (event.key) {
                case 'Enter':
                case 'Tab':
                    this.setState({
                        inputValueTotal: '',
                        valueTotal: [...valueTotal, createOption(inputValueTotal)]
                    });
                    event.preventDefault();
            }
        } else {
            let _inputSearchKeyIndex: number;
            _inputSearchKeyIndex = parseInt(event.currentTarget.getAttribute('id'));
            console.log(_inputSearchKeyIndex);
            this.setState({ inputSearchKeyIndex: _inputSearchKeyIndex });

            const { inputValue, value, inputSearchKeyIndex } = this.state;
            if (!inputValue[inputSearchKeyIndex]) return;

            switch (event.key) {
                case 'Enter':
                case 'Tab':
                    let _value = [...value];
                    if (_value[inputSearchKeyIndex] === undefined) {
                        _value[inputSearchKeyIndex] = [];
                    }
                    _value[inputSearchKeyIndex] = [
                        ..._value[inputSearchKeyIndex],
                        createOption(inputValue[inputSearchKeyIndex])
                    ];

                    this.setState({
                        inputValue: [],
                        value: _value
                    });
                    event.preventDefault();
            }
        }
    };

    getValueSelectRoleAccess(valueRoleAccess, itemIndex) {
        this.state.selectedFile[itemIndex].role = valueRoleAccess;
    }

    getValueInputMetaData(value) {
        this.state.selectedFile[0].metadata = value;
    }

    setValueToSearch = () => {
        const { valueTotal, selectedFile, value } = this.state;
        if (valueTotal.length > 0) {
            var objValue: any = [];
            for (let j = 0; j < selectedFile.length; j++) {
                var objValueSub: any = [];
                for (let i = 0; i < valueTotal.length; i++) {
                    let data = {};
                    data['label'] = valueTotal[i].value;
                    data['value'] = valueTotal[i].value;
                    objValueSub.push(data);
                }
                objValue.push(objValueSub);
            }
            this.setState({ value: objValue });
        }
    };

    public removeSelectedFile(atIndex: number) {
        this.setState(prevState => {
            const nextSelectedFiles = [...prevState.selectedFile];
            nextSelectedFiles.splice(atIndex, 1);

            return {
                ...prevState,
                selectedFile: nextSelectedFiles
            };
        });
    }

    public handleClickApplyAll(formValues: F1ConnectFormValues) {
        const keys: (keyof F1ConnectFormValues)[] = [
            'companyCode',
            'documentType',
            'fiscalYear',
            'documentNo'
        ];

        const prepareValue = keys
            .map(key => formValues[key])
            .filter(value => (value as string).length !== 0);
        const newGroupId = prepareValue.join('');
        const newSearchKey = prepareValue.map(value => ({ label: value, value }));

        this.setState({ groupId: newGroupId, valueTotal: newSearchKey });
    }

    public clickFileInput() {
        this.inputFileElement?.click();
    }

    render() {
        const { id }: IUserContext = this.context;
        const { inputValue, value } = this.state;
        const { folderSelectedSid } = this.props;

        return (
            <div style={{ display: 'inline', paddingLeft: '5px' }}>
                {/* <FileDetailExportExcelComponent isReLoadFile={this.props.isReLoadFile} folderSelectedCode={this.props.folderSelectedCode} />
                &nbsp;&nbsp;

                <FileDetailImportExcelComponent isReLoadFile={this.props.isReLoadFile} folderSelectedCode={this.props.folderSelectedCode} />
                &nbsp;&nbsp; */}

                {/* {this.props.folderSelectedCode === "" ?
                    <label className="btn-manage-file-disable" title="Please select the folder."><FaUpload style={{ marginBottom: '4px' }} /> Upload File</label> :
                    <label htmlFor="inputUploadFile" className="btn-upload-file"><FaUpload style={{ marginBottom: '4px', }} /> Upload File</label>
                } */}
                {/* <Button
                    disabled={this.props.disabled}
                    variant="outline"
                    className={!this.props.disabled ? 'btn-upload-file' : 'btn-manage-file-disable'}
                    onClick={() => this.inputFileElement?.click()}
                >
                    <FaUpload style={{ marginBottom: '4px', marginRight: '4px' }} />
                    Upload File
                </Button> */}
                <input
                    type="file"
                    name="file"
                    id="inputUploadFile"
                    multiple
                    onChange={this.handleFileChange}
                    className="inp-upload-file"
                    ref={input => (this.inputFileElement = input)}
                />

                <Modal
                    size="full"
                    isOpen={this.state.isOpenModal}
                    onClose={this.props.onClose}
                >
                    <ModalOverlay />
                    <ModalContent className="m-4 min-h-[calc(100dvh_-_32px)] rounded-md">
                        <ModalBody>
                            <div className="flex flex-col gap-2 p-2.5">
                                <span className="text-lg font-medium">Path file</span>

                                <div className="bg-[#D2E2F5] px-3 py-[5px]">
                                    <span className="text-base font-normal text-[#2E445D]">
                                        Upload file{' '}
                                        {this.props.folderSelectedName !== '' ? ' to ' : ''}
                                    </span>
                                    <span className="text-base font-normal text-[#3397C3]">
                                        {this.props.folderSelectedFullPath}.
                                    </span>
                                </div>

                                <div className="flex flex-col gap-2 p-2">
                                    <span className="text-xs font-normal text-[#333333]">
                                        Total files : {this.state.selectedFile.length}
                                    </span>

                                    <div className="flex flex-row gap-2.5">
                                        <div className="flex-1 gap-1">
                                            <span className="text-xs font-normal text-[#333333]">
                                                Group Id
                                            </span>
                                            <Input
                                                type="text"
                                                className="h-8"
                                                value={this.state.groupId}
                                                onChange={e => this.addTextvalue(e, 'groupId')}
                                            />

                                            <span className="text-xs font-normal text-[#333333]">
                                                Search Key
                                            </span>
                                            <CreatableSelect
                                                className="create-select"
                                                components={components}
                                                inputValue={this.state.inputValueTotal}
                                                isClearable
                                                isMulti
                                                menuIsOpen={false}
                                                onChange={e => this.handleChange(e, 'Total', '')}
                                                onInputChange={e =>
                                                    this.handleInputChange(e, 'Total')
                                                }
                                                onKeyDown={e => this.handleKeyDown(e, 'Total')}
                                                placeholder="Type something and press enter..."
                                                value={this.state.valueTotal}
                                            />
                                            <span className="text-xs font-normal text-[#B3261E]">
                                                Please enter a error description
                                            </span>
                                        </div>

                                        <div className="flex gap-1">
                                            <Button className="mt-6 h-8 bg-[#3397C3] px-4">
                                                <span className="px-3.5 text-sm font-medium text-white">
                                                    Mass Maintain
                                                </span>
                                            </Button>
                                        </div>

                                        <F1ConnectForm
                                            handleClickApplyAll={this.handleClickApplyAll}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-col rounded border p-2">
                                <table className="w-full table-auto border-2">
                                    <thead className="bg-[#ECECEC]">
                                        <tr className="h-[30px] [&>*]:border  [&>*]:border-[#DDDDDD] [&>*]:px-2 [&>*]:py-[5px] [&>*]:text-xs [&>*]:font-normal">
                                            <th></th>
                                            <th>ID</th>
                                            <th>
                                                <div className="flex justify-center">
                                                    <MdOutlineInsertDriveFile
                                                        size={25}
                                                        color="#7A7A7A"
                                                    />
                                                </div>
                                            </th>
                                            <th>Size</th>
                                            <th>
                                                Primary Index
                                                <span style={{ color: 'red' }}> *</span>
                                            </th>
                                            <th>Search Key</th>
                                        </tr>
                                    </thead>
                                    {this.state.selectedFile.map((file, index) => (
                                        <tbody>
                                            <React.Fragment>
                                                <tr
                                                    key={index}
                                                    className="h-[30px] [&>*]:border  [&>*]:border-[#DDDDDD] [&>*]:px-2 [&>*]:py-[5px] [&>*]:text-xs [&>*]:font-normal"
                                                >
                                                    <td className="w-[34px] bg-[#EFF7FA] text-center">
                                                        <MdDoNotDisturbOn
                                                            size={20}
                                                            color="#B3261E"
                                                            className="cursor-pointer"
                                                            onClick={_event =>
                                                                this.removeSelectedFile(index)
                                                            }
                                                        />
                                                    </td>
                                                    <td className="bg-[#EFF7FA]">{index + 1}</td>
                                                    <td className="bg-[#EFF7FA] text-center">
                                                        <div className="flex justify-center">
                                                            <img
                                                                src={this.previewFile(file)}
                                                                alt="file"
                                                                width="25"
                                                                height="25"
                                                            />
                                                        </div>
                                                    </td>
                                                    <td className="bg-[#EFF7FA]">
                                                        <td>{this.fomatSizeFile(file.size)}</td>
                                                    </td>
                                                    <td>
                                                        <Input
                                                            className="h-8 border-none p-0 transition-none focus:outline-none"
                                                            placeholder="Type something and press enter..."
                                                            value={file.primaryIndex.split('.')[0]}
                                                            data-fileindex={index}
                                                            onChange={this.handleChangePid}
                                                        />
                                                    </td>
                                                    <td>
                                                        <CreatableSelect
                                                            id={index}
                                                            className="create-select-no-border"
                                                            components={components}
                                                            inputValue={inputValue[index]}
                                                            isClearable
                                                            isMulti
                                                            menuIsOpen={false}
                                                            onChange={(e, a) =>
                                                                this.handleChange(e, a, index)
                                                            }
                                                            onInputChange={e =>
                                                                this.handleInputChange(e, '')
                                                            }
                                                            onKeyDown={e =>
                                                                this.handleKeyDown(e, '')
                                                            }
                                                            placeholder="Type something and press enter..."
                                                            value={value[index]}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={100}>
                                                        {this.state.isOpenConfigMetadataMap.get(
                                                            index
                                                        ) && (
                                                            <div className="flex flex-col gap-2 p-2">
                                                                <div className="flex flex-row gap-2">
                                                                    {this.state.selectedFile[index]
                                                                        .metadata.length > 0 && (
                                                                        <React.Fragment>
                                                                            <div className="flex-1 gap-1">
                                                                                <span className="text-xs font-normal text-[#333333]">
                                                                                    Key
                                                                                </span>
                                                                            </div>

                                                                            {/* <div className="flex items-end px-1">
                                                                                <Button
                                                                                    size="sm"
                                                                                    className="bg-[#3397C3]"
                                                                                    onClick={_event =>
                                                                                        this.addMetadata(
                                                                                            index
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <FaPlus color="white" />
                                                                                </Button>
                                                                            </div> */}

                                                                            <div className="flex-1 gap-1">
                                                                                <span className="text-xs font-normal text-[#333333]">
                                                                                    Value
                                                                                </span>
                                                                            </div>

                                                                            <div className="w-8" />
                                                                        </React.Fragment>
                                                                    )}
                                                                </div>

                                                                {this.state.selectedFile[
                                                                    index
                                                                ].metadata.map(
                                                                    (
                                                                        [key, value],
                                                                        metadataIndex
                                                                    ) => (
                                                                        <div className="flex flex-row gap-2">
                                                                            <KeyValuePairInput
                                                                                showPlaceholder
                                                                                // bsSize="sm"
                                                                                // span={3}
                                                                                valuePair={{
                                                                                    key,
                                                                                    value
                                                                                }}
                                                                                onChange={this.onMetadataChange(
                                                                                    index,
                                                                                    metadataIndex
                                                                                )}
                                                                            />
                                                                            <Button
                                                                                size="sm"
                                                                                className="bg-[#B3261E]"
                                                                                onClick={_event =>
                                                                                    this.removeMetadata(
                                                                                        index,
                                                                                        metadataIndex
                                                                                    )
                                                                                }
                                                                            >
                                                                                <FaMinus color="white" />
                                                                            </Button>
                                                                        </div>
                                                                    )
                                                                )}

                                                                <div className="flex-col">
                                                                    <Button
                                                                        size="sm"
                                                                        className="bg-[#3397C3]"
                                                                        onClick={_event =>
                                                                            this.addMetadata(index)
                                                                        }
                                                                    >
                                                                        <FaPlus color="white" />
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={100}>
                                                        <div className="flex">
                                                            <Button
                                                                className="flex h-[24px] w-full bg-[#3397C3] px-4"
                                                                onClick={() =>
                                                                    this.toggleConfigMetaData(index)
                                                                }
                                                            >
                                                                <span className="px-3.5 text-sm font-medium text-white">
                                                                    Config Metadata {index + 1}
                                                                    &nbsp;
                                                                </span>
                                                                <FaAngleDoubleDown
                                                                    size={18}
                                                                    color="white"
                                                                    style={{
                                                                        transition:
                                                                            'transform 0.2s ease',
                                                                        transform: `rotate(${
                                                                            this.state.isOpenConfigMetadataMap.get(
                                                                                index
                                                                            )
                                                                                ? '-3.142rad'
                                                                                : '0'
                                                                        })`
                                                                    }}
                                                                />
                                                            </Button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        </tbody>
                                    ))}
                                    {this.state.selectedFile.length === 0 && (
                                        <td
                                            colSpan={100}
                                            className="p-3 text-center"
                                        >
                                            <Button
                                                disabled={this.props.disabled}
                                                variant="outline"
                                                className={
                                                    !this.props.disabled
                                                        ? 'btn-upload-file'
                                                        : 'btn-manage-file-disable'
                                                }
                                                onClick={() => this.inputFileElement?.click()}
                                            >
                                                <FaUpload
                                                    style={{
                                                        marginBottom: '4px',
                                                        marginRight: '4px'
                                                    }}
                                                />
                                                Upload File
                                            </Button>
                                        </td>
                                    )}
                                </table>
                            </div>
                        </ModalBody>
                        <ModalFooter className="flex gap-2 px-6">
                            <p style={{ color: 'red' }}>{this.state.alertSaveClick}</p>
                            <Button
                                variant={'outline'}
                                onClick={this.toggleModal}
                                className="h-9 px-4 text-neutral-400"
                            >
                                Cancel
                            </Button>
                            <Button
                                variant={'outline'}
                                onClick={this.uploadClick}
                                className="h-9 bg-[#28A745] px-4 text-white"
                            >
                                Save & Upload
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </div>
        );
    }
}

// export default UploadFileComponent;
export default connect(
    (state: ApplicationState) => state.fileUpload,
    FileUploadStore.actionCreators,
    null,
    { forwardRef: true }
)(withLoader(UploadFileModal));
