import { AxiosError } from 'axios';

import { validateDataResponse } from '../../../common/helpers';
import { shareFolderAuthorize } from '../../datasources/v2/shareService';
import { ShareFolderRequestType } from '../../../models/ShareFolder';
import { ResponseMessage } from '../../../models/Common';

export async function saveChangeFolderAuthorize(
    params: ShareFolderRequestType[]
): Promise<ResponseMessage> {
    try {
        const response = await shareFolderAuthorize(params);
        const result: ResponseMessage = validateDataResponse(response);

        return result;
    } catch (error) {
        if (error instanceof AxiosError && error.response) {
            throw new Error(validateDataResponse(error.response).message);
        }

        throw error;
    }
}
