import React, { Component, createRef, PropsWithChildren } from 'react';

interface Props {
    handleClickOutside(event: MouseEvent): void;
}

interface State { }

class OutsideClickFallback extends Component<PropsWithChildren<Props>, State> {
    public wrapperRef: React.RefObject<HTMLDivElement>;

    constructor(props: Props) {
        super(props);

        this.wrapperRef = createRef();
        this.beforeExcecuteHandleClickOutside = this.beforeExcecuteHandleClickOutside.bind(this);
    }

    public componentDidMount(): void {
        document.addEventListener('mousedown', this.beforeExcecuteHandleClickOutside);
    }

    public componentWillUnmount(): void {
        document.removeEventListener('mousedown', this.beforeExcecuteHandleClickOutside);
    }

    public beforeExcecuteHandleClickOutside(event: MouseEvent): void {
        if (this.wrapperRef && !this.wrapperRef.current?.contains(event.target! as Node)) {
            this.props.handleClickOutside(event);
        }
    }

    public render(): React.ReactNode {
        return (
            <div ref={this.wrapperRef}>
                {this.props.children}
            </div>
        );
    }
}

export default OutsideClickFallback;