import React, { useState, useEffect, PropsWithChildren } from 'react';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import Swal from 'sweetalert2';
import { Button } from '@chakra-ui/react';

import { useUserContext } from 'context/UserProvider';
import * as SearchCriteriaStore from 'store/SearchCriteria';

import { Input } from 'common/materials';
import { useLoader } from 'common/hooks';

interface ComponentProps {
    handleClickApplyAll: (formValues: F1ConnectFormValues) => void;
}

export interface F1ConnectFormValues {
    companyCode: string;
    documentType: string;
    fiscalYear: Date | string;
    documentNo: string;
}

type Props = ComponentProps;

function F1ConnectForm(props: PropsWithChildren<Props>) {
    const [formValues, setFormValues] = useState<F1ConnectFormValues>({
        companyCode: '',
        documentType: '',
        fiscalYear: '',
        documentNo: ''
    });
    const [documentTypeOptions, setDocumentTypeOptions] = useState<
        { label: string; value: string }[]
    >([]);
    const [isLoadingDocumentType, setIsLoadingDocumentType] = useState<boolean>(false);

    const dispatch = useDispatch();
    const { availableCompanies } = useUserContext();

    const loader = useLoader();

    useEffect(() => {
        if (isLoadingDocumentType) {
            loader.show();
        } else {
            loader.hide();
        }
    }, [isLoadingDocumentType]);

    const getAvailableCompanies = () => {
        if (availableCompanies !== undefined) {
            const companyOptions = availableCompanies.map(company => {
                const { company_code, company_name, sid, status } = company;

                return {
                    label: `${company_code} : ${company_name}`,
                    value: company_code,
                    sid,
                    status
                };
            });

            return companyOptions;
        }

        return [];
    };

    const handleChangeFormValue = (key: keyof F1ConnectFormValues, value: string | Date) => {
        setFormValues(prevState => {
            return {
                ...prevState,
                [key]: value
            };
        });
    };

    const handleChangeCompany = (
        newData: { value: string; sid: string } | null,
        _actionMeta: unknown
    ) => {
        const { requestDocumentType } = SearchCriteriaStore.actionCreators;

        if (newData !== null) {
            const { value: company, sid } = newData;

            setIsLoadingDocumentType(true);
            dispatch(
                requestDocumentType({ method: 'GET' }, sid, (data, error) => {
                    if (error) {
                        return;
                    }

                    setIsLoadingDocumentType(false);

                    const mappedSelectOptions = data!.result_list.map(item => ({
                        label: item.name,
                        value: item.value
                    }));
                    setDocumentTypeOptions(mappedSelectOptions);
                }) as any
            );

            handleChangeFormValue('companyCode', company);
        } else {
            handleChangeFormValue('companyCode', '');
        }
    };

    const handleChangeDocumentType = (
        newData: { label: string; value: string },
        _actionMeta: unknown
    ) => {
        const { value } = newData ?? {};

        handleChangeFormValue('documentType', value ?? '');
    };

    const handleChangeYear = (date: Date, _event: React.ChangeEvent<HTMLInputElement>) => {
        handleChangeFormValue('fiscalYear', date ?? new Date());
    };

    const validateForm = () => {
        const keys: (keyof F1ConnectFormValues)[] = [
            'companyCode',
            'documentType',
            'fiscalYear',
            'documentNo'
        ];
        const prepareFormValues: F1ConnectFormValues = {
            ...formValues,
            fiscalYear:
                typeof formValues.fiscalYear === 'string'
                    ? ''
                    : (formValues.fiscalYear as Date).getFullYear().toString()
        };
        const warningMessages: string[] = [];

        for (const key of keys) {
            const value = prepareFormValues[key] as string;
            const isEmpty = value.length === 0;
            const has10Digits =
                value.length === 0 ? 0 : value.length < 10 || value.length > 10 ? -1 : 1;

            switch (key) {
                case 'companyCode': {
                    if (isEmpty) {
                        warningMessages.push('ไม่ได้เลือก Company');
                    }

                    break;
                }
                case 'documentType': {
                    if (isEmpty) {
                        warningMessages.push('ไม่ได้เลือก Document Type');
                    }

                    break;
                }
                case 'fiscalYear': {
                    if (isEmpty) {
                        warningMessages.push('ไม่ได้เลือก Fiscal Year');
                    }

                    break;
                }
                case 'documentNo': {
                    if (has10Digits === 0) {
                        warningMessages.push('ไม่ได้กรอก Document No');
                    } else if (has10Digits === -1) {
                        warningMessages.push('Document No ไม่ครบ 10 หลัก');
                    }

                    break;
                }
            }
        }

        if (warningMessages.length > 0) {
            warningMessages.push('ต้องการดำเนินการต่อหรือไม่');

            throw [prepareFormValues, warningMessages];
        }

        return prepareFormValues;
    };

    const handleClickApplyAll = () => {
        try {
            const formValues = validateForm();

            props.handleClickApplyAll(formValues);
        } catch (error) {
            const [formValues, warningMessages] = error as [F1ConnectFormValues, string[]];

            Swal.fire({
                title: 'คำเตือน',
                html: warningMessages.join('<br />'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน'
            }).then(value => {
                if (value.isConfirmed) {
                    props.handleClickApplyAll(formValues);
                }
            });
        }
    };

    return (
        <React.Fragment>
            <div className="flex-1 gap-2">
                <span className="text-xs font-normal text-[#333333]">Company</span>
                <Select
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: (base: any) => ({ ...base, zIndex: 9999 }) }}
                    className="dropdown metadata-group-font create-select"
                    options={getAvailableCompanies()}
                    onChange={handleChangeCompany}
                    isClearable
                />

                <div className="flex flex-row gap-1">
                    <div className="flex-1">
                        <span className="text-xs font-normal text-[#333333]">Document type</span>

                        <Select
                            isDisabled={documentTypeOptions.length === 0}
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: (base: any) => ({ ...base, zIndex: 9999 }) }}
                            className="dropdown metadata-group-font create-select"
                            options={documentTypeOptions}
                            onChange={handleChangeDocumentType}
                            isClearable
                        />
                    </div>

                    <div className="flex-1">
                        <span className="text-xs font-normal text-[#333333]">Fiscal year</span>

                        <div className="flex h-8 flex-1 flex-col [&>*]:w-full">
                            <DatePicker
                                wrapperClassName="w-full"
                                selected={formValues.fiscalYear}
                                onChange={handleChangeYear}
                                customInput={<Input className="menu-form custom-datepicker h-8" />}
                                showYearPicker
                                dateFormat="yyyy"
                                placeholderText="YYYY"
                                isClearable
                            />
                        </div>
                    </div>
                </div>

                <span className="text-xs font-normal text-[#333333]">Document No.</span>
                <Input
                    type="text"
                    className="h-8"
                    value={formValues.documentNo}
                    onChange={event => handleChangeFormValue('documentNo', event.target.value)}
                />

                <div className="mt-2 flex justify-end p-1">
                    <Button
                        variant={'outline'}
                        onClick={handleClickApplyAll}
                        className="h-9 border-[#28A745] p-4"
                    >
                        <span className="px-3.5 text-sm font-medium text-[#28A745]">Apply All</span>
                    </Button>
                </div>
            </div>
        </React.Fragment>
    );
}

export default F1ConnectForm;
