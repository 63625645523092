import * as React from "react";
import { Accordion, Button, Card, Col, Form, Row } from "react-bootstrap";
import { FaAngleDown, FaSearch } from "react-icons/fa";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

export interface optionValue {
    value: string
    label: string
}

export interface searchValue {
    wfNo: string,
    docAmount: string,
    docAmountTo: string,
    requestor: string,
    createBy: string,
    createByTo: string,
}

interface State {
    username: string;
    rolesRaw: string;
}

const customStyles = {
    multiValueRemove: (base, state) => {
        return state.data.isFixed ? { ...base, display: 'none', zIndex: 'auto' } : base;
    },
    menu: (provided, state) => ({
        ...provided,
        zIndex: 9999,
    }),
}

class EmployeeMasterSearchComponent extends React.Component<any, State> {

    constructor(props: any) {
        super(props)

        this.state = {
            username: '',
            rolesRaw: ''
        };
    }

    handleValueSelectAdd = (newValue: any, actionMeta: any) => {
        this.props.handleSelectSearch(newValue)
    };

    private handleChangeRole = (newRoles: any, actionMeta: any) => {
        const rolesRaw = newRoles.map((role: any) => role.value).join('|');

        this.setState({ rolesRaw });
    };

    handleSearchValue = () => {
        this.props.handleSearchValue(this.state.username, this.state.rolesRaw);
    };


    render() {
        const { roleList } = this.props;

        return (
            <div>

                <Card.Body className=" mat-box shadow" style={{ overflow: 'none', borderColor: '#e5e6e9 #dfe0e4 #d0d1d5', borderStyle: 'solid', borderWidth: '1px' }}>
                    <Row>
                        <Col xs={2}  >
                            <Form.Group className="mb-3" controlId="userName">
                                <Form.Label>User Name :</Form.Label>
                                <Form.Control type="text" placeholder="Enter User Name" value={this.state.username} onChange={(event: any) => this.setState({ username: event.target.value })} />
                            </Form.Group>
                        </Col>
                        <Col xs={5}>
                            <Form.Group className="mb-3" controlId="formSelect">
                                <Form.Label>บริษัท :</Form.Label>
                                <div >
                                    <Select
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                        className="dropdown metadata-group-font"
                                        placeholder="เลือก บริษัท"
                                        options={this.props.valueSelect}
                                        onChange={this.handleValueSelectAdd}
                                        isClearable
                                    />
                                </div>
                            </Form.Group>
                        </Col>
                        <Col xs={5}>
                            <Form.Group className="mb-3" controlId="roleAccess">
                                <Form.Label>Role Access :</Form.Label>
                                <CreatableSelect
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    isMulti
                                    isClearable
                                    onChange={(value: string, action: any) => this.handleChangeRole(value, action)}
                                    options={roleList.map((role: { id: string, name: string }) => ({ value: role.id, label: role.name }))}
                                />
                            </Form.Group>
                        </Col>

                    </Row>
                    <Row>
                        <Col>
                            <Button style={{ marginBottom: '10px', marginRight: '10px', float: 'right' }} variant="outline-success" size="sm" onClick={this.handleSearchValue} >
                                <FaSearch />&nbsp;ค้นหา
                            </Button>
                        </Col>
                    </Row>
                </Card.Body>
            </div>
        )
    }

}

export default EmployeeMasterSearchComponent