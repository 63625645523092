import React, { PropsWithChildren } from 'react';
import { InputProps } from 'reactstrap';

import { Input } from 'common/materials';

interface Props extends Omit<InputProps, 'onChange'> {
    showLabel?: boolean;
    showPlaceholder?: boolean;
    span?: number;
    valuePair: { key: string; value: string };

    onChange?(key: string, value: string): void;
}

interface State {}

class KeyValuePairInput extends React.Component<PropsWithChildren<Props>, State> {
    constructor(props: Props) {
        super(props);

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    public handleInputChange(type: 'key' | 'value') {
        const { onChange: onChangeCb } = this.props;

        return (event: React.ChangeEvent<HTMLInputElement>) => {
            event.persist();
            const value = event.target.value;

            const valuePairParams = { ...this.props.valuePair };
            valuePairParams[type] = value;

            if (onChangeCb !== undefined) {
                onChangeCb(valuePairParams.key, valuePairParams.value);
            }
        };
    }

    public render(): React.ReactNode {
        const { span, showLabel, showPlaceholder, bsSize, valuePair } = this.props;

        return (
            <React.Fragment>
                <div className="flex-1 gap-1">
                    {showLabel && <span className="text-xs font-normal text-[#333333]">Key</span>}
                    <Input
                        id="key"
                        name="key"
                        placeholder={showPlaceholder ? 'Key' : ''}
                        type="text"
                        // bsSize={bsSize}
                        value={valuePair.key}
                        onChange={this.handleInputChange('key')}
                    />
                </div>
                <div className="flex-1 gap-1">
                    {showLabel && <span className="text-xs font-normal text-[#333333]">Value</span>}
                    <Input
                        id="value"
                        name="value"
                        placeholder={showPlaceholder ? 'Value' : ''}
                        type="text"
                        // bsSize={bsSize}
                        value={valuePair.value}
                        onChange={this.handleInputChange('value')}
                    />
                </div>
            </React.Fragment>
        );
    }
}

export default KeyValuePairInput;
