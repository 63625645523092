import * as React from 'react';
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableHead from '@material-ui/core/TableHead';

import imgFile from '../../Images/Icon/icon-File.png';
import imgPDF from '../../Images/Icon/icon-PDF.png';
import imgXLS from '../../Images/Icon/icon-XLS.png';
import imgWord from '../../Images/Icon/icon-Word.png';
import imgPowerPoint from '../../Images/Icon/icon-PowerPoint.png';
import imgImage from '../../Images/Icon/icon-image.png';

import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { onClickTr, formatYMDToDMY } from '../../modules/common';

import { Link } from 'react-router-dom';
import { Badge, Spinner } from 'reactstrap';
import { Button } from 'reactstrap';

import * as Models from '../../models/File';
import { IUserContext, UserContext } from '../../context/UserProvider';
import Swal from 'sweetalert2';
import { ApplicationState } from '../../store';
import * as FolderAuthorizeUserMappingStore from '../../store/FolderAuthorizeUserMapping';
import { ButtonStatesType } from '../../store/ui/Role';
import { FolderAuthorizeUserMappingStateStore } from '../../models/FolderAuthorizeUserMapping';

interface ComponentProps {
    [key: string]: any;
    getValueFileList: (_?: string) => void;
}

type Props = ComponentProps &
    FolderAuthorizeUserMappingStateStore &
    typeof FolderAuthorizeUserMappingStore.actionCreators;

interface State {
    modelList: any;
    rowsPerPage: number;
    page: number;
}

class TablePaginationComponent extends React.PureComponent<Props, State> {
    public static contextType: React.Context<any> | undefined = UserContext;

    public context!: React.ContextType<typeof UserContext>;

    public fileRefs: React.RefObject<HTMLInputElement[]>;
    public roleMap = {
        '01': 'Upload',
        '02': 'Edit',
        '03': 'View',
        '04': 'Delete',
        '05': 'Copy Link',
        '06': 'Move Link',
        '99': 'Super Admin',
        '00': 'Admin',
        '51': 'Display Update Box ID',
        '52': 'Edit Update Box ID'
    };

    constructor(props: any) {
        super(props);

        this.state = {
            page: 0,
            rowsPerPage: 5,
            modelList: []
        };

        this.fileRefs = React.createRef<HTMLInputElement[]>();
        this.handleCheckFile = this.handleCheckFile.bind(this);
        this.handleChangeAll = this.handleChangeAll.bind(this);
        this.handleChangeGroup = this.handleChangeGroup.bind(this);
        this.handleChangeSubGroup = this.handleChangeSubGroup.bind(this);
    }

    handleChangeAll(evt) {
        var group = 'group';
        var sub_group = 'sub_group';
        var node = 'node';
        if (evt.target.checked === true) {
            Array.from(
                document.querySelectorAll(
                    'input[name=' +
                        group +
                        ']:not([disabled]), input[name=' +
                        sub_group +
                        ']:not([disabled]), input[name=' +
                        node +
                        ']:not([disabled])'
                )
            ).forEach(checkbox => {
                var cb = document.getElementById(checkbox.id) as HTMLInputElement;
                if (cb) {
                    cb.checked = true;
                }
            });
        } else {
            Array.from(
                document.querySelectorAll(
                    'input[name=' +
                        group +
                        ']:not([disabled]), input[name=' +
                        sub_group +
                        ']:not([disabled]), input[name=' +
                        node +
                        ']:not([disabled])'
                )
            ).forEach(checkbox => {
                var cb = document.getElementById(checkbox.id) as HTMLInputElement;
                if (cb) {
                    cb.checked = false;
                }
            });
        }

        this.props.getValueFileList();
    }

    handleChangeGroup(evt) {
        var sub_group = 'sub_group';
        var name = 'node';
        if (evt.target.checked === true) {
            Array.from(
                document.querySelectorAll(
                    'input[name=' +
                        sub_group +
                        "][id^='" +
                        evt.target.id +
                        "-']:not([disabled]), input[name=" +
                        name +
                        "][id^='" +
                        evt.target.id +
                        "-']:not([disabled])"
                )
            ).forEach(checkbox => {
                var cb = document.getElementById(checkbox.id) as HTMLInputElement;
                if (cb) {
                    cb.checked = true;
                }
            });
        } else {
            Array.from(
                document.querySelectorAll(
                    'input[name=' +
                        sub_group +
                        "][id^='" +
                        evt.target.id +
                        "-']:not([disabled]), input[name=" +
                        name +
                        "][id^='" +
                        evt.target.id +
                        "-']:not([disabled])"
                )
            ).forEach(checkbox => {
                var cb = document.getElementById(checkbox.id) as HTMLInputElement;
                if (cb) {
                    cb.checked = false;
                }
            });
        }

        this.props.getValueFileList();
    }

    handleChangeSubGroup(evt) {
        var name = 'node';
        if (evt.target.checked === true) {
            Array.from(
                document.querySelectorAll(
                    'input[name=' + name + "][id^='" + evt.target.id + "-']:not([disabled])"
                )
            ).forEach(checkbox => {
                var cb = document.getElementById(checkbox.id) as HTMLInputElement;
                if (cb) {
                    cb.checked = true;
                }
            });
        } else {
            Array.from(
                document.querySelectorAll(
                    'input[name=' + name + "][id^='" + evt.target.id + "-']:not([disabled])"
                )
            ).forEach(checkbox => {
                var cb = document.getElementById(checkbox.id) as HTMLInputElement;
                if (cb) {
                    cb.checked = false;
                }
            });
        }

        this.props.getValueFileList();
    }

    public handleCheckFile(): void {
        this.props.getValueFileList();
    }

    private highlight(name, search, id) {
        const parts = name.split(new RegExp(`(${search})`, 'gi'));
        return (
            <span
                key={id}
                data-filename={name}
                data-id={id}
            >
                {parts.map(part =>
                    part.toLowerCase() === search.toLowerCase() ? (
                        <b
                            data-filename={name}
                            data-id={id}
                            style={{ backgroundColor: 'yellow' }}
                        >
                            {part}
                        </b>
                    ) : (
                        part
                    )
                )}
            </span>
        );
    }

    private previewFile(item: Models.File) {
        if (item.file_name.indexOf('.jpg') !== -1 || item.file_name.indexOf('.png') !== -1) {
            return imgImage;
        } else if (item.file_name.indexOf('.pdf') !== -1) {
            return imgPDF;
        } else if (
            item.file_name.indexOf('.xls') !== -1 ||
            item.file_name.indexOf('.xlsx') !== -1
        ) {
            return imgXLS;
        } else if (
            item.file_name.indexOf('.doc') !== -1 ||
            item.file_name.indexOf('.docx') !== -1
        ) {
            return imgWord;
        } else if (
            item.file_name.indexOf('.ppt') !== -1 ||
            item.file_name.indexOf('.pptx') !== -1
        ) {
            return imgPowerPoint;
        } else {
            return imgFile;
        }
    }

    componentDidUpdate() {
        const { modelListFile } = this.props;
        if (modelListFile.length > 0) {
            this.setState({
                modelList: modelListFile[0]
            });
        }
    }

    convertCompanyName = (name: any) => {
        const { company } = this.props;
        var company_name: string = '';
        for (var i = 0; i < company.result_list.length; i++) {
            var data: any = company.result_list[i];
            if (data.sid === name) {
                company_name = data.company_name;
            }
        }
        return company_name;
    };

    // Check role access before open a file
    public handleBeforeOpenFile(indexString: string, item: any) {
        // const companyCode = indexString.split('|')[0];
        return (event: React.MouseEvent<HTMLTableDataCellElement, MouseEvent>) => {
            event.persist();

            const { id: username }: IUserContext = this.context;
            const { disabledDisplay, onSelectFolder } = this.props;

            if (!disabledDisplay) {
                // onSelectFolder({ sid: item.sid, nodeCode: item.bucket, username }, (buttonStates: ButtonStatesType) => {
                //     console.log('ngame buttons', buttonStates);
                // });
                onClickTr(event);
            } else {
                Swal.fire({
                    title: 'คุณไม่มีสิทธิ์การดูไฟล์นี้',
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'ตกลง'
                });
            }
            //try {
            //    const { availableCompanies, list_company }: IUserContext = this.context;

            //    // Don't forget to remove first if statement, We don't need it anymore
            //    const foundAvailableCompany = availableCompanies.find(availableCompany => availableCompany.company_code === companyCode);

            //    if (foundAvailableCompany !== undefined) {
            //        const foundCompany = list_company.find(company => company.sid === foundAvailableCompany.sid);

            //        if (foundCompany !== undefined) {
            //            const roles = foundCompany.role_access.split('|');

            //            if (roles.includes('03')) {
            //                onClickTr(event);
            //            } else {
            //                throw null;
            //            }
            //        } else {
            //            throw null;
            //        }
            //    } else {
            //        throw null;
            //    }
            //} catch (_error) {
            //    // console.log('ngame you cannot access this file!');

            //    Swal.fire({
            //        title: 'คุณไม่มีสิทธิ์การดูไฟล์นี้',
            //        icon: 'error',
            //        showCancelButton: false,
            //        confirmButtonColor: '#3085d6',
            //        confirmButtonText: 'ตกลง'
            //    });
            //}
        };
    }

    public getFileRoles(bucket: string, options?: { idOnly?: boolean }): string[] {
        const { responseFAUM } = this.props;

        if (responseFAUM === undefined || responseFAUM === null || responseFAUM.total_count === 0) {
            return [];
        }

        const FAUMs = responseFAUM.result_list;
        const foundFAUM = FAUMs.find(faum => faum.roleId.includes(bucket));

        if (foundFAUM !== undefined) {
            const roles = foundFAUM.roleId
                .split('ID')[1]
                .split('|')
                .filter(role => role !== '01');

            return options && options.idOnly ? roles : roles.map(role => this.roleMap[role] || '');
        } else {
            return [];
        }
    }

    render() {
        const { valueModelFile, modelListFile, isNewLoad, checkValueFile, SearchData } = this.props;

        if (checkValueFile === false) {
            this.setState({
                modelList: modelListFile[0]
            });
        }

        const isFromSearch = SearchData !== undefined;

        return (
            <TableContainer component={Paper}>
                <Table
                    className="custom-table table-hover table"
                    stickyHeader
                    aria-label="sticky table"
                >
                    <TableHead>
                        <TableRow>
                            <th
                                className="td-select td-left"
                                style={{ width: '120px' }}
                            >
                                <input
                                    type="checkbox"
                                    name="all"
                                    onChange={this.handleChangeAll}
                                />
                            </th>
                            <th
                                className="td-icon th-center"
                                style={{ width: '90px' }}
                            >
                                <img
                                    src={imgFile}
                                    alt="file"
                                    width="30"
                                    height="30"
                                />
                            </th>
                            <th>Name</th>
                            {isFromSearch && SearchData !== '' && <th>Path</th>}
                            <th className="td-detail th-center">Company</th>
                            <th className="td-detail th-center">Detail</th>
                            <th className="td-meta-data th-center">Metadata</th>
                            <th className="td-update-on">Modified</th>
                            <th
                                className="td-update-by"
                                style={{ width: '180px' }}
                            >
                                Modified By
                            </th>
                            <th className="td-verify th-center">Verify</th>
                            <th className="td-version th-center">Last Version</th>
                            <th className="td-action th-center"></th>
                        </TableRow>
                    </TableHead>

                    {isNewLoad ? (
                        <TableBody>
                            <tr>
                                <td
                                    colSpan={100}
                                    style={{
                                        background: '#87C4DA',
                                        color: 'black',
                                        fontSize: '10pt',
                                        textAlign: 'center',
                                        padding: '8px 12px 3px 12px'
                                    }}
                                >
                                    <Spinner color="primary" />
                                </td>
                            </tr>
                        </TableBody>
                    ) : null}
                    {modelListFile.length === 0 && !isNewLoad ? (
                        <TableBody>
                            <tr>
                                <td
                                    colSpan={100}
                                    style={{
                                        background: '#b7b7a4',
                                        color: 'black',
                                        fontSize: '10pt',
                                        textAlign: 'center'
                                    }}
                                >
                                    No data...
                                </td>
                            </tr>
                        </TableBody>
                    ) : modelListFile[0] && modelListFile[0].length === 0 && !isNewLoad ? (
                        <TableBody>
                            <tr>
                                <td
                                    colSpan={100}
                                    style={{
                                        background: '#b7b7a4',
                                        color: 'black',
                                        fontSize: '10pt',
                                        textAlign: 'center'
                                    }}
                                >
                                    No data...
                                </td>
                            </tr>
                        </TableBody>
                    ) : null}
                    {modelListFile.length > 0 &&
                        (isFromSearch ? this.props.responseFAUM !== null : true) &&
                        modelListFile[0].map((items: any, index: any) => (
                            <TableBody>
                                <tr
                                    key={index}
                                    style={{ height: '37px', background: '#5FAECD' }}
                                >
                                    <td className="td-center">
                                        <input
                                            style={{ marginLeft: -40 }}
                                            type="checkbox"
                                            id={index}
                                            name="group"
                                            onChange={this.handleChangeGroup}
                                        />
                                    </td>
                                    <td
                                        colSpan={100}
                                        style={{ fontWeight: 'bold' }}
                                    >
                                        ({items.count_group_id}) Group ID: {items.group_id}
                                    </td>
                                </tr>
                                {items.sub_group_detail.map((item, id) => (
                                    <React.Fragment>
                                        <tr
                                            key={index + '-' + id}
                                            style={{ height: '37px', background: '#9FCFE1' }}
                                        >
                                            <td className="td-center">
                                                <input
                                                    style={{ marginLeft: 40 }}
                                                    id={index + '-' + id}
                                                    type="checkbox"
                                                    name="sub_group"
                                                    onChange={this.handleChangeSubGroup}
                                                />
                                            </td>
                                            <td
                                                colSpan={100}
                                                style={{ fontWeight: 'bold' }}
                                            >
                                                ({item.count_sub_group_id}) Sub Group ID:{' '}
                                                {item.sub_group_id}
                                            </td>
                                        </tr>
                                        {item.sub_group_id_detail.map(
                                            (itemSub: Models.File, idSub: number) => {
                                                itemSub.roles = this.getFileRoles(itemSub.bucket, {
                                                    idOnly: true
                                                });
                                                const fileData = JSON.stringify(itemSub);

                                                return (
                                                    <tr key={index + '-' + id + '-' + idSub}>
                                                        <td style={{ textAlignLast: 'right' }}>
                                                            <input
                                                                disabled={
                                                                    isFromSearch &&
                                                                    itemSub.roles.length === 0
                                                                }
                                                                id={index + '-' + id + '-' + idSub}
                                                                type="checkbox"
                                                                name="node"
                                                                // data-filelist={JSON.stringify(itemSub)}
                                                                data-filelist={fileData}
                                                                onChange={this.handleCheckFile}
                                                            />
                                                        </td>
                                                        <td
                                                            className="td-icon td-center"
                                                            style={{ width: '90px' }}
                                                        >
                                                            <img
                                                                src={this.previewFile(itemSub)}
                                                                alt="file"
                                                                width="30"
                                                                height="30"
                                                            />
                                                        </td>

                                                        {SearchData !== undefined &&
                                                        SearchData !== '' ? (
                                                            <td
                                                                data-filename={itemSub.file_name}
                                                                data-id={itemSub.id}
                                                                onClick={this.handleBeforeOpenFile(
                                                                    itemSub.index_string,
                                                                    itemSub
                                                                )}
                                                                className="td-file-name"
                                                            >
                                                                {this.highlight(
                                                                    itemSub.file_name,
                                                                    SearchData,
                                                                    itemSub.id
                                                                )}
                                                            </td>
                                                        ) : (
                                                            <td
                                                                data-filename={itemSub.file_name}
                                                                data-id={itemSub.id}
                                                                onClick={this.handleBeforeOpenFile(
                                                                    itemSub.index_string,
                                                                    itemSub
                                                                )}
                                                                className="td-file-name"
                                                            >
                                                                <span
                                                                    data-filename={
                                                                        itemSub.file_name
                                                                    }
                                                                    data-id={itemSub.id}
                                                                >
                                                                    {itemSub.file_name}
                                                                </span>
                                                            </td>
                                                        )}

                                                        {SearchData !== undefined &&
                                                            SearchData !== '' && (
                                                                <td style={{ width: 500 }}>
                                                                    <div>
                                                                        <span
                                                                            style={{
                                                                                display: 'block',
                                                                                flex: 1
                                                                            }}
                                                                        >
                                                                            {itemSub.full_path}
                                                                        </span>
                                                                        <span>
                                                                            {this.getFileRoles(
                                                                                itemSub.bucket
                                                                            ).map(role => {
                                                                                const lowerCaseRole =
                                                                                    role.toLowerCase();
                                                                                const color =
                                                                                    lowerCaseRole.includes(
                                                                                        'create'
                                                                                    )
                                                                                        ? 'warning'
                                                                                        : lowerCaseRole.includes(
                                                                                              'change'
                                                                                          ) ||
                                                                                          lowerCaseRole.includes(
                                                                                              'move'
                                                                                          ) ||
                                                                                          lowerCaseRole.includes(
                                                                                              'copy'
                                                                                          )
                                                                                        ? 'info'
                                                                                        : lowerCaseRole.includes(
                                                                                              'delete'
                                                                                          )
                                                                                        ? 'danger'
                                                                                        : 'primary';

                                                                                return (
                                                                                    <Badge
                                                                                        color={
                                                                                            color
                                                                                        }
                                                                                        className="mr-1 p-1"
                                                                                    >
                                                                                        {role}
                                                                                    </Badge>
                                                                                );
                                                                            })}
                                                                        </span>
                                                                    </div>
                                                                </td>
                                                            )}
                                                        <td>
                                                            {this.convertCompanyName(itemSub.sid)}
                                                        </td>
                                                        {valueModelFile !== undefined ? (
                                                            <td
                                                                className="td-detail td-center"
                                                                style={{ width: 100 }}
                                                            >
                                                                <Button
                                                                    outline
                                                                    style={{ border: '0px' }}
                                                                    data-id={itemSub.id}
                                                                    onClick={e =>
                                                                        this.props.onDetailClick(
                                                                            e,
                                                                            index,
                                                                            id,
                                                                            idSub
                                                                        )
                                                                    }
                                                                >
                                                                    <i className="fas fa-list-ul"></i>
                                                                </Button>
                                                            </td>
                                                        ) : (
                                                            <td className="td-detail td-center">
                                                                <Button
                                                                    outline
                                                                    style={{ border: '0px' }}
                                                                    data-id={itemSub.id}
                                                                    onClick={e =>
                                                                        this.props.onDetailClick(
                                                                            e,
                                                                            index,
                                                                            id,
                                                                            idSub
                                                                        )
                                                                    }
                                                                >
                                                                    <i className="fas fa-list-ul"></i>
                                                                </Button>
                                                            </td>
                                                        )}
                                                        {valueModelFile !== undefined ? (
                                                            <td
                                                                className="td-meta-data td-center"
                                                                style={{ width: 100 }}
                                                            >
                                                                <Button
                                                                    outline
                                                                    style={{ border: '0px' }}
                                                                    data-id={itemSub.id}
                                                                    onClick={e =>
                                                                        this.props.onMetaDataClick(
                                                                            e,
                                                                            index,
                                                                            id,
                                                                            idSub
                                                                        )
                                                                    }
                                                                >
                                                                    <i className="far fa-eye"></i>
                                                                </Button>
                                                            </td>
                                                        ) : (
                                                            <td className="td-meta-data td-center">
                                                                <Button
                                                                    outline
                                                                    style={{ border: '0px' }}
                                                                    data-id={itemSub.id}
                                                                    onClick={e =>
                                                                        this.props.onMetaDataClick(
                                                                            e,
                                                                            index,
                                                                            id,
                                                                            idSub
                                                                        )
                                                                    }
                                                                >
                                                                    <i className="far fa-eye"></i>
                                                                </Button>
                                                            </td>
                                                        )}
                                                        {valueModelFile !== undefined ? (
                                                            //<td className="td-update-on" style={{ width: 120 }}>{(formatYMDToDMY(itemSub.created_on.substring(0, 10)))}</td>
                                                            //: <td className="td-update-on" >{(formatYMDToDMY(itemSub.created_on.substring(0, 10)))}</td>
                                                            <td
                                                                className="td-update-on"
                                                                style={{ width: 120 }}
                                                            >
                                                                {formatYMDToDMY(itemSub.created_on)}
                                                            </td>
                                                        ) : (
                                                            <td className="td-update-on">
                                                                {formatYMDToDMY(itemSub.created_on)}
                                                            </td>
                                                        )}
                                                        {valueModelFile !== undefined ? (
                                                            <td
                                                                className="td-update-by"
                                                                style={{ width: 100 }}
                                                            >
                                                                {itemSub.created_by}
                                                            </td>
                                                        ) : (
                                                            <td
                                                                className="td-update-by"
                                                                style={{ width: '180px' }}
                                                            >
                                                                {itemSub.created_by}
                                                            </td>
                                                        )}
                                                        {valueModelFile !== undefined ? (
                                                            <td
                                                                className="td-verify td-center"
                                                                style={{ width: 70 }}
                                                            >
                                                                <CancelIcon
                                                                    className="td-icon-close"
                                                                    style={{ color: 'red' }}
                                                                />
                                                            </td>
                                                        ) : itemSub.verify === true ? (
                                                            <td className="td-verify td-center">
                                                                <CheckCircleIcon
                                                                    className="td-icon-close"
                                                                    style={{ color: 'green' }}
                                                                />
                                                            </td>
                                                        ) : (
                                                            <td className="td-verify td-center">
                                                                <CancelIcon
                                                                    className="td-icon-close"
                                                                    style={{ color: 'red' }}
                                                                />
                                                            </td>
                                                        )}
                                                        {valueModelFile !== undefined ? (
                                                            <td
                                                                className="td-version td-center"
                                                                style={{ width: 150 }}
                                                            >
                                                                <Link
                                                                    to={
                                                                        '/dms/file-version/' +
                                                                        encodeURIComponent(
                                                                            itemSub.primary_index
                                                                        )
                                                                    }
                                                                    target="_blank"
                                                                >
                                                                    {itemSub.version}
                                                                </Link>
                                                            </td>
                                                        ) : (
                                                            <td className="td-version td-center">
                                                                <Link
                                                                    to={
                                                                        '/dms/file-version/' +
                                                                        encodeURIComponent(
                                                                            itemSub.primary_index
                                                                        )
                                                                    }
                                                                    target="_blank"
                                                                >
                                                                    {itemSub.version}
                                                                </Link>
                                                            </td>
                                                        )}
                                                        {valueModelFile !== undefined ? (
                                                            <td
                                                                className="td-version td-center"
                                                                style={{ width: 150 }}
                                                            >
                                                                <Link
                                                                    to={`/dms/file-explorer/log?p_id=${itemSub.primary_index}`}
                                                                    target="_blank"
                                                                >
                                                                    Logs
                                                                </Link>
                                                            </td>
                                                        ) : (
                                                            <td className="td-version td-center">
                                                                <Link
                                                                    to={`/dms/file-explorer/log?p_id=${itemSub.primary_index}`}
                                                                    target="_blank"
                                                                >
                                                                    Logs
                                                                </Link>
                                                            </td>
                                                        )}
                                                    </tr>
                                                );
                                            }
                                        )}
                                    </React.Fragment>
                                ))}
                            </TableBody>
                        ))}
                </Table>
            </TableContainer>
        );
    }
}

export default connect((state: ApplicationState) => ({ ...state.folderAuthorizeUserMapping }), {
    ...FolderAuthorizeUserMappingStore.actionCreators
})(TablePaginationComponent);
