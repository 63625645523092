import axios from 'axios';

import { ResponseMessage } from 'models/domain/Response';
import { Environment } from 'models/domain/App';

export async function requestAppVersion() {
    return axios.get<ResponseMessage>('/v1/version');
}

export async function requestEnv() {
    return axios.get<Environment>('/v1/env');
}
