import { useEffect, useState } from 'react';

import { AddFodlerProps } from './AddFolder.view';

function AddFolderViewModel({ isSave, afterSuccess }: AddFodlerProps) {
    const [folderName, setFolderName] = useState('');

    useEffect(() => {
        if (isSave) console.log('Save!');
    }, [isSave]);

    const handleAddFolderName = (folderName: string) => {
        setFolderName(folderName);
    };

    return { handleAddFolderName, folderName };
}

export default AddFolderViewModel;
