import React from 'react';
import { connect } from 'react-redux';
import { Badge, Button, Card, Col, Form, Row, Spinner, Table } from 'react-bootstrap';
import CreatableSelect from 'react-select/creatable';
import Swal from 'sweetalert2';

import { ApplicationState } from '../../../store';
import * as AuthorizeStore from '../../../store/Authorize';
import {
    AuthorizeStateStore,
    DisplayAuthorize,
    DefaultRoleAuthorize
} from '../../../models/Authorize';
import * as FolderAuthorizeStore from '../../../store/FolderAuthorize';
import { FolderAuthorize, FolderAuthorizeStateStore } from '../../../models/FolderAuthorize';
import * as EmployeeMasterStore from '../../../store/EmployeeMaster';

import {
    FolderAuthorizeUserMappingResponse,
    UserAuthMappingFolderTreeCheckBox,
    RequestSaveFolderAuthorizeBody
} from '../../../models/FolderAuthorizeUserMapping';

import * as CompanyMasterStore from '../../../store/CompanyMaster';
import { CompanyMasterState, DisplayCompany } from '../../../models/CompanyMaster';

import FoldersComponentForUserFolderAuthorization from './FoldersComponentForUserFolderAuthorization';
import { EmployeeState, DisplayEmployee } from '../../../models/Employee';
import { withRemounter, WithRemounterProps } from '../../common/hoc/withRemounter';

//import { ResponseList, StateStore } from '../../../models/Common';

import './UserFolderAuthorization.css';
import * as XLSX from 'xlsx';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { AdminContext, AdminContextType } from '../../../context/AdminProvider';
import { UserRoleAccessModel } from '../../../models/RoleAccess';

interface OwnProps {
    authorize: AuthorizeStateStore;
    folderAuthorize: FolderAuthorizeStateStore;
    employee: EmployeeState;
    company: CompanyMasterState;
}

type Props = OwnProps &
    typeof AuthorizeStore.actionCreators &
    typeof FolderAuthorizeStore.actionCreators &
    typeof EmployeeMasterStore.actionCreators &
    typeof CompanyMasterStore.actionCreators &
    WithRemounterProps;

interface State {
    node_name: string;
    node_code: string;
    role_id: string;
    auth_ids: string;
    username: string[];
    selectedCompany: string[];
    selectedCompanyExport: string[];
    folderAuthByCompany: FolderAuthorize[];
    filterUser: DisplayEmployee[];
    userFolderAuthMapping: UserAuthMappingFolderTreeCheckBox[];
    userFolderAuthExport: UserAuthMappingFolderTreeCheckBox[];
    openModalExportExcel: boolean;
    isLoading: boolean;
    selectedCompanyShortName: string;
    userRole: UserRoleAccessModel[];
    companyOptions: DisplayCompany[];
    changedFolderAuths: RequestSaveFolderAuthorizeBody;
}

interface FolderAuthCompany {
    short_name: string;
    company_code: string;
    company_name: string;
}

class UserFolderAuthorization extends React.Component<Props, State> {
    static contextType: React.Context<any> | undefined = AdminContext;

    public context!: React.ContextType<typeof AdminContext>;

    constructor(props: Props) {
        super(props);

        this.state = {
            node_name: '',
            node_code: '',
            role_id: '',
            auth_ids: '',
            username: [],
            selectedCompany: [],
            selectedCompanyExport: [],
            folderAuthByCompany: [],
            filterUser: [],
            userFolderAuthMapping: [],
            userFolderAuthExport: [],
            openModalExportExcel: false,
            isLoading: false,
            selectedCompanyShortName: '',
            userRole: [],
            companyOptions: [],
            changedFolderAuths: {
                username: '',
                node_list: []
            }
        };

        this.handleChangeState = this.handleChangeState.bind(this);
        this.handleSelectFolder = this.handleSelectFolder.bind(this);
        this.handleSelectUser = this.handleSelectUser.bind(this);
        this.handleSelectRoleAccess = this.handleSelectRoleAccess.bind(this);
        this.handleSelectCompany = this.handleSelectCompany.bind(this);
        this.filterUserBySid = this.filterUserBySid.bind(this);
        this.findSidFromCompany = this.findSidFromCompany.bind(this);
        this.changeFolderUserAuth = this.changeFolderUserAuth.bind(this);
        this.saveFolderAuthorize = this.saveFolderAuthorize.bind(this);
        this.onToggleModal = this.onToggleModal.bind(this);
        this.toggleIsLoading = this.toggleIsLoading.bind(this);
    }

    public componentDidMount(): void {
        this.props.requestGetAuthorize();
        this.props.requestFunction(
            0,
            'GET',
            { id: '', list_company: [] },
            false,
            null,
            '',
            '',
            '',
            '',
            ''
        );
        this.props.requestFunctionCompanyMaster(true, 'GET', '', '', '');
    }

    public componentDidUpdate() {
        const isLoadingLocal = this.state.isLoading;
        const { userRole, setUserRole }: AdminContextType = this.context;

        if (isLoadingLocal) {
            document.getElementsByClassName('Loader__background')[0].className =
                'Loader__background show';
        } else {
            document.getElementsByClassName('Loader__background')[0].className =
                'Loader__background hide';
        }

        if (userRole.length > 0 && this.state.userRole.length === 0) {
            this.setState({
                userRole
            });
        } else {
            this.handleSetDisplayCompany(this.state.userRole);
        }
    }

    public handleSetDisplayCompany(userRoleAccess: UserRoleAccessModel[]) {
        const { displayCompany } = this.props.company;
        const masterSid = userRoleAccess.find(role => role.sid === '*');

        if (displayCompany && masterSid && this.state.companyOptions.length === 0) {
            this.setState({
                companyOptions: displayCompany
            });
        } else if (
            displayCompany &&
            userRoleAccess.length > 0 &&
            this.state.companyOptions.length === 0
        ) {
            const roleSid = userRoleAccess.map(role => role.sid);
            const filterCompanyByUserRole = displayCompany.filter(company =>
                roleSid.includes(company.sid)
            );

            this.setState({
                companyOptions: filterCompanyByUserRole
            });
        }
    }

    public handleSelectFolder(
        _fullPath: string,
        nodeCode: string,
        nodeName: string,
        _metadata: string
    ): void {
        this.setState({ node_code: nodeCode, node_name: nodeName });
        this.props.requestGetFolderAuthorize({ app_id: '*' });
    }

    public handleSelectUser(employee: DisplayEmployee[]) {
        const { filterUser, selectedCompanyExport } = this.state;
        const { displayEmployee } = this.props.employee;
        const responseCompanyMaster = this.props.company.responseCompanyMaster;

        const selectAll = employee.find(f => f.value === '*');

        let userList: string[] = [];

        if (selectAll && selectedCompanyExport.length > 0 && filterUser.length > 0) {
            userList = filterUser.map(user => user.value).filter(val => val !== '*');
        } else if (selectAll && displayEmployee && displayEmployee.length > 0) {
            userList = displayEmployee.map(user => user.value).filter(val => val !== '*');

            this.getFolderAuthByCompany(
                responseCompanyMaster!.result_list.map(f => ({
                    company_code: f.company_code,
                    company_name: f.company_name,
                    short_name: f.company_short_name
                }))
            );
        } else {
            userList = employee.map(user => user.value);
        }

        this.setState({ username: userList });

        this.getUserFolderAuth(userList, 'ExportExcel');
    }

    public handleSelectUserOnce(employee: DisplayEmployee) {
        this.setState({ username: [employee.value] });
        this.getUserFolderAuth([employee.value]);
    }

    public async getUserFolderAuth(userList: string[], mode: string = '') {
        this.toggleIsLoading();

        const sid = '*';
        const uri = `/v2/folder-auth-user-mapping?app_id=${sid}`;

        const reqBody = {
            username: userList,
            node_code: '',
            role_id: '',
            is_active: true
        };

        const response = await fetch(uri, {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('DMS_APPLICATION'),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(reqBody)
        });

        try {
            const { message, total_count, result_list } =
                (await response.json()) as FolderAuthorizeUserMappingResponse;

            if (!response.ok) {
                throw new Error(message);
            }

            const userFolderAuthList: UserAuthMappingFolderTreeCheckBox[] = [];

            if (result_list !== null && result_list.length > 0) {
                result_list.map(result => {
                    userFolderAuthList.push({
                        auth_ids: result.authIds,
                        node_code: result.nodeCode,
                        role_id: result.roleId,
                        username: result.username
                    });
                });
            }

            if (mode === 'ExportExcel') {
                this.setState({
                    userFolderAuthExport: userFolderAuthList
                });
            } else {
                this.setState({
                    userFolderAuthMapping: userFolderAuthList
                });
            }

            setTimeout(() => this.toggleIsLoading(), 0);
        } catch (error) {
            setTimeout(() => this.toggleIsLoading(), 0);

            Swal.fire('Error', `${(error as any).message}`, 'error');
        }
    }

    public handleSelectCompany(companies: DisplayCompany[], mode: string): void {
        // const companyList = ["J&M"];   // For test
        const companyList = companies.map(company => company.label);

        if (mode === 'ExportExcel') {
            this.setState({
                selectedCompanyExport: companyList
            });
        } else {
            this.setState({
                selectedCompany: companyList,
                selectedCompanyShortName: companies[0].shortName || ''
            });
        }

        const sidList = this.findSidFromCompany(companyList);
        if (sidList !== undefined) {
            this.filterUserBySid(sidList);
        }

        if (companies !== null && companies.length > 0) {
            const companyForGetFolderAuth = companies.map(
                company =>
                    ({
                        short_name: company.shortName,
                        company_code: company.value,
                        company_name: company.label
                    }) as FolderAuthCompany
            );

            this.getFolderAuthByCompany(companyForGetFolderAuth);
        }
    }

    public filterUserBySid(companySidList: string[]) {
        const empResultList = this.props.employee.responseEmployee?.result_list;

        if (empResultList) {
            const userInCompany = empResultList.filter(emp => {
                const foundCompany = emp.list_company.find(company =>
                    companySidList.includes(company.sid)
                );

                return foundCompany ? true : false;
            });

            const foundUserInCompany: DisplayEmployee[] = [];
            foundUserInCompany.push({ label: 'select all user', value: '*' });
            userInCompany.map(user => foundUserInCompany.push({ label: user.id, value: user.id }));

            this.setState(prevState => ({
                filterUser: foundUserInCompany
            }));
        }
    }

    private findSidFromCompany(companyNameArr: string[]): string[] | undefined {
        const companyResultList = this.props.company.responseCompanyMaster?.result_list;

        if (companyResultList === undefined) return;

        const sidArr = companyNameArr.map((companyName, index) => {
            const companyList = companyResultList.filter(
                result => result.company_name === companyName
            );

            return companyList.map(company => company.sid)[0];
        });

        return sidArr;
    }

    public handleSelectRoleAccess(authIdsArr: DisplayAuthorize[]): void {
        const authIds = authIdsArr.map(auth => auth.value).join('|');
        this.setState({ auth_ids: authIds });
    }

    public saveFolderAuthorize() {
        this.toggleIsLoading();

        this.addFolderUserAuth();

        setTimeout(() => this.toggleIsLoading(), 0);
    }

    public async addFolderUserAuth() {
        const changedFolderAuths = { ...this.state.changedFolderAuths };
        changedFolderAuths.username = [...this.state.username][0];
        changedFolderAuths.node_list = changedFolderAuths.node_list.filter(
            node => node.old_role_id !== '' || node.new_role_id !== ''
        );

        const requestBody = JSON.stringify(changedFolderAuths);

        const sid: string = '*';
        const response = await fetch(`/v3/folder-auth?app_id=${sid}`, {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('DMS_APPLICATION'),
                'Content-Type': 'application/json'
            },
            body: requestBody
        });

        try {
            const { message } = await response.json();

            if (!response.ok) {
                throw new Error(message);
            }

            Swal.fire({
                title: 'Success',
                text: 'บันทึกสำเร็จ',
                icon: 'success'
            }).then(result => {
                if (result.isConfirmed) {
                    this.props.remount();
                }
            });
        } catch (error) {
            Swal.fire('Error', `${(error as any).message}`, 'error').then(result => {
                if (result.isConfirmed) {
                    this.props.remount();
                }
            });
        }
    }

    public handleChangeState(
        key: keyof Pick<State, 'node_code' | 'role_id'>
    ): React.ChangeEventHandler<HTMLInputElement> {
        return event => {
            event.persist();
            this.setState(prevState => ({ ...prevState, [key]: event.target.value }));
        };
    }

    // public changeFolderUserAuth(nodeCode: string, authValue: string) {
    //     const userFolderAuthMapping = [...this.state.userFolderAuthMapping];
    //     const nextChangedFolderAuths = { ...this.state.changedFolderAuths };

    //     const foundFolder = userFolderAuthMapping.find(folder => folder.node_code === nodeCode);

    //     if (foundFolder) {
    //         const roles = foundFolder.auth_ids?.split('|')!;
    //         const removePosition = roles!.indexOf(authValue);

    //         if (removePosition > -1) { // If role exist, delete it
    //             roles.splice(removePosition, 1);
    //         } else { // If not, add it
    //             roles.push(authValue);
    //         }

    //         foundFolder.auth_ids = roles.join('|');
    //         foundFolder.hasChange = true;
    //     } else {

    //         userFolderAuthMapping.push({
    //             auth_ids: authValue,
    //             node_code: nodeCode,
    //             hasChange: true
    //         });
    //     }

    //     this.setState({ userFolderAuthMapping });
    // }

    public changeFolderUserAuth(nodeCode: string, authValue: string) {
        const nextUserFolderAuthMapping = [...this.state.userFolderAuthMapping];
        const nextChangedFolderAuths = { ...this.state.changedFolderAuths };
        const foundFolder = [...this.state.userFolderAuthMapping].find(
            folder => folder.node_code === nodeCode
        );

        if (foundFolder) {
            const foundNode = nextChangedFolderAuths.node_list.find(
                node => node.node_code === nodeCode
            );

            const roles = foundFolder.auth_ids?.split('|')!;
            const removePosition = roles!.indexOf(authValue);

            if (removePosition > -1) {
                // If role exist, delete it
                roles.splice(removePosition, 1);
            } else {
                // If not, add it
                roles.push(authValue);
            }

            if (foundNode) {
                foundNode.new_role_id = this.makeRoleId(foundNode.node_code, ...roles);
            } else {
                nextChangedFolderAuths.node_list.push({
                    node_code: foundFolder.node_code!,
                    old_role_id: foundFolder.role_id!,
                    new_role_id: this.makeRoleId(foundFolder.node_code!, ...roles)
                });
            }

            foundFolder.auth_ids = roles.join('|');
            foundFolder.hasChange = true;
        } else {
            nextChangedFolderAuths.node_list.push({
                node_code: nodeCode,
                old_role_id: '',
                new_role_id: this.makeRoleId(nodeCode, authValue)
            });

            nextUserFolderAuthMapping.push({
                auth_ids: authValue,
                node_code: nodeCode,
                hasChange: true
            });
        }

        this.setState({
            changedFolderAuths: nextChangedFolderAuths,
            userFolderAuthMapping: nextUserFolderAuthMapping
        });
    }

    public makeRoleId(nodeCode: string, ...authIds: string[]) {
        return authIds.length > 0 ? `${nodeCode}_ID${authIds.join('|')}` : '';
    }

    public onToggleModal() {
        this.setState({
            openModalExportExcel: !this.state.openModalExportExcel,
            filterUser: []
        });
    }

    public toggleIsLoading(): void {
        this.setState({ isLoading: !this.state.isLoading });
    }

    public exportToExcel = () => {
        type ExportExcelType = {
            Company: string;
            User: string;
            Folder: string;
            Create: string;
            Change: string;
            Display: string;
            Delete: string;
            CopyLink: string;
            MoveLink: string;
        };

        try {
            this.toggleIsLoading();

            const {
                selectedCompanyExport: companiesState,
                username: selectedUser,
                userFolderAuthExport,
                folderAuthByCompany
            } = this.state;

            if (!folderAuthByCompany) {
                throw void 0;
            }

            // Company names
            // const companyNames =
            //     (companiesState.length === 0)
            //         ? selectedUser.reduce<string[]>((accumulate, user) => {
            //             const userDetail = this.props.employee.responseEmployee?.result_list.find(employee => employee.id === user)!;
            //             const companyNames = userDetail.list_company.map(userCompany => {
            //                 const companyMaster = this.props.company.responseCompanyMaster?.result_list.find(company => company.sid === userCompany.sid);

            //                 return (companyMaster) ? companyMaster.company_name : "";
            //             }).filter(company => company !== "");

            //             return [...new Set(accumulate.concat(companyNames))];
            //         }, [])
            //         : companiesState;

            const prepareSheetData = userFolderAuthExport
                .reduce<ExportExcelType[]>((accumulate, userFolder) => {
                    const folder = folderAuthByCompany.find(
                        folder => folder.role_id === userFolder.role_id
                    );

                    if (!folder || !folder.full_path || folder.full_path === '') {
                        return accumulate;
                    }

                    const company = this.props.company.responseCompanyMaster?.result_list.find(
                        company =>
                            company.company_short_name.includes(folder.node_code.split('-')[2])
                    );

                    accumulate.push({
                        Company: company?.company_name || '',
                        User: userFolder.username!,
                        Folder: folder.full_path,
                        Create: folder.auth_ids.includes('01') ? 'Yes' : '',
                        Change: folder.auth_ids.includes('02') ? 'Yes' : '',
                        Display: folder.auth_ids.includes('03') ? 'Yes' : '',
                        Delete: folder.auth_ids.includes('04') ? 'Yes' : '',
                        CopyLink: folder.auth_ids.includes('05') ? 'Yes' : '',
                        MoveLink: folder.auth_ids.includes('06') ? 'Yes' : ''
                    });

                    return accumulate;
                }, [])
                .sort((a, b) => (a.User < b.User ? -1 : a.User > b.User ? 1 : 0))
                .sort((a, b) => (a.Company < b.Company ? -1 : a.Company > b.Company ? 1 : 0));

            // // Group arrays to xlsx format
            // const prepareSheetData = folderAuthByCompany.reduce<ExportExcelType[]>((accumulate, folder) => {
            //     const userFolder = userFolderAuthExport.find(userFolder => userFolder.node_code === folder.node_code);

            //     if (!folder.full_path || folder.full_path === '' || !userFolder) {
            //         return accumulate;
            //     }

            //     accumulate.push({
            //         Company: '',
            //         User: userFolder.username!,
            //         Folder: folder.full_path,
            //         Create: (folder.auth_ids.includes('01')) ? 'Yes' : '',
            //         Change: (folder.auth_ids.includes('02')) ? 'Yes' : '',
            //         Display: (folder.auth_ids.includes('03')) ? 'Yes' : '',
            //         Delete: (folder.auth_ids.includes('04')) ? 'Yes' : '',
            //         CopyLink: (folder.auth_ids.includes('05')) ? 'Yes' : '',
            //         MoveLink: (folder.auth_ids.includes('06')) ? 'Yes' : '',
            //     });

            //     return accumulate;
            // }, []);

            // Assigned company name to sheet data
            // companyNames.forEach((companyName, index) => {
            //     let row = prepareSheetData[index];

            //     if (!row) {
            //         row = {
            //             Company: '',
            //             User: '',
            //             Folder: '',
            //             Create: '',
            //             Change: '',
            //             Display: '',
            //             Delete: '',
            //             CopyLink: '',
            //             MoveLink: ''
            //         };
            //     }

            //     row.Company = companyName;
            // });

            if (selectedUser.length > 0) {
                const date = new Date();
                const fileName = 'FolderUserAuthorization_' + date.getTime().toString() + '.xlsx';
                const worksheet = XLSX.utils.json_to_sheet(prepareSheetData);
                const workbook = XLSX.utils.book_new();

                XLSX.utils.book_append_sheet(workbook, worksheet, 'FolderUserAuthorization');
                XLSX.writeFile(workbook, fileName);
            }

            setTimeout(() => this.toggleIsLoading(), 0);
        } catch {
            setTimeout(() => this.toggleIsLoading(), 0);

            Swal.fire({
                title: 'เกิดข้อผิดพลาด',
                text: 'ไม่มีข้อมูลผู้ใช้นี้',
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน'
            });
        }
    };

    public async getFolderAuthByCompany(companyList: FolderAuthCompany[]) {
        const sid = '*';
        const reqBody = {
            node_code: companyList.map(company => company.short_name)
        };

        // use POST method 'cause query string to long
        // then API response http code 414.
        const response = await fetch('/v1/folder-auth/company?app_id=' + sid, {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('DMS_APPLICATION'),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(reqBody)
        });

        try {
            const { message, result_list } = await response.json(); // as ResponseList<FolderAuthorize>;

            if (!response.ok) {
                throw new Error(message);
            }

            this.setState({
                folderAuthByCompany: result_list as FolderAuthorize[]
            });
        } catch (error) {
            Swal.fire('Error', `${(error as any).message}`, 'error');
        }
    }

    public render(): React.ReactNode {
        const { userFolderAuthMapping } = this.state;
        const folderAuthorize = this.props.folderAuthorize;

        return (
            <React.Fragment>
                <Row>
                    <Col>
                        <div
                            className="trapezoid"
                            style={{ width: 'auto', display: 'inline-block' }}
                        >
                            <span className="employee-font font-size-employee">
                                Folder Authorize
                            </span>
                        </div>
                    </Col>
                    <Col className="d-flex justify-content-end align-items-center">
                        <Button
                            size="sm"
                            className="btn-export-excel mr-2"
                            onClick={this.onToggleModal}
                        >
                            Export Excel
                        </Button>
                        <Button
                            size="sm"
                            onClick={this.saveFolderAuthorize}
                        >
                            Save
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Card.Body className="mat-box shadow">
                            <Row>
                                <Col md={3}>
                                    <Row>
                                        <Col
                                            xs={6}
                                            className="mb-3"
                                        >
                                            <div>
                                                <h6>Company</h6>
                                            </div>
                                        </Col>
                                        <Col xs={12}>
                                            <Form.Group
                                                className="mb-3"
                                                controlId="employee"
                                            >
                                                <CreatableSelect
                                                    menuPortalTarget={document.body}
                                                    styles={{
                                                        menuPortal: base => ({
                                                            ...base,
                                                            zIndex: 9999
                                                        })
                                                    }}
                                                    onChange={company =>
                                                        this.handleSelectCompany([company], '')
                                                    }
                                                    options={this.state.companyOptions}
                                                    isValidNewOption={() => false}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={2}>
                                    <Row>
                                        <Col
                                            xs={6}
                                            className="mb-3"
                                        >
                                            <div>
                                                <h6>User</h6>
                                            </div>
                                        </Col>
                                        <Col xs={12}>
                                            <Form.Group
                                                className="mb-3"
                                                controlId="employee"
                                            >
                                                <CreatableSelect
                                                    menuPortalTarget={document.body}
                                                    styles={{
                                                        menuPortal: base => ({
                                                            ...base,
                                                            zIndex: 9999
                                                        })
                                                    }}
                                                    onChange={(employee: DisplayEmployee) => {
                                                        // this.handleSelectUser(employee)
                                                        this.handleSelectUserOnce(employee);
                                                    }}
                                                    options={this.state.filterUser.filter(
                                                        user => user.value !== '*'
                                                    )}
                                                    // options={this.state.filterUser}
                                                    isValidNewOption={() => false}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={7}>
                                    <div>
                                        <h6>Folder</h6>
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        {DefaultRoleAuthorize.map((auth, index) => {
                                            return (
                                                <label
                                                    key={index}
                                                    htmlFor={auth.label}
                                                    style={{
                                                        border: 'none',
                                                        fontWeight: 'bold',
                                                        marginRight: '18px'
                                                    }}
                                                >
                                                    {auth.label}
                                                </label>
                                            );
                                        })}
                                    </div>
                                    <FoldersComponentForUserFolderAuthorization
                                        onSelectFolder={this.handleSelectFolder}
                                        folderUserAuth={userFolderAuthMapping}
                                        changeFolderUserAuth={this.changeFolderUserAuth}
                                        selectedCompany={this.state.selectedCompanyShortName}
                                        disabledCheckbox={this.state.username.length === 0}
                                    />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Col>
                </Row>

                {/*Show data table*/}
                <Row className="d-none">
                    <Col>
                        <Table
                            className="table-bordered employee-font font-size-employee align-middle"
                            size="sm"
                        >
                            <thead>
                                <tr
                                    style={{ width: '100%' }}
                                    className="table-active thead-light text-center"
                                >
                                    <th
                                        className="td-menu"
                                        style={{
                                            width: '7%',
                                            top: -1,
                                            position: 'sticky',
                                            zIndex: 1
                                        }}
                                    >
                                        SID
                                    </th>
                                    <th
                                        style={{
                                            width: '30%',
                                            position: 'sticky',
                                            top: -1,
                                            zIndex: 1
                                        }}
                                        className="td-id"
                                    >
                                        Folder Code
                                    </th>
                                    <th
                                        style={{
                                            width: '30%',
                                            position: 'sticky',
                                            top: -1,
                                            zIndex: 1
                                        }}
                                        className="td-id"
                                    >
                                        Role ID
                                    </th>
                                    <th
                                        className="td-role-access"
                                        style={{
                                            width: '30%',
                                            top: -1,
                                            position: 'sticky',
                                            zIndex: 1
                                        }}
                                    >
                                        Role Access
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {folderAuthorize.isLoading ? (
                                    <tr>
                                        <th
                                            className="td-code text-center"
                                            colSpan={9}
                                        >
                                            <Spinner
                                                color="dark"
                                                size="sm"
                                                animation={'border'}
                                            />{' '}
                                            Loading...
                                        </th>
                                    </tr>
                                ) : !folderAuthorize.isLoading &&
                                  folderAuthorize.response !== null &&
                                  folderAuthorize.response.total_count === 0 ? (
                                    <tr>
                                        <th
                                            className="td-code text-center"
                                            colSpan={9}
                                        >
                                            No Data
                                        </th>
                                    </tr>
                                ) : (
                                    !folderAuthorize.isLoading &&
                                    folderAuthorize.response !== null &&
                                    folderAuthorize.response.result_list !== null &&
                                    folderAuthorize.response.total_count > 0 &&
                                    folderAuthorize.response.result_list.map((folder, index) => (
                                        <tr key={`table-row-${index + 1}`}>
                                            <td>{folder.sid}</td>
                                            <td>{folder.node_code}</td>
                                            <td>{folder.role_id}</td>
                                            <td>
                                                <div
                                                    className="d-flex"
                                                    style={{ gap: '4px' }}
                                                >
                                                    {folder.auths
                                                        .split('|')
                                                        .map((role, subIndex) => (
                                                            <Badge
                                                                key={`table-cell-badge-${
                                                                    index + 1
                                                                }-${subIndex + 1}`}
                                                                variant="primary"
                                                            >
                                                                {role}
                                                            </Badge>
                                                        ))}
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </Table>
                    </Col>
                </Row>

                {/*Export Excel Model*/}
                <Modal
                    isOpen={this.state.openModalExportExcel}
                    toggle={this.onToggleModal}
                    className="folder-user-auth-modal-content"
                >
                    <ModalHeader toggle={this.onToggleModal}>Export Excel</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col xs={12}>
                                <Card.Body className="mat-box shadow">
                                    <Row>
                                        <Col md={6}>
                                            <Row>
                                                <Col
                                                    sm={6}
                                                    className="mb-3"
                                                >
                                                    <div>
                                                        <h6>Company</h6>
                                                    </div>
                                                </Col>
                                                <Col sm={12}>
                                                    <Form.Group
                                                        className="mb-3"
                                                        controlId="companyExport"
                                                    >
                                                        <CreatableSelect
                                                            menuPortalTarget={document.body}
                                                            styles={{
                                                                menuPortal: base => ({
                                                                    ...base,
                                                                    zIndex: 9999
                                                                })
                                                            }}
                                                            onChange={company =>
                                                                this.handleSelectCompany(
                                                                    [company],
                                                                    'ExportExcel'
                                                                )
                                                            }
                                                            options={this.state.companyOptions}
                                                            isValidNewOption={() => false}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={6}>
                                            <Row>
                                                <Col
                                                    sm={6}
                                                    className="mb-3"
                                                >
                                                    <div>
                                                        <h6>User</h6>
                                                    </div>
                                                </Col>
                                                <Col sm={12}>
                                                    <Form.Group
                                                        className="mb-3"
                                                        controlId="employeeExport"
                                                    >
                                                        <CreatableSelect
                                                            menuPortalTarget={document.body}
                                                            styles={{
                                                                menuPortal: base => ({
                                                                    ...base,
                                                                    zIndex: 9999
                                                                })
                                                            }}
                                                            isMulti
                                                            isClearable
                                                            onChange={employee =>
                                                                this.handleSelectUser(employee)
                                                            }
                                                            options={
                                                                this.state.filterUser.length > 0
                                                                    ? this.state.filterUser
                                                                    : [
                                                                          {
                                                                              label: 'select all user',
                                                                              value: '*'
                                                                          }
                                                                      ]
                                                            }
                                                            isValidNewOption={() => false}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            disabled={this.state.username.length === 0}
                            className="btn-export-excel"
                            onClick={this.exportToExcel}
                        >
                            Export
                        </Button>{' '}
                        <Button
                            color="secondary"
                            onClick={this.onToggleModal}
                        >
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

export default connect(
    (state: ApplicationState, ownProps) => ({
        ...ownProps,
        authorize: state.authorize,
        folderAuthorize: state.folderAuthorize,
        employee: state.employee,
        company: state.companyMaster
    }),
    {
        ...AuthorizeStore.actionCreators,
        ...FolderAuthorizeStore.actionCreators,
        ...EmployeeMasterStore.actionCreators,
        ...CompanyMasterStore.actionCreators
    }
)(withRemounter(UserFolderAuthorization) as any);
