export const MENU_GROUPS = [
    {
        name: 'admin',
        allowed: ['admin', 'super_admin'],
        menus: [
            {
                to: '/user-folder-authorizev2',
                label: 'Folder Authorize Mapping'
            },
            {
                to: '/user-folder-authorizev2/log',
                label: 'Folder Authorize Log'
            }
        ]
    },
    {
        group: 'super-admin',
        allowed: ['super_admin'],
        menus: [
            {
                to: '/dms/meta-data-group',
                label: 'Metadata Group'
            },
            {
                to: '/dms/meta-data',
                label: 'Metadata'
            },
            // {
            //     to: '/dms/role-access',
            //     label: 'Authorization'
            // },
            {
                to: '/employee',
                label: 'Employee'
            },
            {
                to: '/folder-authorize',
                label: 'Folder Authorize'
            },
            {
                to: '/callback',
                label: 'Callback'
            },
            {
                to: '/access-management',
                label: 'Access Management'
            },
            {
                to: '/dms/log',
                label: 'DMS Log'
            }
        ]
    }
];
