import React, { useRef } from 'react';
import {
    Button,
    Modal,
    ModalCloseButton,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalFooter,
    ModalHeader
} from '@chakra-ui/react';
import DeleteFileView, { DeleteFileViewRef } from './DeleteFile.view';

import { connect } from 'react-redux';
import { ApplicationState } from 'store';
import * as Stores from 'store/FileManage';
import * as Models from 'models/File';

type Props = {
    [key: string]: any;
    isOpen: boolean;
    onClose: () => void;
    reloading: (value: string) => void;
    selectedFiles: Models.File[];
    removeSelectedFile: (atIndex: number) => void;
};

function DeleteFileModal(props: Props) {
    const { isOpen, onClose, reloading, selectedFiles, removeSelectedFile } = props;

    const ref = useRef<DeleteFileViewRef>(null);

    return (
        <Modal
            size={'4xl'}
            isOpen={isOpen}
            onClose={onClose}
            closeOnOverlayClick={false}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalBody className="flex flex-col gap-2 py-4">
                    <div className="flex flex-col gap-1">
                        <p className="text-lg font-medium">Delete file</p>
                        <p className="text-sm font-normal text-[#B3261E]">
                            Are you sure to delete file ?
                        </p>
                    </div>

                    <DeleteFileView
                        ref={ref}
                        onClose={onClose}
                        reloading={reloading}
                        selectedFiles={selectedFiles}
                        removeSelectedFile={removeSelectedFile}
                    />

                    <div className="flex justify-end gap-2 p-1">
                        <Button
                            onClick={() => ref.current?.onClickDelete()}
                            variant={'outline'}
                            className="h-9 bg-[#B3261E] px-4 text-white"
                        >
                            <span className="px-[15px] text-sm font-medium">Delete</span>
                        </Button>
                        <Button
                            variant={'outline'}
                            onClick={onClose}
                            className="h-9 px-4 text-neutral-400"
                        >
                            <span className="px-[15px] text-sm font-medium">Cancel</span>
                        </Button>
                    </div>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

// export default DeleteFileModal;
export default connect(
    (state: ApplicationState) => state.fileDelete,
    Stores.actionCreators
)(DeleteFileModal);
