import React from 'react';
import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';

import RenameFolderViewModel from './RenameFolder.viewmodel';

export type RenameFolderProps = {
    folderName: string;
};

function RenameFolder(props: RenameFolderProps) {
    const {} = RenameFolderViewModel();
    const { folderName } = props;

    return (
        <React.Fragment>
            <InputGroup>
                <InputGroupAddon addonType="prepend">
                    <InputGroupText style={{ width: '160px' }}>Folder Name</InputGroupText>
                </InputGroupAddon>
                <Input
                    placeholder="Folder Name"
                    type="text"
                    value={folderName}
                    // onChange={this.handleChangeName}
                />
            </InputGroup>
        </React.Fragment>
    );
}

export default RenameFolder;
