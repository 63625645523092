import React from 'react';
import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalFooter
} from '@chakra-ui/react';

import FileLogView from './FileLog.view';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    primaryId?: string;
};

function FileLogModal(props: Props) {
    const { isOpen, onClose, primaryId } = props;

    return (
        <Modal
            size={'6xl'}
            isOpen={isOpen}
            onClose={onClose}
            closeOnOverlayClick={false}
            scrollBehavior={'inside'}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalBody className="flex flex-col">
                    <FileLogView primaryId={primaryId} />
                </ModalBody>
                <ModalFooter className="px-7">
                    <Button
                        onClick={onClose}
                        variant={'outline'}
                        className="h-9 px-4 text-neutral-400"
                    >
                        <span className="px-[15px] text-sm font-medium">Close</span>
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default FileLogModal;
