import * as React from 'react';
import "./View.css";
import { FaDownload } from "react-icons/fa";
import Swal from 'sweetalert2';
import * as QueryString from "query-string"

//pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

class IMGView extends React.Component<any, {
    filePath: string,
    readOnly: boolean,
    scaleIndex: number,
    scaleList: number[],
    base64Image: string | ArrayBuffer | null
}> {
    constructor(props: any) {
        super(props);

        this.state = {
            filePath: '',
            readOnly: false,
            scaleIndex: 7,
            scaleList: [0.25, 0.33, 0.5, 0.67, 0.75, 0.80, 0.90, 1, 1.11, 1.25, 1.50, 1.75, 2.0, 2.5, 3, 4, 5],
            base64Image: null
        }

        this.onClickScaleDown = this.onClickScaleDown.bind(this);
        this.onClickScaleUp = this.onClickScaleUp.bind(this);
        this.onClickDownload = this.onClickDownload.bind(this);
    }

    componentDidMount() {
        var { filePath } = this.props
        let params = QueryString.parse(filePath.substr(filePath.lastIndexOf('?') + 1))
        var readOnly = false
        if (params.read_only) {
            if (String(params.read_only).toLowerCase() === 'true') {
                readOnly = true
            }
        }

        fetch(filePath,
            {
                method: "GET"
            })
            .then(response => {
                if (response.ok) {
                    return response.blob()
                }
                throw new Error('Something went wrong');
            })
            .then(response => {
                //console.log(response)

                var reader = new FileReader();
                reader.readAsDataURL(response);
                reader.onloadend = () => {
                    this.setState({
                        filePath: filePath,
                        base64Image: reader.result,
                        readOnly: (readOnly !== undefined) ? readOnly : false
                    })
                }
            })
            .catch((error) => {
                this.onLoadError()
            });
    }

    onLoadError() {
        Swal.fire({
            title: 'ไม่สำเร็จ!',
            text: 'ไม่พบข้อมูล หรือไม่ได้รับสิทธิ์ให้เข้าดูไฟล์ กรุณาติดต่อผู้ดูแลระบบ',
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'ยืนยัน!'
        }).then((result) => {
            if (result.value) {
            }

            this.setState({
                filePath: '',
                base64Image: '',
                readOnly: true
            })
        })
    }

    viewScale = () => {
        var scale = this.state.scaleList[this.state.scaleIndex] * 100;

        return scale.toFixed(0)
    }

    onClickScaleUp() {
        let { scaleIndex, scaleList } = this.state
        if (scaleIndex < scaleList.length - 1) {
            this.setState({
                scaleIndex: scaleIndex + 1
            })
        }
    }

    onClickScaleDown() {
        let { scaleIndex } = this.state
        if (scaleIndex > 0) {
            this.setState({
                scaleIndex: scaleIndex - 1
            })
        }
    }

    downloadFile(filePath) {
        fetch(filePath, {
            method: 'GET',
        }).then(function (resp) {
            return resp.blob();
        }).then(function (blob) {
            const newBlob = new Blob([blob], { /*type: "application/pdf", charset: "UTF-8"*/ })

            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob);

                return;
            }
            const data = window.URL.createObjectURL(newBlob);
            const link = document.createElement('a');
            //link.dataType = "json";
            link.href = data;
            link.download = filePath.substr(filePath.lastIndexOf('file_name=') + 10);
            link.dispatchEvent(new MouseEvent('click'));
            setTimeout(function () {
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(data)
            }, 60);
        });
    }

    onClickDownload() {
        let { filePath } = this.state
        //params += '&download=true'
        //var url = window.location.origin + '/view?' + params
        //window.open(filePath)
        this.downloadFile(filePath)
    }

    render() {
        let { filePath, readOnly, scaleIndex, scaleList, base64Image } = this.state
        let scale = scaleList[scaleIndex];
        let zoom = { zoom: scale }
        let imgStyle = {
            //width: 'calc(100vw - 10%)',
            height: 'auto',
            maxWidth: '1110px'
        }
        //console.log(this.state)

        if (filePath) {
            return (
                <div className="Showcase Showcase--react-pdf" onContextMenu={(e) => e.preventDefault()}>
                    <div className="react-component" onContextMenu={(e) => e.preventDefault()}>
                        {/*<img className="image-container" src={file_path} height="100%" width="100%"></img>*/}
                        <div className="react-pdf__Document" style={zoom} onContextMenu={(e) => e.preventDefault()}>
                            <img className="image-container div-disable-select" style={imgStyle} src={base64Image as string}>

                            </img>
                        </div>

                        <div className="page-controls">
                            <button id="btnScaleDown" type="button" onClick={this.onClickScaleDown}>-</button>
                            <span>{this.viewScale()}%</span>
                            <button id="btnScaleUp" type="button" onClick={this.onClickScaleUp}>+</button>
                            {readOnly === false &&
                                <div style={{ display: "inline-block" }}>
                                    <span>&nbsp;|&nbsp;</span>
                                    <button id="btnDownload" type="button" onClick={this.onClickDownload}><FaDownload /></button>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div></div>
            );
        }
    }
}

export default IMGView;