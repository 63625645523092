// TODO - Eject this file.

import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { UserRoleAccessModel } from 'models/RoleAccess';
import { useStore } from 'common/hooks';

export type AdminContextType = {
    userRole: UserRoleAccessModel[];
    setUserRole: (value: UserRoleAccessModel[]) => void;
};

export const AdminContext = createContext<AdminContextType>({} as AdminContextType);

export function useAdminContext() {
    return useContext<AdminContextType>(AdminContext);
}

function AdminProvider({ children }: PropsWithChildren<{}>) {
    const { userStore } = useStore();
    const { userRoles } = userStore;
    const [userRole, setUserRole] = useState<UserRoleAccessModel[]>([]);

    useEffect(() => {
        if (userRoles.length === 0) {
            return;
        }

        setUserRole(userRoles);
    }, [userRoles]);

    const provide = {
        userRole,
        setUserRole
    };

    return <AdminContext.Provider value={provide}>{children}</AdminContext.Provider>;
}

export default observer(AdminProvider);
