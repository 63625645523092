import React from 'react';

export interface WithRemounterProps {
    remount: () => void;
}

interface State {
    key: number;
}

export function withRemounter<P extends {}>(Component: React.ComponentType<P>) {
    return class WithRemounter extends React.Component<P & WithRemounterProps, State> {
        constructor(props: P & WithRemounterProps) {
            super(props);

            this.state = {
                key: this.getRandomNumber()
            };

            this.remount = this.remount.bind(this);
        }

        private getRandomNumber(): number {
            const max = 100000;
            const min = 1;

            return Math.ceil(Math.random() * (max - min) + min);
        }

        public remount(): void {
            this.setState({ key: this.getRandomNumber() });
        }

        public render(): React.ReactNode {
            const { key } = this.state;

            return (
                <Component
                    key={key}
                    {...this.props}
                    remount={this.remount}
                />
            )
        }
    }
}