import React from 'react';
import Select from 'react-select';

import { SharedUserType } from 'models/ShareFolder';

import ShareUserSelector from './ShareUserSelector';

interface States {
    addNewUsers: string[];
    // selectedShareFileAuthorize: OptionType;
    // shareFileAuthorizeOptions: OptionType[];
    // listEmployeeOptions: OptionType[];
    // shareFileAuthorizeMaster: Authorize[];
    // sharedUsers: SharedUserType[];
    // shareRequest: ShareFolderRequestType[];
    displayShareUsers: SharedUserType[];
}

const MOCKUP_OPTION = [
    { label: 'View Only', value: 'viewOnly' },
    { label: 'Edit', value: 'edit' },
    { label: 'Owner', value: 'owner' },
    { label: 'Remove', value: 'remove' }
];

type Props = {
    onClose?: () => void;
};

function ShareView(props: Props) {
    const { onClose } = props;
    const displayShareUsers: SharedUserType[] = Array.from({ length: 3 }, () => {
        return {
            shareTo: 'test',
            authorize: { label: 'authorize', value: 'value' }
        };
    });

    return (
        <div className="flex flex-col gap-2">
            <div className="p-2.5">
                <ShareUserSelector
                    onClose={onClose}
                    employeeList={['test1', 'test2', 'test3', 'test4']}
                    onSelectUser={() => {}}
                    sharedUsers={['share1', 'share2']}
                />

                <div className="mt-2 bg-[#F7F7F7] p-2.5">
                    <div className="inline-flex gap-2.5 px-2.5 pb-2.5">
                        <div className="text-sm font-medium text-black">User With Access rule</div>
                    </div>
                    <ul className="[&>li:not(:last-child)]:border-b">
                        {displayShareUsers.map((user, index) => (
                            <li
                                key={index}
                                className="align-items-center justify-content-between flex cursor-pointer bg-white px-2 py-2"
                            >
                                <span className="text-sm font-normal">{user.shareTo}</span>

                                <div className="w-1/4">
                                    <Select
                                        options={MOCKUP_OPTION}
                                        className="select-access-rule"
                                        // options={shareFileAuthorizeOptions}
                                        // value={user.authorize}
                                        // onChange={(authOption: OptionType) =>
                                        //     handleChangeUserAuthorize(
                                        //         authOption,
                                        //         index
                                        //     )
                                        // }
                                        // isValidNewOption={() => false}
                                    />
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default ShareView;
