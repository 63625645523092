////import React from 'react';
import * as QueryString from 'query-string';
import * as React from 'react';
import { connect } from 'react-redux';
import * as SearchCriteriaStore from '../../../store/SearchCriteria';
import * as CompanyMasterStore from '../../../store/CompanyMaster';
import { Row, Col, Button, InputGroup, Form, Card } from 'react-bootstrap';
import { FaSearch, FaPlus, FaTimes } from 'react-icons/fa';
import SearchCriteriaTable from './SearchCriteriaTable';
import {
    SearchCriteriaState,
    SearchCriteriaStoreState,
    InputRange,
    DocumentType
} from '../../../models/SearchCriteria';
import { CompanyMasterState } from '../../../models/CompanyMaster';
import { EmployeeState } from '../../../models/Employee';
import * as CheckCompanyUserStore from '../../../store/CheckCompanyUser';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ApplicationState } from '../../../store';
import Select from 'react-select';
import { parseJwt, checkExp, getRedirectAppPoolUrl } from '../../../modules/common';
import Swal from 'sweetalert2';
import { RouteComponentProps } from 'react-router-dom';

import * as CheckCompanyUserModels from '../../../models/Employee';

import UserProvider, { IUserContext, UserContext } from '../../../context/UserProvider';

interface SearchWithCriteriaComponentProps {}

type Props = SearchWithCriteriaComponentProps &
    SearchCriteriaStore.ActionCreators &
    typeof CompanyMasterStore.actionCreators &
    typeof CheckCompanyUserStore.actionCreators &
    CompanyMasterState &
    EmployeeState &
    SearchCriteriaStoreState &
    RouteComponentProps;

type SearchCriteriaPreviewState = Pick<SearchCriteriaState, 'year' | 'posting_date'> & {
    document_type: { label: string; value: string }[];
} & { user: { label: string; value: string }[] };

interface States {
    searchCriteria: SearchCriteriaState;
    searchCriteriaPreview: SearchCriteriaPreviewState;
    user: string;
    isLoadedCompanyList: boolean;
    documentTypeList: { label: string; value: string }[];
    userList: { label: string; value: string }[];
}

class SearchWithCriteriaComponent extends React.Component<Props, States> {
    static contextType: React.Context<any> | undefined = UserContext;

    public context!: React.ContextType<typeof UserContext>;

    private inputRange: InputRange = {
        from: '',
        to: ''
    };

    constructor(props: Props) {
        super(props);

        this.state = {
            searchCriteria: {
                sid: '',
                company: '',
                document_type: [{ ...this.inputRange }],
                document_no: [{ ...this.inputRange }],
                // document_no: [{ from: '3010000100', to: '3010000400' }], // Uncomment this line for development purpose (quick test)
                year: [{ ...this.inputRange }],
                posting_date: [{ ...this.inputRange }],
                irm_no: [{ ...this.inputRange }],
                user: [{ ...this.inputRange }],
                ref_no: [{ ...this.inputRange }]
            },
            searchCriteriaPreview: {
                year: [{ ...this.inputRange }],
                posting_date: [{ ...this.inputRange }],
                document_type: [{ label: '', value: '' }],
                user: [{ label: '', value: '' }]
            },
            user: '',
            isLoadedCompanyList: false,
            documentTypeList: [],
            userList: []
        };

        this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
        this.handleChangeCompany = this.handleChangeCompany.bind(this);
    }

    public componentDidMount() {
        document.title = 'Update Box ID';
        window.history.pushState({}, 'dms/search-with-criteria', '/dms/search-with-criteria');
        // console.log(this.props.location.search)
        this.checkUser();
        //this.fetchCompanyList();

        this.handleChangeInputYear('year', { key: 'from', atIndex: 0 })(new Date(), false);
        this.handleChangeInputYear('year', { key: 'to', atIndex: 0 })(new Date(), false);
        this.handleChangeInputDate('posting_date', { key: 'from', atIndex: 0 })(new Date(), false);
        this.handleChangeInputDate('posting_date', { key: 'to', atIndex: 0 })(new Date(), false);
    }

    private checkUser() {
        this.props.requestFunctionCompanyMaster(true, 'GET', '', 'active', '');
        const { token } = QueryString.parse(this.props.location.search);

        // console.log(token)
        if (token) {
            var jwt = parseJwt(String(token));
            checkExp(jwt);
            var data = {
                access_token: token
            };
            localStorage.removeItem('DMS_APPLICATION');
            localStorage.setItem('DMS_APPLICATION', JSON.stringify(data));
        }

        let ls: any = localStorage.getItem('DMS_APPLICATION');
        if (ls) {
            let jls: any = JSON.parse(ls);
            let accessToken = jls.access_token;
            var jwt = parseJwt(accessToken);
            var extra = JSON.parse(jwt.extra);

            let userId = '';
            let checkUser = '';
            if (extra.username) {
                userId = extra.username;
                checkUser = extra.username;
            } else if (extra.first_name) {
                userId = extra.first_name + ' ' + extra.last_name;
                checkUser = extra.citizen_id;
            } else if (extra.citizen_id) {
                userId = extra.citizen_id;
                checkUser = extra.citizen_id;
            }

            //fetch("/v1/admin?citizen_id=" + checkUser,
            //    {
            //        headers: {
            //            'Content-Type': 'application/json',
            //            'authorization': 'Bearer ' + ls
            //        }
            //    })
            //    .then(res => res)
            //    .then((result) => {
            //        if (result.ok) {
            //            //this.setState({
            //            //    checkTypeUser: result
            //            //});
            //            // console.log(result);
            //        } else {
            //            result.json().then(data => {
            //                if (data.message === "No such host is known." || data.message === "An invalid request URI was provided. The request URI must either be an absolute URI or BaseAddress must be set.") {
            //                    Swal.fire({
            //                        title: 'เกิดข้อผิดพลาด',
            //                        text: 'ไม่สามารถเชื่อมต่อกับ server ได้ในขณะนี้',
            //                        icon: 'error',
            //                        showCancelButton: false,
            //                        confirmButtonColor: '#3085d6',
            //                        confirmButtonText: 'ยืนยัน',
            //                    }).then((result) => { window.location.reload() })
            //                }
            //            })
            //        }
            //    });

            if (checkUser !== '') {
                fetch('/v1/user?id=' + userId + '&mode=CheckUser', {
                    headers: {
                        'Content-Type': 'application/json',
                        authorization: 'Bearer ' + ls
                    }
                })
                    .then(res => res.json())
                    .then((result: CheckCompanyUserModels.ResponseEmployee) => {
                        let total_count = result.total_count;

                        if (total_count === 0) {
                            Swal.fire({
                                title: 'เกิดข้อผิดพลาด',
                                text: 'ไม่พบ ' + userId + ' ในระบบ',
                                icon: 'error',
                                showCancelButton: false,
                                confirmButtonColor: '#3085d6',
                                confirmButtonText: 'ยืนยัน'
                            }).then(() => {
                                getRedirectAppPoolUrl();
                            });
                        } else {
                            const user = result.result_list[0];
                            const { actions }: IUserContext = this.context;

                            actions.setId(user.id);
                            var list_company = user.list_company.filter(
                                item =>
                                    item.role_access.includes('51') ||
                                    item.role_access.includes('52')
                            );

                            if (list_company.length === 0) {
                                Swal.fire({
                                    title: 'เกิดข้อผิดพลาด',
                                    text: userId + ' ไม่ได้รับอนุญาตให้เข้าใช้งานส่วนงานนี้',
                                    icon: 'error',
                                    showCancelButton: false,
                                    confirmButtonColor: '#3085d6',
                                    confirmButtonText: 'ยืนยัน'
                                }).then(() => {
                                    getRedirectAppPoolUrl();
                                });
                            }

                            actions.setListCompany(list_company);
                        }
                    });

                this.props.requestFunctionCheckCompanyUser(0, 'GET', checkUser, '', '', userId);
            } else {
                Swal.fire({
                    title: 'เกิดข้อผิดพลาด',
                    text: 'ไม่พบ token เข้าใช้งานระบบ หรือ token หมดอายุ',
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'ยืนยัน'
                }).then(() => {
                    getRedirectAppPoolUrl();
                });
            }

            this.setState({ user: userId });
        }
    }

    //// Reference from NavMenu lines 55
    //public fetchCompanyList() {
    //    this.props.requestFunctionCompanyMaster(true, "GET", "", "active", "");

    //    let ls: any = localStorage.getItem("DMS_APPLICATION");
    //    let userId = "";
    //    let checkUser = "";

    //    if (ls) {
    //        let jls: any = JSON.parse(ls)
    //        let accessToken = jls.access_token
    //        let jwt = parseJwt(accessToken);
    //        let extra = JSON.parse(jwt.extra);

    //        checkExp(jwt)

    //        if (extra.username) {
    //            userId = extra.username
    //            checkUser = extra.username
    //        } else if (extra.first_name) {
    //            userId = extra.first_name + " " + extra.last_name
    //            checkUser = extra.citizen_id
    //        } else if (extra.citizen_id) {
    //            userId = extra.citizen_id
    //            checkUser = extra.citizen_id
    //        }

    //        if (checkUser === "") {
    //            Swal.fire({
    //                title: 'เกิดข้อผิดพลาด',
    //                text: 'ไม่พบ user ใน token',
    //                icon: 'error',
    //                showCancelButton: false,
    //                confirmButtonColor: '#3085d6',
    //                confirmButtonText: 'ยืนยัน',
    //            }).then((result) => { getRedirectAppPoolUrl() })
    //        }

    //        this.setState({ user: userId })
    //    }

    //    if (checkUser !== "") {
    //        fetch("/v1/user?id=" + userId + "&mode=CheckUser", {
    //            headers: {
    //                'Content-Type': 'application/json',
    //                'authorization': 'Bearer ' + ls
    //            }
    //        })
    //            .then(res => res.json())
    //            .then((result: CheckCompanyUserModels.ResponseEmployee) => {
    //                let total_count = result.total_count

    //                if (total_count === 0 || result === undefined) {
    //                    Swal.fire({
    //                        title: 'เกิดข้อผิดพลาด',
    //                        text: 'ไม่พบ user นี้ในระบบ',
    //                        icon: 'error',
    //                        showCancelButton: false,
    //                        confirmButtonColor: '#3085d6',
    //                        confirmButtonText: 'ยืนยัน',
    //                    }).then(() => { getRedirectAppPoolUrl() })
    //                } else {
    //                    const user = result.result_list[0];
    //                    const { actions }: IUserContext = this.context;
    //                    actions.setId(user.id);
    //                    actions.setListCompany(user.list_company);
    //                }
    //            });

    //        this.props.requestFunctionCheckCompanyUser(0, "GET", checkUser, "", "", userId);
    //    }
    //}

    // Reference from NavMenu lines 241
    public componentDidUpdate() {
        const {
            isLoadingCompanyMaster,
            responseCompanyMaster,
            statusCompanyMaster,
            isLoadingEmployee,
            statusEmployee,
            responseEmployee,
            isLoadingDocumentType
        } = this.props;
        if (
            !this.state.isLoadedCompanyList &&
            isLoadingCompanyMaster &&
            statusCompanyMaster === 200 &&
            responseCompanyMaster!.total_count !== 0 &&
            isLoadingEmployee &&
            statusEmployee === 200 &&
            responseEmployee !== undefined
        ) {
            //let listCompany = [...responseEmployee.result_list]
            //let listCompanyUser = listCompany.find(item => item.id.toLocaleLowerCase() === this.state.user.toLocaleLowerCase())
            //listCompanyUser.list_company
            const { list_company }: IUserContext = this.context;
            let arrCompany: any = [];
            if (list_company != undefined) {
                for (let i = 0; i < responseCompanyMaster!.result_list.length; i++) {
                    for (let j = 0; j < list_company.length; j++) {
                        if (responseCompanyMaster!.result_list[i].sid === list_company[j].sid) {
                            arrCompany.push(responseCompanyMaster!.result_list[i]);
                        }
                    }
                }
            }
            // console.log('ngame availableCompanies ->', arrCompany)
            let CheckCompanyShortName_: Array<string> = [];
            for (let i = 0; i < arrCompany.length; i++) {
                CheckCompanyShortName_.push(arrCompany[i].company_short_name);
            }

            const { actions }: IUserContext = this.context;
            actions.setAvailableCompanies(arrCompany);
            this.setState({ isLoadedCompanyList: true });
        }
    }

    private handleClearInput(
        key: keyof SearchCriteriaState,
        range?: { key: keyof InputRange; atIndex: number }
    ) {
        const newInputRange = [...(this.state.searchCriteria[key] as InputRange[])];

        if (range !== undefined) {
            newInputRange[range.atIndex][range.key] = '';
        }

        this.setState({
            searchCriteria: {
                ...this.state.searchCriteria,
                [key]: range === undefined ? '' : [...newInputRange]
            }
        });
    }

    private handleChangeInputDate(
        key: keyof Pick<SearchCriteriaState, 'posting_date'>,
        range: { key: keyof InputRange; atIndex: number }
    ) {
        return (date: Date, _event: unknown) => {
            if (date === null) {
                const newInputRangeDatePreview = [...this.state.searchCriteriaPreview[key]];
                newInputRangeDatePreview[range.atIndex][range.key] = '';

                this.setState({
                    searchCriteriaPreview: {
                        ...this.state.searchCriteriaPreview,
                        [key]: [...newInputRangeDatePreview]
                    }
                });
                this.handleClearInput(key, { key: range.key, atIndex: range.atIndex });

                return;
            }

            const month = ('0' + (date.getMonth() + 1)).slice(-2);
            const day = ('0' + date.getDate()).slice(-2);
            const reqDate = date.getFullYear() + month + day + '000000';

            const newInputRangeDate = [...this.state.searchCriteria[key]];
            const newInputRangeDatePreview = [...this.state.searchCriteriaPreview[key]];

            newInputRangeDate[range.atIndex][range.key] = reqDate;
            newInputRangeDatePreview[range.atIndex][range.key] = date as any;

            this.setState({
                searchCriteria: {
                    ...this.state.searchCriteria,
                    [key]: [...newInputRangeDate]
                },
                searchCriteriaPreview: {
                    ...this.state.searchCriteriaPreview,
                    [key]: [...newInputRangeDatePreview]
                }
            });
        };
    }

    private handleChangeInputYear(
        key: keyof Pick<SearchCriteriaState, 'year'>,
        range: { key: keyof InputRange; atIndex: number }
    ) {
        return (year: Date, _event: unknown) => {
            if (year === null) {
                const newInputRangeYearPreview = [...this.state.searchCriteriaPreview[key]];
                newInputRangeYearPreview[range.atIndex][range.key] = '';

                this.setState({
                    searchCriteriaPreview: {
                        ...this.state.searchCriteriaPreview,
                        [key]: [...newInputRangeYearPreview]
                    }
                });
                this.handleClearInput(key, { key: range.key, atIndex: range.atIndex });

                return;
            }

            const reqYear = year.getFullYear().toString();

            const newInputRangeYear = [...this.state.searchCriteria[key]];
            const newInputRangeYearPreview = [...this.state.searchCriteriaPreview[key]];

            newInputRangeYear[range.atIndex][range.key] = reqYear;
            newInputRangeYearPreview[range.atIndex][range.key] = year as any;

            this.setState({
                searchCriteria: {
                    ...this.state.searchCriteria,
                    [key]: [...newInputRangeYear]
                },
                searchCriteriaPreview: {
                    ...this.state.searchCriteriaPreview,
                    [key]: [...newInputRangeYearPreview]
                }
            });
        };
    }

    private handleChangeInput(
        key: keyof SearchCriteriaState,
        range?: { key: keyof InputRange; atIndex: number }
    ): React.ChangeEventHandler<HTMLInputElement> {
        return event => {
            event.persist();

            const newInputRange = [...(this.state.searchCriteria[key] as InputRange[])];

            if (range !== undefined) {
                newInputRange[range.atIndex][range.key] = event.target.value;
            }

            this.setState({
                searchCriteria: {
                    ...this.state.searchCriteria,
                    [key]: range === undefined ? event.target.value : [...newInputRange]
                }
            });
        };
    }

    private handleAddInputRange(
        key: keyof Omit<SearchCriteriaState, 'company'>
    ): React.MouseEventHandler<HTMLElement> {
        return _event => {
            let year_preview = [...this.state.searchCriteriaPreview.year];
            let posting_date_preview = [...this.state.searchCriteriaPreview.posting_date];
            let document_type_preview = [...this.state.searchCriteriaPreview.document_type];
            let user_preview = [...this.state.searchCriteriaPreview.user];

            switch (key) {
                case 'year': {
                    year_preview.push({ ...this.inputRange });
                    break;
                }
                case 'posting_date': {
                    posting_date_preview.push({ ...this.inputRange });
                    break;
                }
                case 'document_type': {
                    document_type_preview.push({ label: '', value: '' });
                    break;
                }
                case 'user': {
                    user_preview.push({ label: '', value: '' });
                    break;
                }
            }

            this.setState({
                searchCriteria: {
                    ...this.state.searchCriteria,
                    [key]: [...this.state.searchCriteria[key], { ...this.inputRange }]
                },
                searchCriteriaPreview: {
                    year: year_preview,
                    posting_date: posting_date_preview,
                    document_type: document_type_preview,
                    user: user_preview
                }
            });
        };
    }

    private handleRemoveInputRange(
        key: keyof Omit<SearchCriteriaState, 'company'>,
        atIndex: number
    ): React.MouseEventHandler<HTMLElement> {
        return _event => {
            let year_preview = [...this.state.searchCriteriaPreview.year];
            let posting_date_preview = [...this.state.searchCriteriaPreview.posting_date];
            let document_type_preview = [...this.state.searchCriteriaPreview.document_type];
            let user_preview = [...this.state.searchCriteriaPreview.user];

            switch (key) {
                case 'year': {
                    year_preview.splice(atIndex, 1);
                    break;
                }
                case 'posting_date': {
                    posting_date_preview.splice(atIndex, 1);
                    break;
                }
                case 'document_type': {
                    document_type_preview.splice(atIndex, 1);
                    break;
                }
                case 'user': {
                    user_preview.splice(atIndex, 1);
                    break;
                }
            }

            const newInputRange = [...this.state.searchCriteria[key]];
            newInputRange.splice(atIndex, 1);

            this.setState({
                searchCriteria: {
                    ...this.state.searchCriteria,
                    [key]: [...newInputRange]
                },
                searchCriteriaPreview: {
                    year: year_preview,
                    posting_date: posting_date_preview,
                    document_type: document_type_preview,
                    user: user_preview
                }
            });
        };
    }

    private canAddInputRange = (
        key: keyof Omit<SearchCriteriaState, 'company' | 'sid'>
    ): boolean => {
        const lastInputRange =
            this.state.searchCriteria[key][this.state.searchCriteria[key].length - 1];

        return lastInputRange.from === '' && lastInputRange.to === '';
    };

    private handleSearchSubmit(event: React.FormEvent<HTMLFormElement>): void {
        event.preventDefault();
        const searchCriteria: SearchCriteriaState = { ...this.state.searchCriteria };
        const [document_no, irm_no, ref_no] = [
            [...searchCriteria.document_no],
            [...searchCriteria.irm_no],
            [...searchCriteria.ref_no]
        ];

        const filteredWhiteSpaceDocno = document_no
            .filter((range, _index) => {
                if (range.from.includes(' ')) {
                    const removeAt = searchCriteria.document_no.findIndex(
                        findRange => findRange.from === range.from
                    );
                    searchCriteria.document_no.splice(removeAt, 1);
                    return true;
                }

                return false;
            })
            .map(range => {
                const docNoList = range.from.split(' ').map(docNo => ({ from: docNo, to: '' }));

                return docNoList;
            })
            .reduce((prev, current) => prev.concat(current), []);

        const filteredWhiteSpaceIrmNo = irm_no
            .filter((range, _index) => {
                if (range.from.includes(' ')) {
                    const removeAt = searchCriteria.irm_no.findIndex(
                        findRange => findRange.from === range.from
                    );
                    searchCriteria.irm_no.splice(removeAt, 1);
                    return true;
                }

                return false;
            })
            .map(range => {
                const irmNoList = range.from.split(' ').map(irmNo => ({ from: irmNo, to: '' }));

                return irmNoList;
            })
            .reduce((prev, current) => prev.concat(current), []);

        const filteredWhiteSpaceRefNo = ref_no
            .filter((range, _index) => {
                if (range.from.includes(' ')) {
                    const removeAt = searchCriteria.ref_no.findIndex(
                        findRange => findRange.from === range.from
                    );
                    searchCriteria.ref_no.splice(removeAt, 1);
                    return true;
                }

                return false;
            })
            .map(range => {
                const refNoList = range.from.split(' ').map(refNo => ({ from: refNo, to: '' }));

                return refNoList;
            })
            .reduce((prev, current) => prev.concat(current), []);

        searchCriteria.document_no.push(...filteredWhiteSpaceDocno);
        searchCriteria.irm_no.push(...filteredWhiteSpaceIrmNo);
        searchCriteria.ref_no.push(...filteredWhiteSpaceRefNo);

        // console.log('ngame', { ...searchCriteria }) // Uncomment this line to see json request body (For development purpose)

        this.props.requestCriteriaSearch({
            method: 'POST',
            body: { ...searchCriteria }
        });
    }

    private handleChangeCompany(
        newData: { value: string; sid: string },
        _actionMeta: unknown
    ): void {
        if (newData !== null) {
            const { value: company, sid } = newData;

            this.props.requestDocumentType({ method: 'GET' }, sid, (data, error) => {
                if (error) {
                    return;
                }

                const mappedSelectOptions = data!.result_list.map(item => ({
                    label: item.name,
                    value: item.value
                }));
                this.setState({ documentTypeList: mappedSelectOptions });
            });

            this.props.requestUser({ method: 'GET', params: { sid } }, (data, error) => {
                if (error) {
                    return;
                }

                const mappedSelectOptions = data!.result_list.map(item => ({
                    label: item.name,
                    value: item.value
                }));
                this.setState({ userList: mappedSelectOptions });
            });

            this.setState(prevState => ({
                ...prevState,
                searchCriteria: {
                    ...prevState.searchCriteria,
                    sid,
                    company
                }
            }));
        } else {
            this.setState(prevState => ({
                ...prevState,
                searchCriteria: {
                    ...prevState.searchCriteria,
                    sid: '',
                    company: ''
                }
            }));
        }
    }

    private handleChangeUser(key: keyof InputRange, atIndex: number) {
        return (newData: { label: string; value: string }, _actionMeta: unknown) => {
            const newInputRange = [...(this.state.searchCriteria.user as InputRange[])];
            const newInputPreviewRange = [...this.state.searchCriteriaPreview.user];

            if (newData !== null) {
                const { label, value } = newData;

                newInputRange[atIndex][key] = value;

                newInputPreviewRange[atIndex].label = label;
                newInputPreviewRange[atIndex].value = value;
            } else {
                newInputRange[atIndex][key] = '';

                newInputPreviewRange[atIndex].label = '';
                newInputPreviewRange[atIndex].value = '';
            }

            this.setState(prevState => ({
                ...prevState,
                searchCriteria: {
                    ...prevState.searchCriteria,
                    user: [...newInputRange]
                },
                searchCriteriaPreview: {
                    ...prevState.searchCriteriaPreview,
                    user: [...newInputPreviewRange]
                }
            }));
        };
    }

    private handleChangeDocumentType(key: keyof InputRange, atIndex: number) {
        return (newData: { label: string; value: string }, _actionMeta: unknown) => {
            const newInputRange = [...(this.state.searchCriteria.document_type as InputRange[])];
            const newInputPreviewRange = [...this.state.searchCriteriaPreview.document_type];

            if (newData !== null) {
                const { label, value } = newData;

                newInputRange[atIndex][key] = value;

                newInputPreviewRange[atIndex].label = label;
                newInputPreviewRange[atIndex].value = value;
            } else {
                newInputRange[atIndex][key] = '';

                newInputPreviewRange[atIndex].label = '';
                newInputPreviewRange[atIndex].value = '';
            }

            this.setState(prevState => ({
                ...prevState,
                searchCriteria: {
                    ...prevState.searchCriteria,
                    document_type: [...newInputRange]
                },
                searchCriteriaPreview: {
                    ...prevState.searchCriteriaPreview,
                    document_type: [...newInputPreviewRange]
                }
            }));
        };
    }

    private bindAvailableCompanies() {
        const { availableCompanies }: IUserContext = this.context;

        if (availableCompanies !== undefined) {
            const companyOptions: any = [];

            for (let i = 0; i < availableCompanies.length; i++) {
                let data: any = {};
                data['value'] = availableCompanies[i].company_code;
                data['label'] = availableCompanies[i].company_name;
                data['sid'] = availableCompanies[i].sid;
                data['status'] = false;

                companyOptions.push(data);
            }

            return companyOptions;
        }
    }

    private canSearch(): boolean {
        const { company, sid, document_no, irm_no, ref_no } = this.state.searchCriteria;

        return (
            company !== '' &&
            sid !== '' &&
            (document_no[0].from !== '' || irm_no[0].from !== '' || ref_no[0].from !== '')
        );
    }

    private shouldDisabled(
        key: keyof Pick<SearchCriteriaState, 'document_no' | 'irm_no' | 'ref_no'>,
        atIndex: number
    ): boolean {
        const from = this.state.searchCriteria[key][atIndex].from;

        if (from.includes(' ')) {
            return true;
        }

        return false;
    }

    public render() {
        const { list_company }: IUserContext = this.context;
        console.log(list_company);
        var disableImportExcel = true;
        if (list_company !== undefined) {
            var canImportCompanyList = list_company.filter(item => item.role_access.includes('52'));
            if (canImportCompanyList.length > 0) {
                disableImportExcel = false;
            }
        }

        const searchCriteriaTableProps = {
            // make sure all required component's inputs/Props keys&types match
            disableImportExcelButton: disableImportExcel
        };

        return (
            <div className="div-meta-data-main div-meta-role">
                <div>
                    <div
                        className="trapezoid"
                        style={{ width: 'auto', display: 'inline-block' }}
                    >
                        <span className="metadata-font">Criteria</span>
                    </div>
                </div>
                <div className="mat-box">
                    <Row>
                        <Col>
                            <Card>
                                <Card.Header
                                    className="h-50 d-inline-block"
                                    style={{ marginBottom: '-12px', marginTop: '-12px' }}
                                >
                                    <Row>
                                        <div className="col-lg-6">
                                            <div className="d-flex justify-content-start my-lg-0 my-2">
                                                <Form.Label
                                                    column
                                                    sm="3"
                                                    className="mt-2"
                                                >
                                                    ค้นหาข้อมูล
                                                </Form.Label>
                                            </div>
                                        </div>
                                    </Row>
                                </Card.Header>
                                <Card.Body style={{ overflow: 'none' }}>
                                    <form onSubmit={event => this.handleSearchSubmit(event)}>
                                        <Form.Row>
                                            {/* Company */}
                                            <Col
                                                lg={6}
                                                className="order-lg-1 pr-lg-2"
                                            >
                                                <Form.Group>
                                                    <Form.Label>
                                                        Company
                                                        <span className="text-danger h5">*</span>
                                                    </Form.Label>
                                                    <Select
                                                        menuPortalTarget={document.body}
                                                        styles={{
                                                            menuPortal: (base: any) => ({
                                                                ...base,
                                                                zIndex: 9999
                                                            })
                                                        }}
                                                        className="dropdown metadata-group-font"
                                                        options={this.bindAvailableCompanies()}
                                                        onChange={this.handleChangeCompany}
                                                        isClearable
                                                    />
                                                </Form.Group>
                                            </Col>

                                            {/* Document Type */}
                                            <Col
                                                lg={6}
                                                className="order-lg-3 pr-lg-2"
                                            >
                                                <Form.Group>
                                                    <Form.Label>Document Type</Form.Label>
                                                    {this.state.searchCriteriaPreview.document_type.map(
                                                        (form, index) => (
                                                            <div
                                                                key={`document-type-form-${
                                                                    index + 1
                                                                }`}
                                                                className="d-flex"
                                                                // style={{ gap: '15px' }}
                                                            >
                                                                <InputGroup className="d-flex flex-nowrap">
                                                                    <InputGroup.Prepend>
                                                                        <InputGroup.Text>
                                                                            From
                                                                        </InputGroup.Text>
                                                                    </InputGroup.Prepend>
                                                                    <div className="w-100">
                                                                        <Select
                                                                            value={{
                                                                                label: form.label,
                                                                                value: form.value
                                                                            }}
                                                                            menuPortalTarget={
                                                                                document.body
                                                                            }
                                                                            styles={{
                                                                                menuPortal: (
                                                                                    base: any
                                                                                ) => ({
                                                                                    ...base,
                                                                                    zIndex: 9999
                                                                                })
                                                                            }}
                                                                            className="dropdown metadata-group-font"
                                                                            options={
                                                                                this.state
                                                                                    .documentTypeList
                                                                            }
                                                                            onChange={this.handleChangeDocumentType(
                                                                                'from',
                                                                                index
                                                                            )}
                                                                            isDisabled={
                                                                                this.state
                                                                                    .searchCriteria
                                                                                    .company === ''
                                                                            }
                                                                            isClearable
                                                                        />
                                                                    </div>
                                                                </InputGroup>
                                                                {index === 0 ? (
                                                                    <Button
                                                                        title="Add another range"
                                                                        variant="outline-primary"
                                                                        onClick={this.handleAddInputRange(
                                                                            'document_type'
                                                                        )}
                                                                        disabled={this.canAddInputRange(
                                                                            'document_type'
                                                                        )}
                                                                    >
                                                                        <FaPlus />
                                                                    </Button>
                                                                ) : (
                                                                    <Button
                                                                        title="Delete range"
                                                                        variant="outline-danger"
                                                                        onClick={this.handleRemoveInputRange(
                                                                            'document_type',
                                                                            index
                                                                        )}
                                                                    >
                                                                        <FaTimes />
                                                                    </Button>
                                                                )}
                                                            </div>
                                                        )
                                                    )}
                                                </Form.Group>
                                            </Col>

                                            {/* Document No */}
                                            <Col
                                                lg={6}
                                                className="order-lg-5 pr-lg-2"
                                            >
                                                <Form.Group>
                                                    <Form.Label>
                                                        Document No
                                                        <span className="text-danger h5">*</span>
                                                    </Form.Label>
                                                    {this.state.searchCriteria.document_no.map(
                                                        (form, index) => (
                                                            <div
                                                                key={`document-no-form-${
                                                                    index + 1
                                                                }`}
                                                                className="d-flex"
                                                                // style={{ gap: '15px' }}
                                                            >
                                                                <InputGroup
                                                                    style={{ marginRight: '15px' }}
                                                                >
                                                                    <InputGroup.Prepend>
                                                                        <InputGroup.Text>
                                                                            From
                                                                        </InputGroup.Text>
                                                                    </InputGroup.Prepend>
                                                                    <Form.Control
                                                                        type="text"
                                                                        value={form.from}
                                                                        onChange={this.handleChangeInput(
                                                                            'document_no',
                                                                            {
                                                                                key: 'from',
                                                                                atIndex: index
                                                                            }
                                                                        )}
                                                                    />
                                                                </InputGroup>
                                                                <InputGroup>
                                                                    <InputGroup.Prepend>
                                                                        <InputGroup.Text>
                                                                            To
                                                                        </InputGroup.Text>
                                                                    </InputGroup.Prepend>
                                                                    <Form.Control
                                                                        type="text"
                                                                        value={form.to}
                                                                        onChange={this.handleChangeInput(
                                                                            'document_no',
                                                                            {
                                                                                key: 'to',
                                                                                atIndex: index
                                                                            }
                                                                        )}
                                                                        disabled={this.shouldDisabled(
                                                                            'document_no',
                                                                            index
                                                                        )}
                                                                    />
                                                                </InputGroup>
                                                                {index === 0 ? (
                                                                    <Button
                                                                        title="Add another range"
                                                                        variant="outline-primary"
                                                                        onClick={this.handleAddInputRange(
                                                                            'document_no'
                                                                        )}
                                                                        disabled={this.canAddInputRange(
                                                                            'document_no'
                                                                        )}
                                                                    >
                                                                        <FaPlus />
                                                                    </Button>
                                                                ) : (
                                                                    <Button
                                                                        title="Delete range"
                                                                        variant="outline-danger"
                                                                        onClick={this.handleRemoveInputRange(
                                                                            'document_no',
                                                                            index
                                                                        )}
                                                                    >
                                                                        <FaTimes />
                                                                    </Button>
                                                                )}
                                                            </div>
                                                        )
                                                    )}
                                                </Form.Group>
                                            </Col>

                                            {/* IRM No. */}
                                            <Col
                                                lg={6}
                                                className="order-lg-7 pr-lg-2"
                                            >
                                                <Form.Group>
                                                    <Form.Label>
                                                        IRM No.
                                                        <span className="text-danger h5">*</span>
                                                    </Form.Label>
                                                    {this.state.searchCriteria.irm_no.map(
                                                        (form, index) => (
                                                            <div
                                                                key={`irm-no-form-${index + 1}`}
                                                                className="d-flex"
                                                                // style={{ gap: '15px' }}
                                                            >
                                                                <InputGroup
                                                                    style={{ marginRight: '15px' }}
                                                                >
                                                                    <InputGroup.Prepend>
                                                                        <InputGroup.Text>
                                                                            From
                                                                        </InputGroup.Text>
                                                                    </InputGroup.Prepend>
                                                                    <Form.Control
                                                                        type="text"
                                                                        value={form.from}
                                                                        onChange={this.handleChangeInput(
                                                                            'irm_no',
                                                                            {
                                                                                key: 'from',
                                                                                atIndex: index
                                                                            }
                                                                        )}
                                                                    />
                                                                </InputGroup>
                                                                <InputGroup>
                                                                    <InputGroup.Prepend>
                                                                        <InputGroup.Text>
                                                                            To
                                                                        </InputGroup.Text>
                                                                    </InputGroup.Prepend>
                                                                    <Form.Control
                                                                        type="text"
                                                                        value={form.to}
                                                                        onChange={this.handleChangeInput(
                                                                            'irm_no',
                                                                            {
                                                                                key: 'to',
                                                                                atIndex: index
                                                                            }
                                                                        )}
                                                                        disabled={this.shouldDisabled(
                                                                            'irm_no',
                                                                            index
                                                                        )}
                                                                    />
                                                                </InputGroup>
                                                                {index === 0 ? (
                                                                    <Button
                                                                        title="Add another range"
                                                                        variant="outline-primary"
                                                                        onClick={this.handleAddInputRange(
                                                                            'irm_no'
                                                                        )}
                                                                        disabled={this.canAddInputRange(
                                                                            'irm_no'
                                                                        )}
                                                                    >
                                                                        <FaPlus />
                                                                    </Button>
                                                                ) : (
                                                                    <Button
                                                                        title="Delete range"
                                                                        variant="outline-danger"
                                                                        onClick={this.handleRemoveInputRange(
                                                                            'irm_no',
                                                                            index
                                                                        )}
                                                                    >
                                                                        <FaTimes />
                                                                    </Button>
                                                                )}
                                                            </div>
                                                        )
                                                    )}
                                                </Form.Group>
                                            </Col>

                                            {/* Reference Doc. */}
                                            <Col
                                                lg={6}
                                                className="order-lg-7 pr-lg-2"
                                            >
                                                <Form.Group>
                                                    <Form.Label>
                                                        Reference Doc.
                                                        <span className="text-danger h5">*</span>
                                                    </Form.Label>
                                                    {this.state.searchCriteria.ref_no.map(
                                                        (form, index) => (
                                                            <div
                                                                key={`irm-no-form-${index + 1}`}
                                                                className="d-flex"
                                                                // style={{ gap: '15px' }}
                                                            >
                                                                <InputGroup
                                                                    style={{ marginRight: '15px' }}
                                                                >
                                                                    <InputGroup.Prepend>
                                                                        <InputGroup.Text>
                                                                            From
                                                                        </InputGroup.Text>
                                                                    </InputGroup.Prepend>
                                                                    <Form.Control
                                                                        type="text"
                                                                        value={form.from}
                                                                        onChange={this.handleChangeInput(
                                                                            'ref_no',
                                                                            {
                                                                                key: 'from',
                                                                                atIndex: index
                                                                            }
                                                                        )}
                                                                    />
                                                                </InputGroup>
                                                                <InputGroup>
                                                                    <InputGroup.Prepend>
                                                                        <InputGroup.Text>
                                                                            To
                                                                        </InputGroup.Text>
                                                                    </InputGroup.Prepend>
                                                                    <Form.Control
                                                                        type="text"
                                                                        value={form.to}
                                                                        onChange={this.handleChangeInput(
                                                                            'ref_no',
                                                                            {
                                                                                key: 'to',
                                                                                atIndex: index
                                                                            }
                                                                        )}
                                                                        disabled={this.shouldDisabled(
                                                                            'ref_no',
                                                                            index
                                                                        )}
                                                                    />
                                                                </InputGroup>
                                                                {index === 0 ? (
                                                                    <Button
                                                                        title="Add another range"
                                                                        variant="outline-primary"
                                                                        onClick={this.handleAddInputRange(
                                                                            'ref_no'
                                                                        )}
                                                                        disabled={this.canAddInputRange(
                                                                            'ref_no'
                                                                        )}
                                                                    >
                                                                        <FaPlus />
                                                                    </Button>
                                                                ) : (
                                                                    <Button
                                                                        title="Delete range"
                                                                        variant="outline-danger"
                                                                        onClick={this.handleRemoveInputRange(
                                                                            'ref_no',
                                                                            index
                                                                        )}
                                                                    >
                                                                        <FaTimes />
                                                                    </Button>
                                                                )}
                                                            </div>
                                                        )
                                                    )}
                                                </Form.Group>
                                            </Col>

                                            {/* Year */}
                                            <Col
                                                lg={6}
                                                className="order-lg-2 pl-lg-2"
                                            >
                                                <Form.Group>
                                                    <Form.Label>Year</Form.Label>
                                                    {this.state.searchCriteriaPreview.year.map(
                                                        (form, index) => (
                                                            <div
                                                                key={`year-form-${index + 1}`}
                                                                className="d-flex"
                                                                style={{ gap: '15px' }}
                                                            >
                                                                <InputGroup className="flex-nowrap">
                                                                    <InputGroup.Prepend>
                                                                        <InputGroup.Text>
                                                                            From
                                                                        </InputGroup.Text>
                                                                    </InputGroup.Prepend>
                                                                    <DatePicker
                                                                        selected={form.from}
                                                                        onChange={this.handleChangeInputYear(
                                                                            'year',
                                                                            {
                                                                                key: 'from',
                                                                                atIndex: index
                                                                            }
                                                                        )}
                                                                        customInput={
                                                                            <Form.Control className="menu-form custom-datepicker" />
                                                                        }
                                                                        showYearPicker
                                                                        dateFormat="yyyy"
                                                                        placeholderText="yyyy"
                                                                        isClearable
                                                                    />
                                                                </InputGroup>
                                                                <InputGroup className="flex-nowrap">
                                                                    <InputGroup.Prepend>
                                                                        <InputGroup.Text>
                                                                            To
                                                                        </InputGroup.Text>
                                                                    </InputGroup.Prepend>
                                                                    <DatePicker
                                                                        selected={form.to}
                                                                        onChange={this.handleChangeInputYear(
                                                                            'year',
                                                                            {
                                                                                key: 'to',
                                                                                atIndex: index
                                                                            }
                                                                        )}
                                                                        customInput={
                                                                            <Form.Control className="menu-form custom-datepicker" />
                                                                        }
                                                                        showYearPicker
                                                                        dateFormat="yyyy"
                                                                        placeholderText="yyyy"
                                                                        isClearable
                                                                    />
                                                                </InputGroup>
                                                                {/*
                                                                (index === 0)
                                                                    ? (
                                                                        <Button
                                                                            title="Add another range"
                                                                            variant="outline-primary"
                                                                            onClick={this.handleAddInputRange('year')}
                                                                            disabled={this.canAddInputRange('year')}
                                                                        >
                                                                            <FaPlus />
                                                                        </Button>
                                                                    ) : (
                                                                        <Button
                                                                            title="Delete range"
                                                                            variant="outline-danger"
                                                                            onClick={this.handleRemoveInputRange('year', index)}
                                                                        >
                                                                            <FaTimes />
                                                                        </Button>
                                                                    )
                                                            */}
                                                            </div>
                                                        )
                                                    )}
                                                </Form.Group>
                                            </Col>

                                            {/* Posting Date */}
                                            <Col
                                                lg={6}
                                                className="order-lg-4 pl-lg-2"
                                            >
                                                <Form.Group>
                                                    <Form.Label>Posting Date</Form.Label>
                                                    {this.state.searchCriteriaPreview.posting_date.map(
                                                        (form, index) => (
                                                            <div
                                                                key={`posting-date-form-${
                                                                    index + 1
                                                                }`}
                                                                className="d-flex"
                                                                style={{ gap: '15px' }}
                                                            >
                                                                <InputGroup className="flex-nowrap">
                                                                    <InputGroup.Prepend>
                                                                        <InputGroup.Text>
                                                                            From
                                                                        </InputGroup.Text>
                                                                    </InputGroup.Prepend>
                                                                    <DatePicker
                                                                        selected={form.from}
                                                                        onChange={this.handleChangeInputDate(
                                                                            'posting_date',
                                                                            {
                                                                                key: 'from',
                                                                                atIndex: index
                                                                            }
                                                                        )}
                                                                        customInput={
                                                                            <Form.Control className="menu-form custom-datepicker" />
                                                                        }
                                                                        dateFormat="dd/MM/yyyy"
                                                                        placeholderText="dd/mm/yyyy"
                                                                        isClearable
                                                                    />
                                                                </InputGroup>
                                                                <InputGroup className="flex-nowrap">
                                                                    <InputGroup.Prepend>
                                                                        <InputGroup.Text>
                                                                            To
                                                                        </InputGroup.Text>
                                                                    </InputGroup.Prepend>
                                                                    <DatePicker
                                                                        selected={form.to}
                                                                        onChange={this.handleChangeInputDate(
                                                                            'posting_date',
                                                                            {
                                                                                key: 'to',
                                                                                atIndex: index
                                                                            }
                                                                        )}
                                                                        customInput={
                                                                            <Form.Control className="menu-form custom-datepicker" />
                                                                        }
                                                                        dateFormat="dd/MM/yyyy"
                                                                        placeholderText="dd/mm/yyyy"
                                                                        isClearable
                                                                    />
                                                                </InputGroup>
                                                                {/*
                                                                (index === 0)
                                                                    ? (
                                                                        <Button
                                                                            title="Add another range"
                                                                            variant="outline-primary"
                                                                            onClick={this.handleAddInputRange('posting_date')}
                                                                            disabled={this.canAddInputRange('posting_date')}
                                                                        >
                                                                            <FaPlus />
                                                                        </Button>
                                                                    ) : (
                                                                        <Button
                                                                            title="Delete range"
                                                                            variant="outline-danger"
                                                                            onClick={this.handleRemoveInputRange('posting_date', index)}
                                                                        >
                                                                            <FaTimes />
                                                                        </Button>
                                                                    )
                                                            */}
                                                            </div>
                                                        )
                                                    )}
                                                </Form.Group>
                                            </Col>

                                            {/* User */}
                                            <Col
                                                lg={6}
                                                className="order-lg-6 pl-lg-2"
                                            >
                                                <Form.Group>
                                                    <Form.Label>User</Form.Label>
                                                    {this.state.searchCriteriaPreview.user.map(
                                                        (form, index) => (
                                                            <div
                                                                key={`user-form-${index + 1}`}
                                                                className="d-flex"
                                                                style={{ gap: '15px' }}
                                                            >
                                                                <InputGroup className="d-flex flex-nowrap">
                                                                    <InputGroup.Prepend>
                                                                        <InputGroup.Text>
                                                                            From
                                                                        </InputGroup.Text>
                                                                    </InputGroup.Prepend>
                                                                    <div className="w-100">
                                                                        <Select
                                                                            value={{
                                                                                label: form.label,
                                                                                value: form.value
                                                                            }}
                                                                            menuPortalTarget={
                                                                                document.body
                                                                            }
                                                                            styles={{
                                                                                menuPortal: (
                                                                                    base: any
                                                                                ) => ({
                                                                                    ...base,
                                                                                    zIndex: 9999
                                                                                })
                                                                            }}
                                                                            className="dropdown metadata-group-font"
                                                                            options={
                                                                                this.state.userList
                                                                            }
                                                                            onChange={this.handleChangeUser(
                                                                                'from',
                                                                                index
                                                                            )}
                                                                            isDisabled={
                                                                                this.state
                                                                                    .searchCriteria
                                                                                    .company === ''
                                                                            }
                                                                            isClearable
                                                                        />
                                                                    </div>
                                                                </InputGroup>
                                                                {/*
                                                                (index === 0)
                                                                    ? (
                                                                        <Button
                                                                            title="Add another range"
                                                                            variant="outline-primary"
                                                                            onClick={this.handleAddInputRange('user')}
                                                                            disabled={this.canAddInputRange('user')}
                                                                        >
                                                                            <FaPlus />
                                                                        </Button>
                                                                    ) : (
                                                                        <Button
                                                                            title="Delete range"
                                                                            variant="outline-danger"
                                                                            onClick={this.handleRemoveInputRange('user', index)}
                                                                        >
                                                                            <FaTimes />
                                                                        </Button>
                                                                    )
                                                            */}
                                                            </div>
                                                        )
                                                    )}
                                                </Form.Group>
                                            </Col>

                                            <Col
                                                xl={12}
                                                className="order-lg-last text-right"
                                            >
                                                <Button
                                                    variant="outline-success"
                                                    size="sm"
                                                    type="submit"
                                                    disabled={!this.canSearch()}
                                                >
                                                    <FaSearch />
                                                    &nbsp;ค้นหา
                                                </Button>
                                            </Col>
                                        </Form.Row>
                                    </form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <SearchCriteriaTable {...(searchCriteriaTableProps as any)} />
                </div>
            </div>
        );
    }
}

const enhance = connect(
    (state: ApplicationState) => ({
        ...state.companyMaster,
        ...state.CheckCompanyUser,
        isLoadingDocumentType: state.searchCriteria?.isLoadingDocumentType
    }),
    {
        ...SearchCriteriaStore.actionCreators,
        ...CompanyMasterStore.actionCreators,
        ...CheckCompanyUserStore.actionCreators
    }
);
export default enhance(SearchWithCriteriaComponent as any);
