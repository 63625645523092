import React from 'react';
import { Button } from '@chakra-ui/react';
import { FiChevronLeft, FiChevronRight, FiChevronsLeft, FiChevronsRight } from 'react-icons/fi';

import '../../styles/TwoSideSelector.css';

interface States {
    isInitialAvailableUsers: boolean;
    availableUsers: string[];
    selectedUsers: string[];
    backupAvailableUsers: string[];
    backupSelectedUsers: string[];
    checkAvailableUsers: boolean[];
    checkSelectedUser: boolean[];
}

interface Props {
    shareFolderName?: string;
    employeeList: string[];
    sharedUsers: string[];
    onSelectUser: (selectedUsers: string[]) => void;
    onClose: () => void;
}

class ShareUserSelector extends React.Component<Props, States> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isInitialAvailableUsers: false,
            availableUsers: [],
            selectedUsers: [],
            backupAvailableUsers: [],
            backupSelectedUsers: [],
            checkAvailableUsers: [],
            checkSelectedUser: []
        };

        this.handleBackupAvailableUser = this.handleBackupAvailableUser.bind(this);
        this.handleBackupSelectedUser = this.handleBackupSelectedUser.bind(this);
        this.handleMoveToSelectedUser = this.handleMoveToSelectedUser.bind(this);
        this.handleMoveToAvailableUser = this.handleMoveToAvailableUser.bind(this);
        this.handleMoveToSelectedAll = this.handleMoveToSelectedAll.bind(this);
        this.handleMoveToAvailableAll = this.handleMoveToAvailableAll.bind(this);
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<States>) {
        if (
            this.props.employeeList.length > 0 &&
            this.state.availableUsers.length === 0 &&
            !this.state.isInitialAvailableUsers
        ) {
            this.initialAvailableUsers();
        }
    }

    initialAvailableUsers = () => {
        this.setState({
            availableUsers: this.props.employeeList,
            isInitialAvailableUsers: true
        });
    };

    setStatusRowAvailableUser = (index: number) => {
        const newStatusAvailableUser = [...this.state.checkAvailableUsers];
        newStatusAvailableUser[index] = !newStatusAvailableUser[index];

        this.setState({ checkAvailableUsers: newStatusAvailableUser });
    };

    setStatusRowSelected = (index: number) => {
        const newStatusSelected = [...this.state.checkSelectedUser];
        newStatusSelected[index] = !newStatusSelected[index];

        this.setState({
            checkSelectedUser: newStatusSelected
        });
    };

    setStatusRowIsFalseAll = () => {
        const { availableUsers, selectedUsers } = this.state;

        this.setState({
            checkAvailableUsers: availableUsers.map(employee => false),
            checkSelectedUser: selectedUsers.map(employee => false)
        });
    };

    handleBackupAvailableUser = (index: number, rowCurrent: string) => {
        if (!this.state.backupAvailableUsers.includes(rowCurrent)) {
            this.setState(prevState => ({
                backupAvailableUsers: [...prevState.backupAvailableUsers, rowCurrent]
            }));

            this.setStatusRowAvailableUser(index);
        } else {
            this.setState({
                backupAvailableUsers: this.state.backupAvailableUsers.filter(
                    rowSame => rowSame !== rowCurrent
                )
            });

            this.setStatusRowAvailableUser(index);
        }
    };

    handleBackupSelectedUser = (index: number, rowCurrent: string) => {
        if (!this.state.backupSelectedUsers.includes(rowCurrent)) {
            this.setState(prevState => ({
                backupSelectedUsers: [...prevState.backupSelectedUsers, rowCurrent]
            }));

            this.setStatusRowSelected(index);
        } else {
            this.setState({
                backupSelectedUsers: this.state.backupSelectedUsers.filter(
                    rowSame => rowSame !== rowCurrent
                )
            });

            this.setStatusRowSelected(index);
        }
    };

    handleMoveToSelectedUser = () => {
        if (this.state.backupAvailableUsers) {
            this.setState({
                selectedUsers: [...this.state.selectedUsers, ...this.state.backupAvailableUsers],
                availableUsers: this.state.availableUsers.filter(
                    availableUser => !this.state.backupAvailableUsers.includes(availableUser)
                ),
                backupAvailableUsers: []
            });

            this.setStatusRowIsFalseAll();
        }
    };

    handleMoveToAvailableUser = () => {
        if (this.state.backupSelectedUsers) {
            this.setState({
                availableUsers: [...this.state.availableUsers, ...this.state.backupSelectedUsers],
                selectedUsers: this.state.selectedUsers.filter(
                    selectedUser => !this.state.backupSelectedUsers.includes(selectedUser)
                ),
                backupSelectedUsers: []
            });

            this.setStatusRowIsFalseAll();
        }
    };

    handleMoveToSelectedAll = () => {
        this.setState(prevState => ({
            selectedUsers: [...prevState.selectedUsers, ...this.state.availableUsers],
            availableUsers: [],
            backupSelectedUsers: []
        }));

        this.setStatusRowIsFalseAll();
    };

    handleMoveToAvailableAll = () => {
        this.setState(prevState => ({
            availableUsers: [...prevState.availableUsers, ...this.state.selectedUsers],
            selectedUsers: [],
            backupAvailableUsers: []
        }));

        this.setStatusRowIsFalseAll();
    };

    commitSelectedUser = () => {
        this.props.onSelectUser(this.state.selectedUsers);
    };

    render() {
        const selectorAction = [
            {
                onClick: this.handleMoveToSelectedUser,
                Icon: FiChevronRight
            },
            {
                onClick: this.handleMoveToAvailableUser,
                Icon: FiChevronLeft
            },
            {
                onClick: this.handleMoveToSelectedAll,
                Icon: FiChevronsRight
            },
            {
                onClick: this.handleMoveToAvailableAll,
                Icon: FiChevronsLeft
            }
        ];

        return (
            <React.Fragment>
                <p className="flex flex-col px-2 text-sm font-bold text-[#7A7A7A]">
                    Share : folder name
                </p>

                <div className="mt-3 flex">
                    {/* User Option */}
                    <div className="flex min-h-[166px] flex-1 flex-col bg-[#F7F7F7] p-2.5">
                        <div className="inline-flex  gap-2.5 px-2.5 pb-2.5">
                            <div className="text-sm font-medium text-black">Available</div>
                        </div>

                        <ul className="[&>li:not(:last-child)]:border-b">
                            {this.state.availableUsers
                                .filter(rowOption => {
                                    return !this.props.sharedUsers.includes(rowOption);
                                })
                                .map((rowOption, index) => {
                                    return (
                                        <li
                                            key={index}
                                            className="d-flex align-items-center justify-content-between cursor-pointer bg-white px-2 py-2"
                                            onClick={() =>
                                                this.handleBackupAvailableUser(index, rowOption)
                                            }
                                        >
                                            <span className="text-sm font-normal">{rowOption}</span>
                                            <input
                                                type="checkbox"
                                                className="h-4 w-4 cursor-pointer accent-[#3397C3]"
                                                checked={this.state.checkAvailableUsers[index]}
                                            />
                                        </li>
                                    );
                                })}
                        </ul>
                    </div>

                    {/* Add and Remove button */}
                    <div className="flex flex-col items-center justify-center gap-2.5 p-2.5">
                        {selectorAction.map(({ onClick, Icon }) => (
                            <Button
                                className="flex h-6 w-3 min-w-min items-center justify-center rounded-[4px] border border-[#7A7A7A] bg-white p-0.5 hover:border-[#3397C3]"
                                onClick={onClick}
                            >
                                <Icon
                                    size={20}
                                    color="#7A7A7A"
                                    className="hover:text-[#3397C3]"
                                />
                            </Button>
                        ))}
                        {/* <div
                            style={{ gap: 3, padding: '30px' }}
                            className="d-flex flex-column align-items-center justify-content-center"
                        >
                            <button
                                onClick={this.handleMoveToSelectedUser}
                                className="rounded-md border-2 border-gray-400 p-2 text-gray-400 hover:border-primary-800 hover:text-primary-800"
                            >
                                <AiOutlineRight size={18} />
                            </button>
                            <button
                                onClick={this.handleMoveToAvailableUser}
                                className="rounded-md border-2 border-gray-400 p-2 text-gray-400 hover:border-primary-800 hover:text-primary-800"
                            >
                                <AiOutlineLeft size={18} />
                            </button>
                            <button
                                onClick={this.handleMoveToSelectedAll}
                                className="rounded-md border-2 border-gray-400 p-2 text-gray-400 hover:border-primary-800 hover:text-primary-800"
                            >
                                <AiOutlineDoubleRight size={18} />
                            </button>
                            <button
                                onClick={this.handleMoveToAvailableAll}
                                className="rounded-md border-2 border-gray-400 p-2 text-gray-400 hover:border-primary-800 hover:text-primary-800"
                            >
                                <AiOutlineDoubleLeft size={18} />
                            </button>
                        </div> */}
                    </div>

                    {/* Selected User */}
                    <div className="flex min-h-[166px] flex-1 flex-col bg-[#F7F7F7] p-2.5">
                        <div className="inline-flex gap-2.5 px-2.5 pb-2.5">
                            <div className="text-sm font-medium text-black">Selected User</div>
                        </div>
                        <ul className="[&>li:not(:last-child)]:border-b">
                            {this.state.selectedUsers.map((rowSelected, index) => {
                                return (
                                    <li
                                        key={index}
                                        className="d-flex align-items-center justify-content-between cursor-pointer bg-white px-2 py-2"
                                        onClick={() =>
                                            this.handleBackupSelectedUser(index, rowSelected)
                                        }
                                    >
                                        <span>{rowSelected}</span>
                                        <input
                                            type="checkbox"
                                            className="h-4 w-4 cursor-pointer accent-[#3397C3]"
                                            checked={this.state.checkSelectedUser[index]}
                                        />
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>

                {/* Apply Button */}
                <div className="mt-3 flex flex-row justify-end gap-2 p-1">
                    <Button
                        variant={'outline'}
                        onClick={this.props.onClose}
                        className="h-9 rounded-md border border-[#D8D8D8] px-7 text-[#999999]"
                    >
                        <span className="px-[15px] text-sm font-medium">Cancel</span>
                    </Button>
                    <Button
                        colorScheme="primary"
                        className="h-9 px-4"
                        onClick={this.commitSelectedUser.bind(this)}
                    >
                        <span className="px-[15px] text-sm font-medium">Apply</span>
                    </Button>
                </div>
            </React.Fragment>
        );
    }
}

export default ShareUserSelector;
