import * as React from 'react';

class DownloadView extends React.Component<any, { file_path: string }> {
    constructor(props: any) {
        super(props);

        this.state = {
            file_path: '',
        }
    }

    componentDidMount() {
        var { filePath } = this.props
        //console.log(filePath)
        //window.location.href = filePath;

        fetch(filePath, {
            method: 'GET',
        }).then(function (resp) {
            return resp.blob();
        }).then(function (blob) {
            const newBlob = new Blob([blob], { /*type: "application/pdf", charset: "UTF-8"*/ })

            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob);

                return;
            }
            const data = window.URL.createObjectURL(newBlob);
            const link = document.createElement('a');
            //link.dataType = "json";
            link.href = data;
            link.download = filePath.substr(filePath.lastIndexOf('file_name=') + 10);
            link.dispatchEvent(new MouseEvent('click'));
            setTimeout(function () {
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(data)
            }, 60);
        });
    }

    render() {
        let { file_path } = this.state
        if (file_path) {
            return (
                <div>
                </div>
            );
        } else {
            return (
                <div></div>
            );
        }
    }
}

export default DownloadView;