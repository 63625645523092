import * as React from 'react';
import {
    Modal,
    ModalHeader,
    ModalFooter,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Input,
    Label,
    ModalBody
} from 'reactstrap';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';

import { ApplicationState } from '../../../store';
import * as Stores from '../../../store/FolderManage';
import * as Models from '../../../models/Folder';

import * as StoresMetaData from '../../../store/MetaData';
import * as ModelsMetaDataGroup from '../../../models/MetaDataGroup';
import * as StoreMetaDataGroup from '../../../store/MetaDataGroup';

import * as StoresRoleAccess from '../../../store/RoleAccess';
import * as ModelsRoleAccess from '../../../models/RoleAccess';

import FolderImg from '../../../Images/Icon/folder.png';

import { UserContext, IUserContext } from '../../../context/UserProvider';
import { convertFullPathToParentFolder } from '../../../modules/common';
import ModalShareFileComponent from '../../../components/dms/ShareFile/ModalShareFileComponent';

//Select Box
import Select from 'react-select';
import RenameFolder from '../../../presentations/view/RenameFolder/RenameFolder.view';
import { Button } from '../../materials';
import AddFolder from '../../../presentations/view/AddFolder/AddFolder.view';
import EditFolder from '../../../presentations/view/EditFolder/EditFolder.view';
import DeleteFolder from '../../../presentations/view/DeleteFolder/DeleteFolder.view';

import withLoader from 'components/common/hoc/withLoader';

interface State {
    check: Models.checkFolder;
    pageLoad: boolean;
    modelListRoleAccess: Array<ModelsRoleAccess.RoleAccess>;
    modelRoleAccess: ModelsRoleAccess.RoleAccess;
    valueSelectMetaGroup: any;
    modelListGet: Array<ModelsMetaDataGroup.MetaDataGroup>;
    modelListGetDetail: Array<ModelsMetaDataGroup.MetaDataGroup>;
    version: number;
    nameMetaOrRole: string;
    multiValue: any;
    valueSelect: any;
    modelMetaDataGroup: ModelsMetaDataGroup.MetaDataGroup;
    switchPageMetaOrRole: any;
    isOpenModalMeta: any;
    valueRole: any;
    valueMeataData: any;
    isOpenModal: boolean;
    modelFolder: Models.Folder;
    isSuccess?: boolean;
    folderName: string;
    parentFolder: string;
    valueSelectRole: any;
    valueSelectFileExtension: any;
    isOpenModalShare: boolean;

    bucket: any;
    isShowFolderMap: Map<number, boolean>;
    isSaveClick: boolean; // use `React.useRef` after re-structure to function component?
}

type NAVIGATOR = 'rename' | 'add' | 'edit' | 'delete' | 'share';

class FolderManageMenuNavigate extends React.Component<any, State> {
    static contextType: React.Context<any> | undefined = UserContext;

    public context!: React.ContextType<typeof UserContext>;

    constructor(props: any) {
        super(props);

        this.state = {
            isOpenModal: false,
            isOpenModalMeta: false,
            check: {
                checkPdf: false,
                checkPng: false,
                checkJpg: false,
                checkZip: false,
                checkWork: false,
                checlAll: false,
                discheckPdf: false,
                discheckPng: false,
                discheckJpg: false,
                discheckZip: false,
                discheckWork: false
            },
            switchPageMetaOrRole: '',
            nameMetaOrRole: '',
            multiValue: [],
            valueSelect: [],
            valueSelectMetaGroup: [],
            valueSelectRole: [],
            valueSelectFileExtension: [],
            version: 0,
            modelListGet: [],
            modelListGetDetail: [],
            modelFolder: {
                sid: '',
                company_code: '',
                folder_name: '',
                full_path: '',
                node_code: '',
                node_parent: '',
                created_by: '',
                created_on: '',
                updated_by: '',
                updated_on: '',
                node_level: 0,
                metadata_group_code: '',
                role_access_code: '',
                max_file_size: '',
                file_extension: '',
                owner: ''
            },
            modelMetaDataGroup: {
                sid: 'DEV',
                metadata_group_code: 'metadata_group_code',
                metadata_group_name: 'metadata_group_name',
                metadata_code: 'metadata_code',
                metadata_name: 'metadata_name',
                created_by: 'created_by',
                created_on: 'created_on',
                updated_by: 'updated_by',
                updated_on: 'updated_on'
            },
            isSuccess: undefined,
            folderName: '',
            parentFolder: '',
            valueMeataData: [],
            valueRole: [],
            modelRoleAccess: {
                sid: 'DEV',
                role_access_code: '',
                role_access_name: '',
                created_by: '',
                created_on: '',
                updated_by: '',
                updated_on: '',
                can_view_file: false,
                can_edit_file: false,
                can_edit_folder: false,
                can_edit_box: false,
                can_edit_relation: false,
                can_create_file: false,
                can_create_folder: false,
                can_create_box: false,
                can_create_relation: false
            },
            modelListRoleAccess: [],
            pageLoad: true,
            isOpenModalShare: false,

            bucket: '',
            isShowFolderMap: new Map(),
            isSaveClick: false
        };

        this.toggleModal = this.toggleModal.bind(this);
        this.toggleModalMeta = this.toggleModalMeta.bind(this);
        this.handleChangeName = this.handleChangeName.bind(this);
        this.handleChangeMataDataCode = this.handleChangeMataDataCode.bind(this);
        this.handleChangeMataDataName = this.handleChangeMataDataName.bind(this);
        this.handleChangeFileExtension = this.handleChangeFileExtension.bind(this);
        this.renameClick = this.renameClick.bind(this);
        this.addClick = this.addClick.bind(this);
        this.editClick = this.editClick.bind(this);
        this.deleteClick = this.deleteClick.bind(this);
        this.actionsClick = this.actionsClick.bind(this);
    }

    private toggleModal() {
        this.setState({
            isOpenModal: !this.state.isOpenModal
        });
    }

    private toggleModalMeta(value) {
        this.setState({
            isOpenModalMeta: !this.state.isOpenModalMeta
        });
        if (value === 'Meta') {
            this.props.requestFunction(this.state.version, 'GET');
            this.setState({
                switchPageMetaOrRole: value,
                nameMetaOrRole: 'Add Mata Data Group',
                valueSelect: [],
                multiValue: []
            });
        } else if (value === 'Role') {
            this.setState({ switchPageMetaOrRole: value, nameMetaOrRole: 'Add Role Access' });
        } else if (value === 'clear') {
            this.setState({
                valueSelect: [],
                multiValue: []
            });
        }
    }

    componentDidMount() {
        this.props.requestFunction(this.state.version, 'GET');
        this.props.requestFunctionGroup(this.state.version, 'GET');
        this.setState({ pageLoad: true });
        this.props.requestFunctionRole(this.state.pageLoad, this.state.version, 'GET', {
            role_access_code: ''
        });
        this.setState({ pageLoad: false });
    }

    componentDidUpdate() {
        const { response, versionGroup, isLoading } = this.props;
        if (this.props.isLoadingGroup === true) {
            this.formatDataGet(versionGroup);
        }

        if (this.props.isLoadingRole) {
            if (response !== undefined) {
                var DataSelectArray = this.state.valueSelectRole;
                for (var i = 0; i < response.total_count; i++) {
                    let data = {};
                    data['value'] = response.result_list[i].role_access_code;
                    data['label'] = response.result_list[i].role_access_name;
                    DataSelectArray.push(data);
                }
            }
        }

        if (this.props.dataModal.isOpenModal) {
            this.props.afterOpenModal();

            let dataModal = this.props.dataModal;
            // console.log('[Debug] dataModal', dataModal);

            var valueRole: any;
            var valueMeataData: any;
            var modelFolder: Models.Folder = {
                sid: dataModal.sid,
                company_code: '',
                folder_name: dataModal.folderName,
                node_code: dataModal.nodeCode,
                node_parent: dataModal.nodeParent,
                created_by: '',
                created_on: '',
                updated_by: '',
                updated_on: '',
                node_level: dataModal.nodeLevel ? parseInt(dataModal.nodeLevel) : 0,
                full_path: dataModal.fullPath,
                metadata_group_code: dataModal.metadata_group_code,
                role_access_code: dataModal.metadata_group_code,
                max_file_size: dataModal.metadata_group_code,
                file_extension: dataModal.metadata_group_code,
                owner: dataModal.owner
            };

            if (dataModal.mode === 'add') {
                modelFolder.folder_name = '';
                modelFolder.node_parent = dataModal.nodeCode;
                modelFolder.node_level = dataModal.nodeLevel
                    ? parseInt(dataModal.nodeLevel) + 1
                    : 0;
                modelFolder.node_code = '';
                modelFolder.metadata_group_code = '-';
                modelFolder.role_access_code = '*';
                modelFolder.max_file_size = '10485760'; //10MB
                modelFolder.file_extension = '*';

                valueMeataData = ['-'];
                valueRole = ['*'];
            } else if (dataModal.mode === 'edit') {
                modelFolder.folder_name = this.props.detailFolder.folder_name;
                modelFolder.node_parent = dataModal.nodeParent;
                modelFolder.node_level = dataModal.nodeLevel ? parseInt(dataModal.nodeLevel) : 0;
                modelFolder.node_code = this.props.detailFolder.node_code;
                modelFolder.metadata_group_code = this.props.detailFolder.metadata_group_code;
                modelFolder.role_access_code = this.props.detailFolder.role_access_code;
                modelFolder.max_file_size = this.props.detailFolder.max_file_size; //10MB
                modelFolder.file_extension = this.props.detailFolder.file_extension;

                var DataSelectArray: any = [];
                var DataSelectMeta: any = [];
                var rolData = this.props.detailFolder.role_access_code.split('|');

                let data = {};
                data['value'] = this.props.detailFolder.metadata_group_code;
                data['label'] = this.props.detailFolder.metadata_group_code;
                DataSelectMeta.push(data);

                for (var i = 0; i < rolData.length; i++) {
                    let data = {};
                    data['value'] = rolData[i];
                    data['label'] = rolData[i];
                    DataSelectArray.push(data);
                }

                valueMeataData = DataSelectMeta;
                valueRole = DataSelectArray;

                var valuetype = this.props.detailFolder.file_extension.split('/');

                var checkvalue: any = [];
                for (let v = 0; v < valuetype.length; v++) {
                    console.log(valuetype[v]);
                    if (valuetype[v] === '.pdf') {
                        checkvalue.checkPdf = true;
                    } else if (valuetype[v] === '.png') {
                        checkvalue.checkPng = true;
                    } else if (valuetype[v] === '.jpeg|.jpg') {
                        checkvalue.checkJpg = true;
                    } else if (valuetype[v] === '.zip') {
                        checkvalue.checkZip = true;
                    } else if (valuetype[v] === '.doc|.docx|.xls|.xlsx|.ppt|.pptx') {
                        checkvalue.checkWork = true;
                    } else if (valuetype[v] === '*') {
                        checkvalue.checlAll = true;
                        checkvalue.discheckPdf = true;
                        checkvalue.discheckPng = true;
                        checkvalue.discheckJpg = true;
                        checkvalue.discheckZip = true;
                        checkvalue.discheckWork = true;
                        break;
                    }
                }
                this.setState({
                    check: checkvalue
                });
            } else if (dataModal.mode === 'share') {
                modelFolder.folder_name = dataModal.folderName;
                modelFolder.node_code = dataModal.nodeCode;

                this.setState({
                    modelFolder: modelFolder,
                    isOpenModalShare: !this.state.isOpenModalShare
                });

                return;
            }

            this.setState({
                isOpenModal: true,
                folderName: dataModal.folderName,
                parentFolder: convertFullPathToParentFolder(dataModal.fullPath),
                modelFolder: modelFolder,
                valueMeataData: valueMeataData,
                valueRole: valueRole
            });
        }

        if (this.props.isSuccess === false) {
            this.afterError();
        } else if (this.props.isSuccess === true) {
            this.afterSuccess();
        }
    }

    public setModelFolderState(key: keyof Models.Folder, value: string) {
        const modelFolder = { ...this.state.modelFolder, [key]: value } as Models.Folder;

        this.setState({ modelFolder });
    }

    private handleChangeName(e: any) {
        var folder: Models.Folder = this.state.modelFolder;
        folder.folder_name = e.target.value;

        this.setState({ modelFolder: folder });
    }

    private handleChangeMaxFileSize(e: any) {
        var folder: Models.Folder = this.state.modelFolder;
        folder.max_file_size = e.target.value;

        this.setState({ modelFolder: folder });
    }

    private handleChangeFileExtension(e: any) {
        var name = 'cbFileExtension';
        var Uniqe = 'cbFileExtensionUniqe';
        var checkdisable: boolean = false;
        Array.from(document.querySelectorAll('input[name=' + Uniqe + ']')).forEach(checkbox => {
            var cb = checkbox as HTMLInputElement;
            if (cb.checked === true) {
                checkdisable = true;
            }
        });

        Array.from(document.querySelectorAll('input[name=' + name + ']')).forEach(checkbox => {
            var cb = checkbox as HTMLInputElement;
            if (checkdisable === true) {
                cb.checked = false;
                cb.disabled = true;
            } else if (checkdisable === false) {
                cb.disabled = false;
            }
        });
    }

    private renameClick(e: any) {
        this.props.requestFunctionFolder('RENAME', this.state.modelFolder);
        document.getElementsByClassName('Loader__background')[0].className =
            'Loader__background show';
    }

    private addClick(e: any) {
        this.setState({ isSaveClick: true });
        return;

        var valueRole: string = '';
        var valueCheck: string = '';
        for (let i = 0; i < this.state.valueRole.length; i++) {
            if (i === 0) {
                valueRole += this.state.valueRole[i].value;
            } else {
                valueRole += '|' + this.state.valueRole[i].value;
            }
        }
        var Uniqe = 'cbFileExtensionUniqe';
        var checkdisable: boolean = false;
        Array.from(document.querySelectorAll('input[name=' + Uniqe + ']')).forEach(checkbox => {
            var cb = checkbox as HTMLInputElement;
            if (cb.checked === true) {
                checkdisable = true;
                if (cb.dataset.fileextension) {
                    valueCheck = cb.dataset.fileextension;
                }
            }
        });
        if (checkdisable === false) {
            var name = 'cbFileExtension';
            Array.from(document.querySelectorAll('input[name=' + name + ']')).forEach(
                (checkbox, key) => {
                    var cb = checkbox as HTMLInputElement;
                    if (cb.checked) {
                        if (key === 0) {
                            valueCheck += cb.dataset.fileextension;
                        } else {
                            valueCheck += '/' + cb.dataset.fileextension;
                        }
                    }
                }
            );
        }

        const { id: username }: IUserContext = this.context;
        var { dataModal } = this.props;

        var data = this.state.modelFolder;
        data.sid = dataModal.nodeCode === 'N-MB_' ? username : dataModal.sid;
        data.metadata_group_code = this.state.valueMeataData.value;
        data.role_access_code = valueRole;
        data.file_extension = valueCheck;

        this.setState({
            modelFolder: data
        });

        this.props.requestFunctionFolder('ADD', this.state.modelFolder);
        document.getElementsByClassName('Loader__background')[0].className =
            'Loader__background show';
    }

    private editClick(e: any) {
        var valueRole: string = '';
        var valueCheck: string = '';
        for (let i = 0; i < this.state.valueRole.length; i++) {
            if (i === 0) {
                valueRole += this.state.valueRole[i].value;
            } else {
                valueRole += '|' + this.state.valueRole[i].value;
            }
        }
        var Uniqe = 'cbFileExtensionUniqe';
        var checkdisable: boolean = false;
        Array.from(document.querySelectorAll('input[name=' + Uniqe + ']')).forEach(checkbox => {
            var cb = checkbox as HTMLInputElement;
            if (cb.checked === true) {
                checkdisable = true;
                if (cb.dataset.fileextension) {
                    valueCheck = cb.dataset.fileextension;
                }
            }
        });
        if (checkdisable === false) {
            var name = 'cbFileExtension';
            Array.from(document.querySelectorAll('input[name=' + name + ']')).forEach(
                (checkbox, key) => {
                    var cb = checkbox as HTMLInputElement;
                    if (cb.checked) {
                        if (key === 0) {
                            valueCheck += cb.dataset.fileextension;
                        } else {
                            valueCheck += '/' + cb.dataset.fileextension;
                        }
                    }
                }
            );
        }
        var data = this.state.modelFolder;
        if (this.state.valueMeataData.value !== undefined) {
            data.metadata_group_code = this.state.valueMeataData.value;
        }
        data.role_access_code = valueRole;
        data.file_extension = valueCheck;

        this.setState({
            modelFolder: data
        });

        this.props.requestFunctionFolder('EDIT', this.state.modelFolder);
        document.getElementsByClassName('Loader__background')[0].className =
            'Loader__background show';
    }

    private deleteClick(e: any) {
        this.props.requestFunctionFolder('DELETE', this.state.modelFolder);
        document.getElementsByClassName('Loader__background')[0].className =
            'Loader__background show';
    }

    handleChange = (newValue: any, actionMeta: any, check: string) => {
        if (check === 'Meta') {
            this.setState({ valueMeataData: newValue });
        } else if (check === 'Role') {
            this.setState({ valueRole: newValue });
        }
    };

    handleInputChange = (inputValue: any, actionMeta: any, check: string) => {};

    setTextHeaderModal = () => {
        var txtHead = '';
        if (this.props.dataModal.mode === 'rename') {
            txtHead = 'Rename folder ';
        } else if (this.props.dataModal.mode === 'add') {
            txtHead = 'Add folder in ';
        } else if (this.props.dataModal.mode === 'edit') {
            txtHead = 'Edit folder in ';
        } else if (this.props.dataModal.mode === 'delete') {
            txtHead = 'Delete folder ';
        }
        return txtHead;
    };

    private handleChangeMataDataCode(e: any, check: any) {
        if (check === 'Meta') {
            var data = this.state.modelMetaDataGroup;
            data.metadata_group_code = e.target.value;

            this.setState({
                modelMetaDataGroup: data
            });
        } else if (check === 'Role') {
            var dataRole = this.state.modelRoleAccess;
            dataRole.role_access_code = e.target.value;

            this.setState({
                modelRoleAccess: dataRole
            });
        }
    }

    private handleChangeMataDataName(e: any, check) {
        if (check === 'Meta') {
            var data = this.state.modelMetaDataGroup;
            data.metadata_group_name = e.target.value;

            this.setState({
                modelMetaDataGroup: data
            });
        } else if (check === 'Role') {
            var dataRole = this.state.modelRoleAccess;
            dataRole.role_access_name = e.target.value;

            this.setState({
                modelRoleAccess: dataRole
            });
        }
    }

    private handleChangeFile(e: any, check: string) {
        var data = this.state.modelRoleAccess;

        if (check === 'viewfile') {
            data.can_view_file = e.target.checked;
        } else if (check === 'editfile') {
            data.can_edit_file = e.target.checked;
        } else if (check === 'editfolder') {
            data.can_edit_folder = e.target.checked;
        } else if (check === 'editbox') {
            data.can_edit_box = e.target.checked;
        } else if (check === 'editrelation') {
            data.can_edit_relation = e.target.checked;
        } else if (check === 'createfile') {
            data.can_create_file = e.target.checked;
        } else if (check === 'createfolder') {
            data.can_create_folder = e.target.checked;
        } else if (check === 'createbox') {
            data.can_create_box = e.target.checked;
        } else if (check === 'createrelation') {
            data.can_create_relation = e.target.checked;
        }

        this.setState({
            modelRoleAccess: data
        });
    }

    handleValueSelectAdd = (e, { action }) => {
        this.setState({
            multiValue: Array.isArray(e) ? e.map(x => x.value) : []
        });
    };

    setBodyModalMeta = () => {
        const { switchPageMetaOrRole } = this.state;
        if (switchPageMetaOrRole === 'Meta') {
            return (
                <div style={{ paddingBottom: 0 }}>
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText className="metadata-group-font">
                                Metadata Group Code
                            </InputGroupText>
                        </InputGroupAddon>
                        <Input
                            placeholder=""
                            type="text"
                            onChange={e => this.handleChangeMataDataCode(e, 'Meta')}
                        />
                    </InputGroup>
                    <br />
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText className="metadata-group-font">
                                Metadata Group Name
                            </InputGroupText>
                        </InputGroupAddon>
                        <Input
                            placeholder=""
                            type="text"
                            onChange={e => this.handleChangeMataDataName(e, 'Meta')}
                        />
                    </InputGroup>
                    <br />
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText
                                className="metadata-group-font"
                                style={{ width: 190 }}
                            >
                                Select Metadata
                            </InputGroupText>
                        </InputGroupAddon>
                        <div style={{ width: '80%' }}>
                            <Select
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                className="dropdown metadata-group-font"
                                placeholder="Select Metadata"
                                value={this.state.valueSelect.filter(obj =>
                                    this.state.multiValue.includes(obj.value)
                                )}
                                options={this.state.valueSelect}
                                onChange={this.handleValueSelectAdd}
                                isMulti
                                isClearable
                            />
                        </div>
                    </InputGroup>
                </div>
            );
        } else if (switchPageMetaOrRole === 'Role') {
            return (
                <div>
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText className="role-font">Role Access Code</InputGroupText>
                        </InputGroupAddon>
                        <Input
                            placeholder="Role Access Code"
                            type="text"
                            onChange={e => this.handleChangeMataDataCode(e, 'Role')}
                        />
                    </InputGroup>
                    <br />
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText className="role-font">Role Access Name</InputGroupText>
                        </InputGroupAddon>
                        <Input
                            placeholder="Role Access Name"
                            type="text"
                            onChange={e => this.handleChangeMataDataName(e, 'Role')}
                        />
                    </InputGroup>
                    <InputGroup className="mt-3">
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText style={{ width: '160px' }}>Permission</InputGroupText>
                        </InputGroupAddon>

                        <div
                            className="row"
                            style={{ width: '80%' }}
                        >
                            <div
                                className="column"
                                style={{ marginLeft: '50px', width: '25%' }}
                            >
                                <InputGroup check="true">
                                    <Label check>
                                        <Input
                                            type="checkbox"
                                            name="cbFileExtension"
                                            data-fileextension="*"
                                            onChange={e => this.handleChangeFile(e, 'viewfile')}
                                        />{' '}
                                        View File
                                    </Label>
                                </InputGroup>
                            </div>
                            <div
                                className="column"
                                style={{ marginLeft: '50px', width: '25%' }}
                            >
                                <InputGroup check="true">
                                    <Label check>
                                        <Input
                                            type="checkbox"
                                            name="cbFileExtension"
                                            data-fileextension="*"
                                            onChange={e => this.handleChangeFile(e, 'editfile')}
                                        />{' '}
                                        Edit File
                                    </Label>
                                </InputGroup>
                                <InputGroup check="true">
                                    <Label check>
                                        <Input
                                            type="checkbox"
                                            name="cbFileExtension"
                                            data-fileextension="*"
                                            onChange={e => this.handleChangeFile(e, 'editfolder')}
                                        />{' '}
                                        Edit Folder
                                    </Label>
                                </InputGroup>
                                <InputGroup check="true">
                                    <Label check>
                                        <Input
                                            type="checkbox"
                                            name="cbFileExtension"
                                            data-fileextension="*"
                                            onChange={e => this.handleChangeFile(e, 'editbox')}
                                        />{' '}
                                        Edit Box
                                    </Label>
                                </InputGroup>
                                <InputGroup check="true">
                                    <Label check>
                                        <Input
                                            type="checkbox"
                                            name="cbFileExtension"
                                            data-fileextension="*"
                                            onChange={e => this.handleChangeFile(e, 'editrelation')}
                                        />{' '}
                                        Edit Relation
                                    </Label>
                                </InputGroup>
                            </div>
                            <div
                                className="column"
                                style={{ marginLeft: '50px', width: '25%' }}
                            >
                                <InputGroup check="true">
                                    <Label check>
                                        <Input
                                            type="checkbox"
                                            name="cbFileExtension"
                                            data-fileextension="*"
                                            onChange={e => this.handleChangeFile(e, 'createfile')}
                                        />{' '}
                                        Create File
                                    </Label>
                                </InputGroup>
                                <InputGroup check="true">
                                    <Label check>
                                        <Input
                                            type="checkbox"
                                            name="cbFileExtension"
                                            data-fileextension="*"
                                            onChange={e => this.handleChangeFile(e, 'createfolder')}
                                        />{' '}
                                        Create Folder
                                    </Label>
                                </InputGroup>
                                <InputGroup check="true">
                                    <Label check>
                                        <Input
                                            type="checkbox"
                                            name="cbFileExtension"
                                            data-fileextension="*"
                                            onChange={e => this.handleChangeFile(e, 'createbox')}
                                        />{' '}
                                        Create Box
                                    </Label>
                                </InputGroup>
                                <InputGroup check="true">
                                    <Label check>
                                        <Input
                                            type="checkbox"
                                            name="cbFileExtension"
                                            data-fileextension="*"
                                            onChange={e =>
                                                this.handleChangeFile(e, 'createrelation')
                                            }
                                        />{' '}
                                        Create Relation
                                    </Label>
                                </InputGroup>
                            </div>
                        </div>
                    </InputGroup>
                </div>
            );
        }
    };

    // setButtonSaveModal = () => {
    //     if (this.props.dataModal.mode === 'rename') {
    //         return <Button onClick={this.renameClick}>Rename</Button>;
    //     } else if (this.props.dataModal.mode === 'add') {
    //         return <Button onClick={this.addClick}>Add</Button>;
    //     } else if (this.props.dataModal.mode === 'edit') {
    //         return <Button onClick={this.editClick}>Edit</Button>;
    //     } else if (this.props.dataModal.mode === 'delete') {
    //         return <Button onClick={this.deleteClick}>Delete</Button>;
    //     }
    // };

    saveClick(event: any) {
        if (this.props.dataModal.mode === 'rename') {
            this.renameClick(event);
        } else if (this.props.dataModal.mode === 'add') {
            this.addClick(event);
        } else if (this.props.dataModal.mode === 'edit') {
            this.editClick(event);
        } else if (this.props.dataModal.mode === 'delete') {
            this.deleteClick(event);
        }
    }

    private actionsClick(type) {
        if (type === 'PostMeta') {
            if (this.state.multiValue !== '') {
                var countValue = [];
                countValue = this.state.multiValue;
                document.getElementsByClassName('Loader__background')[0].className =
                    'Loader__background show';
                this.props.requestFunctionGroup(
                    this.state.version,
                    'POST',
                    this.state.modelMetaDataGroup,
                    countValue
                );
                this.toggleModalMeta('');
            } else {
                this.props.requestFunctionGroup(
                    this.state.version,
                    'POST',
                    this.state.modelMetaDataGroup
                );
                document.getElementsByClassName('Loader__background')[0].className =
                    'Loader__background show';
                this.toggleModalMeta('');
            }
        } else if (type === 'PostRole') {
            this.props.requestFunctionRole(
                this.state.pageLoad,
                this.state.version,
                'POST',
                this.state.modelRoleAccess
            );
            document.getElementsByClassName('Loader__background')[0].className =
                'Loader__background show';
            this.toggleModalMeta('');
        }
    }

    setButtonModalMeta = () => {
        if (this.state.switchPageMetaOrRole === 'Meta') {
            return (
                <Button
                    className=""
                    onClick={() => this.actionsClick('PostMeta')}
                >
                    Add
                </Button>
            );
        } else if (this.state.switchPageMetaOrRole === 'Role') {
            return (
                <Button
                    className=""
                    onClick={() => this.actionsClick('PostRole')}
                >
                    Add
                </Button>
            );
        }
    };

    afterSuccess = () => {
        document.getElementsByClassName('Loader__background')[0].className =
            'Loader__background hide';

        this.props.requestFunctionFolder('CLEAR', this.state.modelFolder);
        // this.props.requestFunction(this.state.pageLoad, 'DEV', '1000', this.state.version, 'GET');

        Swal.fire({
            title: 'Success',
            icon: 'success'
        }).then(result => {
            if (result.isConfirmed) {
                this.toggleModal();
                this.props.afterModalSaveSuccess();
                window.location.reload();
            }
        });
    };

    afterError = () => {
        document.getElementsByClassName('Loader__background')[0].className =
            'Loader__background hide';

        Swal.fire({
            title: 'Error',
            icon: 'error'
        }).then(result => {
            if (result.isConfirmed) {
                this.props.requestFunctionFolder('CLEAR', this.state.modelFolder);
                this.toggleModal();
                this.props.afterModalSaveSuccess();
            }
        });
    };

    getUnique(arr, index) {
        if (arr === undefined || arr === null) {
            return null;
        }

        const unique = arr
            .map(e => e[index])
            .map((e, i, final) => final.indexOf(e) === i && i)
            .filter(e => arr[e])
            .map(e => arr[e]);

        return unique;
    }

    formatDataGet = versionGroup => {
        var valueGetSelect = this.props.responseGroup
            .result_list as Array<ModelsMetaDataGroup.MetaDataGroup>;
        var valueGet = this.props.responseGroup
            .result_list as Array<ModelsMetaDataGroup.MetaDataGroup>;
        var valueSelectMetaGroup: any = [];
        if (this.props.responseGroup !== undefined) {
            var DataSelectArray = this.state.valueSelectMetaGroup;
            for (var i = 0; i < this.props.responseGroup.total_count; i++) {
                let data = {};
                data['value'] = valueGet[i].metadata_group_code;
                data['label'] = valueGet[i].metadata_group_name;
                DataSelectArray.push(data);
            }
            valueSelectMetaGroup = this.getUnique(DataSelectArray, 'value');
            this.setState({ valueSelectMetaGroup: valueSelectMetaGroup });
        }

        this.props.loader.hide();
        valueGet = this.getUnique(valueGet, 'metadata_group_code');

        this.props.requestFunctionGroup(this.state.version, 'CLEAR');
        if (versionGroup === 10) {
            this.setState({ modelListGet: valueGet, modelListGetDetail: valueGetSelect });
        } else {
            this.setState({ modelListGet: valueGet, modelListGetDetail: valueGetSelect });
        }
    };

    toggleModalShare = () => {
        this.setState({
            isOpenModalShare: !this.state.isOpenModalShare
        });
    };

    render() {
        const { isLoading, response, versionGroup, isLoadingGroup, isLoadingRole, isSuccess } =
            this.props;
        if (isLoading) {
            if (response !== undefined) {
                var DataSelectArray = this.state.valueSelect;
                for (var i = 0; i < response.total_count; i++) {
                    let data = {};
                    data['value'] = response.result_list[i].metadata_code;
                    data['label'] = response.result_list[i].metadata_name;
                    DataSelectArray.push(data);
                }
            }
        }
        if (isLoadingRole) {
            this.props.loader.hide();
        }

        return (
            <div id="div-modal-folder-manage">
                <Modal
                    isOpen={this.state.isOpenModal}
                    className="dms-modal"
                >
                    <ModalHeader toggle={this.toggleModal}>
                        {(() => {
                            switch (this.props.dataModal.mode as NAVIGATOR) {
                                case 'rename':
                                    return 'Rename folder ';

                                case 'add':
                                    return 'Add folder in ';

                                case 'edit':
                                    return 'Edit folder in ';

                                case 'delete':
                                    return 'Delete folder ';

                                default:
                                    return '';
                            }
                        })()}
                        <span className="text-[#117a8b]">
                            {this.props.valueCodeAndName?.fullpath}.
                        </span>
                    </ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col-3 text-center">
                                <img
                                    src={FolderImg}
                                    alt="file"
                                    style={{ width: '50%' }}
                                />
                            </div>
                            <div className="col-9">
                                {(() => {
                                    switch (this.props.dataModal.mode as NAVIGATOR) {
                                        case 'rename':
                                            return (
                                                <RenameFolder
                                                    folderName={
                                                        this.state.modelFolder.folder_name ??
                                                        'Unknown'
                                                    }
                                                />
                                            );

                                        case 'add':
                                            return <AddFolder isSave={this.state.isSaveClick} />;

                                        case 'edit':
                                            return (
                                                <EditFolder
                                                    folderName={
                                                        this.state.modelFolder.folder_name ??
                                                        'Unknown'
                                                    }
                                                    parentFolder={this.state.parentFolder}
                                                    folderInfo={this.state.modelFolder}
                                                />
                                            );

                                        case 'delete':
                                            return (
                                                <DeleteFolder
                                                    folderName={
                                                        this.state.modelFolder.folder_name ??
                                                        'Unknown'
                                                    }
                                                />
                                            );

                                        // case 'share':
                                        //     return (
                                        //         <ModalShareFileComponent
                                        //             isFolder={true}
                                        //             nodeSid={this.state.modelFolder.sid}
                                        //             nodeName={this.state.modelFolder.folder_name}
                                        //             nodeCode={this.state.modelFolder.node_code}
                                        //             isOpenModalShare={true}
                                        //             toggleModalShare={() => {}}
                                        //         />
                                        //     );

                                        default:
                                            return <></>;
                                    }
                                })()}
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            className="cursor-pointer rounded-[4px] border border-[#D8D8D8] bg-white pl-4 pr-4 text-[#999999]"
                            onClick={this.toggleModal}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="cursor-pointer rounded-[4px] bg-[#3397C3] pl-4 pr-4 text-white"
                            onClick={this.saveClick.bind(this)}
                        >
                            Save
                        </Button>
                    </ModalFooter>
                </Modal>

                {/* Meta Data */}
                <Modal
                    isOpen={this.state.isOpenModalMeta}
                    className="dms-modal"
                >
                    <ModalHeader
                        className="metadata-group-font"
                        toggle={this.toggleModalMeta}
                    >
                        {this.state.nameMetaOrRole}
                    </ModalHeader>
                    <ModalBody>{this.setBodyModalMeta()}</ModalBody>
                    <ModalFooter>
                        {this.setButtonModalMeta()}
                        <Button
                            color="secondary"
                            className="metadata-group-font"
                            onClick={() => this.toggleModalMeta('clear')}
                        >
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>

                {this.state.isOpenModalShare && (
                    <ModalShareFileComponent
                        isFolder={true}
                        nodeSid={this.state.modelFolder.sid}
                        nodeName={this.state.modelFolder.folder_name}
                        nodeCode={this.state.modelFolder.node_code}
                        isOpenModalShare={this.state.isOpenModalShare}
                        toggleModalShare={this.toggleModalShare.bind(this)}
                    />
                )}
            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => ({
        ...state.folder,
        ...state.metaData,
        ...state.metaDataGroup,
        ...state.roleAccess
    }),
    {
        ...Stores.actionCreators,
        ...StoresMetaData.actionCreators,
        ...StoreMetaDataGroup.actionCreators,
        ...StoresRoleAccess.actionCreators /*...FolderStores.actionCreators*/
    }
)(withLoader(FolderManageMenuNavigate));
